
import { defineComponent, PropType } from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import { PipelineTemplate, Visualizations } from '@/models/customModels';
import { Pipeline } from '@/models';

export default defineComponent({
  name: 'Study Phase Table',
  emits: ['setVizToken'],
  props: {
    id: { type: String, required: true },
    sessionId: { type: String, required: false },
    sessionAction: { type: String, required: false },
    pipelines: { type: Array as PropType<PipelineTemplate[] | Pipeline[]>, required: true },
    passedVisualization: { type: Object as PropType<Visualizations>, required: true },
  },
  data() {
    return {
      loading: false,
      iframeSrc: '',
    };
  },
  mounted() {
    console.log('this.pipelines in visualization :>> ', this.pipelines);
    this.main();
  },
  methods: {
    async main() {
      this.openVisualization(this.pipelines, this.passedVisualization);
      this.createMessageListenerForWhenIframeIsLoaded();
    },
    createMessageListenerForWhenIframeIsLoaded() {
      window.addEventListener('message', this.receiveMessage, false);
    },
    // eslint-disable-next-line no-unused-vars
    async openVisualization(pipelines: PipelineTemplate[] | Pipeline[], visualization: Visualizations): Promise<void> {
      const authToken = await this.getAuthToken(pipelines, this.id);
      this.$emit('setVizToken', { id: this.id, token: authToken });
      // Uncomment this when development finished
      // if (this.sessionId && this.sessionAction) this.iframeSrc = `${visualization.deploymentPath}?token=${authToken}&sessionAction=${this.sessionAction}&sessionId=${this.sessionId}`;
      // else this.iframeSrc = `${visualization.deploymentPath}?token=${authToken}`;
      // this.iframeSrc = `${visualization.deploymentPath}?token=${authToken}&vizMetadataPath=${data.pipeline.vizMetadataPath}`;
      this.iframeSrc = `${visualization.deploymentPath}?token=${authToken}`;
      // this.iframeSrc = `http://localhost:8050/?token=${authToken}`;
      console.log('this.iframeSrc :>> ', this.iframeSrc);
    },
    async getAuthToken(pipelines: PipelineTemplate[] | Pipeline[], vizId: string): Promise<string | null> {
      try {
        const lambdaParams = {
          event: JSON.stringify({
            pipelines,
            bucket: process.env.VUE_APP_DATA_BUCKET,
            vizId,
          }),
        };
        console.log('lambdaParams :>> ', lambdaParams);
        const res = (await API.graphql(graphqlOperation(queries.generatePlotlyJWT, lambdaParams)) as { data: { generatePlotlyJWT: string } }).data.generatePlotlyJWT;
        console.log('res :>> ', res);
        return res;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    receiveMessage(event: any) {
      // Check the origin of the message
      // if (event.origin !== 'http://localhost:8050') return;

      if (event.data.type && (event.data.type === 'FROM_DASH')) {
        const dataToSend = this.makeCSSDataForViz();
        this.sendMessageToViz(dataToSend);
        if (this.sessionAction && this.sessionId) this.sendSessionMessageToViz(this.sessionAction, this.sessionId);
      }
    },
    makeCSSDataForViz(): { messageType: string, [key: string]: any } {
      const cssLinks: any = document.querySelectorAll('link[rel="stylesheet"]');
      const styles: any = document.querySelectorAll('style');
      const dataToSend = {
        messageType: 'injectCSS',
        visualizationId: this.id,
        links: Array.from(cssLinks).map((linkObj: any) => linkObj.href),
        styles: Array.from(styles).map((s: any) => s.innerHTML),
        organizationTag: this.$route.params.organizationName,
      };
      return dataToSend;
    },
    sendSessionMessageToViz(sessionAction: string, sessionId: string) {
      const dataToSend = this.makeSessionDataForViz(sessionAction, sessionId);
      this.sendMessageToViz(dataToSend);
    },
    makeSessionDataForViz(sessionAction: string, sessionId: string): { messageType: string, [key: string]: any } {
      const dataToSend = {
        messageType: sessionAction,
        sessionId,
        visualizationId: this.id,
      };
      return dataToSend;
    },
    sendMessageToViz(dataToSend: { messageType: string, [key: string]: any }) {
      const visIf: any = document.getElementById(`vis-if-${this.id}`);
      visIf.contentWindow.postMessage(dataToSend, '*');
      console.log('dataToSend :>> ', dataToSend);
    },
  },
  watch: {
    sessionId() {
      console.log('SESSION ID in second CHANGED');
      console.log('this.sessionId :>> ', this.sessionId);
      console.log('this.sessionAction :>> ', this.sessionAction);
      if (this.sessionAction && this.sessionId) this.sendSessionMessageToViz(this.sessionAction, this.sessionId);
    },
  },
});
