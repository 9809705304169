import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.template, (groupSchema, groupIndex) => {
      return (_openBlock(), _createElementBlock("div", { key: groupIndex }, [
        (_ctx.makeDivider(groupIndex))
          ? (_openBlock(), _createBlock(_component_Divider, { key: 0 }))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: "add-btn",
          icon: "pi pi-plus",
          onClick: ($event: any) => (_ctx.addRow(groupSchema, groupIndex))
        }, null, 8, ["onClick"]),
        (_ctx.canRemove(groupIndex))
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "remove-btn",
              icon: "pi pi-trash",
              onClick: ($event: any) => (_ctx.removeRow(groupSchema, groupIndex))
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupSchema, (schema, schemaIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: String(groupIndex) + String(schemaIndex),
            class: _normalizeClass((schema.input_type === 'Input') ? 'group-div' : 'group-div-check')
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(schema.input_type), {
              id: schema.id,
              name: schema.name,
              data: _ctx.data,
              value: _ctx.data[schema.id]?.value,
              description: schema.description,
              onInput: ($event: any) => (_ctx.updateData(schema.id, $event, schema)),
              index: schema.id,
              nullable: schema.nullable,
              value_type: schema.value_type_FE
            }, null, 40, ["id", "name", "data", "value", "description", "onInput", "index", "nullable", "value_type"]))
          ], 2))
        }), 128))
      ]))
    }), 128))
  ]))
}