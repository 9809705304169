
import * as AWS from 'aws-sdk';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
// eslint-disable-next-line import/no-extraneous-dependencies
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { listItems, setCredentials, uniqueObjectsByKey } from '@/utils';
import {
  // eslint-disable-next-line no-unused-vars
  ComplexTemplateValidationItem, FEMetadataCROTemplate, MetaDataAllowedValuesCustomTemplate, MetadataCROTemplate, DownloadebleTemplates, AWSConfigCredentialsObj,
} from '@/models/customModels';
import { Auth, API } from 'aws-amplify';
import axios from 'axios';
import {
  MetaDataValidationSchemaTemplate, Phase, Study, StudyPhase,
} from '@/models';
import DynamicCRORenderer from './DynamicCRORenderer/DynamicCRORenderer.vue';

export default defineComponent({
  name: 'CRO',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DynamicCRORenderer,
  },
  data() {
    return {
      loading: false as boolean,
      downloaded: false as boolean,
      selectedStudyPhase: null as unknown as { id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase },
      availableStudyPhases: [] as unknown as { id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[],
      selectedMetadataTemplate: null as unknown as MetaDataAllowedValuesCustomTemplate | null,
      downloadableTemplates: [] as DownloadebleTemplates[],
      selectedDownloadableTemplates: [] as string[],
      data: {} as {[key: string]: any},
      groupMap: {} as { [key: string] : Array<ComplexTemplateValidationItem> },
      mergeMap: {} as {[key: string]: any},
      templateArray: [] as Array<Array<FEMetadataCROTemplate[]>>,
      template: [] as Array<FEMetadataCROTemplate[]>,
      group: null as unknown as string,
      uploadToken: null as unknown as string,
      credentials: null as unknown as AWSConfigCredentialsObj,
    };
  },
  mounted() {
    this.main();
  },
  methods: {
    async main() {
      try {
        this.loading = true;
        let groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        groups = groups.filter((grp) => grp.startsWith('M2MAdmin') || grp.startsWith('ORG/') || grp.startsWith('S/') || grp.startsWith('SP/') || grp.startsWith('CRO/'));
        const groupsRemapped: { id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[] = await this.getGroupNames(groups);
        this.availableStudyPhases = groupsRemapped;
        if (this.availableStudyPhases && this.availableStudyPhases.length === 1) {
          this.selectedStudyPhase = this.availableStudyPhases[0];
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async getGroupNames(groups: string[]): Promise<{ id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[] | []> {
      try {
        const studyPhaseGroups: string[] = [];
        const orgStudyPhaseGroupGroups: string[] = [];
        for (let i = 0; i < groups.length; i += 1) {
          const grp = groups[i];
          if (grp === 'M2MAdmin') return this.getAllStudyPhases();
          if (grp.startsWith('CRO/')) studyPhaseGroups.push(grp);
          if (grp.startsWith('ORG/')) orgStudyPhaseGroupGroups.push(grp);
        }
        const orgGroups = await this.getStudyPhaseGroupsFromOrgNames(orgStudyPhaseGroupGroups);
        const studyPhasesFromCRO = await this.getStudyPhaseGroupsNames(studyPhaseGroups);
        const studyPhases = uniqueObjectsByKey([...orgGroups, ...studyPhasesFromCRO], 'id');
        return studyPhases;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getAllStudyPhases(): Promise<{ id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[] | []> {
      try {
        const allStudyPhases = await listItems(customQueries.listStudyPhasesForCroM2MAdmin, {});
        if (allStudyPhases) return allStudyPhases as unknown as { id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[];
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getStudyPhaseGroupsFromOrgNames(groups: string[]): Promise<{ id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[] | []> {
      try {
        const studyPromises: any[] = [];
        for (let i = 0; i < groups.length; i += 1) {
          const group = groups[i];
          const organizationId = group.split('/')[1];
          studyPromises.push(listItems(customQueries.studiesByOrganizationForUpload, { organizationId }));
        }
        const studies: Study[] = ((await Promise.all(studyPromises)).flat()) as Study[];
        const studyPhasePromises: any[] = [];
        studies.forEach((study) => {
          studyPhasePromises.push(listItems(customQueries.studyPhasesByStudyForUpload, { studyId: study.id })) as unknown as StudyPhase[];
        });
        const groupNames = ((await Promise.all(studyPhasePromises)).flat()) as StudyPhase[];
        if (groupNames) return groupNames as unknown as { id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[];
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getStudyPhaseGroupsNames(groups: string[]): Promise<{ id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[] | []> {
      try {
        const namePromises: any[] = [];
        for (let i = 0; i < groups.length; i += 1) {
          const group = groups[i];
          const studyPhaseId = group.split('/')[1];
          namePromises.push(API.graphql(graphqlOperation(customQueries.getStudyPhaseIdNameSchemaMetadataTemplate, { id: studyPhaseId })));
        }
        const groupNames = await Promise.all(namePromises);
        const groupNamesRemapped = groupNames.map((res) => (res as GraphQLResult<any>).data.getStudyPhase);
        if (groupNames) return groupNamesRemapped as unknown as { id: string, studyPhaseName: string, validationSchema: string | null, metaDataValidationSchemaTemplate: MetaDataValidationSchemaTemplate, phase: Phase }[];
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async parseTemplate(): Promise<void> {
      this.loading = true;
      this.downloaded = true;
      try {
        const promises: any[] = [];
        if (!this.selectedStudyPhase.validationSchema) {
          // eslint-disable-next-line camelcase
          const finalTemplate: { simple_validation?: object, complex_validation?: object} = {};
          if (this.selectedMetadataTemplate && this.selectedMetadataTemplate.validationSchemaTemplate) {
            const validationSchemaTemplateJSON: MetadataCROTemplate = JSON.parse(this.selectedMetadataTemplate.validationSchemaTemplate);
            finalTemplate.simple_validation = validationSchemaTemplateJSON.backend;
            for (const [key, schemaTemplate] of Object.entries(this.data)) {
              if (schemaTemplate.value !== undefined) {
                // eslint-disable-next-line no-continue
                if (schemaTemplate.name === 'Tumor' && schemaTemplate.value === false) continue; // Edge case
                if (!schemaTemplate.nullable && schemaTemplate.value === null) {
                  this.$toast.add({
                    severity: 'error', summary: 'Error', detail: `Field ${schemaTemplate.template_column_name} cannot be null!`, life: 5000,
                  });
                  throw new Error(`Field ${schemaTemplate.template_column_name} was null!`);
                }
                const groupIndex: string = key.split('-')[1];
                if (!(groupIndex in this.groupMap)) {
                  this.groupMap[groupIndex] = [];
                }
                this.formatComplexTemplateValue(schemaTemplate as FEMetadataCROTemplate, groupIndex);
              } else if (!schemaTemplate.nullable) {
                if (!schemaTemplate.nullable && (schemaTemplate.value === null || schemaTemplate.value === undefined)) {
                  this.$toast.add({
                    severity: 'error', summary: 'Error', detail: `Field ${schemaTemplate.template_column_name} cannot be null!`, life: 5000,
                  });
                  throw new Error(`Field ${schemaTemplate.template_column_name} was null!`);
                }
              }
            }
          }
          if (Object.values(this.groupMap).length > 0) {
            finalTemplate.complex_validation = Object.values(this.groupMap);
          }
          if (finalTemplate) promises.push(this.updateStudyPhase(finalTemplate));
        }
        if (this.selectedDownloadableTemplates.length > 0) promises.push(this.downloadTemplates(this.selectedDownloadableTemplates));
        // promises.push(this.downloadCLIScript()); Commented out since this is dead code
        await Promise.all(promises);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async downloadCLIScriptWrapper(pathFromUpload: string): Promise<void> {
      this.loading = true;
      try {
        await this.downloadCLIScript(pathFromUpload);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    downloadTemplate() {
      if (this.selectedDownloadableTemplates.length > 0) this.downloadTemplates(this.selectedDownloadableTemplates);
    },
    // eslint-disable-next-line camelcase
    formatComplexTemplateValue(dataObj: { value: any, template_column_name: string, nullable: string | boolean, name: string, input_type: string, description?: string, to_merge_with?: string }, groupIndex: string): void {
      try {
        if (!dataObj) throw new Error('dataObj is bad in formatComplexTemplateValue');
        // This entry as to merge with another
        if (dataObj.to_merge_with) {
          const indexOfTargetElement = this.groupMap[groupIndex].findIndex((element: ComplexTemplateValidationItem) => element.name === dataObj.to_merge_with);
          // The field hasn't been populated yet, store the value in a map
          if (indexOfTargetElement === -1) {
            this.mergeMap[dataObj.to_merge_with] = dataObj.value;
            return;
          }
          // The required entry field has allready been populated
          this.groupMap[groupIndex][indexOfTargetElement].value = `${this.groupMap[groupIndex][indexOfTargetElement].value} ${dataObj.value}`;
          return;
        }
        let formatedObj: ComplexTemplateValidationItem = {
          template_column_name: dataObj.template_column_name,
          nullable: dataObj.nullable as boolean,
          value: dataObj.value,
          name: dataObj.name,
        };
        formatedObj = this.checkForSpecialCases(formatedObj);
        // Check if this name is a target of a merge
        if (this.mergeMap[dataObj.name]) {
          formatedObj.value = `${formatedObj.value}${this.mergeMap[dataObj.name]}`;
          delete this.mergeMap[dataObj.name];
        }
        if (this.groupMap[groupIndex]) {
          this.groupMap[groupIndex]?.push(formatedObj);
        } else {
          this.groupMap[groupIndex] = [formatedObj];
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkForSpecialCases(formatedObj: ComplexTemplateValidationItem): ComplexTemplateValidationItem {
      try {
        const objToChange: ComplexTemplateValidationItem = formatedObj;
        if (objToChange.name === 'Tumor' && objToChange.value === true) {
          objToChange.value = '*';
          objToChange.nullable = false;
        }
        return objToChange;
      } catch (error) {
        console.error(error);
        return formatedObj;
      }
    },
    async downloadCLIScript(pathFromUpload: string): Promise<void> {
      try {
        const credentials: AWSConfigCredentialsObj | null = (this.credentials) ? this.credentials : await this.getCredentials();
        if (!credentials) throw new Error('Credentials are null');
        AWS.config.update(credentials);
        const s3 = new AWS.S3();
        if (!process.env.VUE_APP_MASTER_BUCKET) throw new Error('Bucket unavailable');
        const bucket: string = process.env.VUE_APP_MASTER_BUCKET;
        // const path = `s3://${bucket}/code/upload/scripts/data-upload.exe`;
        const path = `s3://${bucket}/code/upload/scripts/${pathFromUpload}`;
        const params = {
          Bucket: bucket,
          Key: path.split(bucket)[1].substring(1, path.split(bucket)[1].length),
        };
        console.log('params :>> ', params);
        const url = s3.getSignedUrl('getObject', params);
        axios({
          url, // File URL Goes Here
          method: 'GET',
          responseType: 'blob',
        }).then((res: any) => {
          const FILE = window.URL.createObjectURL(new Blob([res.data]));
          const docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', this.getFileName(path));
          document.body.appendChild(docUrl);
          docUrl.click();
        });
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Upload app download initiated!', life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to download data upload app!', life: 5000,
        });
      }
    },
    async downloadTemplates(s3Paths: string[]): Promise<void> {
      try {
        const credentials: AWSConfigCredentialsObj | null = (this.credentials) ? this.credentials : await this.getCredentials();
        if (!credentials) throw new Error('Credentials are null');
        AWS.config.update(credentials);
        const s3 = new AWS.S3();
        if (!process.env.VUE_APP_MASTER_BUCKET) throw new Error('Bucket unavailable');
        const bucket: string = process.env.VUE_APP_MASTER_BUCKET;
        for (let i = 0; i < s3Paths.length; i += 1) {
          const path = s3Paths[i];
          const params = {
            Bucket: bucket,
            Key: path.split(bucket)[1].substring(1, path.split(bucket)[1].length),
          };
          const url = s3.getSignedUrl('getObject', params);
          axios({
            url, // File URL Goes Here
            method: 'GET',
            responseType: 'blob',
          }).then((res: any) => {
            const FILE = window.URL.createObjectURL(new Blob([res.data]));
            const docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', this.getFileName(path));
            document.body.appendChild(docUrl);
            docUrl.click();
          });
        }
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Template download initiated!', life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to download templates!', life: 5000,
        });
      }
    },
    async getCredentials(): Promise<AWSConfigCredentialsObj | null> {
      try {
        const groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        console.log('groups :>> ', groups);
        if (this.$store.state.precedenceLevel === 1) {
          const credentials = await setCredentials('M2MAdmin');
          if (credentials) {
            this.group = 'M2MAdmin';
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        if (this.selectedStudyPhase && groups.some((grp) => grp === `CRO/${this.selectedStudyPhase.id}`)) {
          const credentials = await setCredentials(`CRO/${this.selectedStudyPhase.id}`);
          if (credentials) {
            this.group = `CRO/${this.selectedStudyPhase.id}`;
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        } else if (!this.selectedStudyPhase && groups.every((grp) => grp.startsWith('CRO/'))) {
          const credentials = await setCredentials(groups[0]);
          if (credentials) {
            this.group = groups[0];
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        const orgId = this.$route.params.organizationId;
        if (orgId) {
          const credentials = await setCredentials(`ORG/${orgId}/Admin`);
          if (credentials) {
            this.group = `ORG/${orgId}/Admin`;
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    copyUploadToken(textToCopy: string): void {
      navigator.clipboard.writeText(textToCopy).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Token copied successfully!',
          life: 5000,
        });
      }, (err) => {
        console.log(err);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to copy token!',
          life: 5000,
        });
      });
    },
    getFileName(path: string): string {
      try {
        const split = path.split('/');
        if (split && split.at(-1) && split.at(-1) !== undefined) return split.at(-1) as string;
        return path;
      } catch (error) {
        console.error(error);
        return path;
      }
    },
    // eslint-disable-next-line camelcase
    async updateStudyPhase(finalTemplate: { simple_validation?: object, complex_validation?: object}) {
      try {
        const updateObject = {
          id: this.selectedStudyPhase.id,
          validationSchema: JSON.stringify(finalTemplate),
        };
        API.graphql(graphqlOperation(mutations.updateStudyPhase, { input: updateObject }));
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'StudyPhase updated initiated!', life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update study phase!', life: 5000,
        });
      }
    },
    updateData({ name, value, schema }: {name: string, value: any, schema: any}) {
      const dataWithValue = schema;
      dataWithValue.value = value;
      this.data[name as keyof Object] = schema;
    },
    async makeUploadToken() {
      // eslint-disable-next-line global-require, import/no-extraneous-dependencies
      const CryptoJS = require('crypto-js');
      const secret = process.env.VUE_APP_UPLOAD_TOKEN_SECRET;
      const header = {
        alg: 'HS256',
        typ: 'JWT',
      };

      const claims = {
        studyPhaseId: this.selectedStudyPhase.id,
        phase: this.selectedStudyPhase.phase,
        group: this.group,
        exp: Date.now() + 86400000, // 24 hours
      };
      const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
      const encodedHeader = this.base64url(stringifiedHeader);

      const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(claims));
      const encodedData = this.base64url(stringifiedData);

      const token = `${encodedHeader}.${encodedData}`;

      let signature = CryptoJS.HmacSHA256(token, secret);
      signature = this.base64url(signature);

      // eslint-disable-next-line no-unused-vars
      const signedToken = `${token}.${signature}`;
      this.uploadToken = signedToken;
    },
    base64url(source: string) {
      // eslint-disable-next-line global-require, import/no-extraneous-dependencies
      const CryptoJS = require('crypto-js');
      let encodedSource = '';

      // Encode in classical base64
      encodedSource = CryptoJS.enc.Base64.stringify(source);

      // Remove padding equal characters
      encodedSource = encodedSource.replace(/=+$/, '');

      // Replace characters according to base64url specifications
      encodedSource = encodedSource.replace(/\+/g, '-');
      encodedSource = encodedSource.replace(/\//g, '_');

      return encodedSource;
    },
  },
  watch: {
    selectedStudyPhase: {
      async handler() {
        if (this.selectedStudyPhase) {
          if (!this.selectedStudyPhase) {
            this.loading = false;
            return;
          }
          this.selectedMetadataTemplate = this.selectedStudyPhase.metaDataValidationSchemaTemplate;
          if (this.selectedMetadataTemplate.templateFiles) {
            const templateFilesJson = JSON.parse(this.selectedMetadataTemplate.templateFiles);
            this.downloadableTemplates = templateFilesJson.templates;
          }
          await this.getCredentials();
          await this.makeUploadToken();
          if (this.selectedStudyPhase.validationSchema) {
            console.log('Has schema');
            this.selectedMetadataTemplate = null;
            this.template = [];
            this.data = {};
            this.loading = false;
            return;
          }
          // setCredentials(`CRO/${this.selectedStudyPhase.id}`);
          if (this.selectedMetadataTemplate.validationSchemaTemplate) {
            const fullTemplate: MetadataCROTemplate = JSON.parse(this.selectedMetadataTemplate.validationSchemaTemplate);
            // Give each element an id. Each schema will have and id in the format of name-i-j where i and j behave like a matrix. i is the row index and j is the element in each row
            if (fullTemplate.front_end) {
              for (let i = 0; i < fullTemplate.front_end.length; i += 1) {
                const templateGroup = fullTemplate.front_end[i];
                for (let j = 0; j < templateGroup.length; j += 1) {
                  const schema = templateGroup[j];
                  schema.id = `${schema.name}-${i}-${j}`;
                  this.data[schema.id as keyof Object] = schema;
                }
              }
              this.template = fullTemplate.front_end;
            }
          }
          this.loading = false;
        }
      },
    },
  },
});
