import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.pipelineParameters,
    paginator: true,
    responsiveLayout: "scroll",
    ref: "dt",
    paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
    currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
    rows: 10,
    rowsPerPageOptions: [10,20,50],
    loading: _ctx.loading
  }, {
    empty: _withCtx(() => [
      _createTextVNode(" No parameters found. ")
    ]),
    loading: _withCtx(() => [
      _createTextVNode(" Loading parameters... Please wait. ")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "header",
        header: "Parameter Name"
      }),
      _createVNode(_component_Column, {
        field: "field",
        header: "Parameter Value"
      })
    ]),
    _: 1
  }, 8, ["value", "loading"]))
}