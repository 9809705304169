
import { defineComponent } from 'vue';
import {
  StudyPhase, Sample, Batch, Pipeline,
} from '@/models';
import * as customQueries from '@/graphql/customQueries';
import * as queries from '@/graphql/queries';
import {
  formatISOStringCustom, eventTodeleteS3Data, sendNotificationEmail, formatListForView, listItems, camelCaseToWords, assumeCredentials,
} from '@/utils';
import { graphqlOperation, API } from 'aws-amplify';
import {
  DelBackupItem, MetadataGeneralTemplate, QCoptions, dataTypeOptions, ImmunopeptidomicsTemplate, AWSConfigCredentialsObj, // MetadataRnaSeqTemplate, MetadataNanoStringTemplate
} from '@/models/customModels';
import * as mutations from '@/graphql/mutations';
import { FilterMatchMode } from 'primevue/api';
import SamplesDialog from '@/components/Samples/SamplesDialog.vue';
import SamplesReportDialog from '@/components/Samples/SamplesReportDialog.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';

export default defineComponent({
  name: 'Samples Table',
  emits: ['setBreadcrumbItems', 'updateMetadataGeneralTemplate'],
  components: {
    SamplesDialog,
    SamplesReportDialog,
  },
  props: {
    username: { type: String, required: false },
    email: { type: String, required: false },
  },
  data() {
    return {
      itemOptions: [{
        label: 'Request samples deletion',
        precedenceLevel: 5,
        command: () => {
          this.requestDeletion();
        },
      }, {
        label: 'Delete samples',
        precedenceLevel: 4,
        command: () => {
          this.deleteSamples();
        },
      }] as DelBackupItem[],
      qcStatusOptions: [{ name: 'Failed', value: 'FAILED' },
        { name: 'Passed', value: 'PASSED' }],
      sampleTypeOptions: [{
        name: 'RnaSeq', value: 'rnaseq', key: 'MetadataRnaSeqList', mutation: 'updateMetadataRnaSeq',
      },
      {
        name: 'Immunopeptidomics', value: 'general', key: 'metadataImmunopeptidomicsList', mutation: 'updateMetadataImmunopeptidomics',
      },
      {
        name: 'Nanostring', value: 'nanostring', key: 'MetadataNanoStringList', mutation: 'updateMetadataNanoString',
      }],
      restoreOptions: [] as any,
      displayESVOptions: [] as any,
      selectedESV: [] as any,
      setQcStatus: null as unknown as QCoptions | null,
      dataType: null as unknown as dataTypeOptions | null,
      selectedSamples: [] as any,
      studyPhases: [] as StudyPhase[],
      loading: false,
      samples: [] as Sample[],
      samplesDeleted: [] as Sample[],
      studyId: '',
      studyPhaseId: this.$route.params.studyPhaseId,
      studyPhaseSamples: null as unknown as any,
      dialogVisible: false,
      tableVisible: false,
      s3pathsUnassembled: null as unknown as any,
      barcodeS3Path: null as unknown as any,
      barcodeMetadataIds: null as unknown as any,
      deletedSamples: [] as any,
      filters: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        barcode: { value: '', matchMode: FilterMatchMode.CONTAINS },
      },
      notificationInfo: {} as any,
      checked: true,
      sampleMetadata: null as any,
      parsedValidationSchema: [] as any,
      columnsMetadata: [] as any,
      metadataAnalysis: '' as string,
      expSpecVariables: {} as any,
      displayQcFunctionality: true as boolean,
      displayColumns: [] as any,
      studyESV: {} as any,
      filterColumns: ['id', 'glacierBackup', 'created', 'rawDataS3', 'isControl', 'barcodesMappedToControl',
        'barcode', 'domainSampleId', 'metaDataTemplateS3Path', 's3Path',
        'qcStatus', 'sampleId', 'experimentSpecificVariables', 'dataType'] as any,
      sampleUuidBarcode: {} as any,
      pipelinesData: {} as any,
      singleCompletedPipeline: false as boolean,
      bucket: process.env.VUE_APP_DATA_BUCKET,
      credentials: null as unknown as AWSConfigCredentialsObj,
      batch: null as unknown as Batch,
    };
  },
  beforeMount() {
    if (this.$store.state.precedenceLevel > 3) {
      this.itemOptions = [];
    } else if (this.$store.state.precedenceLevel === 3) {
      this.itemOptions = this.itemOptions.slice(0, 1);
    }
  },
  async mounted() {
    if (this.$route.params.origin[0] === 'studyPhase') {
      const studyPhaseId: string = this.$route.params.origin[1];
      this.credentials = await this.setCredentialForSamplesTable(studyPhaseId, null) as AWSConfigCredentialsObj;
      this.loadNotificationInfo(studyPhaseId);
      await this.checkForCompletedPipelineFromStudyPhase(studyPhaseId);
      if (this.$store.state.precedenceLevel < 5) {
        await this.checkForCompletedPipelineFromStudy(this.studyId);
      }
    } else if (this.$route.params.origin[0] === 'batch') {
      this.batch = (await API.graphql(graphqlOperation(customQueries.getBatchForSamplesTable, { id: this.$route.params.origin[1] })) as any).data.getBatch;
      if (!this.batch) {
        console.error('Batch is null in getBatchForSamplesTable');
        return;
      }
      this.credentials = await this.setCredentialForSamplesTable(null, this.batch) as AWSConfigCredentialsObj;
      this.checkForCompletedPipelineFromBatch(this.batch.id);
      this.notificationInfo.notificationEmail = this.batch?.study?.organization?.notificationEmail;
      this.$emit('setBreadcrumbItems', this.batch?.study?.studyName, this.batch?.study?.id, (this.batch?.studyPhase) ? 'Study Phase' : 'Study', (this.batch.studyPhase) ? this.batch?.studyPhase?.studyPhaseName : this.batch?.batchName, this.batch?.studyPhase?.id, this.batch?.batchName);
      if (this.batch.studyPhaseId) {
        this.notificationInfo.notificationStudyPhaseName = this.batch?.studyPhase?.studyPhaseName;
        this.studyESV = JSON.parse(this.batch?.studyPhase?.experimentSpecificVariables as string);
      } else {
        this.notificationInfo.notificationStudyPhaseName = this.batch?.study?.studyName;
        this.studyESV = {};
      }
    }
  },
  methods: {
    async setCredentialForSamplesTable(studyPhaseId: string | null, batch: Batch | null): Promise<AWSConfigCredentialsObj | null> {
      if (studyPhaseId) return this.setCredentialsForSamplesTableIfOpenedFromStudyPhase(studyPhaseId);
      if (batch) return this.setCredentialsForSamplesTableIfOpenedFromBatch(batch);
      throw new Error('Both studyPhaseId and batch are null in setCredentialForSamplesTable');
    },
    async setCredentialsForSamplesTableIfOpenedFromStudyPhase(studyPhaseId: string): Promise<AWSConfigCredentialsObj | null> {
      const studyPhaseForParentIds: { id: string; study: { id: string; organization: { id: string; }; }; } | null = await this.getStudyPhaseForParentIds(studyPhaseId);
      if (studyPhaseForParentIds) {
        return assumeCredentials(this.$store.state.precedenceLevel, studyPhaseForParentIds.study.organization.id, studyPhaseForParentIds.study.id, studyPhaseForParentIds.id);
      }
      return null;
    },
    async setCredentialsForSamplesTableIfOpenedFromBatch(batch: Batch): Promise<AWSConfigCredentialsObj | null> {
      if (batch && batch.study && batch.study.organization) {
        return await assumeCredentials(this.$store.state.precedenceLevel, batch!.study!.organization.id, batch.study.id, (batch.studyPhase) ? batch?.studyPhase.id : null) as AWSConfigCredentialsObj;
      }
      return null;
    },
    async getStudyPhaseForParentIds(studyPhaseId: string): Promise<{ id: string; study: { id: string; organization: { id: string; }; }; } | null> {
      try {
        const res = await API.graphql(graphqlOperation(customQueries.studyPhaseForParentIds, { id: studyPhaseId }));
        return (res as any).data.getStudyPhase;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async loadNotificationInfo(id: string) {
      const studyPhaseForOrganizationEmail: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.organisationEmailBystudyPhaseForSamplesBrowse, { id }));
      const data: { getStudyPhase: { study: { organization: { notificationEmail: string }, id: String, studyName: string }, studyPhaseName: string, experimentSpecificVariables: string } } | undefined = ('data' in studyPhaseForOrganizationEmail) ? studyPhaseForOrganizationEmail.data : undefined;
      console.log('data :>> ', data);
      if (!data) throw new Error('Data is undeinfed from api call in listItems');
      this.notificationInfo.notificationEmail = data.getStudyPhase.study.organization.notificationEmail;
      // this.$route, studyName, studyId, batchParentEntityType, batchParentEntityName, studyPhaseId
      this.$emit('setBreadcrumbItems', data.getStudyPhase.study.studyName, data.getStudyPhase.study.id, null, data.getStudyPhase.studyPhaseName, null);
      this.notificationInfo.notificationStudyPhaseName = data.getStudyPhase.studyPhaseName;
      this.studyESV = JSON.parse(data.getStudyPhase.experimentSpecificVariables);
    },
    async loadSamplesOfDataType() {
      try {
        if (this.$route.params.origin[0] === 'studyPhase') {
          const studyPhaseId: string = this.$route.params.origin[1];
          this.parseValidationSchema(studyPhaseId, this.dataType);
          this.loadSamplesForStudyPhase(studyPhaseId, this.dataType);
          this.getS3Path(studyPhaseId, this.dataType);
        } else if (this.$route.params.origin[0] === 'batch') {
          this.tableVisible = true;
          this.loading = true;
          console.log('With batch');
          const batchAnnotations = JSON.parse(this.batch.batchAnnotations as string);
          console.log('batchAnnotations :>> ', batchAnnotations);
          console.log('this.dataType :>> ', this.dataType);
          const samplePromises: any[] = [];
          batchAnnotations.forEach((sample: any) => {
            samplePromises.push(API.graphql(graphqlOperation(customQueries.getSampleForSamplesTableFromBatch, { id: sample.id })));
          });
          const spSamples = (await Promise.all(samplePromises)).map((metadataRes: any) => metadataRes.data.getSample);
          this.processSamples(spSamples, this.dataType);
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    processSamples(spSamples: Sample[], dataType: any): void {
      try {
        const samplesS3: any[] = [];
        const deletedSamples: any[] = [];
        const samplesDeleted: any[] = [];
        const sampleUuidBarcode = {} as any;
        const barcodeS3Path = {} as any;
        // spSamples = (await Promise.all(samplePromises)).map((metadataRes: any) => metadataRes.data.getSample);
        console.log('spSamples :>> ', spSamples);
        spSamples.forEach((sample: any) => {
          const metadata = sample[(dataType as any)?.key].items;
          const sampleUuid = sample.id;
          sampleUuidBarcode[sampleUuid] = [];
          for (let i = 0; i < metadata.length; i += 1) {
            sampleUuidBarcode[sampleUuid].push([sample.domainSampleId, metadata[i].barcode]);
            // metadata[i].barcode = sample.barcode;
            // metadata[i].barcode = metadata[i].barcode;
            metadata[i].domainSampleId = sample.domainSampleId;
            metadata[i].experimentSpecificVariables = sample.experimentSpecificVariables;
            metadata[i].dataType = (dataType as any).value;
            // barcodeS3Path[metadata[i].barcode] = metadata[i].s3Path[0];
            const barcodeKey = [sample.domainSampleId, metadata[i].barcode].join(',');
            const s3path = metadata[i].s3Path[0].split('/');
            barcodeS3Path[barcodeKey] = s3path.slice(3, -1).join('/');

            if (sample.experimentSpecificVariables) {
              const parsedEsv = JSON.parse(sample.experimentSpecificVariables);
              // eslint-disable-next-line no-unused-vars
              for (const [key, value] of Object.entries(parsedEsv)) {
                this.filterColumns.push(key);
                metadata[i][key] = parsedEsv[key].value;
              }
            }
            if (metadata[i].rawDataS3 === true) {
              samplesS3.push(metadata[i]);
            } else if (metadata[i].qcStatus === 'PASSED') {
              deletedSamples.push(metadata[i].barcode);
              this.restoreOptions.push({ name: metadata[i].barcode, value: metadata[i].barcode, id: metadata[i].id });
              samplesDeleted.push(metadata[i]);
            }
          }
        });
        const barcodeMetadataIds: any = {};
        samplesS3.forEach((element) => {
          const key = [element.domainSampleId, element.barcode].join(',');
          if (key in barcodeMetadataIds) {
            const list = barcodeMetadataIds[key];
            list.push(element.id);
            barcodeMetadataIds[key] = list;
          } else {
            const list = [];
            list.push(element.id);
            barcodeMetadataIds[key] = list;
          }
        });
        console.log('S3 PATHS metadata', barcodeS3Path);
        this.barcodeS3Path = barcodeS3Path;

        this.sampleUuidBarcode = sampleUuidBarcode;
        this.barcodeMetadataIds = barcodeMetadataIds;
        this.samples = samplesS3;
        this.samplesDeleted = samplesDeleted;
        this.deletedSamples = deletedSamples;
        console.log('this.samples :>> ', this.samples);
        this.subsetColumns();
      } catch (error) {
        console.error(error);
      }
    },
    subsetColumns() {
      const displayColumns = [] as any;
      if (this.samples.length === 0) {
        console.log('No samples');
        this.displayColumns = [];
        return;
      }
      const allColumns = Object.keys(this.samples[0]);
      for (let i = 0; i < allColumns.length; i += 1) {
        if (this.filterColumns.indexOf(allColumns[i]) === -1) {
          displayColumns.push(allColumns[i]);
        }
      }
      // }
      this.displayColumns = displayColumns;
      console.log(this.displayColumns);
    },
    async parseValidationSchema(id: string, dataType: any): Promise<void> {
      this.parsedValidationSchema = [];
      try {
        let validationSchema = [];
        const allColumns = [];
        const studyPhaseResult = await API.graphql(graphqlOperation(queries.getStudyPhase, { id }));
        const studyPhase = ('data' in studyPhaseResult) ? studyPhaseResult.data : undefined;
        if (!studyPhase) throw new Error('Data is undeinfed from api call in listItems');
        validationSchema = JSON.parse(studyPhase.getStudyPhase.metaDataValidationSchemaTemplate.validationSchemaTemplate).simple_validation;
        type ObjectKey = keyof typeof validationSchema;
        const analysisType = dataType.value as ObjectKey;
        allColumns.push(...validationSchema.general);
        if (analysisType !== 'general') {
          allColumns.push(...validationSchema[analysisType]);
        }
        this.metadataAnalysis = validationSchema[analysisType][1].db_table;
        allColumns.forEach((element) => {
          if (element.mutable === 'true') {
            this.parsedValidationSchema.push(element);
          }
        });
        this.expSpecVariables = JSON.parse(studyPhase.getStudyPhase.experimentSpecificVariables);
        console.log(this.expSpecVariables);
        this.expSpecVariables.forEach((esv: any) => {
          this.displayESVOptions.push({ name: esv.name, value: esv.name });
        });
      } catch (error) {
        console.error(error);
      }
    },
    async loadSamplesForStudyPhase(studyPhaseId: string, dataType: any): Promise<void> {
      this.loading = true;
      this.tableVisible = true;
      try {
        const spSamples = await listItems(customQueries.samplesBystudyPhaseForSamplesTable, { studyPhaseId });
        this.processSamples(spSamples as Sample[], dataType);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async checkForCompletedPipelineFromStudyPhase(id: string): Promise<void> {
      try {
        let pipelinesData = null as unknown as any;
        pipelinesData = await API.graphql(graphqlOperation(customQueries.studyPhaseForSampleReportDialog, { id }));
        this.studyId = pipelinesData.data.getStudyPhase.study.id;
        console.log('pipelinesData :>> ', pipelinesData);
        this.singleCompletedPipeline = this.isThereAtLeastOneCompletedPipeline(pipelinesData.data.getStudyPhase.pipelines.items as Pipeline[]);
      } catch (error) {
        console.error(error);
      }
    },
    async checkForCompletedPipelineFromStudy(id: string): Promise<void> {
      try {
        let pipelinesData = null as unknown as any;
        pipelinesData = await API.graphql(graphqlOperation(customQueries.studyForSampleReportDialog, { id }));
        console.log('pipelinesData :>> ', pipelinesData);
        this.singleCompletedPipeline = this.isThereAtLeastOneCompletedPipeline(pipelinesData.data.getStudy.pipelines.items as Pipeline[]);
      } catch (error) {
        console.error(error);
      }
    },
    async checkForCompletedPipelineFromBatch(batchId: string): Promise<void> {
      try {
        let pipelinesData = null as unknown as any;
        pipelinesData = await API.graphql(graphqlOperation(customQueries.pipelinesByBatchForSamplesTableCheck, { batchId }));
        console.log('pipelinesData :>> ', pipelinesData);
        this.singleCompletedPipeline = this.isThereAtLeastOneCompletedPipeline(pipelinesData.data.pipelinesByBatch.items as Pipeline[]);
        console.log('this.singleCompletedPipeline :>> ', this.singleCompletedPipeline);
      } catch (error) {
        console.error(error);
      }
    },
    isThereAtLeastOneCompletedPipeline(pipelinesData: Pipeline[]): boolean {
      return pipelinesData.some((pipeline: Pipeline) => pipeline.status === 'COMPLETED' || pipeline.status === 'FINISHED');
    },
    // checkIfExistsAtLeastOneCompletedPipeline()
    async getS3Path(studyPhaseId: string, dataType: any): Promise<void> {
      let data = null as unknown as any;
      try {
        this.s3pathsUnassembled = await API.graphql(graphqlOperation(customQueries.samplePathByStudyPhaseForSamplesBrowse, { id: studyPhaseId }));
        data = ('data' in this.s3pathsUnassembled) ? this.s3pathsUnassembled.data : undefined;
        if (!data) throw new Error('Data is undeinfed from api call in listItems');
      } catch (error) {
        console.error(error);
      }
      const barcodeS3Path: any = {};
      data.samplesByStudyPhase.items.forEach((element: any) => {
        const baseS3 = [element.StudyPhase.study.organization.id,
          element.StudyPhase.study.id,
          element.StudyPhase.id].join('/');
        const keys = this.sampleUuidBarcode[element.id];
        keys.forEach((key: string) => {
          const barcode = String(key).split(',')[1];
          if (element.StudyPhase.phase === 'PRE_CLINICAL') {
            const preClinPath = [element.id, `${dataType.value}`].join('/');
            barcodeS3Path[key] = [baseS3, preClinPath, `${barcode}/`].join('/');
          } else {
            const clinPath = [element.timepoint.patient.group.id,
              element.timepoint.patient.id,
              element.timepoint.id,
              element.id,
              `${dataType.value}`].join('/');
            barcodeS3Path[key] = [baseS3, clinPath, `${barcode}/`].join('/');
          }
        });
      });
      console.log('S3 PATHS front', barcodeS3Path);
      // this.barcodeS3Path = barcodeS3Path;
    },
    async qcStatusMutation(metadataSample: any, sampleQcStatus: any, dataType: any): Promise<void> {
      const updateMetadataGeneralTemplate: MetadataGeneralTemplate = {};
      updateMetadataGeneralTemplate.id = metadataSample.id;
      updateMetadataGeneralTemplate.qcStatus = sampleQcStatus.value;
      // updateMetadataGeneralTemplate.qcStatus = 'AWAITING';
      let updatedMetadataGeneral;
      try {
        if (dataType.value === 'rnaseq') {
          updatedMetadataGeneral = await API.graphql(graphqlOperation(mutations.updateMetadataRnaSeq, { input: updateMetadataGeneralTemplate }));
        } else if (dataType.value === 'nanostring') {
          updatedMetadataGeneral = await API.graphql(graphqlOperation(mutations.updateMetadataNanoString, { input: updateMetadataGeneralTemplate }));
        } else {
          updatedMetadataGeneral = await API.graphql(graphqlOperation(mutations.updateMetadataImmunopeptidomics, { input: updateMetadataGeneralTemplate }));
        }
        this.$emit('updateMetadataGeneralTemplate', updatedMetadataGeneral);
        for (let i = 0; i < this.samples.length; i += 1) {
          let hold: any = {};
          if (metadataSample.id === this.samples[i].id) {
            hold = this.samples[i];
            hold.qcStatus = sampleQcStatus.value;
            this.samples[i] = hold;
          }
        }
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: `Sample ${metadataSample.barcode} QC status updated successfuly!`, life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update Samples QC status!', life: 5000,
        });
      }
    },
    async backupSample(metadataSample: any, setQcStatus: any, dataType: any): Promise<void> {
      const samplesToBackup: any = {};
      const barcodeIds: any = {};
      const key = [metadataSample.domainSampleId, metadataSample.barcode].join(',');
      samplesToBackup[key] = this.barcodeS3Path[key];
      barcodeIds[key] = this.barcodeMetadataIds[key];
      if (setQcStatus.value === 'PASSED') {
        try {
          eventTodeleteS3Data('backup_files', samplesToBackup, barcodeIds, this.notificationInfo, dataType.mutation);
          console.log('event added');
        } catch (error) {
          console.error(error);
        }
      }
    },
    async changeStatus(): Promise<void> {
      this.selectedSamples.forEach((sample: any) => {
        let esvStatus = true;
        if (!this.studyESV) {
          esvStatus = true;
        } else if (Object.keys(this.studyESV).length !== 0) {
          if (sample.experimentSpecificVariables === null) {
            esvStatus = false;
          } else {
            const expSpecVar = JSON.parse(sample.experimentSpecificVariables);
            this.expSpecVariables.forEach((variable: any) => {
              type ObjectKey = keyof typeof expSpecVar;
              const name = variable.name as keyof ObjectKey;
              if (expSpecVar[name].value === undefined) {
                console.log('undefined', name);
                esvStatus = false;
              }
              if (expSpecVar[name].value === '' || expSpecVar[name].value === null) {
                console.log('not added');
                esvStatus = false;
              }
            });
          }
        } else {
          esvStatus = true;
        }
        if (sample.qcStatus === 'AWAITING') {
          if (esvStatus) {
            this.qcStatusMutation(sample, this.setQcStatus, this.dataType);
            this.backupSample(sample, this.setQcStatus, this.dataType);
            console.log('UPDATED');
          } else {
            this.$toast.add({
              severity: 'warn', summary: 'Warning', detail: 'Sample Experiment Specific variables need to be defined!', life: 5000,
            });
          }
        } else {
          this.$toast.add({
            severity: 'warn', summary: 'Warning', detail: `Sample ${sample.barcode} QC status is already set!`, life: 5000,
          });
        }
      });
    },
    async restoreSamples(dataType: any): Promise<void> {
      console.log('restore');
      // console.log(this.restoreSelectedSamples);
      this.selectedSamples.forEach((sample: any) => {
        const key = [sample.domainSampleId, sample.barcode].join(',');
        const s3Path: any = {};
        const barcodeIds: any = {};
        s3Path[key] = this.barcodeS3Path[key];
        barcodeIds[key] = sample.id;
        console.log(barcodeIds);
        console.log(s3Path);
        try {
          eventTodeleteS3Data('restore_files', s3Path, barcodeIds, this.notificationInfo, dataType.mutation);
          this.$toast.add({
            severity: 'success', summary: 'Success', detail: `Sample ${sample.value} S3 raw data is being restored! \n You will recieve email notification when restored!`, life: 6000,
          });
        } catch (error) {
          console.error(error);
        }
      });
    },
    async deleteSamples(): Promise<void> {
      try {
        const noBackup: any = [];
        const samplesToDelete: any = {};
        const barcodeIds: any = {};
        const ids: any = [];
        let message: string = '';
        this.selectedSamples.forEach((sample: any) => {
          console.log('sample :>> ', sample);
          const key = [sample.domainSampleId, sample.barcode].join(',');

          ids.push(sample.id);
          if (sample.glacierBackup === false) {
            noBackup.push(sample.barcode);
          }
          if (sample.rawDataS3 === true) {
            samplesToDelete[key] = this.barcodeS3Path[key];
            barcodeIds[key] = this.barcodeMetadataIds[key];
          }
        });
        if (noBackup.length === 0) {
          message = 'Are you sure you want to proceed with raw data deletion?\n';
        } else {
          message = `Samples with barcodes: ${noBackup} are not backed up!\n Are you sure you want to proceed with raw data deletion?\n`;
        }
        this.warning(message, 'delete_files', samplesToDelete, barcodeIds, this.dataType, ids);
        console.log(this.selectedSamples);
      } catch (error) {
        console.error(error);
      }
    },
    warning(message: string, flagString: any, samplesDict: any, barcodeIds: any, dataType: any, ids: []) {
      this.$confirm.require({
        message: `${message}`,
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: async () => {
          console.log(samplesDict);
          eventTodeleteS3Data(flagString, samplesDict, barcodeIds, this.notificationInfo, dataType.mutation);
          ids.forEach((idDelete: any) => {
            this.samples = this.samples.filter((sp: { id: any; }) => sp.id !== idDelete);
          });
          if (this.dialogVisible) this.dialogVisible = false;
        },
        reject: () => {
          if (this.dialogVisible) this.dialogVisible = false;
        },
      });
    },
    async sendRequestEmail(action: string): Promise<void> {
      try {
        const deleteRequest: any = [];
        this.selectedSamples.forEach((element: any) => {
          deleteRequest.push(element.barcode);
        });
        const spBarcodes = deleteRequest.join(', ');
        console.log('barcodes', deleteRequest);

        this.$toast.add({
          severity: 'success', summary: 'Success', detail: `${action} of samples request has been sent!`, life: 5000,
        });
        const userContent: any = `Study phase name: "${this.notificationInfo.notificationStudyPhaseName}".\n${action} of samples is requested.\nBarcodes:\n${spBarcodes}`;
        if (userContent) sendNotificationEmail(userContent, [this.notificationInfo.notificationEmail], true);
        console.log(this.selectedSamples);
        console.log('orgEmail', this.notificationInfo.notificationEmail);
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: `${action} of samples request has not been sent! Please contact an administrator.`, life: 5000,
        });
      }
    },
    updateSamples() {
      try {
        if (this.sampleMetadata) {
          const samplesToUpdateIndex: number | null | undefined = this.samples.findIndex((sp) => sp.id === this.sampleMetadata.id);
          if (samplesToUpdateIndex !== -1) {
            this.samples[samplesToUpdateIndex] = this.sampleMetadata;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async generateMetadata() {
      const updateImmunopeptidomicsTemplate: ImmunopeptidomicsTemplate = {};
      const sampleID = '88cc7170-af70-44b7-aced-52406050f578' as string;
      updateImmunopeptidomicsTemplate.sampleMetadataImmunopeptidomicsListId = sampleID;
      updateImmunopeptidomicsTemplate.sampleId = sampleID;
      updateImmunopeptidomicsTemplate.qcStatus = 'PASSED';
      updateImmunopeptidomicsTemplate.species = 'test';
      updateImmunopeptidomicsTemplate.sampleType = 'test';
      updateImmunopeptidomicsTemplate.metaDataTemplateS3Path = 'test';
      updateImmunopeptidomicsTemplate.glacierBackup = true;
      updateImmunopeptidomicsTemplate.rawDataS3 = true;
      updateImmunopeptidomicsTemplate.tumorVolume = 6473838;

      try {
        const updatedMetadataGeneral = await API.graphql(graphqlOperation(mutations.createMetadataImmunopeptidomics, { input: updateImmunopeptidomicsTemplate }));
        console.log(updatedMetadataGeneral);
      } catch (error) {
        console.error(error);
      }
    },
    camelCaseToWords(str: string) {
      return camelCaseToWords(str);
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    formatListForView(list: string[]) {
      return formatListForView(list);
    },
    studyPhaseActionClick(studyPhase: StudyPhase, event: MouseEvent) {
      (this.$refs[`study-menu-${studyPhase.id}`] as any).toggle(event);
    },
    sampleActionItems(sampleMetadata: any) {
      const update = {
        label: 'Update Sample',
        command: () => {
          this.openSampleDialog(sampleMetadata);
        },
      };
      const options = [];
      if (this.$store.state.precedenceLevel <= 5) options.push(...[update]);
      return options;
    },
    async openSampleDialog(sampleMetadata: any) {
      this.$store.dispatch('setShowingSamplesDialog', true);
      this.sampleMetadata = sampleMetadata;
    },
    async openReportDialog() {
      this.$store.dispatch('setShowingSamplesReportDialog', true);
    },
  },
  watch: {
  // eslint-disable-next-line func-names
    '$store.state.activeOrganization': async function () {
      // const activeOrganization = await this.$store.state.activeOrganization;
    },
  },
  computed: {
    confirmQcStatus() {
      const computedQCshow = ((Object.keys(this.selectedSamples).length === 0) || (this.setQcStatus === null));
      console.log('computedQCshow', computedQCshow);
      console.log('setQcStatus', this.setQcStatus);
      return computedQCshow;
    },
  },
});
