
import { defineComponent } from 'vue';
import SamplesTable from '@/components/Samples/SamplesTable.vue';
// eslint-disable-next-line no-unused-vars
import { getHomeRouteForBreadcrumb, getOrgNameAndIdForRoute } from '@/utils';
import { MenuItem } from '@/models/customModels';

export default defineComponent({
  name: 'SamplesBrowse',
  components: {
    SamplesTable,
  },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [] as MenuItem[],
    };
  },
  methods: {
    makeBreadcrumbItems(route: any, studyName: string, studyId: string, batchParentEntityType?: string, batchParentEntityName?: string, studyPhaseId?: string, batchName?: string): { label: string; to: any; }[] {
      const items = [
        { label: 'Studies', to: getHomeRouteForBreadcrumb(this.$route) },
        { label: `Study: ${studyName} | Study Phases`, to: `${getOrgNameAndIdForRoute(this.$route)}studyPhaseBrowse/${studyId}` },
      ];

      if (this.$route.params.origin[0] === 'studyPhase') items.push({ label: `Study Phase: ${batchParentEntityName} | Samples`, to: route.fullPath });
      if (this.$route.params.origin[0] === 'batch') {
        items.push(...[
          { label: `${batchParentEntityType}: ${batchParentEntityName} | Batches`, to: `${getOrgNameAndIdForRoute(this.$route)}batchBrowse/${(batchParentEntityType === 'Study') ? 'study' : 'studyPhase'}/${(batchParentEntityType === 'Study') ? studyId : `${studyPhaseId}/${studyId}`}` },
          { label: `Batch: ${batchName} | Samples`, to: route.fullPath },
        ]);
      }
      return items;
    },
    setBreadcrumbItems(studyName: string, studyId: string, batchParentEntityType: string, batchParentEntityName: string, studyPhaseId?: string, batchName?: string): void {
      console.log('Setting breadcrumb items');
      console.log('this.$route.params.origin[0] :>> ', this.$route.params.origin[0]);
      this.breadcrumbItems = this.makeBreadcrumbItems(this.$route, studyName, studyId, batchParentEntityType, batchParentEntityName, studyPhaseId, batchName);
    },
  },
});
