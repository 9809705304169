import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, [
      _withDirectives(_createVNode(_component_InputText, {
        id: _ctx.name + _ctx.index + 'Id',
        modelValue: _ctx.localValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        onInput: _ctx.updateData,
        class: _normalizeClass((!_ctx.nullable && (_ctx.localValue === null || _ctx.localValue === '')) ? 'p-invalid' : '')
      }, null, 8, ["id", "modelValue", "onInput", "class"]), [
        [_directive_tooltip, _ctx.description]
      ]),
      _createElementVNode("label", {
        for: _ctx.name + _ctx.index +'Id'
      }, _toDisplayString(_ctx.getName(_ctx.name)), 9, _hoisted_2)
    ])
  ]))
}