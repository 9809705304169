<template>
  <Dialog v-model:visible="showing" header="Profile" :modal="true" class="p-fluid profile-dialog" :closable="false" :style="{width: '400px'}" :class="orgName">
    <div class="field">
      <label for="email">Email</label>
      <InputText id="email" v-model="email" required="true" :disabled="true" />
    </div>
    <div class="field">
      <Button class="p-button" label="Reset Password" @click="toggleChangePassword()" />
    </div>
    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-outline" @click="hide"/>
    </template>
  </Dialog>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      showing: false,
      email: '',
      isHidden: true,
      orgName: '',
    };
  },
  methods: {
    hide() {
      if (this.isHidden === false) {
        this.isHidden = true;
      } else {
        this.$store.dispatch('showProfile');
      }
    },
    toggleChangePassword() {
      this.$store.dispatch('showProfile');
      this.$store.dispatch('showChangePassword');
    },
  },
  async created() {
    const userInfo = await Auth.currentUserInfo();
    this.email = userInfo.attributes.email;
    this.fullName = userInfo.attributes.name;
    this.orgName = (await this.$store.state.activeOrganization).organizationName;
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingProfile': async function () {
      this.showing = this.$store.state.showingProfile;
    },
  },
};
</script>
