
import { ExperimentSpecificVariable } from '@/models/customModels';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'DynamicExperimentSpecificVariablesInputRenderer',
  props: {
    experimentSpecificVariables: { type: Array as PropType<ExperimentSpecificVariable[]>, required: true },
  },
  data() {
    return {
      variableTypes: ['string', 'number', 'float', 'boolean'] as string[],
    };
  },
  methods: {
    addRow(index: number) {
      // eslint-disable-next-line vue/no-mutating-props
      this.experimentSpecificVariables.splice(index + 1, 0, {
        name: '',
        type: '',
        description: '',
        value: null,
      });
    },
    removeRow(index: number) {
      // eslint-disable-next-line vue/no-mutating-props
      this.experimentSpecificVariables.splice(index, 1);
    },
  },
});
