import { float } from "aws-sdk/clients/cloudfront"
import { Organization, Phase, Sample, Study, StudyPhase, Group, Pipeline } from "."

export enum QcStatus {
  AWAITING = "AWAITING",
  PASSED = "PASSED",
  FAILED = "FAILED"
}

export enum QCoptions {
    FAILED = "FAILED",
    PASSED = "PASSED",
    AWAITING = "AWAITING"
  }

export enum dataTypeOptions {
    rnaseq = "rnaseq",
    nanostring = "nanostring"
}

export enum analysisType {
    RNASEQ = "RNASEQ",
    NANOSTRING = "NANOSTRING",
    IMMUNOPEPTIDOMICS = "IMMUNOPEPTIDOMICS"
  }

export type ActiveOrganizationObject = {
    id: string,
    organizationName: string | null | undefined
    organizationDescription: string | null | undefined
}

export type User = {
    Enabled: boolean,
    UserCreateDate: string,
    UserLastModifiedDate?: string,
    UserStatus?: string,
    Username: string,
    Attributes: UserAttribute[],
    [key: string] : string | boolean | UserAttribute[] | undefined, // LEt anything be added here
}

export type DBUser = {
    id: string,
    organization: string,
    email: string,
    created: string,
    [key: string] : string | boolean | UserAttribute[], // LEt anything be added here
}

export type UserAttribute = {
    Name: string,
    Value: any,
}

export type MenuItem = {
    label: string,
    command? (): void
    to?: string,
}

export type DelBackupItem = {
    label: string,
    precedenceLevel: number,
    command? (): void
}

export type BasicIndexStructure = {
    key: string,
    label: string
    children: BasicIndexStructure[],
    partialChecked?: false,
    checked?: true
    [key: string] : any
}

export type MenuItems = {
    label: string,
    items: MenuItems[]
    [key: string] : any
}

export type customGQLResult = {
    data: any,
    [key: string] : any
}
export enum PipelineStatus {
    INITIATED = "INITIATED",
    RUNNING = "RUNNING",
    COMPLETED = "COMPLETED",
    CANCELLED = "CANCELLED",
    FAILED = "FAILED",
    SCHEDULED = "SCHEDULED",
    FINISHED = "FINISHED",
    ABORTED = "ABORTED",
    ERROR = "ERROR"
}

export enum FinalPipelineStatuses {
    INITIATED = "INITIATED",
    SCHEDULED = "SCHEDULED",
    SCHEDULED_FAILED = "SCHEDULED_FAILED",
    RUNNING = "RUNNING",
    ABORTED = "ABORTED",
    ABORTED_FAILED = "ABORTED_FAILED",
    FINISHED = "FINISHED",
    ERROR = "ERROR"
  }

export type PipelineTemplate = {
    id?: string;
    name?: string;
    description?: string;
    status?: PipelineStatus | keyof typeof PipelineStatus;
    analysisType?: analysisType | keyof typeof analysisType;
    parameters?: string;
    scheduledPipeline?: boolean;
    scheduledDateTime?: string;
    s3Event?: string;
    s3Results?: string;
    runId?: string;
    userEmail?: string;
    batchId?: string;
    batchPipelinesId?: string;
    launchablePipelineId?: string;
    launchablePipelinesPipelinesId?: string;
    studyId: string,
    studyPipelinesId: string,
    studyPhaseId?: string,
    subtypeOfAnalysis?: string,
    studyPhasePipelinesId?: string
    study?: { id: string, organization: { id: string } }
    created?: string;
    updated?: string;
    pipelineStarted?: string | Date,
    pipelineCompleted?: string | Date,
    visFileInfoS3path?: string,
    studyPhase?: StudyPhase,
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
}

export type OrganizationTemplate = {
    id?: string;
    organizationName?: string;
    active?: boolean;
    notificationEmail?: string | null;
    description?: string | null;
    url?: string | null;
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
}

export type StudyTemplate = {
    id?: string;
    studyName?: string;
    organizationId?: string;
    organization?: Organization | null;
    studyPhases?: (StudyPhase | null)[] | null;
    description?: string | null;
    isClinical?: boolean;
    studyCode?: string;
    assay?: string | null;
    cellType?: string | null;
    database?: string | null;
    disease?: string | null;
    studyAnswers?: string | null;
    tissue?: string | null;
    sample?: string | null;
    croGroups?: (string | null)[] | null;
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
    organizationStudiesId?: string | null;
}

export type StudyPhaseTemplate = {
    id?: string;
    studyId?: string;
    study?: Study | null;
    groups?: (Group | null)[] | null;
    samples?: (Sample | null)[] | null;
    description?: string | null;
    studyPhaseName?: string | null;
    phase?: Phase | keyof typeof Phase | null;
    studyPhaseAnswers?: string | null;
    validationSchema?: string;
    groupNames?: (string | null)[] | null;
    specimens?: (string | null)[] | null;
    sampleTypes?: (string | null)[] | null;
    species?: (string | null)[] | null;
    labAssayTechnologies?: (string | null)[] | null;
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
    experimentSpecificVariables?: string | null;
    created?: string;
    updated?: string;
    studyStudyPhasesId?: string | null;
    studyPhaseMetaDataAllowedValuesId?: string | null;
    studyPhaseMetaDataAllowedValuesTemplateId?: string | null;
    metaDataValidationSchemaTemplateId?: string,
    metaDataValidationSchemaTemplateStudyPhasesId?: string,

}

export type MetadataGeneralTemplate = {
    id?: string;
    species?: string;
    qcStatus?: QCoptions | keyof typeof QCoptions | null;
    sampleType?: string;
    barcode?: string;
    isControl?: boolean;
    barcodesMappedToControl?: (string | null)[];
    tumorVolume?: number | null;
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
    created?: string;
    updated?: string;
}

export type BatchTemplate = {
    id?: string;
    batchName?: string;
    description?: string;
    batchAnnotations?: string;
    annotations?: string;
    controlSamples?: string;
    filters?: string;
    batchGroups?: string | null;
    studyId?: (string | Record<string, string>); 
    pipelines?: PipelineTemplate[],
    studyBatchesId?: string,
    studyPhaseId?: string;
    studyPhaseBatchesId?: string,
    readGroups?: string[];
    writeGroups?: string[];
    adminGroups?: string[];
    created?: string;
    updated?: string;
}

export type SampleTemplate = {
    id?: string;
    experimentSpecificVariables?: string;
}

export type MetaDataAllowedValuesCustomTemplate = {
    id?: string;
    studyPhaseId?: string;
    studyPhase?: StudyPhase | null;
    validationSchemaTemplate?: string | null;
    templateFiles: string,
    readGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
    created?: string;
    updated?: string;
}

export type CognitoGroupsObjct = {
    GroupName: string,
}

export type MetadataCROTemplate = {
    backend: object,
    front_end: [FEMetadataCROTemplate[]],
}

// export type FEMetadataCROTemplate = {

// }

export type FEMetadataCROTemplate = {
    id: string,
    description: string,
    input_type: string,
    name: string,
    nullable: boolean,
    template_column_name: string,
    value_type_FE: string,
    value_type: string,
    to_merge_with?: string,
    value: any,
}

export type ComplexTemplateValidationItem = {
    template_column_name: string,
    nullable: boolean,
    value: any
    name: string
}

export type DownloadebleTemplates = {
    displayName: string,
    s3Path: string,
}

export type StudyAnswersObj = {
    id: string,
    studyName: string,
    studyAnswers: string,
}
export type StudyPhaseAnswersObj = {
    id: string,
    studyPhaseName: string,
    studyPhaseAnswers: string,
}

export type EntityQA = {
    question: string,
    answer?: string,
    inputType?: string,
}

export type EntityQAObject = {
    questions: EntityQA[],
    questionGroups: [
        {
            groupLabel: string,
            questions: EntityQA[]
        }
    ],
}

export type S3ContentModel = {
    ChecksumAlgorithm?: any[],
    ETag?: string,
    Key: string
    LastModified: Date,
}

export type S3ExplorerTreeDataModel = {
    children?:  S3ExplorerTreeDataModel[],
    data: string,
    icon: string,
    key: string,
    label: string,
}

export type AWSConfigCredentialsObj = {
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken: string,
    region?: string,
}

export type LaunchablePipelines = {
    id?: string;
    pipelineName?: string | null;
    pipelineVersion?: string | null;
    parameters?: string | null;
    inputFileInfo?: string | null;
    description?: string | null;
    workflowId?: string | null;
    organizations?: { items: Organization[]; } | Organization[];
    supportedAnalyses? : [AnalysisType] | null;
    supportedSubtypeOfAnalyses?: [String] | null;
    gitRepo?: string | null;
    created?: string;
    updated?: string;
  }

export type MetadataGeneral = {
    id: string;
    sampleId: string;
    sample?: Sample | null;
    // metadataNanoString?: MetadataNanoString | null;
    // metadataRnaSeqList?: MetadataRnaSeq | null;
    // treatment?: (Treatment | null)[] | null;
    metaDataTemplateS3Path: string;
    species: string;
    sampleType: string;
    qcStatus: QcStatus | keyof typeof QcStatus;
    glacierBackup: boolean;
    rawDataS3: boolean;
    tumorVolume?: number | null;
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
    created: string;
    updated: string;
    // sampleMetadataGeneralListId?: string | null;
    metadataGeneralMetadataNanoStringId?: string | null;
    metadataGeneralMetadataRnaSeqListId?: string | null;
}

export type ImmunopeptidomicsTemplate = {
    id?: string;
    sampleId?: string;
    sampleMetadataImmunopeptidomicsListId?: string;
    sample?: Sample | null;
    metaDataTemplateS3Path?: string;
    species?: string;
    sampleType?: string;
    qcStatus?: QcStatus | keyof typeof QcStatus;
    glacierBackup?: boolean;
    rawDataS3?: boolean;
    tumorVolume?: number | null;
    readGroups?: (string | null)[] | null;
    writeGroups?: (string | null)[] | null;
    adminGroups?: (string | null)[] | null;
    created?: string;
    updated?: string;
}

export type MetadataRnaSeqTemplate = {
    id?: string;
    sampleId?: string;
    sample?: Sample | null;
    metaDataTemplateS3Path?: string;
    species?: string;
    sampleType?: string;
    qcStatus?: QcStatus | keyof typeof QcStatus;
    glacierBackup?: boolean;
    rawDataS3?: boolean;
    tumorVolume?: number | null;
    rnaMassNanoGram?: number | null;
    rnaVolumeMicroL?: number | null;
    rin?: number | null;
}

export type MetadataNanoStringTemplate = {
    id: string;
    sampleId?: string;
    sample?: Sample | null;
    metaDataTemplateS3Path?: string;
    species?: string;
    sampleType?: string;
    qcStatus?: QcStatus | keyof typeof QcStatus;
    glacierBackup?: boolean;
    rawDataS3?: boolean;
    tumorVolume?: number | null;
    rnaMassNanoGram?: number | null;
    rnaVolumeMicroL?: number | null;
    rin?: number | null;
}

export type ExperimentSpecificVariable = {
    name: string,
    type: string,
    description: string,
    value?: string | boolean | number | float | null,
}

export type entryESV = {
    type: string,
    description: string,
    value?: string | boolean | number | float | null,   
}
export type sampleESV = {
    name: entryESV,
}

export type ExperimentSpecificVariableHolder = {
    imunopeptidomics: ExperimentSpecificVariable[],
    rnaseq: ExperimentSpecificVariable[],
    nanostring: ExperimentSpecificVariable[],
}

export type SampleMetadata = {
    name: string,
    type: string,
    description: string,
    value?: string | boolean | number | float | null,
}

export type AnalysisType = {
    name: string,
    value: string,
}

export type Visualizations = {
    id: string;
    visualizationName?: string | null;
    description?: string | null;
    version?: string | null;
    deploymentPath?: string | null;
    supportedAnalyses?: (AnalysisType | null)[];
}

export type OpenVisualization = {
    id?: string,
    visualization?: Visualizations,
    pipelines?: Pipeline[],
    w?: number,
    h?: number,
    x?: number,
    y?: number,
    draggable?: boolean,
    token?: string,
}

export type CustomVisualizationSession = {
    id?: string,
    statuses?: string,
    data?: string,
    description? :string,
}
