
import { defineComponent } from 'vue';
import { listItems } from '@/utils';
import * as customQueries from '@/graphql/customQueries';
import { Study } from '@/models';
import StudyTable from '@/components/Study/StudyTable.vue';

export default defineComponent({
  name: 'Search',
  components: {
    StudyTable,
  },
  data() {
    return {
      loading: false,
      searchQuery: '' as string,
      studies: [] as unknown as Study[],
    };
  },
  beforeMount() {
  },
  methods: {
    async search(searchQuery: string) {
      this.loading = true;
      const returnedStudies: Study[] = await listItems(customQueries.studiesByOrganizationForStudyTable, {
        organizationId: this.$route.params.organizationId,
        filter: {
          or: [
            {
              studyName: {
                contains: searchQuery,
              },
            },
            {
              studyCode: {
                contains: searchQuery,
              },
            },
            // {
            //   assay: {
            //     contains: searchQuery,
            //   },
            // },
            // {
            //   database: {
            //     contains: searchQuery,
            //   },
            // },
            // {
            //   disease: {
            //     contains: searchQuery,
            //   },
            // },
            // {
            //   cellType: {
            //     contains: searchQuery,
            //   },
            // },
            // {
            //   sample: {
            //     contains: searchQuery,
            //   },
            // },
            // {
            //   tissue: {
            //     contains: searchQuery,
            //   },
            // },
          ],
        },
      });
      this.studies = returnedStudies;
      this.loading = false;
      console.log('this.studies :>> ', this.studies);
    },
  },
  watch: {
  },
});
