
import { StudyPhase } from '@/models';
import { listItems, formatISOStringCustom, formatListForView } from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import { FilterMatchMode } from 'primevue/api';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api-graphql';

export default defineComponent({
  name: 'Study Phase Table',
  emits: ['studyPhaseSelected'],
  props: {
    studyId: { type: String, required: false },
  },
  data() {
    return {
      studyPhases: [] as StudyPhase[],
      loading: false,
      filters: {
        studyPhaseName: { value: '', matchMode: 'contains' },
        phase: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      phaseDDOptions: [
        'PRE_CLINICAL', 'PHASE_1', 'PHASE_2', 'PHASE_3',
      ],
    };
  },
  mounted() {
    if (this.studyId) {
      this.loadStudyPhasesForStudy(this.studyId as string);
    }
  },
  methods: {
    async loadStudyPhasesForStudy(studyId: string): Promise<void> {
      this.loading = true;
      try {
        let studyPhases: StudyPhase[];
        if (studyId) {
          studyPhases = await listItems(customQueries.studyPhasesByStudyForStudyPhaseBrowse, { studyId, sortDirection: 'DESC' });
        } else {
          throw new Error('No study id found');
        }
        this.studyPhases = studyPhases;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    formatListForView(list: string[]) {
      return formatListForView(list);
    },
    formatPhaseName(phaseValue: string): string {
      try {
        switch (phaseValue) {
          case 'PRE_CLINICAL': {
            return 'Pre Clinical';
          }
          case 'PHASE_1': {
            return 'Phase 1';
          }
          case 'PHASE_2': {
            return 'Phase 2';
          }
          case 'PHASE_3': {
            return 'Phase 3';
          }
          default: {
            return '';
          }
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    onStudyPhaseSelect(event: any) {
      if (event.data) {
        console.log('event.data :>> ', event.data);
        this.$emit('studyPhaseSelected', event.data);
      }
    },
  },
});
