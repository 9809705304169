
import { FilterMatchMode } from 'primevue/api';
import { formatListForView } from '@/utils';
import { defineComponent, PropType } from 'vue';
import {
  Sample,
} from '@/models';
import LaunchPipelineDialog from '../Pipeline/LaunchPipelineDialog.vue';

export default defineComponent({
  name: 'BatchSamplesAnnotationGrouping',
  components: {
    LaunchPipelineDialog,
  },
  props: {
    selectedSamples: { type: Array as PropType<Sample[]>, required: true },
    annotationColumns: { type: Object, required: false },
    annotationFilters: { type: Object, required: false },
    batchView: Boolean,
    batchData: { type: Object, required: false },
  },
  emits: ['batchGroups'],
  data() {
    return {
      blabla: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        a: { value: '', matchMode: FilterMatchMode.CONTAINS },
      }, // this.annotationFilters,
      asdf: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
        a: { value: '', matchMode: FilterMatchMode.CONTAINS },
      }, // this.annotationFilters,
      localAnnFilters: this.annotationFilters,
      groupSamples: [] as Sample[],
      groupName: null as unknown as string | null,
      samplesGroups: [] as any,
      samplesGroup: '' as string,
      stepStart: 2,
    };
  },
  mounted() {
    if (this.annotationColumns) {
      this.annotationColumns.forEach((annotation: any) => {
        console.log(annotation);
      });
    }
    console.log(this.annotationFilters);
    console.log(this.selectedSamples);
  },
  methods: {
    async runPipeline() {
      console.log(this.annotationFilters);
      // console.log(this.localAnnFilters);
      console.log(this.selectedSamples);
      console.log(this.annotationColumns);
      console.log('-----------------');
      this.$store.dispatch('setShowLaunchPipeline', true);
    },
    addGroup(groupName: string) {
      const sampleIds = [] as any;
      this.groupSamples.forEach((sp: any) => {
        const sampleId = sp.id;
        sampleIds.push(sampleId);

        this.selectedSamples.forEach((sample: any) => {
          if (sample.id === sp.id) {
            sample.groups.push(groupName);
            console.log(sample);
            console.log('----------------------------');
          }
          // eslint-disable-next-line no-param-reassign
        });
      });
      const group = {
        name: groupName,
        filters: this.localAnnFilters,
        samples: sampleIds,
      };

      this.samplesGroups.push(group);
      this.$emit('batchGroups', this.samplesGroups);
      //  this.$emit('updateStudyPhase', updatedStudyPhase.data.updateStudyPhase);

      this.groupName = '';
      console.log(this.samplesGroups);
      console.log(this.localAnnFilters);
    },
    removeGroup(samplesGroup: any) {
      console.log(samplesGroup);
      this.samplesGroups!.forEach((group: any, index: number) => {
        if (group.name === samplesGroup.name) this.samplesGroups.splice(index, 1);
      });
      this.selectedSamples.forEach((sample: any) => {
        if (sample.groups.includes(samplesGroup.name)) {
          console.log(sample);
          const index = sample.groups.indexOf(samplesGroup.name);
          if (index > -1) {
            sample.groups.splice(index, 1);
          }
        }
      });
      console.log(this.samplesGroups);
      this.samplesGroup = '';
    },
    updateFilters() {
      if (this.annotationColumns) {
        this.annotationColumns.forEach((annotation: any) => {
          console.log(annotation);

          // this.filters[annotation.name] = { value: '', matchMode: FilterMatchMode.CONTAINS };
        });
        console.log('====CHANGE');
      }
    },
    formatListForView(list: string[]) {
      return formatListForView(list);
    },
  },
});
