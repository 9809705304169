
import { defineComponent } from 'vue';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import {
  listItems, setPrecedence, switchToDarkTheme, switchToLightTheme, wait,
} from '@/utils';
import * as queries from '@/graphql/queries';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';
import { Organization } from '@/models';
import { ActiveOrganizationObject } from '@/models/customModels';
import TopBar from '@/components/TopBar.vue';
import ProfileDialog from '@/components/ProfileDialog.vue';
import ChangePassword from '@/components/Users/ChangePassword.vue';
import ChangeOrganizationDialog from '@/components/Organization/ChangeOrganizationDialog.vue';
import TimedLogoutComponent from '@/components/Utils/TimedLogoutComponent.vue';
// Force rebuild comment 4
export default defineComponent({
  name: 'Home',
  components: {
    TopBar,
    ProfileDialog,
    ChangePassword,
    ChangeOrganizationDialog,
    TimedLogoutComponent,
  },
  data() {
    return {
      allLoaded: false,
      orgName: '',
      layoutMode: 'static',
      menuClick: false,
      version: '0.1.1',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      isUser: false as boolean,
    };
  },
  async beforeMount() {
    this.allLoaded = false;
    const groups: string[] | null = await this.checkIfGroupsEmpty();
    if (this.$route.params.organizationId && this.$route.params.organizationName) {
      await Promise.all([this.checkOrgInRoute(), setPrecedence()]);
    } else {
      await setPrecedence(true);
    }
    const activeOrg: ActiveOrganizationObject = await this.$store.state.activeOrganization;
    if (groups && groups.every((group: string) => group.startsWith('CRO/'))) this.$router.push(`/${activeOrg.organizationName}/${activeOrg.id}/CRO`);
    if (groups) {
      const allUserPrecedence : number[] = [4, 6];
      if (allUserPrecedence.includes(this.$store.state.precedenceLevel)) {
        this.isUser = true;
      }
    }
  },
  methods: {
    async checkOrgInRoute() {
      try {
        console.log('this.$route.params.organizationId :>> ', this.$route.params.organizationId);
        const res: GraphQLResult<any> = await API.graphql(graphqlOperation(queries.getOrganization, { id: this.$route.params.organizationId }));
        if (res?.data?.getOrganization) {
          console.log('res :>> ', res);
          const activeOrganization: ActiveOrganizationObject = {
            id: res.data.getOrganization.id,
            organizationName: res.data.getOrganization.organizationName,
            organizationDescription: res.data.getOrganization.organizationDescription,
          };
          this.$store.dispatch('setActiveOrganization', activeOrganization);
        }
        console.log('res :>> ', res);
      } catch (error) {
        console.error(error);
      }
    },
    // Initiate the setting of the active organization. Set the promise into the store so when someone wants to get the active organization they can just call await on this.$store.state.activeOrganization
    async setActiveOrganization(): Promise<void> {
      console.log('Setting active org');
      try {
        if (this.$store.state.activeOrganization && 'organizationName' in this.$store.state.activeOrganization) {
          this.orgName = this.$store.state.activeOrganization.organizationName;
          document.body.classList.value = this.orgName;
        } else {
          const activeOrgObjectPromise: Promise<ActiveOrganizationObject | null> = this.createActiveOrgPromise('m2m');
          console.log('activeOrgObjectPromise :>> ', activeOrgObjectPromise);
          this.$store.dispatch('setActiveOrganization', activeOrgObjectPromise);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async createActiveOrgPromise(orgName: string): Promise<ActiveOrganizationObject | null> {
      try {
        const allOrganizations: Organization[] = await listItems(queries.listOrganizations);
        console.log('allOrganizations :>> ', allOrganizations);
        const m2mOrg: Organization | undefined = allOrganizations.find((org: Organization) => org.organizationName === orgName);
        if (!m2mOrg) throw new Error(`No ${orgName} orgnaization`);
        const m2mOrgActiveOrg: ActiveOrganizationObject = {
          id: m2mOrg.id,
          organizationName: m2mOrg.organizationName,
          organizationDescription: m2mOrg.description,
        };
        return m2mOrgActiveOrg;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async checkIfGroupsEmpty() {
      try {
        const currentAuthenticatedUser: any = await Auth.currentAuthenticatedUser();
        this.checkIfUserHasSetOrg(currentAuthenticatedUser.username);
        const groups: string[] = currentAuthenticatedUser.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups === undefined) {
          this.$router.push('/notAssigned');
          return null;
        }
        return groups;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async checkIfUserHasSetOrg(userId: string) {
      const user: any = await API.graphql(graphqlOperation(queries.getUser, { id: userId }));
      if (!user?.data?.getUser && !user?.data?.getUser?.organization) {
        this.$store.dispatch('setNewUserData', { id: user?.data?.getUser.id, email: user?.data?.getUser.email });
        this.$store.dispatch('setShowingAddCustomOrg', true);
      }
    },
    signOut() {
      this.$store.dispatch('setActiveOrganization', null);
      Auth.signOut().then(() => {
        this.$router.push('/auth/login');
      }).catch((e: any) => {
        console.log('error signing out: ', e);
      });
      document.body.classList.value = '';
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    checkTheme() {
      const themeElement = document.getElementById('theme-link');
      if (!themeElement) return;
      switch (this.orgName) {
        case 'm2m': case 'gwt':
          switchToDarkTheme();
          break;
        case 'tfb': case 'pt':
          switchToLightTheme();
          break;
        default:
          switchToDarkTheme();
          break;
      }
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
    },
  },
  computed: {
    commitId() {
      const commitId = process.env.VUE_APP_AWS_COMMIT_ID;
      if (commitId !== undefined && commitId !== null) {
        return commitId.substring(0, 6);
      }
      return 'Id not found';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    // '$store.state.activeOrganization': async function () {
    //   const activeOrganization = await this.$store.state.activeOrganization;
    //   // await this.$store.state.activeOrganization.then(() => {
    //   if (activeOrganization) {
    //     console.log(activeOrganization.organizationName);
    //     this.orgName = activeOrganization.organizationName;
    //     console.log('activeOrganization :>> ', activeOrganization);
    //     this.checkTheme();
    //     this.allLoaded = true;
    //   }
    // });
    '$store.state.activeOrganization': {
      async handler(newActiveOrganization) {
        if (newActiveOrganization) {
          this.allLoaded = false; // Set allLoaded to false before awaiting the store state
          const activeOrganization: ActiveOrganizationObject = await newActiveOrganization;
          console.log('activeOrganization :>> ', activeOrganization);
          if (activeOrganization.organizationName) {
            this.orgName = activeOrganization.organizationName;
            document.body.classList.value = this.orgName;
          }
          if (!this.$route.params.organizationName && !this.$route.params.organizationId) {
            this.$router.push({ path: `/${activeOrganization.organizationName}/${activeOrganization.id}/studyBrowse` });
          }
          this.checkTheme();
          await wait(100); // Wait 100 ms for theme to load
          this.allLoaded = true; // Set allLoaded to true after awaiting the store state
        }
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
});
