
import { API, graphqlOperation } from 'aws-amplify';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';
import {
  EntityQAObject, StudyAnswersObj, StudyPhaseAnswersObj,
} from '@/models/customModels';

export default defineComponent({
  name: 'Details',
  data() {
    return {
      loading: false,
      QAArray: {} as EntityQAObject,
      entityTypeSelected: '' as string,
    };
  },
  created() {
    this.main();
  },
  methods: {
    async main(): Promise<void> {
      this.loading = true;
      try {
        if (this.$route.params.entityType && this.$route.params.entytyId) {
          await this.handleEntity(this.$route.params.entityType as string, this.$route.params.entytyId as string);
        } else {
          throw new Error('Entity type or id missing in route');
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
      this.loading = false;
    },
    async handleEntity(entityType: string, entityId: string): Promise<void> {
      try {
        switch (entityType) {
          case 'STUDY': {
            const study: StudyAnswersObj | null = await this.getStudyAnswersById(entityId);
            if (study) {
              this.QAArray = JSON.parse(study.studyAnswers);
              this.entityTypeSelected = 'Study';
            }
            break;
          }
          case 'STUDY_PHASE': {
            const studyPhase: StudyPhaseAnswersObj | null = await this.getStudyPhaseAnswersById(entityId);
            if (studyPhase) {
              this.QAArray = JSON.parse(studyPhase.studyPhaseAnswers);
              this.entityTypeSelected = 'Study Phase';
            }
            break;
          }
          default:
            throw new Error('Unhandeled entity type');
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getStudyAnswersById(studyId: string): Promise<StudyAnswersObj | null> {
      try {
        const study: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getStudyAnswers, { id: studyId }));
        if (study) return study.data.getStudy;
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getStudyPhaseAnswersById(studyPhaseId: string): Promise<StudyPhaseAnswersObj | null> {
      try {
        const studyPhase: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getStudyPhaseAnswers, { id: studyPhaseId }));
        if (studyPhase) return studyPhase.data.getStudyPhase;
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
  },
});
