
import {
  validateInputName, listItems, getQuestions, makeEntityQAObj, updateDynamoDBDirectly,
} from '@/utils';
import { API, graphqlOperation } from 'aws-amplify';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
// eslint-disable-next-line no-unused-vars
import {
  EntityQA, EntityQAObject, ExperimentSpecificVariable, StudyPhaseTemplate,
} from '@/models/customModels';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import {
  EntityType,
  MetaDataValidationSchemaTemplate, Phase, Study, StudyPhase,
} from '@/models';
import DynamicQuestionsRenderer from '@/components/Utils/DynamicQuestions/DynamicQuestionsRenderer.vue';
import DynamicExperimentSpecificVariablesInputRenderer from '@/components/StudyPhase/DynamicExperimentSpecificVariablesInputRenderer.vue';

export default defineComponent({
  name: 'Users',
  components: { DynamicQuestionsRenderer, DynamicExperimentSpecificVariablesInputRenderer },
  emits: {
    newStudyPhase: (studyPhase: StudyPhase) => {
      if (studyPhase) {
        return true;
      }
      console.error('Invalid newStudyPhase event payload!');
      return false;
    },
    updateStudyPhase: (studyPhase: StudyPhase) => {
      if (studyPhase) {
        return true;
      }
      console.error('Invalid updateStudyPhase event payload!');
      return false;
    },
    clearStudyPhaseForUpdate: () => true,
  },
  props: {
    studyPhaseForUpdate: { type: StudyPhase, required: false },
  },
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      description: null as unknown as string | undefined,
      studyPhaseName: null as unknown as string | null,
      availableMetadataTemplates: [] as MetaDataValidationSchemaTemplate[],
      selectedMetadataTemplate: null as unknown as MetaDataValidationSchemaTemplate,
      parentStudy: null as unknown as Study,
      activeOrgName: '',
      activeTabIndex: 0,
      shouldShowExpSpecVars: true,
      phase: null as unknown as Phase | null,
      phaseDDOptions: [
        'PRE_CLINICAL', 'PHASE_1', 'PHASE_2', 'PHASE_3',
      ],
      experimentSpecificVariables: [
        {
          name: '',
          type: '',
          description: '',
          value: null,
        },
      ] as ExperimentSpecificVariable[],
      questions: {} as unknown as EntityQAObject | null,
      questionData: {} as { [key: string] : string },
    };
  },
  async beforeMount() {
    await this.main();
  },
  methods: {
    async main() {
      this.loading = true;
      console.log('Main in studyPhase dialog');
      try {
        this.setQuestions();
        this.shouldShowExpSpecVars = this.checkIfShouldShowExpSpecVars();
        if ('studyId' in (this.$route as any).params && this.$route.params.studyId) {
          const study: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getStudyBasicWithGroups, { id: this.$route.params.studyId }));
          this.parentStudy = study.data.getStudy;
        }
        await this.loadMetadataTemplates();
        if (!this.studyPhaseForUpdate) {
          this.loading = false;
          return;
        }
        if (this.studyPhaseForUpdate.studyPhaseName) this.studyPhaseName = this.studyPhaseForUpdate.studyPhaseName as string;
        if (this.studyPhaseForUpdate.description) this.description = this.studyPhaseForUpdate.description;
        if (this.studyPhaseForUpdate.experimentSpecificVariables !== '[]') {
          if (this.studyPhaseForUpdate.experimentSpecificVariables) this.experimentSpecificVariables = JSON.parse(this.studyPhaseForUpdate.experimentSpecificVariables);
        }
        if (this.studyPhaseForUpdate.studyPhaseAnswers) {
          const entityQAObject: EntityQAObject = JSON.parse(this.studyPhaseForUpdate.studyPhaseAnswers);
          for (let i = 0; i < entityQAObject.questions.length; i += 1) {
            const questionObj: EntityQA = entityQAObject.questions[i];
            if (questionObj.answer && questionObj.answer !== 'NaN' && questionObj.answer !== 'undefined') this.questionData[questionObj.question] = questionObj.answer as string;
          }
          for (let i = 0; i < entityQAObject.questionGroups.length; i += 1) {
            const questionGroup = entityQAObject.questionGroups[i];
            for (let j = 0; j < questionGroup.questions.length; j += 1) {
              const questionObj: EntityQA = questionGroup.questions[j];
              if (questionObj.answer && questionObj.answer !== 'NaN' && questionObj.answer !== 'undefined') this.questionData[questionObj.question] = questionObj.answer as string;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    checkIfShouldShowExpSpecVars() {
      if (this.studyPhaseForUpdate && (this.studyPhaseForUpdate.samples as unknown as { items: any[] }).items.length > 0) return false;
      return true;
    },
    async setQuestions() {
      this.questions = await getQuestions(EntityType.STUDY_PHASE);
    },
    async createStudyPhase() {
      this.loading = true;
      try {
        if (!this.parentStudy) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Error creating study phase!', life: 3000,
          });
          throw new Error('parentStudy was not there in createStudyPhase');
        }
        if (!this.studyPhaseName) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Study Phase Name cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.phase) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Phase cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.description) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Description cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (this.badExperimentSpecificVariables(this.experimentSpecificVariables)) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Error in experiment specific variables!', life: 3000,
          });
          this.loading = false;
          return;
        }
        // if (!this.selectedMetadataTemplate) {
        //   this.$toast.add({
        //     severity: 'error', summary: 'Error', detail: 'Template cannot be empty!', life: 3000,
        //   });
        //   this.loading = false;
        // }
        if (!this.validateStudyPhaseName()) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Validation error!', life: 3000,
          });
          this.loading = false;
          return;
        }
        const newStudyPhaseTemplate: StudyPhaseTemplate = {
          studyPhaseName: this.studyPhaseName,
          phase: this.phase as Phase,
          studyId: this.parentStudy.id,
          studyStudyPhasesId: this.parentStudy.id,
          description: this.description,
          experimentSpecificVariables: JSON.stringify(this.filterOutEmptyExperimentSpecificVariables(this.experimentSpecificVariables)),
          readGroups: [`S/${this.parentStudy.id}/User`],
          writeGroups: [`S/${this.parentStudy.id}/Admin`],
          adminGroups: [`ORG/${this.parentStudy.organization!.id}/Admin`],
          groupNames: [],
          specimens: [],
          sampleTypes: [],
          species: [],
          labAssayTechnologies: [],
          // metaDataValidationSchemaTemplateId: this.selectedMetadataTemplate.id,
          // metaDataValidationSchemaTemplateId: '98436c1b-b5f8-4560-b8fc-f4438d03d458',
          metaDataValidationSchemaTemplateId: this.availableMetadataTemplates[0].id,
          // metaDataValidationSchemaTemplateStudyPhasesId: this.selectedMetadataTemplate.id,
          // metaDataValidationSchemaTemplateStudyPhasesId: '98436c1b-b5f8-4560-b8fc-f4438d03d458',
          metaDataValidationSchemaTemplateStudyPhasesId: this.availableMetadataTemplates[0].id,
        };
        // const validationSchema = await this.getValidationSchema('98436c1b-b5f8-4560-b8fc-f4438d03d458');
        const validationSchema = this.availableMetadataTemplates[0].validationSchemaTemplate;
        if (validationSchema) {
          newStudyPhaseTemplate.validationSchema = validationSchema;
        }
        if (this.questions) {
          newStudyPhaseTemplate.studyPhaseAnswers = JSON.stringify(makeEntityQAObj(this.questions, this.questionData));
        }
        console.log('newStudyPhaseTemplate :>> ', newStudyPhaseTemplate);
        let newStudyPhaseObject: GraphQLResult<any>;
        try {
          newStudyPhaseObject = await API.graphql(graphqlOperation(mutations.createStudyPhase, { input: newStudyPhaseTemplate }));
        } catch (error) {
          console.error(error);
          throw new Error('Failed to create new study phase');
        }
        const newStudyPhaseId = newStudyPhaseObject.data.createStudyPhase.id;
        this.updateStudyPhaseGroups(this.parentStudy.organization!.id, this.parentStudy.id, newStudyPhaseId);
        this.updateStudyGroups(this.parentStudy.id, newStudyPhaseId);
        this.updateOrganizationGroups(this.parentStudy.organization!.id, newStudyPhaseId);
        this.$emit('newStudyPhase', newStudyPhaseObject.data.createStudyPhase as StudyPhase);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'StudyPhase created successfuly!', life: 3000,
        });
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to create studyPhase!', life: 3000,
        });
      }
      this.hideDialog();
    },
    async updateStudyPhaseGroups(organizationId: string, studyId: string, studyPhaseId: string): Promise<void> {
      // const readGroups = [`SP/${newStudyPhaseId}/User`, `S/${this.parentStudy.id}/User`, `ORG/${this.parentStudy.organization!.id}/Admin`];
      // const croGroups = [`CRO/${newStudyPhaseId}`];
      // const writeGroups = [`SP/${newStudyPhaseId}/Admin`, `S/${this.parentStudy.id}/Admin`, `ORG/${this.parentStudy.organization!.id}/Admin`];
      // const adminGroups = [`SP/${newStudyPhaseId}/Admin`, `S/${this.parentStudy.id}/Admin`, `ORG/${this.parentStudy.organization!.id}/Admin`];

      const adminGroups = [`ORG/${organizationId}/Admin`, `S/${studyId}/Admin`];
      const writeGroups = [`SP/${studyPhaseId}/Admin`];
      const readGroups = [`SP/${studyPhaseId}/User`];
      const croGroups = [`CRO/${studyPhaseId}`];
      const updateStudyPhaseObj = {
        id: studyPhaseId,
        readGroups,
        writeGroups,
        croGroups,
        adminGroups,
      };
      try {
        API.graphql(graphqlOperation(mutations.updateStudyPhase, { input: updateStudyPhaseObj }));
      } catch (error) {
        console.error(error);
        console.error('Failed to update study phase groups in create study phase');
      }
    },
    async updateStudyGroups(studyId: string, studyPhaseId: string): Promise<void> {
      // const studyReadGroups = (this.parentStudy.readGroups) ? this.parentStudy.readGroups : [];
      // const studyCROGroups = (this.parentStudy.croGroups) ? this.parentStudy.croGroups : [];
      // const studyWriteGroups = (this.parentStudy.writeGroups) ? this.parentStudy.writeGroups : [];
      // const studyAdminGroups = (this.parentStudy.adminGroups) ? this.parentStudy.writeGroups : [];
      // studyReadGroups?.push(`SP/${newStudyPhaseId}/User`);
      // studyCROGroups?.push(`CRO/${newStudyPhaseId}`);
      // studyWriteGroups?.push(`SP/${newStudyPhaseId}/Admin`);
      // studyAdminGroups?.push(`SP/${newStudyPhaseId}/Admin`);
      // const updateStudyObject = {
      //   id: this.parentStudy.id,
      //   readGroups: studyReadGroups,
      //   croGroups: studyCROGroups,
      //   writeGroups: studyWriteGroups,
      //   adminGroups: studyAdminGroups,
      // };
      // try {
      //   API.graphql(graphqlOperation(mutations.updateStudy, { input: updateStudyObject }));
      // } catch (error) {
      //   console.error('Failed to update study groups in create study phase');
      // }
      updateDynamoDBDirectly(studyId, 'Study', [{ field: 'readGroups', values: [`SP/${studyPhaseId}/Admin`, `SP/${studyPhaseId}/User`] }, { field: 'croGroups', values: [`CRO/${studyPhaseId}`] }]);
    },
    async updateOrganizationGroups(organizationId: string, studyPhaseId: string): Promise<void> {
      // const orgReadGroups = (this.parentStudy.organization?.readGroups) ? this.parentStudy.organization?.readGroups : [];
      // const orgWriteGroups = (this.parentStudy.organization?.writeGroups) ? this.parentStudy.organization?.writeGroups : [];
      // orgReadGroups?.push(`SP/${newStudyPhaseId}/User`, `CRO/${newStudyPhaseId}`);
      // orgWriteGroups?.push(`SP/${newStudyPhaseId}/Admin`);
      // const updateOrgObj = {
      //   id: this.parentStudy.organization?.id,
      //   readGroups: orgReadGroups,
      //   writeGroups: orgWriteGroups,
      // };
      // try {
      //   API.graphql(graphqlOperation(mutations.updateOrganization, { input: updateOrgObj }));
      // } catch (error) {
      //   console.error('Failed to update organization groups in create study phase');
      // }
      updateDynamoDBDirectly(organizationId, 'Organization', [{ field: 'readGroups', values: [`SP/${studyPhaseId}/Admin`, `SP/${studyPhaseId}/User`, `CRO/${studyPhaseId}`] }]);
    },
    async getValidationSchema(metadataTemplateId: string): Promise<string | null> {
      try {
        const metadataTemplate: MetaDataValidationSchemaTemplate = (await API.graphql(graphqlOperation(queries.getMetaDataValidationSchemaTemplate, { id: metadataTemplateId })) as GraphQLResult<any>).data.getMetaDataValidationSchemaTemplate;
        if (metadataTemplate && metadataTemplate.validationSchemaTemplate) {
          return metadataTemplate.validationSchemaTemplate;
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async updateStudyPhase() {
      this.loading = true;
      try {
        if (!this.studyPhaseName) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Study phase name cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.validateStudyPhaseName()) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Validation error!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (this.badExperimentSpecificVariables(this.experimentSpecificVariables)) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Error in experiment specific variables!', life: 3000,
          });
          this.loading = false;
          return;
        }
        const updateStudyPhaseTemplate: StudyPhaseTemplate = {};
        updateStudyPhaseTemplate.id = this.studyPhaseForUpdate?.id;
        if (this.questions) {
          updateStudyPhaseTemplate.studyPhaseAnswers = JSON.stringify(makeEntityQAObj(this.questions, this.questionData));
        }
        if (this.studyPhaseName && this.studyPhaseName !== this.studyPhaseForUpdate!.studyPhaseName) {
          updateStudyPhaseTemplate.studyPhaseName = this.studyPhaseName;
        }
        if (this.description !== this.studyPhaseForUpdate!.description) {
          updateStudyPhaseTemplate.description = this.description;
        }
        console.log('HERE');
        console.log(this.experimentSpecificVariables);
        updateStudyPhaseTemplate.experimentSpecificVariables = JSON.stringify(this.filterOutEmptyExperimentSpecificVariables(this.experimentSpecificVariables));
        const updatedStudyPhase: GraphQLResult<any> = await API.graphql(graphqlOperation(mutations.updateStudyPhase, { input: updateStudyPhaseTemplate }));
        this.$emit('updateStudyPhase', updatedStudyPhase.data.updateStudyPhase);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Study phase updated successfuly!', life: 3000,
        });
      } catch (error) {
        console.log('errorhere');
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update study phase!', life: 3000,
        });
      }
      this.hideDialog();
    },
    badExperimentSpecificVariables(experimentSpecificVariables: ExperimentSpecificVariable[]): boolean {
      if (experimentSpecificVariables.some((expSpecVar: ExperimentSpecificVariable) => (expSpecVar.name === '' || expSpecVar.type === '') && !(this.isEmptyExperimentSpecificVariableObj(expSpecVar)))) return true;
      console.log(experimentSpecificVariables);
      return false;
    },
    isEmptyExperimentSpecificVariableObj(expSpecVar: ExperimentSpecificVariable): boolean {
      return expSpecVar.name === '' && expSpecVar.type === '' && expSpecVar.description === '';
    },
    filterOutEmptyExperimentSpecificVariables(experimentSpecificVariables: ExperimentSpecificVariable[]): ExperimentSpecificVariable[] {
      // eslint-disable-next-line no-param-reassign
      experimentSpecificVariables = experimentSpecificVariables.filter((expSpecVar: ExperimentSpecificVariable) => !this.isEmptyExperimentSpecificVariableObj(expSpecVar));
      return experimentSpecificVariables;
    },
    formatPhaseName(phaseValue: string): string {
      try {
        switch (phaseValue) {
          case 'PRE_CLINICAL': {
            return 'Pre Clinical';
          }
          case 'PHASE_1': {
            return 'Phase 1';
          }
          case 'PHASE_2': {
            return 'Phase 2';
          }
          case 'PHASE_3': {
            return 'Phase 3';
          }
          default: {
            return '';
          }
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async loadMetadataTemplates() {
      try {
        const allMetadataTemplates = await listItems(customQueries.listMetaDataValidationSchemaTemplatesForStudyPhaseCreation);
        this.availableMetadataTemplates = allMetadataTemplates as MetaDataValidationSchemaTemplate[];
        console.log('allMetadataTemplates :>> ', allMetadataTemplates);
      } catch (error) {
        console.error(error);
      }
    },
    validateStudyPhaseName() {
      return validateInputName(this.studyPhaseName! as string);
    },
    updateQuestionData({ id, value }: {id: string, value: any}) {
      this.questionData[id as keyof Object] = value;
    },
    hideDialog() {
      this.studyPhaseName = null;
      this.phase = null;
      this.loading = false;
      this.activeTabIndex = 0;
      this.description = undefined;
      this.shouldShowExpSpecVars = true;
      this.$emit('clearStudyPhaseForUpdate');
      this.$store.dispatch('setShowingStudyPhaseDialog', false);
      this.experimentSpecificVariables = [
        {
          name: '',
          type: '',
          description: '',
          value: null,
        },
      ];
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingStudyPhaseDialog': async function () {
      this.showing = this.$store.state.showingStudyPhaseDialog;
      if (this.showing) this.main();
    },
  },
});
