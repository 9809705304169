
import { Batch, EntityType, StudyPhase } from '@/models';
import { listItems, formatISOStringCustom, formatListForView } from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
import { FilterMatchMode } from 'primevue/api';
import StudyPhaseDialog from '@/components/StudyPhase/StudyPhaseDialog.vue';
import BatchDialog from '@/components/Batch/BatchDialog.vue';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import LaunchPipelineDialog from '../Pipeline/LaunchPipelineDialog.vue';

export default defineComponent({
  name: 'Study Phase Table',
  components: {
    StudyPhaseDialog,
    LaunchPipelineDialog,
    BatchDialog,
  },
  data() {
    return {
      studyPhases: [] as StudyPhase[],
      loading: false,
      studyPhaseForUpdate: null as unknown as StudyPhase,
      studyPhaseIdForBatchCreation: undefined as string | undefined,
      filters: {
        studyPhaseName: { value: '', matchMode: 'contains' },
        phase: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      phaseDDOptions: [
        'PRE_CLINICAL', 'PHASE_1', 'PHASE_2', 'PHASE_3',
      ],
      items: [
        {
          label: 'temp',
        },
      ],
      studyId: '' as string,
    };
  },
  async mounted() {
    if (this.$route.params.studyId) {
      await this.loadStudyPhasesForStudy(this.$route.params.studyId as string);
      if (this.studyPhases.length > 0) this.updateBreadCrumbWithStudyName(this.studyPhases[0]!.study!.studyName);
      this.studyId = this.$route.params.studyId as string;
    }
  },
  methods: {
    async loadStudyPhasesForStudy(studyId: string): Promise<void> {
      this.loading = true;
      try {
        let studyPhases: StudyPhase[];
        if (studyId) {
          studyPhases = await listItems(customQueries.studyPhasesByStudyForStudyPhaseBrowseWithSamples, { studyId, sortDirection: 'DESC' });
        } else {
          throw new Error('No study id found');
        }
        console.log('studyPhases :>> ', studyPhases);
        this.studyPhases = studyPhases;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    // eslint-disable-next-line no-unused-vars
    pushNewStudyPhaseToTopOfTable(studyPhase: StudyPhase): void {
      try {
        // if (studyPhase) this.studyPhases.unshift(studyPhase);
        this.loadStudyPhasesForStudy(this.$route.params.studyId as string);
      } catch (error) {
        console.error(error);
      }
    },
    updateStudyPhaseInTable(studyPhase: StudyPhase): void {
      try {
        if (studyPhase) {
          const studyPhaseToUpdateIndex: number | null | undefined = this.studyPhases.findIndex((sp) => sp.id === studyPhase.id);
          if (studyPhaseToUpdateIndex !== -1) {
            let newStudyPhaseObject = this.studyPhases[studyPhaseToUpdateIndex];
            newStudyPhaseObject = studyPhase;
            this.studyPhases[studyPhaseToUpdateIndex] = newStudyPhaseObject;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    clearStudyPhaseForUpdate() {
      this.studyPhaseForUpdate = null as unknown as any; // This is horrible but it must be done for the sake of time. Fix later. Higher powers will forgive me.
    },
    async deleteStudyPhase(studyPhase: StudyPhase): Promise<void> {
      try {
        this.studyPhases = this.studyPhases.filter((sp) => sp.id !== studyPhase.id);
        API.graphql(graphqlOperation(mutations.deleteStudyPhase, { input: { id: studyPhase.id } }));
        const study = (await API.graphql(graphqlOperation(customQueries.getStudyBasicWithGroups, { id: studyPhase.studyId })) as GraphQLResult<any>).data.getStudy;
        let studyReadGroups = (study.readGroups) ? study.readGroups : [];
        let studyCROGroups = (study.croGroups) ? study.croGroups : [];
        let studyWriteGroups = (study.writeGroups) ? study.writeGroups : [];
        let studyAdminGroups = (study.adminGroups) ? study.adminGroups : [];
        studyReadGroups = studyReadGroups?.filter((grp: string) => grp !== `SP/${studyPhase.id}/User`);
        studyCROGroups = studyCROGroups?.filter((grp: string) => grp !== `CRO/${studyPhase.id}/`);
        studyWriteGroups = studyWriteGroups?.filter((grp: string) => grp !== `SP/${studyPhase.id}/Admin`);
        studyAdminGroups = studyAdminGroups?.filter((grp: string) => grp !== `SP/${studyPhase.id}/Admin`);
        const updateStudyObject = {
          id: study.id,
          readGroups: studyReadGroups,
          croGroups: studyCROGroups,
          writeGroups: studyWriteGroups,
          adminGroups: studyAdminGroups,
        };
        try {
          API.graphql(graphqlOperation(mutations.updateStudy, { input: updateStudyObject }));
        } catch (error) {
          console.error('Failed to update study groups in create study phase');
        }
        let orgReadGroups = (study.organization?.readGroups) ? study.organization?.readGroups : [];
        let orgWriteGroups = (study.organization?.writeGroups) ? study.organization?.writeGroups : [];
        orgReadGroups = orgReadGroups?.filter((grp: string) => grp !== `SP/${studyPhase.id}/User` && grp !== `CRO/${studyPhase.id}`);
        orgWriteGroups = orgWriteGroups?.filter((grp: string) => grp !== `SP/${studyPhase.id}/Admin`);
        const updateOrgObj = {
          id: study.organization?.id,
          readGroups: orgReadGroups,
          writeGroups: orgWriteGroups,
        };
        try {
          API.graphql(graphqlOperation(mutations.updateOrganization, { input: updateOrgObj }));
        } catch (error) {
          console.error('Failed to update organization groups in create study phase');
        }
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Study phase deleted successfuly!', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to delete study!', life: 3000,
        });
      }
    },
    studyPhaseActionClick(studyPhase: StudyPhase, event: MouseEvent) {
      (this.$refs[`study-menu-${studyPhase.id}`] as any).toggle(event);
    },
    studyPhaseActionItems(studyPhase: StudyPhase) {
      const explore = {
        label: 'Explore Files',
        command: () => {
          this.exploreStudyPhase(`${studyPhase!.study!.organizationId}!${studyPhase.studyId}!${studyPhase.id}`);
        },
      };
      const update = {
        label: 'Update',
        command: () => {
          this.studyPhaseForUpdate = studyPhase;
          this.openStudyPhaseDialog();
        },
      };
      const details = {
        label: 'Details',
        command: () => {
          this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/entytyDetailTabs/${EntityType.STUDY_PHASE}/${studyPhase.id}`);
        },
      };
      const createStudyPhaseBatch = {
        label: 'Create Study Phase Batch',
        command: () => {
          this.studyPhaseIdForBatchCreation = studyPhase.id;
          this.openBatchDialog();
        },
      };
      // eslint-disable-next-line no-unused-vars
      const deleteStudyPhase = {
        label: 'Delete',
        command: () => {
          this.$confirm.require({
            message: 'Are you sure you want to proceed?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
              this.deleteStudyPhase(studyPhase);
            },
            reject: () => {
            },
          });
        },
      };
      const options = [details];
      if (this.$store.state.precedenceLevel <= 6) options.push(explore);
      if (this.$store.state.precedenceLevel <= 2) options.push(...[update]);
      if (this.$store.state.precedenceLevel <= 5) options.push(createStudyPhaseBatch);
      return options;
    },
    async exploreStudy(): Promise<void> {
      try {
        const orgId: string = (await this.$store.state.activeOrganization).id;
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/explore/${orgId}!${this.$route.params.studyId as string}` });
      } catch (error) {
        console.error(error);
      }
    },
    async exploreStudyPhase(id: string): Promise<void> {
      try {
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/explore/${id}` });
      } catch (error) {
        console.error(error);
      }
    },
    newBatch(newBatch: Batch) {
      try {
        const found = this.studyPhases.find((sp) => sp.id === newBatch.studyPhaseId);
        (found?.batches as any).items.push(newBatch);
      } catch (error) {
        console.error(error);
      }
    },
    updateBreadCrumbWithStudyName(studyName: string) {
      this.$emit('updateBreadCrumbWithStudyName', studyName);
    },
    openStudyPhaseDialog() {
      this.$store.dispatch('setShowingStudyPhaseDialog', true);
    },
    openBatchDialog() {
      console.log(this.studyPhases);
      this.$store.dispatch('setShowingBatchDialog', true);
      console.log(this.$store.state.setShowingBatchDialog);
    },
    formatListForView(list: string[]) {
      return formatListForView(list);
    },
    formatPhaseName(phaseValue: string): string {
      try {
        switch (phaseValue) {
          case 'PRE_CLINICAL': {
            return 'Pre Clinical';
          }
          case 'PHASE_1': {
            return 'Phase 1';
          }
          case 'PHASE_2': {
            return 'Phase 2';
          }
          case 'PHASE_3': {
            return 'Phase 3';
          }
          default: {
            return '';
          }
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    onStudyPhaseSelect(event: any) {
      if (event.data) {
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/samplesBrowse/studyPhase/${event.data.id}` });
      }
    },
  },
  watch: {
  // eslint-disable-next-line func-names
    '$store.state.activeOrganization': async function () {
      // const activeOrganization = await this.$store.state.activeOrganization;
      // if (activeOrganization) this.loadStudiesForOrg(activeOrganization.id);
    },
  },
});
