/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryS3UploadPathsAndValidationSchema = /* GraphQL */ `
  query QueryS3UploadPathsAndValidationSchema($event: AWSJSON!) {
    queryS3UploadPathsAndValidationSchema(event: $event)
  }
`;
export const queryTriggerMetaDataUpload = /* GraphQL */ `
  query QueryTriggerMetaDataUpload($event: AWSJSON!) {
    queryTriggerMetaDataUpload(event: $event)
  }
`;
export const generatePlotlyJWT = /* GraphQL */ `
  query GeneratePlotlyJWT($event: AWSJSON!) {
    generatePlotlyJWT(event: $event)
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      organizationName
      organizationCode
      active
      studies {
        items {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        nextToken
      }
      launchablePipelines {
        items {
          id
          organizationId
          launchablePipelinesId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationEmail
      description
      organizationAnswers
      url
      s3Path
      readGroups
      writeGroups
      created
      updated
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationName
        organizationCode
        active
        studies {
          nextToken
        }
        launchablePipelines {
          nextToken
        }
        notificationEmail
        description
        organizationAnswers
        url
        s3Path
        readGroups
        writeGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const getStudy = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      studyName
      organizationId
      organization {
        id
        organizationName
        organizationCode
        active
        studies {
          nextToken
        }
        launchablePipelines {
          nextToken
        }
        notificationEmail
        description
        organizationAnswers
        url
        s3Path
        readGroups
        writeGroups
        created
        updated
      }
      studyPhases {
        items {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        nextToken
      }
      batches {
        items {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          name
          status
          description
          s3Event
          s3ParamsFile
          s3Results
          visFileInfoS3path
          s3NfLog
          s3NfTasksLogs
          analysisType
          subtypeOfAnalysis
          parameters
          studyId
          studyPhaseId
          scheduledPipeline
          scheduledDateTime
          launchablePipelineId
          batchId
          runId
          userEmail
          pipelineStarted
          pipelineCompleted
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPipelinesId
          studyPhasePipelinesId
          batchPipelinesId
          launchablePipelinesPipelinesId
        }
        nextToken
      }
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
    }
  }
`;
export const listStudies = /* GraphQL */ `
  query ListStudies(
    $id: ID
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      nextToken
    }
  }
`;
export const getStudyPhase = /* GraphQL */ `
  query GetStudyPhase($id: ID!) {
    getStudyPhase(id: $id) {
      id
      studyId
      study {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      metaDataValidationSchemaTemplateId
      metaDataValidationSchemaTemplate {
        id
        studyPhases {
          nextToken
        }
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
      }
      groups {
        items {
          id
          studyPhaseId
          type
          groupName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseGroupsId
        }
        nextToken
      }
      samples {
        items {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        nextToken
      }
      batches {
        items {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          name
          status
          description
          s3Event
          s3ParamsFile
          s3Results
          visFileInfoS3path
          s3NfLog
          s3NfTasksLogs
          analysisType
          subtypeOfAnalysis
          parameters
          studyId
          studyPhaseId
          scheduledPipeline
          scheduledDateTime
          launchablePipelineId
          batchId
          runId
          userEmail
          pipelineStarted
          pipelineCompleted
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPipelinesId
          studyPhasePipelinesId
          batchPipelinesId
          launchablePipelinesPipelinesId
        }
        nextToken
      }
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
    }
  }
`;
export const listStudyPhases = /* GraphQL */ `
  query ListStudyPhases(
    $id: ID
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudyPhases(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      nextToken
    }
  }
`;
export const getEntityQuestions = /* GraphQL */ `
  query GetEntityQuestions($id: ID!) {
    getEntityQuestions(id: $id) {
      id
      entityType
      questions
      created
      updated
    }
  }
`;
export const listEntityQuestions = /* GraphQL */ `
  query ListEntityQuestions(
    $id: ID
    $filter: ModelEntityQuestionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEntityQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        entityType
        questions
        created
        updated
      }
      nextToken
    }
  }
`;
export const getMetaDataValidationSchemaTemplate = /* GraphQL */ `
  query GetMetaDataValidationSchemaTemplate($id: ID!) {
    getMetaDataValidationSchemaTemplate(id: $id) {
      id
      studyPhases {
        items {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        nextToken
      }
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
    }
  }
`;
export const listMetaDataValidationSchemaTemplates = /* GraphQL */ `
  query ListMetaDataValidationSchemaTemplates(
    $id: ID
    $filter: ModelMetaDataValidationSchemaTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMetaDataValidationSchemaTemplates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyPhases {
          nextToken
        }
        version
        description
        templateFiles
        validationSchemaTemplate
        created
        updated
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      studyPhaseId
      studyPhase {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      type
      groupName
      s3Path
      patients {
        items {
          id
          groupId
          patientStudyPhaseId
          patientStudyId
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          groupPatientsId
        }
        nextToken
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $id: ID
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        type
        groupName
        s3Path
        patients {
          nextToken
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
      }
      nextToken
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      groupId
      group {
        id
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        type
        groupName
        s3Path
        patients {
          nextToken
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
      }
      timepoints {
        items {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        nextToken
      }
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $id: ID
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPatients(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        groupId
        group {
          id
          studyPhaseId
          type
          groupName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseGroupsId
        }
        timepoints {
          nextToken
        }
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
      }
      nextToken
    }
  }
`;
export const getTimepoint = /* GraphQL */ `
  query GetTimepoint($id: ID!) {
    getTimepoint(id: $id) {
      id
      patientId
      patient {
        id
        groupId
        group {
          id
          studyPhaseId
          type
          groupName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseGroupsId
        }
        timepoints {
          nextToken
        }
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
      }
      samples {
        items {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        nextToken
      }
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
    }
  }
`;
export const listTimepoints = /* GraphQL */ `
  query ListTimepoints(
    $id: ID
    $filter: ModelTimepointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTimepoints(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        patientId
        patient {
          id
          groupId
          patientStudyPhaseId
          patientStudyId
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          groupPatientsId
        }
        samples {
          nextToken
        }
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
      }
      nextToken
    }
  }
`;
export const getSample = /* GraphQL */ `
  query GetSample($id: ID!) {
    getSample(id: $id) {
      id
      timepointId
      timepoint {
        id
        patientId
        patient {
          id
          groupId
          patientStudyPhaseId
          patientStudyId
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          groupPatientsId
        }
        samples {
          nextToken
        }
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
      }
      studyPhaseId
      StudyPhase {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      BatchId
      Batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        samples {
          nextToken
        }
        pipelines {
          nextToken
        }
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
      }
      metadataImmunopeptidomicsList {
        items {
          id
          sampleId
          metaDataTemplateS3Path
          species
          subtypeOfAnalysis
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataImmunopeptidomicsListId
        }
        nextToken
      }
      MetadataNanoStringList {
        items {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          dv200Percentage
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataNanoStringListId
        }
        nextToken
      }
      MetadataRnaSeqList {
        items {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          sequenceType
          strandSpecific
          UMI
          adaptorsRemoved
          adaptorsS3Path
          indexSequences
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataRnaSeqListId
        }
        nextToken
      }
      AuxiliaryAnalysislList {
        items {
          id
          sampleId
          auxiliaryFileS3Path
          vendorId
          specimenType
          parameters
          collectionDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          sampleAuxiliaryAnalysislListId
        }
        nextToken
      }
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
    }
  }
`;
export const listSamples = /* GraphQL */ `
  query ListSamples(
    $id: ID
    $filter: ModelSampleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSamples(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      nextToken
    }
  }
`;
export const getBatch = /* GraphQL */ `
  query GetBatch($id: ID!) {
    getBatch(id: $id) {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      samples {
        items {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        nextToken
      }
      pipelines {
        items {
          id
          name
          status
          description
          s3Event
          s3ParamsFile
          s3Results
          visFileInfoS3path
          s3NfLog
          s3NfTasksLogs
          analysisType
          subtypeOfAnalysis
          parameters
          studyId
          studyPhaseId
          scheduledPipeline
          scheduledDateTime
          launchablePipelineId
          batchId
          runId
          userEmail
          pipelineStarted
          pipelineCompleted
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPipelinesId
          studyPhasePipelinesId
          batchPipelinesId
          launchablePipelinesPipelinesId
        }
        nextToken
      }
      studyId
      study {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
    }
  }
`;
export const listBatches = /* GraphQL */ `
  query ListBatches(
    $id: ID
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBatches(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        samples {
          nextToken
        }
        pipelines {
          nextToken
        }
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
      }
      nextToken
    }
  }
`;
export const getPipeline = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      subtypeOfAnalysis
      parameters
      studyId
      study {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      studyPhaseId
      studyPhase {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      launchablePipeline {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      batchId
      batch {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        samples {
          nextToken
        }
        pipelines {
          nextToken
        }
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
      }
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;
export const listPipelines = /* GraphQL */ `
  query ListPipelines(
    $id: ID
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        batchId
        batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;
export const getAuxiliaryAnalysis = /* GraphQL */ `
  query GetAuxiliaryAnalysis($id: ID!) {
    getAuxiliaryAnalysis(id: $id) {
      id
      sampleId
      sample {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      auxiliaryFileS3Path
      vendorId
      specimenType
      parameters
      collectionDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      sampleAuxiliaryAnalysislListId
    }
  }
`;
export const listAuxiliaryAnalyses = /* GraphQL */ `
  query ListAuxiliaryAnalyses(
    $id: ID
    $filter: ModelAuxiliaryAnalysisFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAuxiliaryAnalyses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        auxiliaryFileS3Path
        vendorId
        specimenType
        parameters
        collectionDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        sampleAuxiliaryAnalysislListId
      }
      nextToken
    }
  }
`;
export const getMetadataImmunopeptidomics = /* GraphQL */ `
  query GetMetadataImmunopeptidomics($id: ID!) {
    getMetadataImmunopeptidomics(id: $id) {
      id
      sampleId
      sample {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      treatment {
        items {
          id
          metadataImmunopeptidomicsId
          metadataNanoStringId
          metadataRnaSeqId
          treatmentName
          dose
          doseUnit
          readGroups
          writeGroups
          adminGroups
          created
          updated
          metadataImmunopeptidomicsTreatmentId
          metadataNanoStringTreatmentId
          metadataRnaSeqTreatmentId
        }
        nextToken
      }
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
    }
  }
`;
export const listMetadataImmunopeptidomics = /* GraphQL */ `
  query ListMetadataImmunopeptidomics(
    $id: ID
    $filter: ModelMetadataImmunopeptidomicsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMetadataImmunopeptidomics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
      }
      nextToken
    }
  }
`;
export const getMetadataNanoString = /* GraphQL */ `
  query GetMetadataNanoString($id: ID!) {
    getMetadataNanoString(id: $id) {
      id
      sampleId
      sample {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      treatment {
        items {
          id
          metadataImmunopeptidomicsId
          metadataNanoStringId
          metadataRnaSeqId
          treatmentName
          dose
          doseUnit
          readGroups
          writeGroups
          adminGroups
          created
          updated
          metadataImmunopeptidomicsTreatmentId
          metadataNanoStringTreatmentId
          metadataRnaSeqTreatmentId
        }
        nextToken
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
    }
  }
`;
export const listMetadataNanoStrings = /* GraphQL */ `
  query ListMetadataNanoStrings(
    $id: ID
    $filter: ModelMetadataNanoStringFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMetadataNanoStrings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
      }
      nextToken
    }
  }
`;
export const getMetadataRnaSeq = /* GraphQL */ `
  query GetMetadataRnaSeq($id: ID!) {
    getMetadataRnaSeq(id: $id) {
      id
      sampleId
      sample {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      treatment {
        items {
          id
          metadataImmunopeptidomicsId
          metadataNanoStringId
          metadataRnaSeqId
          treatmentName
          dose
          doseUnit
          readGroups
          writeGroups
          adminGroups
          created
          updated
          metadataImmunopeptidomicsTreatmentId
          metadataNanoStringTreatmentId
          metadataRnaSeqTreatmentId
        }
        nextToken
      }
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
    }
  }
`;
export const listMetadataRnaSeqs = /* GraphQL */ `
  query ListMetadataRnaSeqs(
    $id: ID
    $filter: ModelMetadataRnaSeqFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMetadataRnaSeqs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
      }
      nextToken
    }
  }
`;
export const getTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      metadataImmunopeptidomicsId
      metadataImmunopeptidomics {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
      }
      metadataNanoStringId
      metadataNanoString {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
      }
      metadataRnaSeqId
      metadataRnaSeq {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
      }
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
    }
  }
`;
export const listTreatments = /* GraphQL */ `
  query ListTreatments(
    $id: ID
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTreatments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        metadataImmunopeptidomicsId
        metadataImmunopeptidomics {
          id
          sampleId
          metaDataTemplateS3Path
          species
          subtypeOfAnalysis
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataImmunopeptidomicsListId
        }
        metadataNanoStringId
        metadataNanoString {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          dv200Percentage
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataNanoStringListId
        }
        metadataRnaSeqId
        metadataRnaSeq {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          sequenceType
          strandSpecific
          UMI
          adaptorsRemoved
          adaptorsS3Path
          indexSequences
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataRnaSeqListId
        }
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
      }
      nextToken
    }
  }
`;
export const getLaunchablePipelines = /* GraphQL */ `
  query GetLaunchablePipelines($id: ID!) {
    getLaunchablePipelines(id: $id) {
      id
      pipelineName
      organizations {
        items {
          id
          organizationId
          launchablePipelinesId
          createdAt
          updatedAt
        }
        nextToken
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      pipelines {
        items {
          id
          name
          status
          description
          s3Event
          s3ParamsFile
          s3Results
          visFileInfoS3path
          s3NfLog
          s3NfTasksLogs
          analysisType
          subtypeOfAnalysis
          parameters
          studyId
          studyPhaseId
          scheduledPipeline
          scheduledDateTime
          launchablePipelineId
          batchId
          runId
          userEmail
          pipelineStarted
          pipelineCompleted
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPipelinesId
          studyPhasePipelinesId
          batchPipelinesId
          launchablePipelinesPipelinesId
        }
        nextToken
      }
      gitRepo
      workflowId
      created
      updated
    }
  }
`;
export const listLaunchablePipelines = /* GraphQL */ `
  query ListLaunchablePipelines(
    $id: ID
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLaunchablePipelines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      organization
      readGroups
      created
      updated
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        organization
        readGroups
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const getUploadLog = /* GraphQL */ `
  query GetUploadLog($id: ID!) {
    getUploadLog(id: $id) {
      id
      uploadS3Path
      status
      readGroups
      created
      updated
    }
  }
`;
export const listUploadLogs = /* GraphQL */ `
  query ListUploadLogs(
    $id: ID
    $filter: ModelUploadLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUploadLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        uploadS3Path
        status
        readGroups
        created
        updated
      }
      nextToken
    }
  }
`;
export const getVisualizations = /* GraphQL */ `
  query GetVisualizations($id: ID!) {
    getVisualizations(id: $id) {
      id
      visualizationName
      description
      version
      deploymentPath
      validationSchema
      allowedExtensions
      supportedAnalyses
      created
      updated
    }
  }
`;
export const listVisualizations = /* GraphQL */ `
  query ListVisualizations(
    $id: ID
    $filter: ModelVisualizationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVisualizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        visualizationName
        description
        version
        deploymentPath
        validationSchema
        allowedExtensions
        supportedAnalyses
        created
        updated
      }
      nextToken
    }
  }
`;
export const getVisualizationSession = /* GraphQL */ `
  query GetVisualizationSession($id: ID!) {
    getVisualizationSession(id: $id) {
      id
      statuses
      data
      description
      created
      updated
      owner
    }
  }
`;
export const listVisualizationSessions = /* GraphQL */ `
  query ListVisualizationSessions(
    $filter: ModelVisualizationSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisualizationSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        statuses
        data
        description
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const getOrganizationLaunchablePipelines = /* GraphQL */ `
  query GetOrganizationLaunchablePipelines($id: ID!) {
    getOrganizationLaunchablePipelines(id: $id) {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        studies {
          nextToken
        }
        launchablePipelines {
          nextToken
        }
        notificationEmail
        description
        organizationAnswers
        url
        s3Path
        readGroups
        writeGroups
        created
        updated
      }
      launchablePipelines {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizationLaunchablePipelines = /* GraphQL */ `
  query ListOrganizationLaunchablePipelines(
    $filter: ModelOrganizationLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationLaunchablePipelines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        launchablePipelinesId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        launchablePipelines {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const studiesByOrganization = /* GraphQL */ `
  query StudiesByOrganization(
    $organizationId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studiesByOrganization(
      organizationId: $organizationId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyName
        organizationId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        studyPhases {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        description
        studyCode
        studyAnswers
        s3Path
        croGroups
        readGroups
        writeGroups
        adminGroups
        created
        updated
        organizationStudiesId
      }
      nextToken
    }
  }
`;
export const studyPhasesByStudy = /* GraphQL */ `
  query StudyPhasesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStudyPhaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    studyPhasesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        metaDataValidationSchemaTemplateId
        metaDataValidationSchemaTemplate {
          id
          version
          description
          templateFiles
          validationSchemaTemplate
          created
          updated
        }
        groups {
          nextToken
        }
        samples {
          nextToken
        }
        batches {
          nextToken
        }
        pipelines {
          nextToken
        }
        validationSchema
        description
        studyPhaseName
        studyPhaseAnswers
        experimentSpecificVariables
        sampleTypes
        specimens
        species
        s3Path
        groupNames
        labAssayTechnologies
        phase
        readGroups
        writeGroups
        adminGroups
        croGroups
        created
        updated
        studyStudyPhasesId
        metaDataValidationSchemaTemplateStudyPhasesId
      }
      nextToken
    }
  }
`;
export const questionsByEntityType = /* GraphQL */ `
  query QuestionsByEntityType(
    $entityType: EntityType!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEntityQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByEntityType(
      entityType: $entityType
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityType
        questions
        created
        updated
      }
      nextToken
    }
  }
`;
export const groupsByStudyPhase = /* GraphQL */ `
  query GroupsByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    groupsByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        type
        groupName
        s3Path
        patients {
          nextToken
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseGroupsId
      }
      nextToken
    }
  }
`;
export const patientsByGroup = /* GraphQL */ `
  query PatientsByGroup(
    $groupId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientsByGroup(
      groupId: $groupId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        group {
          id
          studyPhaseId
          type
          groupName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseGroupsId
        }
        timepoints {
          nextToken
        }
        patientStudyPhaseId
        patientStudyId
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        groupPatientsId
      }
      nextToken
    }
  }
`;
export const timepointsByPatient = /* GraphQL */ `
  query TimepointsByPatient(
    $patientId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTimepointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    timepointsByPatient(
      patientId: $patientId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        patientId
        patient {
          id
          groupId
          patientStudyPhaseId
          patientStudyId
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          groupPatientsId
        }
        samples {
          nextToken
        }
        timepointName
        s3Path
        readGroups
        writeGroups
        adminGroups
        created
        updated
        patientTimepointsId
      }
      nextToken
    }
  }
`;
export const samplesByTimepoint = /* GraphQL */ `
  query SamplesByTimepoint(
    $timepointId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    samplesByTimepoint(
      timepointId: $timepointId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      nextToken
    }
  }
`;
export const samplesByStudyPhase = /* GraphQL */ `
  query SamplesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    samplesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      nextToken
    }
  }
`;
export const samplesByBatch = /* GraphQL */ `
  query SamplesByBatch(
    $BatchId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    samplesByBatch(
      BatchId: $BatchId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      nextToken
    }
  }
`;
export const sampleByDomainSampleId = /* GraphQL */ `
  query SampleByDomainSampleId(
    $domainSampleId: String!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSampleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sampleByDomainSampleId(
      domainSampleId: $domainSampleId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timepointId
        timepoint {
          id
          patientId
          timepointName
          s3Path
          readGroups
          writeGroups
          adminGroups
          created
          updated
          patientTimepointsId
        }
        studyPhaseId
        StudyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        BatchId
        Batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        metadataImmunopeptidomicsList {
          nextToken
        }
        MetadataNanoStringList {
          nextToken
        }
        MetadataRnaSeqList {
          nextToken
        }
        AuxiliaryAnalysislList {
          nextToken
        }
        domainSampleId
        experimentSpecificVariables
        collectionDate
        processingDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPhaseSamplesId
        timepointSamplesId
        batchSamplesId
      }
      nextToken
    }
  }
`;
export const batchesByStudy = /* GraphQL */ `
  query BatchesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batchesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        samples {
          nextToken
        }
        pipelines {
          nextToken
        }
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
      }
      nextToken
    }
  }
`;
export const batchesByStudyPhase = /* GraphQL */ `
  query BatchesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batchesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        batchName
        batchAnnotations
        batchGroups
        annotations
        controlSamples
        filters
        description
        samples {
          nextToken
        }
        pipelines {
          nextToken
        }
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyBatchesId
        studyPhaseBatchesId
      }
      nextToken
    }
  }
`;
export const pipelinesByStudy = /* GraphQL */ `
  query PipelinesByStudy(
    $studyId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByStudy(
      studyId: $studyId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        batchId
        batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;
export const pipelinesByStudyPhase = /* GraphQL */ `
  query PipelinesByStudyPhase(
    $studyPhaseId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByStudyPhase(
      studyPhaseId: $studyPhaseId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        batchId
        batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;
export const pipelinesByBLaunchablePipelines = /* GraphQL */ `
  query PipelinesByBLaunchablePipelines(
    $launchablePipelineId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByBLaunchablePipelines(
      launchablePipelineId: $launchablePipelineId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        batchId
        batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;
export const pipelinesByBatch = /* GraphQL */ `
  query PipelinesByBatch(
    $batchId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelinesByBatch(
      batchId: $batchId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        batchId
        batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;
export const pipelineByRunId = /* GraphQL */ `
  query PipelineByRunId(
    $runId: String!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pipelineByRunId(
      runId: $runId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        description
        s3Event
        s3ParamsFile
        s3Results
        visFileInfoS3path
        s3NfLog
        s3NfTasksLogs
        analysisType
        subtypeOfAnalysis
        parameters
        studyId
        study {
          id
          studyName
          organizationId
          description
          studyCode
          studyAnswers
          s3Path
          croGroups
          readGroups
          writeGroups
          adminGroups
          created
          updated
          organizationStudiesId
        }
        studyPhaseId
        studyPhase {
          id
          studyId
          metaDataValidationSchemaTemplateId
          validationSchema
          description
          studyPhaseName
          studyPhaseAnswers
          experimentSpecificVariables
          sampleTypes
          specimens
          species
          s3Path
          groupNames
          labAssayTechnologies
          phase
          readGroups
          writeGroups
          adminGroups
          croGroups
          created
          updated
          studyStudyPhasesId
          metaDataValidationSchemaTemplateStudyPhasesId
        }
        scheduledPipeline
        scheduledDateTime
        launchablePipelineId
        launchablePipeline {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        batchId
        batch {
          id
          batchName
          batchAnnotations
          batchGroups
          annotations
          controlSamples
          filters
          description
          studyId
          studyPhaseId
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyBatchesId
          studyPhaseBatchesId
        }
        runId
        userEmail
        pipelineStarted
        pipelineCompleted
        readGroups
        writeGroups
        adminGroups
        created
        updated
        studyPipelinesId
        studyPhasePipelinesId
        batchPipelinesId
        launchablePipelinesPipelinesId
      }
      nextToken
    }
  }
`;
export const auxiliaryAnalysisBySample = /* GraphQL */ `
  query AuxiliaryAnalysisBySample(
    $sampleId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuxiliaryAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auxiliaryAnalysisBySample(
      sampleId: $sampleId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        auxiliaryFileS3Path
        vendorId
        specimenType
        parameters
        collectionDate
        readGroups
        writeGroups
        adminGroups
        created
        updated
        sampleAuxiliaryAnalysislListId
      }
      nextToken
    }
  }
`;
export const metadataImmunopeptidomicsBySample = /* GraphQL */ `
  query MetadataImmunopeptidomicsBySample(
    $sampleId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetadataImmunopeptidomicsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MetadataImmunopeptidomicsBySample(
      sampleId: $sampleId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        species
        subtypeOfAnalysis
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataImmunopeptidomicsListId
      }
      nextToken
    }
  }
`;
export const metadataNanoStringBySample = /* GraphQL */ `
  query MetadataNanoStringBySample(
    $sampleId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetadataNanoStringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MetadataNanoStringBySample(
      sampleId: $sampleId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        dv200Percentage
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataNanoStringListId
      }
      nextToken
    }
  }
`;
export const metadataRnaSeqBySample = /* GraphQL */ `
  query MetadataRnaSeqBySample(
    $sampleId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMetadataRnaSeqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    MetadataRnaSeqBySample(
      sampleId: $sampleId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sampleId
        sample {
          id
          timepointId
          studyPhaseId
          BatchId
          domainSampleId
          experimentSpecificVariables
          collectionDate
          processingDate
          readGroups
          writeGroups
          adminGroups
          created
          updated
          studyPhaseSamplesId
          timepointSamplesId
          batchSamplesId
        }
        treatment {
          nextToken
        }
        metaDataTemplateS3Path
        subtypeOfAnalysis
        species
        sampleType
        qcStatus
        glacierBackup
        rawDataS3
        tumorVolume
        sequenceType
        strandSpecific
        UMI
        adaptorsRemoved
        adaptorsS3Path
        indexSequences
        concNanoGramPerMicroL
        rnaMassNanoGram
        rnaVolumeMicroL
        rin
        readGroups
        writeGroups
        adminGroups
        created
        updated
        isControl
        barcodesMappedToControl
        barcode
        s3Path
        sampleMetadataRnaSeqListId
      }
      nextToken
    }
  }
`;
export const treatmentsByMetadataImmunopeptidomics = /* GraphQL */ `
  query TreatmentsByMetadataImmunopeptidomics(
    $metadataImmunopeptidomicsId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentsByMetadataImmunopeptidomics(
      metadataImmunopeptidomicsId: $metadataImmunopeptidomicsId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        metadataImmunopeptidomicsId
        metadataImmunopeptidomics {
          id
          sampleId
          metaDataTemplateS3Path
          species
          subtypeOfAnalysis
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataImmunopeptidomicsListId
        }
        metadataNanoStringId
        metadataNanoString {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          dv200Percentage
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataNanoStringListId
        }
        metadataRnaSeqId
        metadataRnaSeq {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          sequenceType
          strandSpecific
          UMI
          adaptorsRemoved
          adaptorsS3Path
          indexSequences
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataRnaSeqListId
        }
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
      }
      nextToken
    }
  }
`;
export const treatmentsByMetadataNanoString = /* GraphQL */ `
  query TreatmentsByMetadataNanoString(
    $metadataNanoStringId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentsByMetadataNanoString(
      metadataNanoStringId: $metadataNanoStringId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        metadataImmunopeptidomicsId
        metadataImmunopeptidomics {
          id
          sampleId
          metaDataTemplateS3Path
          species
          subtypeOfAnalysis
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataImmunopeptidomicsListId
        }
        metadataNanoStringId
        metadataNanoString {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          dv200Percentage
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataNanoStringListId
        }
        metadataRnaSeqId
        metadataRnaSeq {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          sequenceType
          strandSpecific
          UMI
          adaptorsRemoved
          adaptorsS3Path
          indexSequences
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataRnaSeqListId
        }
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
      }
      nextToken
    }
  }
`;
export const treatmentsByMetadataRnaSeq = /* GraphQL */ `
  query TreatmentsByMetadataRnaSeq(
    $metadataRnaSeqId: ID!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    treatmentsByMetadataRnaSeq(
      metadataRnaSeqId: $metadataRnaSeqId
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        metadataImmunopeptidomicsId
        metadataImmunopeptidomics {
          id
          sampleId
          metaDataTemplateS3Path
          species
          subtypeOfAnalysis
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataImmunopeptidomicsListId
        }
        metadataNanoStringId
        metadataNanoString {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          dv200Percentage
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataNanoStringListId
        }
        metadataRnaSeqId
        metadataRnaSeq {
          id
          sampleId
          metaDataTemplateS3Path
          subtypeOfAnalysis
          species
          sampleType
          qcStatus
          glacierBackup
          rawDataS3
          tumorVolume
          sequenceType
          strandSpecific
          UMI
          adaptorsRemoved
          adaptorsS3Path
          indexSequences
          concNanoGramPerMicroL
          rnaMassNanoGram
          rnaVolumeMicroL
          rin
          readGroups
          writeGroups
          adminGroups
          created
          updated
          isControl
          barcodesMappedToControl
          barcode
          s3Path
          sampleMetadataRnaSeqListId
        }
        treatmentName
        dose
        doseUnit
        readGroups
        writeGroups
        adminGroups
        created
        updated
        metadataImmunopeptidomicsTreatmentId
        metadataNanoStringTreatmentId
        metadataRnaSeqTreatmentId
      }
      nextToken
    }
  }
`;
export const launchablePipelinesByPipelineNameAndVersion = /* GraphQL */ `
  query LaunchablePipelinesByPipelineNameAndVersion(
    $pipelineName: String!
    $pipelineVersion: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    launchablePipelinesByPipelineNameAndVersion(
      pipelineName: $pipelineName
      pipelineVersion: $pipelineVersion
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      nextToken
    }
  }
`;
export const usersByOrganization = /* GraphQL */ `
  query UsersByOrganization(
    $organization: String!
    $created: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOrganization(
      organization: $organization
      created: $created
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        organization
        readGroups
        created
        updated
        owner
      }
      nextToken
    }
  }
`;
export const organizationLaunchablePipelinesByOrganizationId = /* GraphQL */ `
  query OrganizationLaunchablePipelinesByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationLaunchablePipelinesByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        launchablePipelinesId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        launchablePipelines {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const organizationLaunchablePipelinesByLaunchablePipelinesId = /* GraphQL */ `
  query OrganizationLaunchablePipelinesByLaunchablePipelinesId(
    $launchablePipelinesId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationLaunchablePipelinesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationLaunchablePipelinesByLaunchablePipelinesId(
      launchablePipelinesId: $launchablePipelinesId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        launchablePipelinesId
        organization {
          id
          organizationName
          organizationCode
          active
          notificationEmail
          description
          organizationAnswers
          url
          s3Path
          readGroups
          writeGroups
          created
          updated
        }
        launchablePipelines {
          id
          pipelineName
          pipelineVersion
          parameters
          description
          inputFileInfo
          supportedAnalyses
          supportedSubtypeOfAnalyses
          gitRepo
          workflowId
          created
          updated
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
