
// import { listItems } from '@/utils';
// import { Organization } from '@/models';
import {
  validateEmail, validateURL,
} from '@/utils';
import { API, graphqlOperation } from 'aws-amplify';
import { defineComponent } from 'vue';
// import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
import { OrganizationTemplate } from '@/models/customModels';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import { Organization } from '@/models';

export default defineComponent({
  name: 'Users',
  emits: {
    newOrganization: (organization: Organization) => {
      if (organization) {
        return true;
      }
      console.error('Invalid newOrganization event payload!');
      return false;
    },
    updateOrganization: (organization: Organization) => {
      if (organization) {
        return true;
      }
      console.error('Invalid newOrganization event payload!');
      return false;
    },
  },
  props: {
    organizationForUpdate: { type: Organization, required: false },
  },
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      notificationEmail: null as unknown as string | null,
      organizationActive: true,
      url: null as unknown as string | null,
    };
  },
  async beforeMount() {
    await this.main();
  },
  methods: {
    async main() {
      try {
        if (!this.organizationForUpdate) return;
        if (this.organizationForUpdate.notificationEmail) this.notificationEmail = this.organizationForUpdate.notificationEmail;
        if (this.organizationForUpdate.url) this.url = this.organizationForUpdate.url;
        this.organizationActive = this.organizationForUpdate.active;
      } catch (error) {
        console.error(error);
      }
    },
    async createOrganization() {
      this.loading = true;
      try {
        if (!this.notificationEmail) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Notification email cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.validateEmail()) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Validation error!', life: 3000,
          });
          this.loading = false;
          return;
        }
        const newOrganizationTemplate: OrganizationTemplate = {
          notificationEmail: this.notificationEmail,
          active: this.organizationActive,
        };
        if (this.url) newOrganizationTemplate.url = this.url;
        const newOrganizationObject: GraphQLResult<any> = await API.graphql(graphqlOperation(mutations.createOrganization, { input: newOrganizationTemplate }));
        const newOrgId = newOrganizationObject.data.createOrganization.id;
        const readGroups: string[] = [];
        const writeGroups: string[] = [`ORG/${newOrgId}/Admin`];
        const updateOrgObj = {
          id: newOrgId,
          readGroups,
          writeGroups,
        };
        API.graphql(graphqlOperation(mutations.updateOrganization, { input: updateOrgObj }));
        this.$emit('newOrganization', newOrganizationObject.data.createOrganization as Organization);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Organization created successfuly!', life: 3000,
        });
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to create organization!', life: 3000,
        });
      }
      this.hideDialog();
    },
    async updateOrganization() {
      console.log('Updateing org');
      this.loading = true;
      try {
        if (!this.notificationEmail) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Notification email cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.validateEmail()) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Validation error!', life: 3000,
          });
          this.loading = false;
          return;
        }
        let somethingToUpdate = false;
        const updateOrganizationtemplate: OrganizationTemplate = {};
        updateOrganizationtemplate.id = this.organizationForUpdate?.id;
        if (this.notificationEmail !== this.organizationForUpdate!.notificationEmail) {
          updateOrganizationtemplate.notificationEmail = this.notificationEmail;
          somethingToUpdate = true;
        }
        if (this.url !== this.organizationForUpdate!.url) {
          updateOrganizationtemplate.url = this.url;
          somethingToUpdate = true;
        }
        if (this.organizationActive !== this.organizationForUpdate!.active) {
          updateOrganizationtemplate.active = this.organizationActive;
          somethingToUpdate = true;
        }
        if (somethingToUpdate) {
          const updatedOrganization: GraphQLResult<any> = await API.graphql(graphqlOperation(mutations.updateOrganization, { input: updateOrganizationtemplate }));
          this.$emit('updateOrganization', updatedOrganization.data.updateOrganization);
          this.$toast.add({
            severity: 'success', summary: 'Success', detail: 'Organization updated successfuly!', life: 3000,
          });
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update organization!', life: 3000,
        });
      }
      this.hideDialog();
    },
    validateEmail() {
      return validateEmail(this.notificationEmail! as string);
    },
    validateURL() {
      return validateURL(this.url as string);
    },
    hideDialog() {
      this.notificationEmail = null;
      this.url = null;
      this.loading = false;
      this.$store.dispatch('setShowingCreateOrganizationDialog', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingCreateOrganizationDialog': async function () {
      this.showing = this.$store.state.showingCreateOrganizationDialog;
      if (this.showing) this.main();
    },
  },
});
