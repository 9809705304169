
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Check',
  props: ['description', 'value', 'name', 'index'],
  emits: ['input'],
  data() {
    return {
      localValue: this.value,
    };
  },
  mounted() {
    // eslint-disable-next-line vue/no-mutating-props
    this.localValue = false;
    this.$emit('input', false);
  },
});
