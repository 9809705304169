import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_BatchTable = _resolveComponent("BatchTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Breadcrumb, {
        class: "breadcrumb pb-3",
        home: _ctx.breadcrumbHome,
        model: _ctx.breadcrumbItems
      }, null, 8, ["home", "model"])
    ]),
    _createVNode(_component_BatchTable, { onSetBreadcrumbItems: _ctx.setBreadcrumbItems }, null, 8, ["onSetBreadcrumbItems"])
  ], 64))
}