
import { defineComponent } from 'vue';
// import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { S3ContentModel } from '@/models/customModels';
import { getPresignedLink, assumeCredentialsFromPrecedenceLevel } from '@/utils';
// eslint-disable-next-line import/no-webpack-loader-syntax
const help = (require('raw-loader!@/assets/help.txt')).default;
// eslint-disable-next-line import/no-extraneous-dependencies
const Markdown = (require('vue3-markdown-it')).default;

export default defineComponent({
  name: 'Help',
  // eslint-disable-next-line vue/no-unused-components
  components: { Markdown },
  data() {
    return {
      mdString: '',
      loading: false,
      videos: [] as any[],
      credentials: null as any,
      galleryItems: [] as any[],
      renderVideo: true,
      activeIndex: 0,
      currentVideo: null,
      loadingVideos: false,
      loadingUserManual: true,
      userManualPromise: null as unknown as any,
      userManual: '',
      responsiveOptions: [
        {
          breakpoint: '1300px',
          numVisible: 4,
        },
        {
          breakpoint: '575px',
          numVisible: 1,
        },
      ],
    };
  },
  mounted() {
    this.main();
  },
  methods: {
    // Force rebuild comment
    async main() {
      this.loadingVideos = true;
      // const credentials = await Auth.currentCredentials();
      const credentials = await assumeCredentialsFromPrecedenceLevel(this.$store.state.precedenceLevel);
      console.log('credentials :>> ', credentials);
      this.credentials = credentials;
      if (this.$store.state.precedenceLevel === 7) this.userManualPromise = this.loadUserManual('documentation/all-users/userManual/CRO-User-Manual.html');
      else this.userManualPromise = this.loadUserManual('documentation/all-users/userManual/User-Manual.html');
      this.loadDemoVideos('documentation/all-users/demoVideos/');
      this.mdString = help.replace('export default "', '').replace('";', '').replace(/\\n/g, '\n');
    },
    async loadUserManual(originalPath: String, token?: String): Promise<void> {
      this.loadingUserManual = true;
      const s3 = new AWS.S3(({ region: 'eu-west-2', credentials: this.credentials }));
      let params = {};
      if (token == null) {
        params = {
          Bucket: process.env.VUE_APP_MASTER_BUCKET,
          Key: originalPath,
        };
      } else {
        params = {
          Bucket: process.env.VUE_APP_MASTER_BUCKET,
          Key: originalPath,
          ContinuationToken: token,
        };
      }
      try {
        s3.getObject(params as { Bucket: string, Key: string, ContinuationToken?: string}, (err, data) => {
          if (data) {
            const className = this.$route.params.organizationName;
            const decoder = new TextDecoder();
            const decodedString = decoder.decode(data.Body as Uint8Array);
            // this.userManual = (decodedString as string).replace('myCustomClass', className as string);
            this.userManual = (decodedString as string).replace('theme-default', `theme-default ${className as string}`);
            // this.loadingUserManual = false;
          }
          if (err) {
            this.loading = false;
            console.error(err);
          }
        });
      } catch (error) {
        console.error('Error with S3.getObject');
        console.error(error);
      }
    },
    async loadDemoVideos(originalPath: String, token?: String) {
      const s3 = new AWS.S3(({ region: 'eu-west-2', credentials: this.credentials }));
      let params = {};
      if (token == null) {
        params = {
          Bucket: process.env.VUE_APP_MASTER_BUCKET,
          Prefix: originalPath,
        };
      } else {
        params = {
          Bucket: process.env.VUE_APP_MASTER_BUCKET,
          Prefix: originalPath,
          ContinuationToken: token,
        };
      }
      try {
        s3.listObjectsV2(params as { Bucket: string, Prefix: string, ContinuationToken?: string}, async (err, data) => {
          if (data) {
            console.log('data :>> ', data);
            if (!data || !data.Contents || data.Contents.length === 0) {
              this.loading = false;
              return;
            }
            this.videos.push(...data.Contents as S3ContentModel[]);
            if (data.IsTruncated) {
              this.loadDemoVideos(originalPath, data.NextContinuationToken);
            } else {
              await this.userManualPromise;
              this.embedVideos(this.videos);
            }
          }
          if (err) {
            this.loading = false;
            console.error(err);
          }
        });
      } catch (error) {
        console.error('Error with S3.getObject');
        console.error(error);
      }
    },
    async embedVideos(videos: any[]) {
      const res: {[key: string]: string}[] = await Promise.all(videos.map((video: { Key: string, [key: string]: any}) => this.getAndEmbedVideo(video.Key)));
      const mergedObj: {[key: string]: string} = res.reduce((result, current) => Object.assign(result, current), {});
      const userManualWithVideos = Object.keys(mergedObj).reduce((acc, key) => {
        const regex = new RegExp(`\\${key}`, 'g');
        return acc.replace(regex, mergedObj[key]);
      }, this.userManual);
      this.userManual = userManualWithVideos;
      this.loadingUserManual = false;
    },
    async getAndEmbedVideo(videoKey: string) {
      const videoPresignedLink = await getPresignedLink(videoKey, this.credentials);
      const htmlReplacement = `<video width="640" height="480" controls preload="auto"> <source src="${videoPresignedLink}" type="video/mp4"> </video>`;
      const stringToReplace = `<span>!${videoKey.split('documentation/all-users/demoVideos/').at(-1)}!</span>`;
      return {
        [`${stringToReplace}`]: htmlReplacement,
      };
    },
  },
});
