import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c4d8612"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-float-label" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "p-float-label" }
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionsFull?.questions, (questionObj, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "group-div"
      }, [
        _createElementVNode("span", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(questionObj.inputType), {
            id: questionObj.question,
            modelValue: _ctx.data[questionObj.question],
            "onUpdate:modelValue": ($event: any) => ((_ctx.data[questionObj.question]) = $event),
            onInput: ($event: any) => (_ctx.updateData(questionObj.question, _ctx.data[questionObj.question]))
          }, null, 40, ["id", "modelValue", "onUpdate:modelValue", "onInput"])),
          _createElementVNode("label", {
            for: String(index)
          }, _toDisplayString(questionObj.question), 9, _hoisted_2)
        ])
      ]))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionsFull?.questionGroups, (questionGroup, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "group-div"
      }, [
        _createTextVNode(_toDisplayString(questionGroup.groupLabel) + " ", 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionGroup.questions, (questionObj, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "group-div"
          }, [
            _createElementVNode("span", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(questionObj.inputType), {
                id: questionObj.question,
                modelValue: _ctx.data[questionObj.question],
                "onUpdate:modelValue": ($event: any) => ((_ctx.data[questionObj.question]) = $event),
                onInput: ($event: any) => (_ctx.updateData(questionObj.question, _ctx.data[questionObj.question]))
              }, null, 40, ["id", "modelValue", "onUpdate:modelValue", "onInput"])),
              _createElementVNode("label", {
                for: String(index)
              }, _toDisplayString(questionObj.question), 9, _hoisted_4)
            ])
          ]))
        }), 128))
      ]))
    }), 128))
  ]))
}