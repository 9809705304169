
import { Organization, Study, StudyPhase } from '@/models';
// eslint-disable-next-line no-unused-vars
import { listItems, sendNotificationEmail } from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
// import * as queries from '@/graphql/queries';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { customGQLResult, MenuItems } from '@/models/customModels';

export default defineComponent({
  name: 'Users',
  props: {
    username: { type: String, required: true },
    email: { type: String, required: true },
  },
  data() {
    return {
      loading: false as boolean,
      loadingOrganizations: false as boolean,
      loadingStudies: false as boolean,
      loadingUsersGroups: true as boolean,
      addingUsers: false as boolean,
      showing: false as boolean,
      usersGroupsMenuItems: [] as MenuItems[],
      userGroupsSimpleString: [] as string[],
      selectedGroup: null,
      viewGroupsAsList: true as boolean,
      selectedOrganization: null as Organization | null,
      availableOrganizations: [] as Organization[],
      m2mAdmin: false,
      selectedUserIsM2MAdmin: false,
      entityOptions: [] as string[],
      selectedEntity: null,
      studies: [] as Study[],
      selectedStudy: null,
      permisionOptions: ['User', 'Admin'],
      studyPhases: [] as StudyPhase[],
      selectedStudyPhase: null,
      showAddAsGlobalAdminBtn: false,
    };
  },
  methods: {
    async main() {
      try {
        const groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        if (groups.some((grp) => grp === 'M2MAdmin')) this.m2mAdmin = true;
        this.availableOrganizations = await this.loadAvailableOrganizations();
        this.usersGroupsMenuItems = await this.listGroupsForUser(this.username);
        console.log('this.usersGroupsMenuItems :>> ', this.usersGroupsMenuItems);
      } catch (error) {
        console.error(error);
      }
    },
    // Reload the listBox
    async listGroupsForUser(username: string): Promise<MenuItems[]> {
      this.loadingUsersGroups = true;
      try {
        const apiName = 'AdminQueries';
        const path = '/listGroupsForUser';
        const myInit = {
          queryStringParameters: {
            username,
            limit: 60,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        const { ...groups } = await API.get(apiName, path, myInit);
        const usersGroups = groups.Groups;
        const mappedGroups: string[] = usersGroups.map((grp: any) => grp?.GroupName);
        if (mappedGroups.some((grp) => grp === 'M2MAdmin')) this.selectedUserIsM2MAdmin = true;
        this.userGroupsSimpleString = mappedGroups;
        const remappedGroups: MenuItems[] = await this.remapGroups(mappedGroups);
        this.loadingUsersGroups = false;
        return remappedGroups;
      } catch (error) {
        console.error(error);
        this.loadingUsersGroups = false;
        return [];
      }
    },
    async remapGroups(groups: string[]): Promise<MenuItems[]> {
      try {
        const groupedItems: MenuItems = {
          M2MAdmin: {
            label: 'M2MAdmin',
            items: [],
          },
          ORG: {
            label: 'Organization Administrator',
            items: [],
          },
          S: {
            label: 'Study Administrator',
            items: [],
          },
          BIOINFORMATICIAN: { // Study Phase Admin
            label: 'Bioinformatician',
            items: [],
          },
          EXPLORER: {
            label: 'Explorer',
            items: [],
          },
          CRO: {
            label: 'CRO',
            items: [],
          },
          label: '',
          items: [],
        };
        const promises: Promise<MenuItems>[] = [];
        groups.forEach(async (grp: string) => {
          promises.push(this.makeEntityNameCall(grp, groupedItems));
        });
        await Promise.all(promises);
        const arrOfMenuItems: MenuItems[] = [];
        for (const key in groupedItems) {
          if (key !== 'label' && key !== 'items' && groupedItems[key].items.length > 0) {
            arrOfMenuItems.push(groupedItems[key]);
          }
        }
        return arrOfMenuItems;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async makeEntityNameCall(grp: string, groupedItems: MenuItems): Promise<MenuItems> {
      try {
        if (grp === 'M2MAdmin') {
          groupedItems.M2MAdmin.items.push({
            label: 'M2MAdmin',
            value: 'M2MAdmin',
          });
        } else {
          const split = grp.split('/');
          // eslint-disable-next-line no-nested-ternary
          const groupNameShorthand: string = (split[0] !== 'SP') ? split[0] : (split[2] === 'User') ? 'EXPLORER' : 'BIOINFORMATICIAN';
          groupedItems[groupNameShorthand].items.push({
            label: `${await this.getEntityName(groupNameShorthand, split[1])}`,
            value: grp,
          });
        }
        return groupedItems;
      } catch (error) {
        console.error(error);
        return [] as any;
      }
    },
    async getEntityName(type: string, id: string) {
      try {
        switch (type) {
          case 'ORG': {
            const response = await API.graphql(graphqlOperation(customQueries.getOrganizationNameOnly, {
              id,
            }));
            return (response as customGQLResult).data.getOrganization.description;
          }
          case 'S': {
            const response = await API.graphql(graphqlOperation(customQueries.getStudyNameOnly, {
              id,
            }));
            return (response as customGQLResult).data.getStudy.studyName;
          }
          case 'BIOINFORMATICIAN': case 'EXPLORER': case 'CRO': {
            const response = await API.graphql(graphqlOperation(customQueries.getStudyPhaseNameOnly, {
              id,
            }));
            return (response as customGQLResult).data.getStudyPhase.studyPhaseName;
          }
          default: {
            throw new Error('Unhandled type of group');
          }
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    async loadAvailableOrganizations():Promise<Organization[]> {
      this.loadingOrganizations = true;
      try {
        const organizationsFromCall: Organization[] = await listItems(customQueries.listOrganizationsForGroupSelection);
        const activeOrganizations: Organization[] = organizationsFromCall.filter((organization: Organization) => organization.active);
        this.loadingOrganizations = false;
        return activeOrganizations;
      } catch (error) {
        console.error(error);
        this.loadingOrganizations = false;
        return [];
      }
    },
    // Load studies based on Organization. The Studies will have study phases in them. If there are > 100 study phases in a study, performa a seperate query to fetch the rest
    async loadStudiesByOrganization(organizationId: String): Promise<Study[]> {
      this.loadingStudies = true;
      try {
        const studiesFromCall: Study[] = await listItems(customQueries.studiesByOrganizationForGroupSelection, { organizationId });
        this.loadingStudies = false;
        return studiesFromCall;
      } catch (error) {
        console.error(error);
        this.loadingStudies = false;
        return [];
      }
    },
    async save(): Promise<void> {
      try {
        this.addingUsers = true;
        if (!this.selectedOrganization) throw new Error('Selected Organization is null');
        if (!this.selectedEntity) throw new Error('Selected Entity is null');
        switch (this.selectedEntity) {
          case 'Organization Admin': {
            const expectedGroup = `ORG/${this.selectedOrganization.id}/Admin`;
            if (this.userGroupsSimpleString.includes(expectedGroup)) {
              this.$toast.add({
                severity: 'warn', summary: 'Allready in group', detail: `The selected user is allready an administrator of the ${this.selectedOrganization.organizationName} organization`, life: 3000,
              });
              this.addingUsers = false;
              return;
            }
            try {
              await this.addUserToGroup(expectedGroup, this.username);
              this.$toast.add({
                severity: 'success', summary: 'Success', detail: `The user has been set as an ${this.selectedOrganization.organizationName} organization admin successfuly!`, life: 3000,
              });
              this.addNewUserGroupToMenuItems('Organization', this.selectedOrganization.organizationName, `ORG/${this.selectedOrganization.id}/Admin`);
              this.sendGroupChangeEmail(this.selectedOrganization.organizationName, 'add', this.selectedOrganization.id);
            } catch (error) {
              this.$toast.add({
                severity: 'error', summary: 'Error', detail: 'Operation failed! User was not set as an organization administrator', life: 3000,
              });
            }
            this.clearGlobalVariablesForFurtherAssignment();
            break;
          }
          case 'Study Admin': {
            if (!this.selectedStudy) throw new Error('Selected Study is null');
            const otherPermisionGroup = `S/${(this.selectedStudy as Study).id}/Admin`;
            if (this.userGroupsSimpleString.includes(otherPermisionGroup)) {
              this.$toast.add({
                severity: 'warn', summary: 'Allready in group', detail: `The selected user is already a administrator of this study: ${(this.selectedStudy as Study).studyName}.`, life: 5000,
              });
              this.addingUsers = false;
              return;
            }
            const expectedGroup = `S/${(this.selectedStudy as Study).id}/Admin`;
            if (this.userGroupsSimpleString.includes(expectedGroup)) {
              this.$toast.add({
                severity: 'warn', summary: 'Allready in group', detail: `The selected user is allready an administrator  of this study: ${(this.selectedStudy as Study).studyName}.`, life: 3000,
              });
              this.addingUsers = false;
              return;
            }
            try {
              await this.addUserToGroup(expectedGroup, this.username);
              this.$toast.add({
                severity: 'success', summary: 'Success', detail: `The user has been set as an ${(this.selectedStudy as Study).studyName} study administrator successfully!`, life: 3000,
              });
              this.addNewUserGroupToMenuItems('Study', (this.selectedStudy as Study).studyName, `S/${(this.selectedStudy as Study).id}/Admin`);
              this.sendGroupChangeEmail((this.selectedStudy as Study).studyName, 'add', this.selectedOrganization.id);
            } catch (error) {
              this.$toast.add({
                severity: 'error', summary: 'Error', detail: `Operation failed! User was not set as ${(this.selectedStudy as Study).studyName} study administrator!`, life: 3000,
              });
            }
            this.clearGlobalVariablesForFurtherAssignment();
            break;
          }
          case 'Bioinformatician': case 'Explorer': {
            if (!this.selectedStudy) throw new Error('Selected Study is null');
            if (!this.selectedStudyPhase) throw new Error('Selected Study Phase is null');
            const otherPermissionGroup = `SP/${(this.selectedStudyPhase as StudyPhase).id}/${(this.selectedEntity === 'Bioinformatician') ? 'Admin' : 'User'}`;
            if (this.userGroupsSimpleString.includes(otherPermissionGroup)) {
              this.$toast.add({
                severity: 'warn', summary: 'Already in group', detail: `The selected user is already an ${(this.selectedEntity === 'Bioinformatician') ? 'Bioinformatician' : 'Explorer'} of this study phase: ${(this.selectedStudyPhase as StudyPhase).studyPhaseName}.`, life: 5000,
              });
              this.addingUsers = false;
              return;
            }
            const expectedGroup = `SP/${(this.selectedStudyPhase as StudyPhase).id}/${(this.selectedEntity === 'Bioinformatician') ? 'Admin' : 'User'}`;
            if (this.userGroupsSimpleString.includes(expectedGroup)) {
              this.$toast.add({
                severity: 'warn', summary: 'Already in group', detail: `The selected user is already a ${((this.selectedEntity === 'Bioinformatician') ? 'Bioinformatician' : 'Explorer').toLowerCase()} of this study phase: ${(this.selectedStudyPhase as StudyPhase).studyPhaseName}.`, life: 3000,
              });
              this.addingUsers = false;
              return;
            }
            try {
              await this.addUserToGroup(expectedGroup, this.username);
              this.$toast.add({
                severity: 'success', summary: 'Success', detail: `The user has been set as an ${(this.selectedStudyPhase as StudyPhase).studyPhaseName} study phase ${((this.selectedEntity === 'Bioinformatician') ? 'Bioinformatician' : 'Explorer').toLowerCase()} successfuly!`, life: 3000,
              });
              this.addNewUserGroupToMenuItems(((this.selectedEntity === 'Bioinformatician') ? 'Bioinformatician' : 'Explorer'), (this.selectedStudyPhase as StudyPhase).studyPhaseName, `SP/${(this.selectedStudyPhase as StudyPhase).id}/${(this.selectedEntity === 'Bioinformatician') ? 'Admin' : 'User'}`);
              this.sendGroupChangeEmail((this.selectedStudyPhase as StudyPhase).studyPhaseName as string, 'add', this.selectedOrganization.id);
            } catch (error) {
              this.$toast.add({
                severity: 'error', summary: 'Error', detail: `Operation failed! User was not set as ${(this.selectedStudyPhase as StudyPhase).studyPhaseName} study phase ${((this.selectedEntity === 'Bioinformatician') ? 'Bioinformatician' : 'Explorer').toLowerCase()}!`, life: 3000,
              });
              this.addingUsers = false;
            }
            this.clearGlobalVariablesForFurtherAssignment();
            break;
          }
          case 'CRO': {
            if (!this.selectedStudy) throw new Error('Selected Study is null');
            if (!this.selectedStudyPhase) throw new Error('Selected Study Phase is null');
            const expectedGroup = `CRO/${(this.selectedStudyPhase as StudyPhase).id}`;
            if (this.userGroupsSimpleString.includes(expectedGroup)) {
              this.$toast.add({
                severity: 'warn', summary: 'Allready in group', detail: `The selected user is allready a CRO of this study phase ${(this.selectedStudyPhase as StudyPhase).studyPhaseName}.`, life: 3000,
              });
              this.addingUsers = false;
              return;
            }
            try {
              await this.addUserToGroup(expectedGroup, this.username);
              this.$toast.add({
                severity: 'success', summary: 'Success', detail: `The user has been set as CRO of ${(this.selectedStudyPhase as StudyPhase).studyPhaseName} study phase successfuly!`, life: 3000,
              });
              this.addNewUserGroupToMenuItems('CRO', (this.selectedStudyPhase as StudyPhase).studyPhaseName, `CRO/${(this.selectedStudyPhase as StudyPhase).id}`);
              this.sendGroupChangeEmail((this.selectedStudyPhase as StudyPhase).studyPhaseName as string, 'add', this.selectedOrganization.id);
            } catch (error) {
              this.$toast.add({
                severity: 'error', summary: 'Error', detail: `Operation failed! User was not set as a CRO of ${(this.selectedStudyPhase as StudyPhase).studyPhaseName} study phase!`, life: 3000,
              });
            }
            this.clearGlobalVariablesForFurtherAssignment();
            break;
          }

          default: {
            throw new Error('No entity selected');
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkBeforeAssign() {
      this.$confirm.require({
        message: 'Are you sure you want to proceed? Global administrators have access to all parts of the application!',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.assignAsM2MAdmin();
        },
        reject: () => {
        },
      });
    },
    async assignAsM2MAdmin() {
      this.loading = true;
      try {
        await this.addUserToGroup('M2MAdmin', this.username);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'The user has been set as a global administrator successfully!', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Operation failed! User was not set as a global administrator!', life: 3000,
        });
      }
      this.loading = false;
      // this.hideDialog();
      this.clearGlobalVariablesForFurtherAssignment();
    },
    async addUserToGroup(groupname: string, username: string) {
      try {
        const apiName = 'AdminQueries';
        const path = '/addUserToGroup';
        const myInit = {
          body: {
            username,
            groupname,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        API.post(apiName, path, myInit);
      } catch (error) {
        console.error(error);
      }
    },
    async removeFromGroupTrigger(group: MenuItems): Promise<void> {
      try {
        this.$confirm.require({
          message: 'Are you sure you want to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            try {
              const groupName = group.value;
              await this.removeUserFromGroup(group);
              this.$toast.add({
                severity: 'success', summary: 'Success', detail: 'User removed from groups', life: 3000,
              });
              this.usersGroupsMenuItems = this.usersGroupsMenuItems.filter((parent: MenuItems) => {
                // eslint-disable-next-line no-param-reassign
                parent.items = parent.items.filter((nested: MenuItems) => nested.value !== groupName);
                return parent.items.length > 0;
              });
              this.userGroupsSimpleString = this.userGroupsSimpleString.filter((grp: string) => grp !== groupName);
            } catch (error) {
              console.error(error);
              this.$toast.add({
                severity: 'error', summary: 'Error', detail: 'Failed to remove user from group!', life: 3000,
              });
            }
          },
          reject: () => {
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async removeUserFromGroup(group: MenuItems) {
      console.log('group :>> ', group);
      try {
        const apiName = 'AdminQueries';
        const path = '/removeUserFromGroup';
        const myInit = {
          body: {
            username: `${this.username}`,
            groupname: group.value,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        API.post(apiName, path, myInit);
        this.sendUserRemovedFromGroupEmail(group.value);
      } catch (error) {
        console.error(error);
      }
    },
    async sendUserRemovedFromGroupEmail(groupLabel: string) {
      const organizationId: string | null = await this.getOrganizationEmailFromGroup(groupLabel);
      if (organizationId) this.sendGroupChangeEmail(groupLabel, 'remove', organizationId);
    },
    async getOrganizationEmailFromGroup(group: string): Promise<string | null> {
      if (group.startsWith('ORG/')) {
        return group.split('/')[1];
      } if (group.startsWith('S/')) {
        const res: any = await API.graphql(graphqlOperation(customQueries.getStudyForOrgId, { id: group.split('/')[1] }));
        return res.data.getStudy?.organization.id;
      } if (group.startsWith('SP/') || group.startsWith('CRO/')) {
        const res: any = await API.graphql(graphqlOperation(customQueries.getStudyPhaseForOrgId, { id: group.split('/')[1] }));
        return res.data.getStudyPhase?.study.organizationId;
      }
      return null;
    },
    setEntityOptions(): string[] {
      try {
        for (let i = 0; i < this.usersGroupsMenuItems.length; i += 1) {
          const userGroupMenuItem = this.usersGroupsMenuItems[i];
          if (userGroupMenuItem.label === 'CRO') return ['CRO'];
          if (userGroupMenuItem.label !== 'CRO') return ['Organization Admin', 'Study Admin', 'Bioinformatician', 'Explorer'];
        }
        return ['Organization Admin', 'Study Admin', 'Bioinformatician', 'Explorer', 'CRO'];
      } catch (error) {
        console.error(error);
        return ['Organization Admin', 'Study Admin', 'Bioinformatician', 'Explorer', 'CRO'];
      }
    },
    addNewUserGroupToMenuItems(entity: string, label: string, value: string) {
      const newGrp = {
        label,
        value,
      };
      const entityGroupIndex: number = this.usersGroupsMenuItems.findIndex((entityGrp) => entityGrp.label === entity);
      if (entityGroupIndex === -1) {
        this.usersGroupsMenuItems.push({ label: entity, items: [newGrp as any] });
      } else {
        this.usersGroupsMenuItems[entityGroupIndex].items.push(newGrp as any);
      }
      console.log('newGrp :>> ', newGrp);
      console.log('this.usersGroupsMenuItems :>> ', this.usersGroupsMenuItems);
    },
    // eslint-disable-next-line no-unused-vars
    async sendGroupChangeEmail(groupLabel: string, type: string, organizationId: string | null) {
      try {
        let content: string | null = null;
        let userContent: string | null = null;
        switch (type) {
          case 'add': {
            content = `Hello,\nThis is a notification from the M2M platform.\n\nThe user ${this.email}, has been added to the group: ${groupLabel}\n\nBest regards,\n M2M Team`;
            userContent = `Hello,\nThis is a notification from the M2M platform.\n\nYou have been added to the group: ${groupLabel}\n\nBest regards,\n M2M Team`;
            break;
          }
          case 'remove': {
            content = `Hello,\nThis is a notification from the M2M platform.\n\nThe user ${this.email}, has been removed from the group: ${groupLabel}\n\nBest regards,\n M2M Team`;
            userContent = `Hello,\nThis is a notification from the M2M platform.\n\nYou have been removed from the group: ${groupLabel}\n\nBest regards,\n M2M Team`;
            break;
          }
          default: {
            throw new Error('Wrong type passed');
          }
        }
        if (content) sendNotificationEmail(content, ['filip.gunic@m2m.bio'], true, organizationId as string);
        if (userContent) sendNotificationEmail(userContent, [this.email], false, organizationId as string);
      } catch (error) {
        console.error(error);
      }
    },
    switchViewGroupsAsList(value: boolean) {
      this.viewGroupsAsList = value;
    },
    clearGlobalVariablesForFurtherAssignment() {
      this.addingUsers = false;
      this.selectedGroup = null;
      this.viewGroupsAsList = true as boolean;
      this.selectedOrganization = null as Organization | null;
      this.selectedEntity = null;
      this.studies = [] as Study[];
      this.selectedStudy = null;
      this.studyPhases = [] as StudyPhase[];
      this.selectedStudyPhase = null;
      this.m2mAdmin = false;
      this.selectedUserIsM2MAdmin = false;
    },
    hideDialog() {
      this.loading = false;
      this.loadingOrganizations = false as boolean;
      this.loadingStudies = false as boolean;
      this.addingUsers = false;
      this.loadingUsersGroups = true as boolean;
      this.showing = false as boolean;
      this.usersGroupsMenuItems = [] as MenuItems[];
      this.userGroupsSimpleString = [] as string[];
      this.selectedGroup = null;
      this.viewGroupsAsList = true as boolean;
      this.selectedOrganization = null as Organization | null;
      this.selectedEntity = null;
      this.studies = [] as Study[];
      this.selectedStudy = null;
      this.studyPhases = [] as StudyPhase[];
      this.selectedStudyPhase = null;
      this.m2mAdmin = false;
      this.selectedUserIsM2MAdmin = false;
      this.$store.dispatch('setShowingChangeGroup', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingChangeGroup': async function () {
      this.showing = this.$store.state.showingChangeGroup;
      if (this.showing) this.main();
    },
    selectedOrganization: {
      async handler(selectedOrganization) {
        if (!selectedOrganization) return;
        this.entityOptions = this.setEntityOptions();
        this.selectedEntity = null;
        this.selectedStudy = null;
        this.selectedStudyPhase = null;
        const studies: Study[] = await this.loadStudiesByOrganization(selectedOrganization.id);
        this.studies = studies;
      },
    },
    selectedStudy: {
      async handler(selectedStudy) {
        if (!selectedStudy) return;
        this.selectedStudyPhase = null;
        const studyPhases: StudyPhase[] = await listItems(customQueries.studyPhasesByStudyForGroupSelection, {
          studyId: selectedStudy.id,
        });
        this.studyPhases = studyPhases;
      },
    },
  },
});
