
import { Study } from '@/models';
import { listItems, formatISOStringCustom } from '@/utils';
import { defineComponent } from 'vue';
import * as queries from '@/graphql/queries';

export default defineComponent({
  emits: ['studySelected'],
  data() {
    return {
      studies: [] as Study[],
      loading: false,
      filters: {
        studyName: { value: '', matchMode: 'contains' },
        studyCode: { value: '', matchMode: 'contains' },
      },
    };
  },
  mounted() {
    this.loadStudiesForOrg(this.$route.params.organizationId as string);
  },
  methods: {
    async loadStudiesForOrg(organizationId: string): Promise<void> {
      this.loading = true;
      try {
        this.studies = await listItems(queries.studiesByOrganization, { organizationId, sortDirection: 'DESC' });
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    onStudySelect(event: any) {
      if (event.data) {
        // this.$router.push({ path: `studyPhaseBrowse/${event.data.id}` });
        console.log('event.data :>> ', event.data);
        this.$emit('studySelected', event.data);
      }
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
  },
});
