
import {
//   validateInputName, getOrgNameForClass, getQuestions, makeEntityQAObj,
} from '@/utils';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Pipeline Params Dialog',
  components: { },
  props: ['params'],
  data() {
    return {
      loading: false,
      pipelineParameters: [] as { header: string, field: string }[],
    };
  },
  mounted() {
    if (this.params && Object.keys(this.params).length > 0) this.main();
  },
  methods: {
    async main() {
      this.loading = true;
      try {
        this.pipelineParameters = this.formattedPipelineParameters(this.params);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    formattedPipelineParameters(pipelineParameters: {[key: string]: any}): { header: string, field: string }[] {
      try {
        return Object.keys(pipelineParameters).map((key) => ({ header: key, field: pipelineParameters[key] }));
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
  watch: {
    params() {
      if (this.params && Object.keys(this.params).length > 0) this.main();
    },
  },
});
