
import { listItems } from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import { Organization } from '@/models';
import { ActiveOrganizationObject } from '@/models/customModels';

export default defineComponent({
  name: 'Users',
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      selectedOrganization: null as unknown as Organization,
      availableOrganizations: [] as Organization[],
      orgName: 'm2m' as string,
    };
  },
  methods: {
    async main() {
      try {
        this.loading = true;
        const currentOrganization = await this.$store.state.activeOrganization;
        if (currentOrganization.organizationName) {
          this.orgName = currentOrganization.organizationName;
        }
        const allOrganizations: Organization[] = await listItems(customQueries.listOrganizationsForGroupSelection);
        const withoutCurrentOrganization = allOrganizations.filter((org: Organization) => org.id !== currentOrganization.id);
        this.availableOrganizations = withoutCurrentOrganization;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    switchOrganization() {
      try {
        const selectedId = this.selectedOrganization.id;
        const selectedName = this.selectedOrganization.organizationName;
        this.$store.dispatch('setActiveOrganization', this.createActiveOrgPromise(this.selectedOrganization))
          .then(() => {
            if (this.$route.path.endsWith('/CRO')) {
              this.$router.push(`/${selectedName}/${selectedId}/CRO`);
            } else {
              this.$router.push(`/${selectedName}/${selectedId}/studyBrowse`);
            }
          });
        this.hideDialog();
      } catch (error) {
        console.error(error);
      }
    },
    async createActiveOrgPromise(selectedOrganization: Organization): Promise<ActiveOrganizationObject | null> {
      try {
        const newActiveOrganization: ActiveOrganizationObject = {
          id: selectedOrganization.id,
          organizationName: selectedOrganization.organizationName,
          organizationDescription: selectedOrganization.description,
        };
        return newActiveOrganization;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    hideDialog() {
      this.selectedOrganization = null as unknown as Organization;
      this.availableOrganizations = [];
      this.$store.dispatch('setShowingChangeOrganizationDialog', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingChangeOrganizationDialog': async function () {
      this.showing = this.$store.state.showingChangeOrganizationDialog;
      if (this.showing) this.main();
    },
  },
});
