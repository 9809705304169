
import { defineComponent } from 'vue';
import { getHomeRouteForBreadcrumb, getOrgNameAndIdForRoute } from '@/utils';
import PipelineTable from '@/components/Pipeline/PipelineTable.vue';
import { MenuItem } from '@/models/customModels';

export default defineComponent({
  name: 'Study Phases',
  components: {
    PipelineTable,
  },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [] as MenuItem[],
    };
  },
  methods: {
    setBreadcrumbItems(parentName: string, studyPhaseName? :string) {
      const bsItems: MenuItem[] = [{ label: this.getParentLabel(studyPhaseName), to: this.getRouteToParent() }, { label: this.getPipelinesLabel(parentName), to: this.$route.fullPath }];
      if (this.$route.params.entityAndId[0] === 'studyPhase') bsItems.unshift({ label: 'Studies', to: `${getOrgNameAndIdForRoute(this.$route)}studyBrowse` });
      this.breadcrumbItems = bsItems;
    },
    getPipelinesLabel(parentName: string) {
      return ` ${(this.$route.params.entityAndId[0] === 'study') ? `Study: ${parentName} | Pipelines` : `Study Phase: ${parentName} | Pipelines`}`;
    },
    getParentLabel(studyPhaseName?: string) {
      return `${(this.$route.params.entityAndId[0] === 'study') ? 'Studies' : `Study: ${studyPhaseName} | Study Phases`}`;
    },
    getRouteToParent() {
      return (this.$route.params.entityAndId[0] === 'study') ? `${getOrgNameAndIdForRoute(this.$route)}studyBrowse` : `${getOrgNameAndIdForRoute(this.$route)}studyPhaseBrowse/${this.$route.params.entityAndId[2]}`;
    },
  },
});
