<template>
    <div class="root">
      <!-- Generate non module specific parameters -->
      <h3>Parameters</h3>
      <div v-for="(schema, index) in selectedLaunchablePipelineParameters" :key="index">
        <component
          :key="index"
          :is="schema.parameter_type"
          :value="data[schema.nf_command]"
          @input="updateData(schema.nf_command, $event)"
          v-bind="schema"
          :data="data"
          v-if="(!schema.mode) || (data['mode'] === schema.mode)"
        />
      </div>
      <!-- Generate checks for each module -->
      <h3>Modules</h3>
      <div class="col" v-for="(schema, index) in selectedLaunchablePipelineModules" :key="index">
        <Card class="lp-card mb-1" v-if="(!schema.mode) || (data['mode'] === schema.mode)">
          <template #title>
            <h4 class="mt-1">{{ schema.name }}</h4>
            <h5 class="mb-2">{{ schema.description }}</h5>
          </template>
          <template #content>
            <component
              :key="index"
              :is="schema.skipping_options.parameter_type"
              :value="data[schema.skipping_options.nf_command]"
              @input="updateData(schema.skipping_options.nf_command, $event)"
              v-bind="schema"
              :data="data"
              class="mb-3"
            />
            <!-- Generate module specific parameters -->
            <div v-if="!data[schema.skipping_options.nf_command]">
              <div v-for="(moduleParamSchema, index) in schema.parameters" :key="index">
                <component
                  :key="index"
                  :is="moduleParamSchema.parameter_type"
                  :value="data[moduleParamSchema.nf_command]"
                  @input="updateData(moduleParamSchema.nf_command, $event)"
                  v-bind="moduleParamSchema"
                  :data="data"
                />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </template>

<script>
import Select from '@/components/Pipeline/CustomDropDown.vue';
import Check from '@/components/Pipeline/Check.vue';
import Input from '@/components/Pipeline/CustomInput.vue';

export default {
  name: 'DynamicRenderer',
  emits: ['input'],
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names, vue/no-unused-components
    Select,
    // eslint-disable-next-line vue/no-unused-components
    Check,
    // eslint-disable-next-line vue/no-reserved-component-names, vue/no-unused-components
    Input,
  },
  props: {
    selectedLaunchablePipelineParameters: {
      type: Array,
      default: null,
    },
    selectedLaunchablePipelineModules: {
      type: Array,
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // eslint-disable-next-line camelcase
    updateData(nf_command, value) {
      // eslint-disable-next-line camelcase
      this.$emit('input', { nf_command, value });
    },
    // eslint-disable-next-line camelcase
    populateWithDefaults(nf_command, def) {
      // eslint-disable-next-line vue/no-mutating-props, camelcase
      this.data[nf_command] = def;
    },
  },
  mounted() {
    // Set non module specific attributes
    this.selectedLaunchablePipelineParameters?.forEach((schema) => {
      if (!('mode' in schema) || (schema.mode === this.data.mode)) {
        this.populateWithDefaults(schema.nf_command, schema.default);
      }
    });
    console.log('this.selectedLaunchablePipelineModules :>> ', this.selectedLaunchablePipelineModules);
    if (Array.isArray(this.selectedLaunchablePipelineModules)) {
      // Set module check attributes
      this.selectedLaunchablePipelineModules.forEach((schema) => {
        this.populateWithDefaults(schema.skipping_options.nf_command, schema.skipping_options.default);
        // If the inistal value is false, set the module specific parameters
        if (!(schema.skipping_options.default)) {
          schema.parameters?.forEach((param) => {
            this.populateWithDefaults(param.nf_command, param.default);
          });
        }
        // Set a dynamic wather for each module check, if the check changes act accordingly
        this.$watch(() => this.data[schema.skipping_options.nf_command], (value) => {
          if (!value) {
            if (schema.parameters && schema.parameters?.length > 0) {
              schema.parameters.forEach((param) => {
                this.populateWithDefaults(param.nf_command, param.default);
              });
            }
          } else if (schema.parameters && schema.parameters?.length > 0) {
            schema.parameters.forEach((param) => {
            // eslint-disable-next-line vue/no-mutating-props
              delete this.data[param.nf_command];
            });
          }
        });
      });
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.mode': function (value) {
      this.selectedLaunchablePipelineParameters.forEach((schema) => {
        if (('mode' in schema) && (schema.mode !== value) && (schema.nf_command in this.data)) {
          // eslint-disable-next-line vue/no-mutating-props
          delete this.data[schema.nf_command];
        }
        if (('mode' in schema) && (schema.mode === value) && !(schema.nf_command in this.data)) {
          this.populateWithDefaults(schema.nf_command, schema.default);
        }
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
  .lp-card {
    width: 100%;
  }
  .p-card {
    width: 100% !important;
    position: relative;
  }

  :v-deep(.p-card .p-card-body) {
      display: block !important;
      justify-content: center !important;
    }

  .p-card .p-card-body {
    display: block !important;
    justify-content: center !important;
  }

  :v-deep(.p-card .p-card-title) {
    position: inherit !important;
    bottom: 0%;
  }
  .p-card .p-card-title {
    position: inherit !important;
    bottom: 0%;
  }

  :v-deep(.p-card .p-card-content) {
    padding: 0;
  }

  .p-card .p-card-content {
    padding: 0;
  }

  </style>
