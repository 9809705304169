
import { defineComponent, PropType } from 'vue';
import { FEMetadataCROTemplate } from '@/models/customModels';
import Input from '@/views/CRO/DynamicCRORenderer/Input.vue';
import BooleanCheck from '@/views/CRO/DynamicCRORenderer/BooleanCheck.vue';

export default defineComponent({
  name: 'CRO',
  components: {
    Input,
    BooleanCheck,
  },
  emits: ['input'],
  props: {
    template: { type: Object as PropType<Array<FEMetadataCROTemplate[]>>, required: true },
    templateArrayProp: { type: Object as PropType<Array<Array<FEMetadataCROTemplate[]>>>, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      loading: false as boolean,
      templateArray: this.templateArrayProp,
    };
  },
  methods: {
    makeDivider(index: number): boolean {
      try {
        if (index === 0) return false;
        const prev = this.template[index - 1];
        const curr = this.template[index];
        if (prev.length !== curr.length && prev[0].name !== curr[0].name && prev[prev.length - 1].name !== curr[curr.length - 1].name) return true;
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    addRow(groupSchemaPassed: any, groupIndex: number) {
      const groupSchema = JSON.parse(JSON.stringify(groupSchemaPassed));
      for (let i = 0; i < groupSchema.length; i += 1) {
        const schema = groupSchema[i];
        const split = schema.id.split('-');
        split[1] = this.template.length;
        const newId = split.join('-');
        schema.id = newId;
        schema.value = (typeof schema.value === 'boolean') ? false : null;
        if (schema.input_type === 'BooleanCheck') {
          // eslint-disable-next-line vue/no-mutating-props
          this.data[newId] = schema;
        }
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.template.splice(groupIndex + 1, 0, groupSchema);
    },
    removeRow(groupSchema: any, groupIndex: number) {
      for (let i = 0; i < groupSchema.length; i += 1) {
        const schema = groupSchema[i];
        if (schema.id in this.data) delete this.data[schema.id];
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.template.splice(groupIndex, 1);
    },
    canRemove(index: number): boolean {
      try {
        if (index === 0) return false;
        const prev = this.template[index - 1];
        const curr = this.template[index];
        if (prev.length !== curr.length && prev[0].name !== curr[0].name && prev[prev.length - 1].name !== curr[curr.length - 1].name) return false;
        return true;
      } catch (error) {
        return false;
      }
    },
    updateData(name: string, value: any, schema: FEMetadataCROTemplate) {
      this.$emit('input', { name, value, schema });
    },
  },
  watch: {
  },
});
