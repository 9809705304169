import { createStore } from 'vuex';
import { ActiveOrganizationObject } from '@/models/customModels';

export default createStore({
  state: {
    showingProfile: false,
    availableOrganizations: ['m2m', 'gwt'],
    activeOrganization: null as unknown as Promise<ActiveOrganizationObject | null>,
    showingChangePassword: false as boolean,
    showingChangeGroup: false as boolean,
    showingChangeOrganizationDialog: false as boolean,
    precedenceLevel: 10 as number, // Possible values: 1,2,3,4,10
    showingCreateOrganizationDialog: false as boolean,
    showingStudyDialog: false as boolean,
    showingStudyPhaseDialog: false as boolean,
    showingLaunchPipeline: false as boolean,
    showingLaunchVisualizationDialog: false as boolean,
    showingLaunchablePipelineDialog: false as boolean,
    showingSamplesDialog: false as boolean,
    showingSamplesReportDialog: false as boolean,
    showingBatchDialog: false as boolean,
    showingAddCustomOrg: false as boolean,
    showingLoadVisualizationSession: false as boolean,
    newUserData: null as unknown as String | null,
    showingPipelineParamsDialog: false as boolean,
  },
  mutations: {
    showProfile(state) {
      state.showingProfile = !state.showingProfile;
    },
    setActiveOrganization(state, value: Promise<ActiveOrganizationObject | null>) {
      state.activeOrganization = value;
    },
    showChangePassword(state) {
      state.showingChangePassword = !state.showingChangePassword;
    },
    setShowingPipelineParamsDialog(state, value) {
      state.showingPipelineParamsDialog = value;
    },
    setShowingLoadVisualizationSession(state, value) {
      state.showingLoadVisualizationSession = value;
    },
    setShowingLaunchablePipelineDialog(state, value: boolean) {
      state.showingLaunchablePipelineDialog = value;
    },
    setShowingLaunchVisualizationDialog(state, value) {
      state.showingLaunchVisualizationDialog = value;
    },
    setShowingChangeGroup(state, value) {
      state.showingChangeGroup = value;
    },
    setShowingChangeOrganizationDialog(state, value) {
      state.showingChangeOrganizationDialog = value;
    },
    setPrecedenceLevel(state, value) {
      state.precedenceLevel = value;
    },
    setShowingCreateOrganizationDialog(state, value) {
      state.showingCreateOrganizationDialog = value;
    },
    setShowingStudyDialog(state, value) {
      state.showingStudyDialog = value;
    },
    setShowingStudyPhaseDialog(state, value) {
      state.showingStudyPhaseDialog = value;
    },
    setShowLaunchPipeline(state, value) {
      state.showingLaunchPipeline = value;
    },
    setShowingSamplesDialog(state, value) {
      state.showingSamplesDialog = value;
    },
    setShowingSamplesReportDialog(state, value) {
      state.showingSamplesReportDialog = value;
    },
    setShowingBatchDialog(state, value) {
      state.showingBatchDialog = value;
    },
    setShowingAddCustomOrg(state, value) {
      state.showingAddCustomOrg = value;
    },
    setNewUserData(state, value) {
      state.newUserData = value;
    },
  },
  actions: {
    showProfile({ commit }) {
      commit('showProfile');
    },
    setActiveOrganization({ commit }, value: Promise<ActiveOrganizationObject | null>) {
      commit('setActiveOrganization', value);
    },
    showChangePassword({ commit }) {
      commit('showChangePassword');
    },
    setShowingChangeGroup({ commit }, value: boolean) {
      commit('setShowingChangeGroup', value);
    },
    setShowingChangeOrganizationDialog({ commit }, value: boolean) {
      commit('setShowingChangeOrganizationDialog', value);
    },
    setShowingPipelineParamsDialog({ commit }, value: boolean) {
      commit('setShowingPipelineParamsDialog', value);
    },
    setShowingLoadVisualizationSession({ commit }, value: boolean) {
      commit('setShowingLoadVisualizationSession', value);
    },
    setPrecedenceLevel({ commit }, value: number) {
      commit('setPrecedenceLevel', value);
    },
    setShowingCreateOrganizationDialog({ commit }, value: boolean) {
      commit('setShowingCreateOrganizationDialog', value);
    },
    setShowingLaunchablePipelineDialog({ commit }, value: boolean) {
      commit('setShowingLaunchablePipelineDialog', value);
    },
    setShowingLaunchVisualizationDialog({ commit }, value: boolean) {
      commit('setShowingLaunchVisualizationDialog', value);
    },
    setShowingSamplesDialog({ commit }, value: boolean) {
      commit('setShowingSamplesDialog', value);
    },
    setShowingStudyDialog({ commit }, value: boolean) {
      commit('setShowingStudyDialog', value);
    },
    setShowingStudyPhaseDialog({ commit }, value: boolean) {
      commit('setShowingStudyPhaseDialog', value);
    },
    setShowLaunchPipeline({ commit }, value: boolean) {
      commit('setShowLaunchPipeline', value);
    },
    setShowingSamplesReportDialog({ commit }, value: boolean) {
      commit('setShowingSamplesReportDialog', value);
    },
    setShowingBatchDialog({ commit }, value: boolean) {
      commit('setShowingBatchDialog', value);
    },
    setShowingAddCustomOrg({ commit }, value: boolean) {
      commit('setShowingAddCustomOrg', value);
    },
    setNewUserData({ commit }, value: String | null) {
      commit('setNewUserData', value);
    },
  },
  modules: {
  },
  getters: {
    showingLaunchablePipelineDialog(state) {
      return state.showingLaunchablePipelineDialog;
    },
  },
});
