
import { defineComponent } from 'vue';
import DynamicPipelineParamRenderer from '@/components/Pipeline/DynamicPipelineParamRenderer.vue';
// import * as models from '@/models';
import {
  LaunchablePipelines, AWSConfigCredentialsObj, PipelineTemplate, // analysisType,
} from '@/models/customModels';
import {
  // eslint-disable-next-line no-unused-vars
  listItems, parseBatches, setCredentials, sendEBEvent, validateInputName,
} from '@/utils';
import { Auth, API } from 'aws-amplify'; //
// import * as queries from '@/graphql/queries';
import * as customQueries from '@/graphql/customQueries';
import * as customMutations from '@/graphql/customMutations';
// import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';

// eslint-disable-next-line import/no-extraneous-dependencies
import { graphqlOperation } from '@aws-amplify/api-graphql';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import Calendar from 'primevue/calendar';
import { Batch } from '@/models';
// Force rebuild
export default defineComponent({
  name: 'DynamicLaunchPipeline',
  props: {
    stepStart: Number,
    studyId: String,
    batchData: { type: Object, required: false },
    project: { type: Object, required: false },
  },
  emits: ['newPipeline'],
  components: {
    DynamicPipelineParamRenderer,
    Calendar,
  },
  data() {
    return {
      showing: false as boolean,
      loading: false as boolean,
      loadinListBox: false as boolean,
      dialogWidth: { width: '55%' },
      allLaunchablePipelines: [] as LaunchablePipelines[],
      pipelineName: '' as string,
      pipelineDescription: '' as string,
      schedulePipeline: false as boolean,
      scheduleDateTime: '' as any,
      creating: false,
      canLaunch: false,
      minDate: new Date(),
      selectedLaunchablePipeline: null as unknown as LaunchablePipelines | null,
      selectedLaunchablePipelineParameters: null as unknown as { [key: string]: any},
      selectedLaunchablePipelineModules: null as unknown as { [key: string]: any},
      mappedGroupedLaunchablePipelines: null as unknown as { label: string; items: LaunchablePipelines[]; }[],
      data: {} as {[key: string] : any}, // NF parameters
      step: this.stepStart,
      tabMenuItems: [
        {
          label: 'Parameters',
        },
      ],
      sampleTypeOptions: [{
        name: 'RnaSeq', value: 'rnaseq', key: 'MetadataRnaSeqList', mutation: 'updateMetadataRnaSeq', enumValue: 'RNASEQ',
      },
      {
        name: 'Immunopeptidomics', value: 'general', key: 'metadataImmunopeptidomicsList', mutation: 'updateMetadataImmunopeptidomics', enumValue: 'IMMUNOPEPTIDOMICS',
      },
      {
        name: 'Nanostring', value: 'nanostring', key: 'MetadataNanoStringList', mutation: 'updateMetadataNanoString', enumValue: 'NANOSTRING',
      }],
      dataType: null as any,
      displayBatchOptions: [] as any,
      selectedBatch: {} as any,
      filterColumns: ['id', 'created', 'domainSampleId', 'groups'] as any,
      tooltipMessage: 'The pipelines are grouped by their names. Select the desired pipeline version you wish to run. Hovering over a version gives a quick description.',
      group: null as unknown as string,
      uploadToken: null as unknown as string,
      credentials: null as unknown as AWSConfigCredentialsObj,
      badQCSamplesExist: false,
    };
  },
  methods: {
    async main() {
      this.loading = true;
      await this.loadAllLaunchablePipelines();
      if (this.step === 1) {
        await this.loadBatches();
      } else if (this.batchData) {
        this.displayBatchOptions.push({ name: this.batchData.batchName, value: this.batchData });
        this.selectedBatch = { name: this.batchData.batchName, value: this.batchData };
      }
      this.loading = false;
    },
    async loadBatches() {
      try {
        let batches;
        const studyId = this.studyId;
        batches = await listItems(customQueries.batchByStudyForBatchTable, { studyId, sortDirection: 'DESC' });
        batches = parseBatches(batches, this.filterColumns);
        this.displayBatchOptions = [];
        batches.forEach((element: any) => {
          this.displayBatchOptions.push({ name: element.batchName, value: element });
        });
        console.log('batches :>> ', batches);
      } catch (error) {
        console.error(error);
      }
    },
    async preLaunchCheck() {
      this.loading = true;
      this.creating = true;
      if (this.project === null) {
        console.log('Project is null in preLaunchCheck');
        this.loading = true;
        return;
      }
      try {
        const event = await this.createLaunchPipelineEvent();
        await this.createPipeline(event as any);
      } catch (error) {
        console.error(error);
      }
      this.selectedBatch = {};
      this.displayBatchOptions = [];
      this.data = {};
      this.step = this.stepStart;
      this.selectedLaunchablePipeline = null;
      this.canLaunch = false;
      this.loading = false;
      this.creating = false;
      this.$store.dispatch('setShowLaunchPipeline', false);
    },
    async createPipeline(event: any) {
      try {
        const batchParents = (await API.graphql(graphqlOperation(customQueries.getBatchParents, { id: event.batch.id })) as any)?.data?.getBatch;

        let pipelineTemplate: PipelineTemplate = {
          name: this.pipelineName,
          description: this.pipelineDescription,
          status: event.schedulePipeline ? 'SCHEDULED' : 'INITIATED',
          analysisType: event.analysisType.enumValue,
          parameters: JSON.stringify(this.data),
          scheduledPipeline: event.schedulePipeline,
          userEmail: event.user_email,
          batchId: event.batch.id,
          batchPipelinesId: event.batch.id,
          launchablePipelineId: event.pipeline.id,
          launchablePipelinesPipelinesId: event.pipeline.id,
          studyId: batchParents.study.id,
          studyPipelinesId: batchParents.study.id,
          ...(this.schedulePipeline && { scheduledDateTime: this.scheduleDateTime }),
        };
        console.log('batchParents :>> ', batchParents);
        console.log('event :>> ', event);
        console.log('pipelineTemplate :>> ', pipelineTemplate);

        pipelineTemplate = this.populateGroupsFromBatch(pipelineTemplate, batchParents);
        pipelineTemplate = this.populateStudyPhaseFromBatch(pipelineTemplate, batchParents);
        pipelineTemplate = this.setSubtypeOfAnalysis(pipelineTemplate, event);
        console.log('pipelineTemplate modified :>> ', pipelineTemplate);

        let createdPipeline: GraphQLResult<any> | null = null;
        try {
          createdPipeline = await API.graphql(
            graphqlOperation(customMutations.createPipeline, { input: pipelineTemplate }),
          );
        } catch (error) {
          console.error(error);
        }
        if (!createdPipeline) throw new Error(`Bad createdPipeline: ${createdPipeline}`);
        const createdPipelineObjectId = createdPipeline.data.createPipeline.id;
        this.$emit('newPipeline', createdPipeline.data.createPipeline as any);

        const email = (await Auth.currentUserInfo()).attributes.email;
        const partialEvent = {
          analysisType: {
            name: this.dataType.name,
          },
          batch_id: event.batch.id,
          LaunchablePipeline: {
            pipelineName: event.pipeline.pipelineName,
            pipelineVersion: event.pipeline.pipelineVersion,
          },
          nf_parameters: this.data,
          pipeline_id: createdPipelineObjectId,
          action: 'bioinf_nf_pipeline',
          step: this.schedulePipeline ? 'schedule_pipeline' : 'pipeline_trigger',
          schedulePipeline: this.schedulePipeline,
          scheduleDateTime: this.scheduleDateTime,
          user_email: email,
        };

        this.updatePipelineS3Event(partialEvent);

        await sendEBEvent(partialEvent, batchParents.study.organization.id, batchParents.study.id, pipelineTemplate?.studyPhaseId);
        // console.log(partialEvent, eventPassed, '<<<<<<<<<<<<<<<<<<<<');
        // if (eventPassed === 'failed') throw new Error('Event not passed to event bus!');
        this.hideDialog();
        // const partialEvent = '' as any;
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: (partialEvent as any).schedulePipeline ? 'Pipeline scheduled successfuly!' : 'Pipeline launched successfuly!', life: 3000,
        });
        return partialEvent;
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.hideDialog();
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to launch Pipeline!',
          life: 3000,
        });
        return error;
      }
    },
    populateGroupsFromBatch(pipelineTemplate: PipelineTemplate, batch: any) {
      const modifiedPipelineTemplate = pipelineTemplate;
      modifiedPipelineTemplate.readGroups = batch.readGroups;
      modifiedPipelineTemplate.writeGroups = batch.writeGroups;
      modifiedPipelineTemplate.adminGroups = batch.adminGroups;
      return modifiedPipelineTemplate;
    },
    populateStudyPhaseFromBatch(pipelineTemplate: PipelineTemplate, batch: any) {
      if (batch.studyPhase) {
        const modifiedPipelineTemplate = pipelineTemplate;
        modifiedPipelineTemplate.studyPhaseId = batch.studyPhase.id;
        modifiedPipelineTemplate.studyPhasePipelinesId = batch.studyPhase.id;
        return modifiedPipelineTemplate;
      }
      return pipelineTemplate;
    },
    setSubtypeOfAnalysis(pipelineTemplate: PipelineTemplate, event: any) {
      const modifiedPipelineTemplate = pipelineTemplate;
      if (event.pipeline && event.pipeline.supportedSubtypeOfAnalyses) {
        modifiedPipelineTemplate.subtypeOfAnalysis = event.pipeline.supportedSubtypeOfAnalyses[0];
      }
      return modifiedPipelineTemplate;
    },
    // eslint-disable-next-line camelcase
    async updatePipelineS3Event(event: any): Promise<any> {
      return API.graphql(graphqlOperation(customMutations.updatePipeline, { input: { id: event.pipeline_id, s3Event: JSON.stringify(event) } }));
    },
    async getCredentials(): Promise<AWSConfigCredentialsObj | null> {
      try {
        // const groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        if (this.$store.state.precedenceLevel === 1) {
          const credentials = await setCredentials('M2MAdmin');
          if (credentials) {
            this.group = 'M2MAdmin';
            this.credentials = credentials;
            return credentials;
          } throw new Error('Credentials null');
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async createLaunchPipelineEvent() {
      const email = (await Auth.currentUserInfo()).attributes.email;
      // eslint-disable-next-line
      const event = { batch: (this.selectedBatch) ? this.selectedBatch.value : [],
        analysisType: this.dataType,
        pipeline: this.selectedLaunchablePipeline,
        schedulePipeline: this.schedulePipeline,
        scheduleDateTime: this.scheduleDateTime,
        user_email: email,
      };
      return event;
    },
    getBodyStyle() {
      return 'text-align: center';
    },
    formatNFParamsForRunner() {
      // eslint-disable-next-line no-unused-vars
      const formatedParams = Object.keys(this.data).map((key) => ({
        nf_parameter: key,
        value: this.data[key],
      }));
      return formatedParams;
    },
    async loadAllLaunchablePipelines() {
      try {
        this.loadinListBox = true;
        const response = await this.getLaunchablePipelines();
        const filteredLaunchablePipelines = this.filterLaunchablePipelines(response as LaunchablePipelines[]);
        this.allLaunchablePipelines = [...filteredLaunchablePipelines];
        this.mappedGroupedLaunchablePipelines = this.groupLaunchablePipelinesForListBox(this.allLaunchablePipelines);
      } catch (error) {
        console.error('Error in loading all launchable pipelines');
        console.error(error);
      }
    },
    async getLaunchablePipelines(): Promise<LaunchablePipelines[]> {
      try {
        if (this.$store.state.precedenceLevel === 1) return this.getAllLaunchablePipelines();
        if (this.$store.state.precedenceLevel > 1) return this.getLaunchablePipelinesForOrganization(this.$route.params.organizationId as string);
        return [];
      } catch (error) {
        return [];
      }
    },
    async getAllLaunchablePipelines(): Promise<LaunchablePipelines[]> {
      try {
        const results: any = await listItems(customQueries.lisAllLaunchablePipelinesWithOrganizations, { });
        return results;
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    async getLaunchablePipelinesForOrganization(organizationId: string): Promise<LaunchablePipelines[]> {
      try {
        const results: any = await listItems(customQueries.organizationLaunchablePipelinesByOrganizationId, { organizationId });
        if (results.length === 0) {
          return [];
        }
        if (results && results.length > 0) {
          const extractedLaunchablePipelines: LaunchablePipelines[] = results.map((link: any) => link.launchablePipelines);
          console.log('extractedLaunchablePipelines :>> ', extractedLaunchablePipelines);
          return extractedLaunchablePipelines;
        }
        return [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    // Start of ListBoxFunctions
    groupLaunchablePipelinesForListBox(allLaunchablePipelines: LaunchablePipelines[]): { label: string; items: LaunchablePipelines[]; }[] {
      try {
        // eslint-disable-next-line prefer-const
        let groupedLaunchablePipelines: { [key: string]: LaunchablePipelines[]} = this.groupBy([...allLaunchablePipelines], 'pipelineName');
        const keys: string[] = Object.keys(groupedLaunchablePipelines);
        const mappedGroupedLaunchablePipelines = keys.map((key) => {
          const o = {
            label: key,
            items: groupedLaunchablePipelines[key],
          };
          return o;
        });
        mappedGroupedLaunchablePipelines.forEach((group) => {
          group.items.sort((a: any, b: any) => {
            if (a.pipelineVersion === 'develop' && b.pipelineVersion === 'develop') return 0;
            if (a.pipelineVersion === 'develop') return 1;
            if (b.pipelineVersion === 'develop') return -1;
            const aSplit = a.pipelineVersion.split('.');
            const bSplit = b.pipelineVersion.split('.');
            for (let i = 0; i <= 3; i += 1) {
              if (parseInt(aSplit[i], 10) < parseInt(bSplit[i], 10)) return 1;
              if (parseInt(aSplit[i], 10) > parseInt(bSplit[i], 10)) return -1;
            }
            return 0;
          });
        });
        this.loadinListBox = false;
        return mappedGroupedLaunchablePipelines;
      } catch (error) {
        console.error('Error in grouping launchable pipelines for ListBox');
        console.error(error);
        if (this.loadinListBox) this.loadinListBox = false;
        return [];
      }
    },
    // There are some invalid pipelines in the DB at the moment
    filterLaunchablePipelines(pipelines: LaunchablePipelines[]) {
      return pipelines.filter((pipeline: LaunchablePipelines) => (pipeline.parameters !== null) && (pipeline.parameters !== 'None') && (pipeline.parameters !== 'false'));
    },
    groupBy(array: any, key: any) {
      return array.reduce((rv: any, x: any) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    // End of ListBoxFunctions
    generateDynamicParams() {
      try {
        if (this.selectedLaunchablePipeline) {
          if (!this.selectedLaunchablePipeline.parameters) {
            return;
          }
          const params = JSON.parse(this.selectedLaunchablePipeline?.parameters);
          if (params?.parameters?.length === 0) {
            this.step = 4;
            return;
          }
          this.selectedLaunchablePipelineParameters = params.parameters;
          this.selectedLaunchablePipelineModules = params.modules;
          this.step = 3;
        } else {
          console.error('No selected launchable pipeline');
        }
      } catch (error) {
        console.error('Error generating dynamic parameters');
        console.error(error);
      }
    },
    // eslint-disable-next-line camelcase
    updateData({ nf_command, value }: {[key: string] : any}): void {
      // eslint-disable-next-line camelcase
      this.data[nf_command] = value;
    },
    fixParamType(pipelines: LaunchablePipelines[]): LaunchablePipelines[] {
      pipelines.forEach((pipeline) => {
        if (!pipeline.parameters) {
          return;
        }
        const params = JSON.parse(pipeline.parameters);
        params.parameters = params.parameters.map((param: {[key: string] : any}) => {
          if (param.type === 'Boolean') {
            // eslint-disable-next-line no-param-reassign
            param.type = 'Check';
            return param;
          }
          if (param.options.length > 0) {
            // eslint-disable-next-line no-param-reassign
            param.type = 'Select';
            return param;
          }
          if (param.options.length === 0) {
            // eslint-disable-next-line no-param-reassign
            param.type = 'Input';
            return param;
          }
          return 'Error';
        });
        params.modules.map((module: {[key: string] : any}) => {
          if (module.skipping_options.type === 'Boolean') {
            // eslint-disable-next-line no-param-reassign
            module.skipping_options.type = 'Check';
            return module;
          }
          return 'Error';
        });
        const jsonParams = JSON.stringify(params);
        // eslint-disable-next-line no-param-reassign
        pipeline.parameters = jsonParams;
      });
      return pipelines;
    },
    async checkIfBadQCSamplesExist(batch: Batch, key: string) {
      this.loading = true;
      const promises: any[] = [];
      const batchAnnotations = batch?.batchAnnotations;
      (batchAnnotations as unknown as any[]).forEach((sample: any) => {
        sample[key].forEach((metadata: any) => {
          if (key === 'MetadataRnaSeqList') {
            promises.push(API.graphql(graphqlOperation(customQueries.getMetadataRnaSeqForQC, { id: metadata.id })));
          } else if (key === 'metadataImmunopeptidomicsList') {
            promises.push(API.graphql(graphqlOperation(customQueries.getMetadataImmunopeptidomicsForQC, { id: metadata.id })));
          } else if (key === 'MetadataNanoStringList') {
            promises.push(API.graphql(graphqlOperation(customQueries.getMetadataNanoStringForQC, { id: metadata.id })));
          }
        });
      });
      // const metadata = (await Promise.all(promises));
      const metadata = (await Promise.all(promises)).map((res) => res.data[Object.keys(res.data)[0]]);
      console.log('metadata :>> ', metadata);
      if (metadata.some((data) => data.qcStatus !== 'PASSED')) this.badQCSamplesExist = true;
      this.loading = false;
    },
    filterAnalysisOptionsForSamplesWithCorrespondingAnalysis(batch: Batch) {
      console.log('batch :>> ', batch);
      console.log('this.sampleTypeOptions :>> ', this.sampleTypeOptions);
      this.sampleTypeOptions = this.sampleTypeOptions.filter((option: { key: string}) => (batch?.batchAnnotations as unknown as any[])?.some((metadata: any) => metadata[option.key].length > 0));
      // this.sampleTypeOptions = this.sampleTypeOptions.filter((option: { name: string}) => (batch?.batchAnnotations as unknown as any[])?.some((metadata: any) => metadata.availableAnalysisTypes.includes(option.name)));
    },
    switchToFinalStep() {
      this.step = 4;
    },
    resetSelectedLaunchablePipeline(): void {
      switch (this.step) {
        case 2:
          this.selectedBatch = null;
          this.step = 1;
          break;
        case 3:
          this.data = {};
          this.selectedLaunchablePipeline = null;
          this.step = 2;
          break;
        case 4:
          // eslint-disable-next-line no-unused-expressions
          Object.keys(this.data).length === 0 ? this.step = 2 : this.step = 3;
          break;
        default:
          break;
      }
    },
    changeDialogWidth(width: string): void {
      this.dialogWidth.width = `${width}%`;
    },
    validatePipelineName() {
      return validateInputName(this.pipelineName! as string);
    },
    hideDialog(): void {
      this.pipelineName = '';
      this.pipelineDescription = '';
      this.dataType = null;
      this.data = {};
      this.step = this.stepStart;
      this.selectedLaunchablePipeline = null;
      this.canLaunch = false;
      this.$store.dispatch('setShowLaunchPipeline', false);
      this.creating = false;
      this.showing = false;
      this.scheduleDateTime = '';
      this.schedulePipeline = false;
      this.badQCSamplesExist = false;
    },
  },
  computed: {
    formatedData() {
      return Object.keys(this.data).map((key) => ({ header: key, field: this.data[key] }));
    },
    getProjectProp() {
      return this.project;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLaunchPipeline': async function () {
      this.showing = this.$store.state.showingLaunchPipeline;
      if (this.showing) await this.main();
    },
    project: {
      async handler() {
        console.log('Project changed');
        if (this.showing) {
          console.log('Launch pipeline was already up');
          return;
        }
        if (this.project !== null && this.project !== undefined) {
          this.showing = true;
          await this.loadAllLaunchablePipelines();
        }
      },
      // deep: true,
      // immediate: true,
      // flush: 'post',
    },
    // eslint-disable-next-line func-names
    studyId: {
      async handler() {
        let batches;
        console.log(batches);
      },
    },
    selectedBatch(selectedBatch: { key: string, value: Batch }) {
      if (selectedBatch) this.filterAnalysisOptionsForSamplesWithCorrespondingAnalysis(selectedBatch.value);
    },
    dataType() {
      if (!this.dataType) return;
      let filtered = this.allLaunchablePipelines.filter((lp) => lp.supportedAnalyses?.includes(this.dataType.enumValue));
      console.log('filtered :>> ', filtered);
      const batchSubtypes: string[] = Array.from(new Set(this.selectedBatch.value.batchAnnotations.map((sample: any) => sample.availableAnalysisSubTypes).flat())) as string[];
      console.log('batchSubtypes :>> ', batchSubtypes);
      if (batchSubtypes && batchSubtypes.length > 0) {
        if (batchSubtypes.length > 1) {
          console.error('More than one batch subtype');
        }
        filtered = filtered.filter((lp) => (!lp.supportedSubtypeOfAnalyses) || lp.supportedSubtypeOfAnalyses[0].toLowerCase() === batchSubtypes[0].toLowerCase());
      }
      console.log('filtered :>> ', filtered);
      this.mappedGroupedLaunchablePipelines = this.groupLaunchablePipelinesForListBox(filtered);
      this.checkIfBadQCSamplesExist(this.selectedBatch.value as Batch, this.dataType.key);
    },
    async selectedLaunchablePipeline(value) {
      if (value) {
        this.generateDynamicParams();
        this.canLaunch = true;
      }
    },
  },
});
