
import { defineComponent, PropType } from 'vue';
// eslint-disable-next-line no-unused-vars
import { EntityQA, EntityQAObject } from '@/models/customModels';

export default defineComponent({
  name: 'Questions',
  emits: ['input'],
  props: {
    questionsFull: { type: Object as PropType<EntityQAObject | null>, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
    };
  },
  methods: {
    updateData(id: string, value: any) {
      this.$emit('input', { id, value });
    },
  },
  watch: {
  },
});
