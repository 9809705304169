import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09cb5964"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "studies-table" }
const _hoisted_2 = { class: "flex justify-content-end flex-column sm:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_StudyPhaseDialog = _resolveComponent("StudyPhaseDialog")!
  const _component_LaunchPipelineDialog = _resolveComponent("LaunchPipelineDialog")!
  const _component_BatchDialog = _resolveComponent("BatchDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        value: _ctx.studyPhases,
        paginator: true,
        responsiveLayout: "scroll",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters) = $event)),
        ref: "dt",
        filterDisplay: "row",
        globalFilterFields: ['name'],
        paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
        rows: 10,
        rowsPerPageOptions: [10,20,50],
        dataKey: "id",
        selectionMode: "single",
        loading: _ctx.loading,
        onRowSelect: _ctx.onStudyPhaseSelect
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.$store.state.precedenceLevel <= 4)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  type: "button",
                  label: "Create Study Batch",
                  class: "p-button mb-2",
                  onClick: _ctx.openBatchDialog
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.$store.state.precedenceLevel <= 3)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  type: "button",
                  label: "Add Study Phase",
                  class: "p-button mb-2 ml-3",
                  icon: "pi pi-plus",
                  onClick: _ctx.openStudyPhaseDialog
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (_ctx.$store.state.precedenceLevel <= 4)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 2,
                  type: "button",
                  label: "Explore",
                  icon: "pi pi-compass",
                  class: "p-button mb-2 ml-3",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.exploreStudy()))
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        empty: _withCtx(() => [
          _createTextVNode(" No study phases found. ")
        ]),
        loading: _withCtx(() => [
          _createTextVNode(" Loading study phases... Please wait. ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: "Name",
            field: "studyPhaseName",
            sortable: true,
            filterMatchMode: "startsWith",
            ref: "studyPhaseName",
            style: {"width":"12rem"}
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                class: "p-column-filter",
                placeholder: "Search by study phase name",
                style: {"min-width":"12rem"}
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_Column, {
            field: "phase",
            header: "Phase",
            showFilterMenu: false,
            style: {"width":"11rem"}
          }, {
            filter: _withCtx(({ filterModel, filterCallback }) => [
              _createVNode(_component_Dropdown, {
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onChange: ($event: any) => (filterCallback()),
                options: _ctx.phaseDDOptions,
                placeholder: "Select a Phase",
                class: "p-column-filter",
                style: {"min-width":"11rem"},
                showClear: (filterModel.value) ? true : false
              }, {
                option: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_ctx.formatPhaseName(slotProps.option)), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options", "showClear"])
            ]),
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatPhaseName(data.phase)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Group Names",
            field: "groupNames"
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatListForView(data.groupNames)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Assay Technologies",
            field: "labAssayTechnologies"
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatListForView(data.labAssayTechnologies)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Sample Types",
            field: "sampleTypes"
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatListForView(data.sampleTypes)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Species",
            field: "species"
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatListForView(data.species)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Specimens",
            field: "specimens"
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatListForView(data.specimens)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            header: "Description",
            field: "description"
          }),
          _createVNode(_component_Column, {
            header: "Created",
            class: "created-column-date-only",
            field: "created",
            sortable: true
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatISOStringCustom(data.created)), 1)
            ]),
            _: 1
          }),
          (_ctx.$store.state.precedenceLevel <= 6)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                header: "",
                headerStyle: "width: 13rem",
                bodyStyle: "width: 13rem"
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Button, {
                      class: "rounded-btn larger-rounded-btn",
                      label: "Study Phase Batches",
                      disabled: data.batches.items.length === 0,
                      onClick: ($event: any) => {_ctx.$router.push(`/${_ctx.$route.params.organizationName}/${_ctx.$route.params.organizationId}/batchBrowse/studyPhase/${data.id}/${_ctx.$route.params.studyId}`);}
                    }, null, 8, ["disabled", "onClick"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.$store.state.precedenceLevel <= 6)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 1,
                header: ""
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Button, {
                      class: "rounded-btn larger-rounded-btn",
                      label: "Study Phase Pipelines",
                      disabled: data.pipelines.items.length === 0,
                      onClick: ($event: any) => {_ctx.$router.push(`/${_ctx.$route.params.organizationName}/${_ctx.$route.params.organizationId}/pipelineBrowse/studyPhase/${data.id}/${_ctx.$route.params.studyId}`);}
                    }, null, 8, ["disabled", "onClick"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Column, { class: "menu-column" }, {
            body: _withCtx(({data}) => [
              _createVNode(_component_Button, {
                icon: "pi pi-ellipsis-v",
                class: "text-btn",
                onClick: ($event: any) => (_ctx.studyPhaseActionClick(data, $event))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Menu, {
                ref: 'study-menu-' + data.id,
                model: _ctx.studyPhaseActionItems(data),
                popup: true
              }, null, 8, ["model"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "filters", "loading", "onRowSelect"])
    ]),
    _createVNode(_component_StudyPhaseDialog, {
      studyPhaseForUpdate: _ctx.studyPhaseForUpdate,
      onNewStudyPhase: _ctx.pushNewStudyPhaseToTopOfTable,
      onUpdateStudyPhase: _ctx.updateStudyPhaseInTable,
      onClearStudyPhaseForUpdate: _ctx.clearStudyPhaseForUpdate
    }, null, 8, ["studyPhaseForUpdate", "onNewStudyPhase", "onUpdateStudyPhase", "onClearStudyPhaseForUpdate"]),
    _createVNode(_component_LaunchPipelineDialog),
    _createVNode(_component_BatchDialog, {
      studyPhases: _ctx.studyPhases,
      studyId: _ctx.studyId,
      studyPhaseId: _ctx.studyPhaseIdForBatchCreation,
      onNewBatch: _ctx.newBatch
    }, null, 8, ["studyPhases", "studyId", "studyPhaseId", "onNewBatch"])
  ], 64))
}