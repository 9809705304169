import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e2cbe0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("iframe", {
      id: `vis-if-${_ctx.id}`,
      class: "vis-if",
      src: _ctx.iframeSrc,
      title: "Visualization",
      alt: "visualization"
    }, null, 8, _hoisted_1)
  ]))
}