export const onUpdatePipelineForStatus = /* GraphQL */ `
  subscription OnUpdatePipeline($filter: ModelSubscriptionPipelineFilterInput) {
    onUpdatePipeline(filter: $filter) {
      id
      status
      created
      updated
  }
}
`;
export const onUpdateVisualizationSession = /* GraphQL */ `
  subscription OnUpdateVisualizationSession(
    $filter: ModelSubscriptionVisualizationSessionFilterInput
    $owner: String
  ) {
    onUpdateVisualizationSession(filter: $filter, owner: $owner) {
      id
      statuses
      data
    }
  }
`;
