// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PipelineStatus = {
  "INITIATED": "INITIATED",
  "SCHEDULED": "SCHEDULED",
  "SCHEDULED_FAILED": "SCHEDULED_FAILED",
  "RUNNING": "RUNNING",
  "COMPLETED": "COMPLETED",
  "CANCELLED": "CANCELLED",
  "CANCELLED_FAILED": "CANCELLED_FAILED",
  "ABORTED": "ABORTED",
  "ABORTED_FAILED": "ABORTED_FAILED",
  "FINISHED": "FINISHED",
  "FAILED": "FAILED",
  "ERROR": "ERROR"
};

const AnalysisType = {
  "RNASEQ": "RNASEQ",
  "IMMUNOPEPTIDOMICS": "IMMUNOPEPTIDOMICS",
  "NANOSTRING": "NANOSTRING"
};

const QcStatus = {
  "AWAITING": "AWAITING",
  "PASSED": "PASSED",
  "FAILED": "FAILED"
};

const SequenceType = {
  "PAIRED_END": "PAIRED_END",
  "SINGLE_END": "SINGLE_END"
};

const Phase = {
  "PRE_CLINICAL": "PRE_CLINICAL",
  "PHASE_1": "PHASE_1",
  "PHASE_2": "PHASE_2",
  "PHASE_3": "PHASE_3"
};

const EntityType = {
  "ORGANIZATION": "ORGANIZATION",
  "STUDY": "STUDY",
  "STUDY_PHASE": "STUDY_PHASE"
};

const { LaunchablePipelines, Organization, Study, StudyPhase, MetaDataValidationSchemaTemplate, Group, Patient, Timepoint, Sample, Batch, Pipeline, MetadataImmunopeptidomics, Treatment, MetadataNanoString, MetadataRnaSeq, AuxiliaryAnalysis, EntityQuestions, User, UploadLog, Visualizations, VisualizationSession, OrganizationLaunchablePipelines, ModelBatchConnection, ModelPipelineConnection, ModelStudyConnection, ModelStudyPhaseConnection, ModelAuxiliaryAnalysisConnection, ModelEntityQuestionsConnection, ModelGroupConnection, ModelLaunchablePipelinesConnection, ModelMetaDataValidationSchemaTemplateConnection, ModelMetadataImmunopeptidomicsConnection, ModelMetadataNanoStringConnection, ModelMetadataRnaSeqConnection, ModelOrganizationConnection, ModelPatientConnection, ModelSampleConnection, ModelTimepointConnection, ModelTreatmentConnection, ModelUploadLogConnection, ModelUserConnection, ModelVisualizationSessionConnection, ModelVisualizationsConnection } = initSchema(schema);

export {
  LaunchablePipelines,
  Organization,
  Study,
  StudyPhase,
  MetaDataValidationSchemaTemplate,
  Group,
  Patient,
  Timepoint,
  Sample,
  Batch,
  Pipeline,
  MetadataImmunopeptidomics,
  Treatment,
  MetadataNanoString,
  MetadataRnaSeq,
  AuxiliaryAnalysis,
  EntityQuestions,
  User,
  UploadLog,
  Visualizations,
  VisualizationSession,
  OrganizationLaunchablePipelines,
  PipelineStatus,
  AnalysisType,
  QcStatus,
  SequenceType,
  Phase,
  EntityType,
  ModelBatchConnection,
  ModelPipelineConnection,
  ModelStudyConnection,
  ModelStudyPhaseConnection,
  ModelAuxiliaryAnalysisConnection,
  ModelEntityQuestionsConnection,
  ModelGroupConnection,
  ModelLaunchablePipelinesConnection,
  ModelMetaDataValidationSchemaTemplateConnection,
  ModelMetadataImmunopeptidomicsConnection,
  ModelMetadataNanoStringConnection,
  ModelMetadataRnaSeqConnection,
  ModelOrganizationConnection,
  ModelPatientConnection,
  ModelSampleConnection,
  ModelTimepointConnection,
  ModelTreatmentConnection,
  ModelUploadLogConnection,
  ModelUserConnection,
  ModelVisualizationSessionConnection,
  ModelVisualizationsConnection
};