
import { defineComponent } from 'vue';
import BatchSamplesAnnotationGrouping from '@/components/Batch/BatchSamplesAnnotationGrouping.vue';
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from '@/graphql/customQueries';
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import {
  Sample,
} from '@/models';
import { FilterMatchMode } from 'primevue/api';
import { MenuItem } from '@/models/customModels';
import { getHomeRouteForBreadcrumb, parseBatches, getOrgNameAndIdForRoute } from '@/utils';

export default defineComponent({
  name: 'Batch Samples Browse',
  components: {
    BatchSamplesAnnotationGrouping,
  },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [{ label: 'Batches', to: this.$route.fullPath }] as MenuItem[],
      batchView: true as boolean,
      selectedSamples: [] as Sample[],
      batchId: '' as string,
      annotationColumns: [] as any,
      filterColumns: ['id', 'created', 'domainSampleId', 'groups'] as any,
      annotationFilters: {
        domainSampleId: { value: '', matchMode: FilterMatchMode.CONTAINS },
      } as any,
      batchData: {} as any,
    };
  },
  mounted() {
    if (this.$route.params.batchId) {
      this.loadSamplesForBatch(this.$route.params.batchId as string);
      this.batchId = this.$route.params.batchId as string;
    }
  },
  methods: {
    async loadSamplesForBatch(batchId: string) {
      console.log(batchId);
      const batch = (await API.graphql(graphqlOperation(customQueries.getBatch, { id: batchId })) as GraphQLResult<any>).data.getBatch;
      this.selectedSamples = JSON.parse(batch.batchAnnotations);
      this.extractAnnotations(this.selectedSamples);
      this.annotationFilters = JSON.parse(batch.filters);
      console.log(this.annotationFilters);
      this.annotationColumns = JSON.parse(batch.annotations);
      console.log(this.annotationColumns);
      this.batchData = parseBatches([batch], this.filterColumns)[0];
      this.setBreadcrumbItems(batch.studyId as string);
    },
    extractAnnotations(selectedSamples: any) {
      selectedSamples.forEach((sample: any) => {
        // eslint-disable-next-line no-param-reassign
        sample.annotations.forEach((annot: any) => {
          // eslint-disable-next-line no-param-reassign
          sample[annot.name] = annot.value;
        });
        console.log(sample);
      });
    },
    makeBreadcrumbRouteToBatches(studyId: string): string {
      try {
        return `${getOrgNameAndIdForRoute(this.$route)}batchBrowse/${studyId}`;
      } catch (error) {
        console.error(error);
        return '/';
      }
    },
    makeBreadcrumbItems(route: any, studyId: string): { label: string; to: any; }[] {
      return [
        { label: 'Batches', to: this.makeBreadcrumbRouteToBatches(studyId) as string },
        { label: 'Batch Samples', to: route.fullPath },
      ];
    },
    setBreadcrumbItems(studyId: string): void {
      this.breadcrumbItems = this.makeBreadcrumbItems(this.$route, studyId);
    },
  },
});
