/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const doNotTouchItDoesNothing = /* GraphQL */ `
  mutation DoNotTouchItDoesNothing($input: CreateUserInput!) {
    doNotTouchItDoesNothing(input: $input) {
      id
      pipelineName
      organizations {
        items {
          id
          organizationId
          launchablePipelinesId
          createdAt
          updatedAt
        }
        nextToken
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      gitRepo
      workflowId
      created
      updated
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      organizationName
      organizationCode
      active
      notificationEmail
      description
      organizationAnswers
      url
      s3Path
      readGroups
      writeGroups
      created
      updated
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      organizationName
      organizationCode
      active
      notificationEmail
      description
      organizationAnswers
      url
      s3Path
      readGroups
      writeGroups
      created
      updated
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      organizationName
      organizationCode
      active
      notificationEmail
      description
      organizationAnswers
      url
      s3Path
      readGroups
      writeGroups
      created
      updated
    }
  }
`;
export const createStudy = /* GraphQL */ `
  mutation CreateStudy(
    $input: CreateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    createStudy(input: $input, condition: $condition) {
      id
      studyName
      organizationId
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
    }
  }
`;
export const updateStudy = /* GraphQL */ `
  mutation UpdateStudy(
    $input: UpdateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    updateStudy(input: $input, condition: $condition) {
      id
      studyName
      organizationId
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
    }
  }
`;
export const deleteStudy = /* GraphQL */ `
  mutation DeleteStudy(
    $input: DeleteStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    deleteStudy(input: $input, condition: $condition) {
      id
      studyName
      organizationId
      description
      studyCode
      studyAnswers
      s3Path
      croGroups
      readGroups
      writeGroups
      adminGroups
      created
      updated
      organizationStudiesId
    }
  }
`;
export const createStudyPhase = /* GraphQL */ `
  mutation CreateStudyPhase(
    $input: CreateStudyPhaseInput!
    $condition: ModelStudyPhaseConditionInput
  ) {
    createStudyPhase(input: $input, condition: $condition) {
      id
      studyId
      metaDataValidationSchemaTemplateId
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
    }
  }
`;
export const updateStudyPhase = /* GraphQL */ `
  mutation UpdateStudyPhase(
    $input: UpdateStudyPhaseInput!
    $condition: ModelStudyPhaseConditionInput
  ) {
    updateStudyPhase(input: $input, condition: $condition) {
      id
      studyId
      metaDataValidationSchemaTemplateId
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
    }
  }
`;
export const deleteStudyPhase = /* GraphQL */ `
  mutation DeleteStudyPhase(
    $input: DeleteStudyPhaseInput!
    $condition: ModelStudyPhaseConditionInput
  ) {
    deleteStudyPhase(input: $input, condition: $condition) {
      id
      studyId
      metaDataValidationSchemaTemplateId
      validationSchema
      description
      studyPhaseName
      studyPhaseAnswers
      experimentSpecificVariables
      sampleTypes
      specimens
      species
      s3Path
      groupNames
      labAssayTechnologies
      phase
      readGroups
      writeGroups
      adminGroups
      croGroups
      created
      updated
      studyStudyPhasesId
      metaDataValidationSchemaTemplateStudyPhasesId
    }
  }
`;
export const createEntityQuestions = /* GraphQL */ `
  mutation CreateEntityQuestions(
    $input: CreateEntityQuestionsInput!
    $condition: ModelEntityQuestionsConditionInput
  ) {
    createEntityQuestions(input: $input, condition: $condition) {
      id
      entityType
      questions
      created
      updated
    }
  }
`;
export const updateEntityQuestions = /* GraphQL */ `
  mutation UpdateEntityQuestions(
    $input: UpdateEntityQuestionsInput!
    $condition: ModelEntityQuestionsConditionInput
  ) {
    updateEntityQuestions(input: $input, condition: $condition) {
      id
      entityType
      questions
      created
      updated
    }
  }
`;
export const deleteEntityQuestions = /* GraphQL */ `
  mutation DeleteEntityQuestions(
    $input: DeleteEntityQuestionsInput!
    $condition: ModelEntityQuestionsConditionInput
  ) {
    deleteEntityQuestions(input: $input, condition: $condition) {
      id
      entityType
      questions
      created
      updated
    }
  }
`;
export const createMetaDataValidationSchemaTemplate = /* GraphQL */ `
  mutation CreateMetaDataValidationSchemaTemplate(
    $input: CreateMetaDataValidationSchemaTemplateInput!
    $condition: ModelMetaDataValidationSchemaTemplateConditionInput
  ) {
    createMetaDataValidationSchemaTemplate(
      input: $input
      condition: $condition
    ) {
      id
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
    }
  }
`;
export const updateMetaDataValidationSchemaTemplate = /* GraphQL */ `
  mutation UpdateMetaDataValidationSchemaTemplate(
    $input: UpdateMetaDataValidationSchemaTemplateInput!
    $condition: ModelMetaDataValidationSchemaTemplateConditionInput
  ) {
    updateMetaDataValidationSchemaTemplate(
      input: $input
      condition: $condition
    ) {
      id
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
    }
  }
`;
export const deleteMetaDataValidationSchemaTemplate = /* GraphQL */ `
  mutation DeleteMetaDataValidationSchemaTemplate(
    $input: DeleteMetaDataValidationSchemaTemplateInput!
    $condition: ModelMetaDataValidationSchemaTemplateConditionInput
  ) {
    deleteMetaDataValidationSchemaTemplate(
      input: $input
      condition: $condition
    ) {
      id
      version
      description
      templateFiles
      validationSchemaTemplate
      created
      updated
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      studyPhaseId
      type
      groupName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      studyPhaseId
      type
      groupName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      studyPhaseId
      type
      groupName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseGroupsId
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      groupId
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      groupId
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      groupId
      patientStudyPhaseId
      patientStudyId
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      groupPatientsId
    }
  }
`;
export const createTimepoint = /* GraphQL */ `
  mutation CreateTimepoint(
    $input: CreateTimepointInput!
    $condition: ModelTimepointConditionInput
  ) {
    createTimepoint(input: $input, condition: $condition) {
      id
      patientId
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
    }
  }
`;
export const updateTimepoint = /* GraphQL */ `
  mutation UpdateTimepoint(
    $input: UpdateTimepointInput!
    $condition: ModelTimepointConditionInput
  ) {
    updateTimepoint(input: $input, condition: $condition) {
      id
      patientId
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
    }
  }
`;
export const deleteTimepoint = /* GraphQL */ `
  mutation DeleteTimepoint(
    $input: DeleteTimepointInput!
    $condition: ModelTimepointConditionInput
  ) {
    deleteTimepoint(input: $input, condition: $condition) {
      id
      patientId
      timepointName
      s3Path
      readGroups
      writeGroups
      adminGroups
      created
      updated
      patientTimepointsId
    }
  }
`;
export const createSample = /* GraphQL */ `
  mutation CreateSample(
    $input: CreateSampleInput!
    $condition: ModelSampleConditionInput
  ) {
    createSample(input: $input, condition: $condition) {
      id
      timepointId
      studyPhaseId
      BatchId
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
    }
  }
`;
export const updateSample = /* GraphQL */ `
  mutation UpdateSample(
    $input: UpdateSampleInput!
    $condition: ModelSampleConditionInput
  ) {
    updateSample(input: $input, condition: $condition) {
      id
      timepointId
      studyPhaseId
      BatchId
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
    }
  }
`;
export const deleteSample = /* GraphQL */ `
  mutation DeleteSample(
    $input: DeleteSampleInput!
    $condition: ModelSampleConditionInput
  ) {
    deleteSample(input: $input, condition: $condition) {
      id
      timepointId
      studyPhaseId
      BatchId
      domainSampleId
      experimentSpecificVariables
      collectionDate
      processingDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPhaseSamplesId
      timepointSamplesId
      batchSamplesId
    }
  }
`;
export const createBatch = /* GraphQL */ `
  mutation CreateBatch(
    $input: CreateBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    createBatch(input: $input, condition: $condition) {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      studyId
      studyPhaseId
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
    }
  }
`;
export const updateBatch = /* GraphQL */ `
  mutation UpdateBatch(
    $input: UpdateBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    updateBatch(input: $input, condition: $condition) {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      studyId
      studyPhaseId
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
    }
  }
`;
export const deleteBatch = /* GraphQL */ `
  mutation DeleteBatch(
    $input: DeleteBatchInput!
    $condition: ModelBatchConditionInput
  ) {
    deleteBatch(input: $input, condition: $condition) {
      id
      batchName
      batchAnnotations
      batchGroups
      annotations
      controlSamples
      filters
      description
      studyId
      studyPhaseId
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyBatchesId
      studyPhaseBatchesId
    }
  }
`;
export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      parameters
      subtypeOfAnalysis
      studyId
      studyPhaseId
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      batchId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;
export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      subtypeOfAnalysis
      s3NfTasksLogs
      analysisType
      parameters
      studyId
      studyPhaseId
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      batchId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;
export const deletePipeline = /* GraphQL */ `
  mutation DeletePipeline(
    $input: DeletePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    deletePipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      parameters
      studyId
      studyPhaseId
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      batchId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;
export const createAuxiliaryAnalysis = /* GraphQL */ `
  mutation CreateAuxiliaryAnalysis(
    $input: CreateAuxiliaryAnalysisInput!
    $condition: ModelAuxiliaryAnalysisConditionInput
  ) {
    createAuxiliaryAnalysis(input: $input, condition: $condition) {
      id
      sampleId
      auxiliaryFileS3Path
      vendorId
      specimenType
      parameters
      collectionDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      sampleAuxiliaryAnalysislListId
    }
  }
`;
export const updateAuxiliaryAnalysis = /* GraphQL */ `
  mutation UpdateAuxiliaryAnalysis(
    $input: UpdateAuxiliaryAnalysisInput!
    $condition: ModelAuxiliaryAnalysisConditionInput
  ) {
    updateAuxiliaryAnalysis(input: $input, condition: $condition) {
      id
      sampleId
      auxiliaryFileS3Path
      vendorId
      specimenType
      parameters
      collectionDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      sampleAuxiliaryAnalysislListId
    }
  }
`;
export const deleteAuxiliaryAnalysis = /* GraphQL */ `
  mutation DeleteAuxiliaryAnalysis(
    $input: DeleteAuxiliaryAnalysisInput!
    $condition: ModelAuxiliaryAnalysisConditionInput
  ) {
    deleteAuxiliaryAnalysis(input: $input, condition: $condition) {
      id
      sampleId
      auxiliaryFileS3Path
      vendorId
      specimenType
      parameters
      collectionDate
      readGroups
      writeGroups
      adminGroups
      created
      updated
      sampleAuxiliaryAnalysislListId
    }
  }
`;
export const createMetadataImmunopeptidomics = /* GraphQL */ `
  mutation CreateMetadataImmunopeptidomics(
    $input: CreateMetadataImmunopeptidomicsInput!
    $condition: ModelMetadataImmunopeptidomicsConditionInput
  ) {
    createMetadataImmunopeptidomics(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
    }
  }
`;
export const updateMetadataImmunopeptidomics = /* GraphQL */ `
  mutation UpdateMetadataImmunopeptidomics(
    $input: UpdateMetadataImmunopeptidomicsInput!
    $condition: ModelMetadataImmunopeptidomicsConditionInput
  ) {
    updateMetadataImmunopeptidomics(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
    }
  }
`;
export const deleteMetadataImmunopeptidomics = /* GraphQL */ `
  mutation DeleteMetadataImmunopeptidomics(
    $input: DeleteMetadataImmunopeptidomicsInput!
    $condition: ModelMetadataImmunopeptidomicsConditionInput
  ) {
    deleteMetadataImmunopeptidomics(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      species
      subtypeOfAnalysis
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataImmunopeptidomicsListId
    }
  }
`;
export const createMetadataNanoString = /* GraphQL */ `
  mutation CreateMetadataNanoString(
    $input: CreateMetadataNanoStringInput!
    $condition: ModelMetadataNanoStringConditionInput
  ) {
    createMetadataNanoString(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
    }
  }
`;
export const updateMetadataNanoString = /* GraphQL */ `
  mutation UpdateMetadataNanoString(
    $input: UpdateMetadataNanoStringInput!
    $condition: ModelMetadataNanoStringConditionInput
  ) {
    updateMetadataNanoString(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
    }
  }
`;
export const deleteMetadataNanoString = /* GraphQL */ `
  mutation DeleteMetadataNanoString(
    $input: DeleteMetadataNanoStringInput!
    $condition: ModelMetadataNanoStringConditionInput
  ) {
    deleteMetadataNanoString(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      dv200Percentage
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataNanoStringListId
    }
  }
`;
export const createMetadataRnaSeq = /* GraphQL */ `
  mutation CreateMetadataRnaSeq(
    $input: CreateMetadataRnaSeqInput!
    $condition: ModelMetadataRnaSeqConditionInput
  ) {
    createMetadataRnaSeq(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
    }
  }
`;
export const updateMetadataRnaSeq = /* GraphQL */ `
  mutation UpdateMetadataRnaSeq(
    $input: UpdateMetadataRnaSeqInput!
    $condition: ModelMetadataRnaSeqConditionInput
  ) {
    updateMetadataRnaSeq(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
    }
  }
`;
export const deleteMetadataRnaSeq = /* GraphQL */ `
  mutation DeleteMetadataRnaSeq(
    $input: DeleteMetadataRnaSeqInput!
    $condition: ModelMetadataRnaSeqConditionInput
  ) {
    deleteMetadataRnaSeq(input: $input, condition: $condition) {
      id
      sampleId
      metaDataTemplateS3Path
      subtypeOfAnalysis
      species
      sampleType
      qcStatus
      glacierBackup
      rawDataS3
      tumorVolume
      sequenceType
      strandSpecific
      UMI
      adaptorsRemoved
      adaptorsS3Path
      indexSequences
      concNanoGramPerMicroL
      rnaMassNanoGram
      rnaVolumeMicroL
      rin
      readGroups
      writeGroups
      adminGroups
      created
      updated
      isControl
      barcodesMappedToControl
      barcode
      s3Path
      sampleMetadataRnaSeqListId
    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment(
    $input: CreateTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    createTreatment(input: $input, condition: $condition) {
      id
      metadataImmunopeptidomicsId
      metadataNanoStringId
      metadataRnaSeqId
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment(
    $input: UpdateTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    updateTreatment(input: $input, condition: $condition) {
      id
      metadataImmunopeptidomicsId
      metadataNanoStringId
      metadataRnaSeqId
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
    }
  }
`;
export const deleteTreatment = /* GraphQL */ `
  mutation DeleteTreatment(
    $input: DeleteTreatmentInput!
    $condition: ModelTreatmentConditionInput
  ) {
    deleteTreatment(input: $input, condition: $condition) {
      id
      metadataImmunopeptidomicsId
      metadataNanoStringId
      metadataRnaSeqId
      treatmentName
      dose
      doseUnit
      readGroups
      writeGroups
      adminGroups
      created
      updated
      metadataImmunopeptidomicsTreatmentId
      metadataNanoStringTreatmentId
      metadataRnaSeqTreatmentId
    }
  }
`;
export const createLaunchablePipelines = /* GraphQL */ `
  mutation CreateLaunchablePipelines(
    $input: CreateLaunchablePipelinesInput!
    $condition: ModelLaunchablePipelinesConditionInput
  ) {
    createLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      organizations {
        items {
          id
          organizationId
          launchablePipelinesId
          createdAt
          updatedAt
        }
        nextToken
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      gitRepo
      workflowId
      created
      updated
    }
  }
`;
export const updateLaunchablePipelines = /* GraphQL */ `
  mutation UpdateLaunchablePipelines(
    $input: UpdateLaunchablePipelinesInput!
    $condition: ModelLaunchablePipelinesConditionInput
  ) {
    updateLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      organizations {
        items {
          id
          organizationId
          launchablePipelinesId
          createdAt
          updatedAt
        }
        nextToken
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      gitRepo
      workflowId
      created
      updated
    }
  }
`;
export const deleteLaunchablePipelines = /* GraphQL */ `
  mutation DeleteLaunchablePipelines(
    $input: DeleteLaunchablePipelinesInput!
    $condition: ModelLaunchablePipelinesConditionInput
  ) {
    deleteLaunchablePipelines(input: $input, condition: $condition) {
      id
      pipelineName
      organizations {
        items {
          id
          organizationId
          launchablePipelinesId
          createdAt
          updatedAt
        }
        nextToken
      }
      pipelineVersion
      parameters
      description
      inputFileInfo
      supportedAnalyses
      supportedSubtypeOfAnalyses
      gitRepo
      workflowId
      created
      updated
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      organization
      readGroups
      created
      updated
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      organization
      readGroups
      created
      updated
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      organization
      readGroups
      created
      updated
      owner
    }
  }
`;
export const createUploadLog = /* GraphQL */ `
  mutation CreateUploadLog(
    $input: CreateUploadLogInput!
    $condition: ModelUploadLogConditionInput
  ) {
    createUploadLog(input: $input, condition: $condition) {
      id
      uploadS3Path
      status
      readGroups
      created
      updated
    }
  }
`;
export const updateUploadLog = /* GraphQL */ `
  mutation UpdateUploadLog(
    $input: UpdateUploadLogInput!
    $condition: ModelUploadLogConditionInput
  ) {
    updateUploadLog(input: $input, condition: $condition) {
      id
      uploadS3Path
      status
      readGroups
      created
      updated
    }
  }
`;
export const deleteUploadLog = /* GraphQL */ `
  mutation DeleteUploadLog(
    $input: DeleteUploadLogInput!
    $condition: ModelUploadLogConditionInput
  ) {
    deleteUploadLog(input: $input, condition: $condition) {
      id
      uploadS3Path
      status
      readGroups
      created
      updated
    }
  }
`;
export const createVisualizations = /* GraphQL */ `
  mutation CreateVisualizations(
    $input: CreateVisualizationsInput!
    $condition: ModelVisualizationsConditionInput
  ) {
    createVisualizations(input: $input, condition: $condition) {
      id
      visualizationName
      description
      version
      deploymentPath
      validationSchema
      allowedExtensions
      supportedAnalyses
      created
      updated
    }
  }
`;
export const updateVisualizations = /* GraphQL */ `
  mutation UpdateVisualizations(
    $input: UpdateVisualizationsInput!
    $condition: ModelVisualizationsConditionInput
  ) {
    updateVisualizations(input: $input, condition: $condition) {
      id
      visualizationName
      description
      version
      deploymentPath
      validationSchema
      allowedExtensions
      supportedAnalyses
      created
      updated
    }
  }
`;
export const deleteVisualizations = /* GraphQL */ `
  mutation DeleteVisualizations(
    $input: DeleteVisualizationsInput!
    $condition: ModelVisualizationsConditionInput
  ) {
    deleteVisualizations(input: $input, condition: $condition) {
      id
      visualizationName
      description
      version
      deploymentPath
      validationSchema
      allowedExtensions
      supportedAnalyses
      created
      updated
    }
  }
`;
export const createVisualizationSession = /* GraphQL */ `
  mutation CreateVisualizationSession(
    $input: CreateVisualizationSessionInput!
    $condition: ModelVisualizationSessionConditionInput
  ) {
    createVisualizationSession(input: $input, condition: $condition) {
      id
      statuses
      data
      description
      created
      updated
      owner
    }
  }
`;
export const updateVisualizationSession = /* GraphQL */ `
  mutation UpdateVisualizationSession(
    $input: UpdateVisualizationSessionInput!
    $condition: ModelVisualizationSessionConditionInput
  ) {
    updateVisualizationSession(input: $input, condition: $condition) {
      id
      statuses
      data
      description
      created
      updated
      owner
    }
  }
`;
export const deleteVisualizationSession = /* GraphQL */ `
  mutation DeleteVisualizationSession(
    $input: DeleteVisualizationSessionInput!
    $condition: ModelVisualizationSessionConditionInput
  ) {
    deleteVisualizationSession(input: $input, condition: $condition) {
      id
      statuses
      data
      description
      created
      updated
      owner
    }
  }
`;
export const createOrganizationLaunchablePipelines = /* GraphQL */ `
  mutation CreateOrganizationLaunchablePipelines(
    $input: CreateOrganizationLaunchablePipelinesInput!
    $condition: ModelOrganizationLaunchablePipelinesConditionInput
  ) {
    createOrganizationLaunchablePipelines(
      input: $input
      condition: $condition
    ) {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        studies {
          nextToken
        }
        launchablePipelines {
          nextToken
        }
        notificationEmail
        description
        organizationAnswers
        url
        s3Path
        readGroups
        writeGroups
        created
        updated
      }
      launchablePipelines {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganizationLaunchablePipelines = /* GraphQL */ `
  mutation UpdateOrganizationLaunchablePipelines(
    $input: UpdateOrganizationLaunchablePipelinesInput!
    $condition: ModelOrganizationLaunchablePipelinesConditionInput
  ) {
    updateOrganizationLaunchablePipelines(
      input: $input
      condition: $condition
    ) {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        studies {
          nextToken
        }
        launchablePipelines {
          nextToken
        }
        notificationEmail
        description
        organizationAnswers
        url
        s3Path
        readGroups
        writeGroups
        created
        updated
      }
      launchablePipelines {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganizationLaunchablePipelines = /* GraphQL */ `
  mutation DeleteOrganizationLaunchablePipelines(
    $input: DeleteOrganizationLaunchablePipelinesInput!
    $condition: ModelOrganizationLaunchablePipelinesConditionInput
  ) {
    deleteOrganizationLaunchablePipelines(
      input: $input
      condition: $condition
    ) {
      id
      organizationId
      launchablePipelinesId
      organization {
        id
        organizationName
        organizationCode
        active
        studies {
          nextToken
        }
        launchablePipelines {
          nextToken
        }
        notificationEmail
        description
        organizationAnswers
        url
        s3Path
        readGroups
        writeGroups
        created
        updated
      }
      launchablePipelines {
        id
        pipelineName
        organizations {
          nextToken
        }
        pipelineVersion
        parameters
        description
        inputFileInfo
        supportedAnalyses
        supportedSubtypeOfAnalyses
        pipelines {
          nextToken
        }
        gitRepo
        workflowId
        created
        updated
      }
      createdAt
      updatedAt
    }
  }
`;
