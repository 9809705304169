
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['name', 'data', 'index', 'value', 'description', 'nullable', 'value_type'],
  emits: ['input'],
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    getName(passedName: string): string {
      try {
        const capitalized = passedName.charAt(0).toUpperCase()
  + passedName.slice(1);
        return capitalized;
      } catch (error) {
        console.error();
        return passedName;
      }
    },
    updateData() {
      this.$emit('input', this.localValue);
    },
  },
  watch: {
    value: {
      handler() {
        this.localValue = this.value;
      },
    },
  },
});
