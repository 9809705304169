import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Auth } from 'aws-amplify';
import Login from '@/views/Auth/Login.vue';
import NotAssigned from '@/views/Auth/NotAssigned.vue';
import MainLayout from '@/views/Layout/MainLayout.vue';
import StudyBrowse from '@/views/Study/StudyBrowse.vue';
import Search from '@/views/Study/Search.vue';
import AdminLayout from '@/views/Layout/AdminLayout.vue';
import Users from '@/views/Users/Users.vue';
import Organizations from '@/views/Organization/Organization.vue';
import CRO from '@/views/CRO/CRO.vue';
import Help from '@/views/Help/Help.vue';
import S3Explorer from '@/views/S3Explorer/S3Explorer.vue';
import StudyPhaseBrowse from '@/views/StudyPhase/StudyPhaseBrowse.vue';
import AdminFunctionsView from '@/views/Admin/AdminView.vue';
import EntityTabs from '@/components/Utils/Tabs/EntityTabs.vue';
import Details from '@/components/Utils/Tabs/Details.vue';
import SamplesBrowse from '@/views/Samples/SamplesBrowse.vue';
import PageNotFound from '@/views/Auth/PageNotFound.vue';
import BatchBrowse from '@/views/Batch/BatchBrowse.vue';
import BatchSamplesBrowse from '@/views/Batch/BatchSamplesBrowse.vue';
import PipelineBrowse from '@/views/Pipeline/PipelineBrowse.vue';
import VisualizationWrapper from '@/components/Visualization/VisualizationWrapper.vue';
import LaunchablePipelineActions from '@/views/Admin/LaunchablePipelineActions.vue';
// import store from '@/store/index';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'Auth',
    component: Login,
  },
  {
    path: '/',
    name: 'Home',
    component: MainLayout,
    children: [
      {
        path: '/:organizationName/:organizationId/',
        name: 'redirectOrgId',
        // redirect: '',
        children: [
          // {
          //   path: '',
          //   name: 'redirectSearch',
          //   redirect: 'search',
          // },
          {
            path: 'studyBrowse',
            component: StudyBrowse,
            meta: {
              title: 'Study Browse',
            },
          },
          {
            path: 'entytyDetailTabs/:entityType/:entytyId',
            component: EntityTabs,
            meta: {
              title: 'Entity Tabs',
            },
            children: [
              {
                path: '',
                redirect: { name: 'Details' },
              },
              {
                path: 'details',
                name: 'Details',
                component: Details,
                meta: {
                  title: 'Details',
                },
              },
              // {
              //   path: 'qc',
              //   component: Organizations,
              //   meta: {
              //     title: 'Quality control',
              //   },
              // },
              // {
              //   path: 'vis',
              //   component: Organizations,
              //   meta: {
              //     title: 'Analysis/Visualizations',
              //   },
              // },
            ],
          },
          {
            path: 'studyPhaseBrowse/:studyId/',
            component: StudyPhaseBrowse,
            meta: {
              title: 'Study Phase Browse',
            },
            // children: [
            // ],
          },
          {
            path: 'batchBrowse/:entityAndId*',
            component: BatchBrowse,
            meta: {
              title: 'Batches Browse',
            },
          },
          {
            path: 'pipelineBrowse/:entityAndId*',
            component: PipelineBrowse,
            meta: {
              title: 'Pipeline Browse',
            },
          },
          {
            path: 'batchSamplesBrowse/:batchId/',
            component: BatchSamplesBrowse,
            meta: {
              title: 'Batch Samples Browse',
            },
          },
          {
            path: 'samplesBrowse/:origin*',
            component: SamplesBrowse,
            meta: {
              title: 'SamplesBrowse',
            },
          },
          {
            path: 'search',
            component: Search,
            meta: {
              title: 'Search',
            },
          },
          {
            path: 'visualize/:vizDataToken*',
            component: VisualizationWrapper,
            meta: {
              title: 'Visualize',
            },
          },
          {
            path: 'admin',
            name: 'AdminLayout',
            component: AdminLayout,
            children: [
              {
                path: '',
                name: 'redirectAdmin',
                redirect: 'admin/organizations',
              },
              {
                path: 'users',
                component: Users,
                meta: {
                  title: 'Users',
                },
              },
              {
                path: 'launchablePipelineActions',
                component: LaunchablePipelineActions,
                meta: {
                  title: 'Launchable Pipelines',
                },
              },
              {
                path: 'organizations',
                component: Organizations,
                meta: {
                  title: 'Organizations',
                },
              },
              {
                path: 'adminFunctions',
                component: AdminFunctionsView,
                meta: {
                  title: 'Admin Functions',
                },
              },
            ],
          },
          {
            path: 'CRO',
            name: 'CRO',
            component: CRO,
          },
          {
            path: 'Help',
            name: 'Help',
            component: Help,
          },
          {
            path: 'explore/:s3Path/',
            name: 'Explore',
            component: S3Explorer,
          },
        ],
      },
    ],
  },
  {
    path: '/notAssigned',
    name: 'Not Assigned',
    component: NotAssigned,
  },
  {
    name: 'PageNotFound',
    path: '/:pathMatch(.*)*',
    component: PageNotFound,
    meta: {
      title: 'PageNotFound',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const AuthFilter = (to: any, from: any, next: any) => {
  // eslint-disable-next-line no-unused-vars
  Auth.currentAuthenticatedUser().then((user) => {
    if (localStorage.getItem('timeSinceActive')) {
      const timeSinceActive = parseInt(localStorage.getItem('timeSinceActive') as string, 10);
      const now = Date.now();
      if (now - timeSinceActive > 1000 * 60 * 60 * 2) {
        Auth.signOut().then(() => {
          router.push('/auth/login');
        }).catch((e: any) => {
          console.log('error signing out: ', e);
        });
      }
      localStorage.setItem('timeSinceActive', Date.now().toString());
    }
    next();
  }).catch((err) => {
    console.error(err);
    if (!to.path.startsWith('/auth')) {
      next('/auth/login');
    } else {
      console.error('In else');
      next();
    }
  });
};

router.beforeEach(AuthFilter);

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = (toRoute.meta && toRoute.meta.title) ? `${toRoute.meta.title}` : 'Home';
  next();
});

export default router;
