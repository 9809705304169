
// eslint-disable-next-line no-unused-vars
import {
  AWSConfigCredentialsObj, MenuItem, S3ContentModel, S3ExplorerTreeDataModel,
} from '@/models/customModels';
import {
  getHomeRouteForBreadcrumb, getOrgNameAndIdForRoute, setCredentials, setPrecedence,
} from '@/utils';
import { API, graphqlOperation } from 'aws-amplify';
import AWS from 'aws-sdk';
import axios from 'axios';
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      loading: false,
      treeNodes: [] as S3ExplorerTreeDataModel[],
      dialogVisible: false,
      expandedKeys: {} as { [key: string] : boolean },
      selectedItems: {} as { [key: string] : { [key: string]: boolean } },
      selectedFiles: [] as any,
      checkedItems: [] as any,
      s3Contents: [] as S3ContentModel[],
      nameMap: {} as any,
      allowedExtentions: ['txt', 'csv', 'xlsx', 'tsv'] as string[],
      bucket: process.env.VUE_APP_DATA_BUCKET,
      orgName: '' as string,
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [] as MenuItem[],
      credentials: null as unknown as AWSConfigCredentialsObj,
    };
  },
  async mounted() {
    this.loading = true;
    if (this.$route.params.s3Path) {
      this.breadcrumbItems = this.makeBreadcrumbItems(this.$route.params.s3Path as string);
      if (this.$store.state.precedenceLevel === 10) await setPrecedence();
      const credentials: AWSConfigCredentialsObj | null = await this.checkCredentials();
      if (credentials) this.credentials = credentials;
      await this.createNameMap();
      const s3Path: string = this.$route.params.s3Path as string;
      const key = s3Path.replaceAll('!', '/');
      await this.getStructure(key);
    }
    const currentOrganization = await this.$store.state.activeOrganization;
    this.orgName = currentOrganization.organizationName;
    // this.orgName = await this.$store.state.activeOrganization.organizationName;
  },
  methods: {
    makeBreadcrumbItems(s3Path: string): MenuItem[] {
      try {
        const splitPath = s3Path.split('!');
        switch (splitPath.length) {
          case 0: case 1:
            return [];
          case 2: case 3:
            return [
              { label: 'Study Phases', to: `${getOrgNameAndIdForRoute(this.$route)}studyPhaseBrowse/${splitPath[1]}` },
            ];
          default:
            return [];
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async checkCredentials(): Promise<AWSConfigCredentialsObj | null> {
      try {
        switch (this.$store.state.precedenceLevel) {
          case 1:
            return await setCredentials('M2MAdmin');
          case 2:
            return await setCredentials(`ORG/${(this.$route.params.s3Path as string).split('!')[0]}/Admin`);
          case 3:
            return await setCredentials(`S/${(this.$route.params.s3Path as string).split('!')[1]}/Admin`);
          case 4:
            return await setCredentials(`S/${(this.$route.params.s3Path as string).split('!')[1]}/User`);
          case 5:
            return await setCredentials(`SP/${(this.$route.params.s3Path as string).split('!')[2]}/Admin`);
          case 6:
            return await setCredentials(`SP/${(this.$route.params.s3Path as string).split('!')[2]}/User`);
          default:
            return null;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async createNameMap() {
      try {
        // eslint-disable-next-line no-unused-vars
        const studyPhaseItemsQueryString = `
            id
            studyPhaseName
            samples(limit: 1000) {
              items {
                id
                domainSampleId
              }
            }
            pipelines {
              items {
                id
                name
              }
            }
            batches {
              items {
                id
                batchName
              }
            }
            groups(limit: 1000) {
              items {
                id
                groupName
                patients(limit: 1000) {
                  items {
                    id
                    patientStudyPhaseId
                    timepoints(limit: 1000) {
                      items {
                        id
                        timepointName
                        samples(limit: 1000) {
                          items {
                            id
                            domainSampleId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        `;
        const s3PathSplit = (this.$route.params.s3Path as string).split('!');
        let query: string;
        let id: string;
        if (s3PathSplit.length === 1) {
          query = `
            query GetOrganization($id: ID!) {
              getOrganization(id: $id) {
                id
                organizationName
                studies(limit: 1000) {
                  items {
                    id
                    studyName
                    studyPhases(limit: 1000) {
                      items {
                        ${studyPhaseItemsQueryString}
                      }
                    }
                  }
                  nextToken
                }
              }
            }
            `;
          id = s3PathSplit[0];
        } else if (s3PathSplit.length === 2) {
          query = `
            query GetStudy($id: ID!) {
              getStudy(id: $id) {
                id
                studyName
                studyPhases(limit: 1000) {
                  items {
                    ${studyPhaseItemsQueryString}
                  }
                }
                pipelines {
                  items {
                    id
                    name
                  }
                }
                batches {
                  items {
                    id
                    batchName
                  }
                }
              }
            }
            `;
          id = s3PathSplit[1];
        } else if (s3PathSplit.length === 3) {
          query = `
            query GetStudyPhase($id: ID!) {
              getStudyPhase(id: $id) {
                ${studyPhaseItemsQueryString}
              }
            }
              `;
          id = s3PathSplit[2];
        } else {
          throw new Error('Bad s3PathSplit in makeNameMap');
        }
        const res = await API.graphql(graphqlOperation(query, { id }));
        console.log('res :>> ', res);
        this.populateNamesMap(res);
        console.log('this.nameMap :>> ', this.nameMap);
      } catch (error) {
        console.error(error);
      }
    },
    populateNamesMap(obj: any) {
      if (typeof obj === 'object') {
        if ('id' in obj) {
          if (Object.keys(obj)[1]) {
            this.nameMap[obj.id] = obj[Object.keys(obj)[1]];
          }
          if ('samples' in obj && obj.samples.items.length > 0) {
            this.populateNamesMap(obj.samples);
          } if ('groups' in obj && obj.groups.items.length > 0) {
            this.populateNamesMap(obj.groups);
          } if ('patients' in obj && obj.patients.items.length > 0) {
            this.populateNamesMap(obj.patients);
          } if ('timepoints' in obj && obj.timepoints.items.length > 0) {
            this.populateNamesMap(obj.timepoints);
          } if ('pipelines' in obj && obj.pipelines.items.length > 0) {
            this.populateNamesMap(obj.pipelines);
          } if ('batches' in obj && obj.batches.items.length > 0) {
            this.populateNamesMap(obj.batches);
          }
          this.populateNamesMap(obj[Object.keys(obj)[2]]);
        } else if (Object.keys(obj).length === 1) {
          this.populateNamesMap(obj[Object.keys(obj)[0]]);
        } else if ('items' in obj) {
          for (const item of obj.items) {
            this.populateNamesMap(item);
          }
        } else if (Array.isArray(obj)) {
          for (const item of obj) {
            this.populateNamesMap(item);
          }
        }
      }
    },
    // Loads the files from S3 and creates the necessary structure for the Tree component
    async getStructure(originalPath: String, token?: String) {
      const s3 = new AWS.S3(({ region: 'eu-west-2', credentials: this.credentials }));
      let params = {};
      if (token == null) {
        params = {
          Bucket: this.bucket,
          Prefix: originalPath,
        };
      } else {
        params = {
          Bucket: this.bucket,
          Prefix: originalPath,
          ContinuationToken: token,
        };
      }
      try {
        s3.listObjectsV2(params as { Bucket: string, Prefix: string, ContinuationToken?: string}, (err, data) => {
          if (data) {
            console.log('data :>> ', data);
            if (!data || !data.Contents || data.Contents.length === 0) {
              this.loading = false;
              return;
            }
            this.s3Contents.push(...data.Contents as S3ContentModel[]);
            if (!data.IsTruncated) {
              this.makeTreeStructure(this.s3Contents, originalPath);
            } else {
              this.getStructure(originalPath, data.NextContinuationToken);
            }
          }
          if (err) {
            this.loading = false;
            console.error(err);
          }
        });
      } catch (error) {
        console.error('Error with S3.getObject');
        console.error(error);
      }
    },
    makeTreeStructure(data: S3ContentModel[], originalPath: String) {
      try {
        if (!data || data.length === 0) {
          this.loading = false;
          return;
        }
        const splitedStrings = new Set<string>();
        let splited = data.map((content: S3ContentModel) => {
          const pathSet = new Set();
          const spl = content.Key.split(`${originalPath}/`)[1];
          const aditionalSplit = spl.split('/');
          if (aditionalSplit.length > 1) {
            let str = '';
            for (let i = 0; i < aditionalSplit.length; i += 1) {
              if (aditionalSplit[i] !== '') {
                str = str.concat(aditionalSplit[i]);
                if (!str.includes('.')) {
                  str = str.concat('/');
                }
                pathSet.add(str);
                splitedStrings.add(str);
              }
            }
          }
          return spl;
        });
        splited = [...splitedStrings];

        const exists = new Set<string>(); // This will hold the direct children of the root
        const root: S3ExplorerTreeDataModel[] = [];
        const indexMap: any = {}; // Should be called depthMap, here the depths will be stored
        // Create the initial object for the reduce
        let i = 0;
        while (splited[i] === '') {
          i += 1;
        }
        let firstSplit: string | string[] = splited[i].split('/');
        if (firstSplit[1] === '') {
          firstSplit = firstSplit[0];
        }
        let obj: S3ExplorerTreeDataModel;
        if (!firstSplit.includes('.')) {
          obj = {
            key: '0',
            label: firstSplit as string,
            data: originalPath.concat('/', firstSplit as string),
            icon: 'pi pi-fw pi-folder',
            children: [],
          };
          indexMap[0] = 1;
          root.push(obj);
          exists.add(firstSplit as string);
        }
        // If the current element is a folder, return it as a new object. If the current element is a file, return its parent(the folder). This way, an object will always have its parent for prev.
        splited.reduce((prev: string | S3ExplorerTreeDataModel, curr: string) => {
          if (curr === '') return prev;
          let splitedCurr = curr.split('/');
          let last;
          if (splitedCurr[0] === '') return prev;
          if (splitedCurr.at(-1) === '') {
            last = splitedCurr.at(-2);
            splitedCurr = splitedCurr.slice(0, -1);
          } else {
            last = splitedCurr.at(-1);
          }
          if (!last || last === '') throw new Error('Last is null');
          if (last.includes('.')) { // Its a file
            const myDepth = splitedCurr.length - 1;
            if (indexMap[myDepth] === null || indexMap[myDepth] === undefined) {
              indexMap[myDepth] = 0;
            } else {
              indexMap[myDepth] += 1;
            }
            if (!(splitedCurr[myDepth - 1].split('.').at(0) === (prev as S3ExplorerTreeDataModel).label)) {
              const hisRoot = root.find((item: any) => item.label === splitedCurr.at(0));
              const parentToFind = splitedCurr[myDepth - 1];
              const parent = this.getObject(hisRoot, parentToFind);
              const myKey = parent.key.concat('-', indexMap[myDepth].toString());
              const fileObj = {
                key: myKey,
                label: last,
                data: parent.data.concat('/', last),
                icon: this.getIcon(false, last.split('.').at(-1)!),
              };
              parent.children.push(fileObj);
              return parent;
            }
            if (myDepth > 0) {
              const myKey = (prev as S3ExplorerTreeDataModel).key.concat('-', indexMap[myDepth].toString());
              const fileObj = {
                key: myKey,
                label: last,
                data: (prev as S3ExplorerTreeDataModel).data.concat('/', last),
                icon: this.getIcon(false, last.split('.').at(-1)!),
              };
              (prev as S3ExplorerTreeDataModel)!.children?.push(fileObj);
              return prev;
              // eslint-disable-next-line no-else-return
            } else {
              const fileInRoot = {
                key: indexMap[myDepth].toString(),
                label: last,
                data: originalPath.concat('/', last),
                icon: this.getIcon(false, last.split('.').at(-1)!),
              };
              root.push(fileInRoot);
              return prev;
            }
            // eslint-disable-next-line no-else-return
          } else { // Folders
            // eslint-disable-next-line no-lonely-if
            if ((prev as S3ExplorerTreeDataModel).label === last) {
              return prev;
            }
            if (splitedCurr.length === 1) { // one of roots children
              const rootObj = {
                key: indexMap[0].toString(),
                label: last,
                data: originalPath.concat('/', last),
                icon: this.getIcon(true, ''),
                children: [],
              };
              indexMap[0] += 1;
              for (const [key] of Object.entries(indexMap)) {
                if (Number(key) > 0) {
                  indexMap[key] = -1;
                }
              }
              root.push(rootObj);
              exists.add(last);
              return rootObj;
              // eslint-disable-next-line no-else-return
            } else { // Subfolders
              const mainParent = root.at(-1);
              const myDepth = splitedCurr.length - 1;
              let parent = mainParent;
              try {
                if (parent !== undefined && parent?.key !== undefined) {
                  while (parent!.key.split('-').length < myDepth) {
                    parent = parent!.children?.at(-1);
                  }
                }
              } catch (error) {
                console.error('This error can occur at times');
                console.error(error);
                return prev;
              }
              for (const [key] of Object.entries(indexMap)) {
                if (Number(key) > myDepth) {
                  indexMap[key] = -1;
                }
              }
              if (indexMap[myDepth] === null || indexMap[myDepth] === undefined) {
                indexMap[myDepth] = 0;
              } else {
                indexMap[myDepth] += 1;
              }
              const myKey = parent!.key.concat('-', indexMap[myDepth].toString());
              const childFolder = {
                key: myKey,
                label: last,
                data: parent!.data.concat('/', last),
                icon: this.getIcon(true, ''),
                children: [],
              };
              parent!.children?.push(childFolder);
              return childFolder;
            }
          }
        }, obj!);
        this.treeNodes = root;
        this.loading = false;
      } catch (error) {
        console.error('Problem with making the tree structure.');
        console.error(error);
      }
    },
    getObject(theObject: S3ExplorerTreeDataModel | undefined, label: string): any {
      let result = null;
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i += 1) {
          result = this.getObject(theObject[i], label);
          if (result) {
            break;
          }
        }
      } else {
        // eslint-disable-next-line guard-for-in
        for (const prop in theObject) {
          if (prop === 'label') {
            if (theObject[prop] === label) {
              return theObject;
            }
          }
          if (theObject[prop as keyof S3ExplorerTreeDataModel] instanceof Object || theObject[prop as keyof S3ExplorerTreeDataModel] instanceof Array) {
            result = this.getObject(theObject[prop as keyof S3ExplorerTreeDataModel] as any, label);
            if (result) {
              break;
            }
          }
        }
      }
      return result;
    },
    getLabel(id: string): string {
      try {
        if (id in this.nameMap) return this.nameMap[id];
        return id;
      } catch (error) {
        console.error(error);
        return id;
      }
    },
    getIcon(folder: any, extention: string) {
      if (folder) return 'pi pi-fw pi-folder';
      switch (extention) {
        case 'html':
          return 'pi pi-fw pi-paperclip';
        case 'gz':
          return 'pi pi-fw pi-box';
        case 'fastq':
          return 'pi pi-fw pi-file';
        case 'yml':
          return 'pi pi-fw pi-file';
        case 'json':
          return 'pi pi-fw pi-file';
        case 'vcf':
          return 'pi pi-fw pi-file';
        case 'bam':
          return 'pi pi-fw pi-file';
        case 'bai':
          return 'pi pi-fw pi-file';
        case 'csv':
          return 'pi pi-fw pi-database';
        default:
          return 'pi pi-fw pi-cog';
      }
    },
    download() {
      this.$confirm.require({
        message: 'Are you sure you want to download the selected files?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        accept: async () => {
          this.getSelectedKeys();
          if (this.dialogVisible) this.dialogVisible = false;
        },
        reject: () => {
          if (this.dialogVisible) this.dialogVisible = false;
        },
      });
    },
    copyFilePath(textToCopy: string): void {
      const modifiedText: string = `s3://${process.env.VUE_APP_DATA_BUCKET}/${textToCopy}`;
      navigator.clipboard.writeText(modifiedText).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Path copied successfully!',
          life: 3000,
        });
      }, (err) => {
        console.error(err);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to copy path!',
          life: 3000,
        });
      });
    },
    // The mapping is a bit strange because of the way the Tree component lists files. We need to remap them in a more useful way.
    getSelectedKeys() {
      const checkedItems = [];
      for (const [key, value] of Object.entries(this.selectedItems)) {
        if ((value as { [key: string]: boolean }).checked) {
          checkedItems.push(key);
        }
      }
      this.checkedItems = checkedItems;
      this.treeNodes.forEach((parentNode: S3ExplorerTreeDataModel) => {
        this.getSelectedFiles(parentNode);
      });
      this.selectedFiles.forEach((file: S3ExplorerTreeDataModel) => {
        if (file.label.includes('.') && this.allowedExtentions.includes(file.label.split('.').at(-1)!)) {
          this.getPresignedLink(file.data);
        } else {
          this.$toast.add({
            severity: 'warn', summary: 'Not allowed', detail: `The selected file ${file.label} does not have an appropriate extension. You can only download files with the following extension: txt, csv, tsv, xlsx`, life: 6000,
          });
        }
      });
      // Reset for the arrays so that we don't download already downloaded files again
      this.checkedItems = [];
      this.selectedFiles = [];
    },
    getSelectedFiles(node: S3ExplorerTreeDataModel) {
      if (this.checkedItems.indexOf(node.key) !== -1 && !node.children) {
        this.selectedFiles.push(node);
      }
      if (node.children && node.children.length) {
        for (const child of node.children) {
          this.getSelectedFiles(child);
        }
      }
    },
    // Actually downloads the files
    async getPresignedLink(key: String) {
      const s3 = new AWS.S3();
      const params = { Bucket: this.bucket, Key: key };
      const url = s3.getSignedUrl('getObject', params);
      axios({
        url, // File URL Goes Here
        method: 'GET',
        responseType: 'blob',
      }).then((res: any) => {
        console.log('res :>> ', res);
        const FILE = window.URL.createObjectURL(new Blob([res.data]));
        const docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', `${key.split('/').at(-1)}`);
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandAll() {
      for (const node of this.treeNodes) {
        this.expandNode(node);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },
    expandNode(node: S3ExplorerTreeDataModel) {
      this.expandedKeys[node.key] = true;
      if (node.children && node.children.length) {
        for (const child of node.children) {
          this.expandNode(child);
        }
      }
    },
  },
});
