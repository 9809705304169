import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "overlayBG"
}
const _hoisted_2 = { class: "layout-main-container" }
const _hoisted_3 = { class: "layout-main" }
const _hoisted_4 = { class: "version" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ProfileDialog = _resolveComponent("ProfileDialog")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_ChangeOrganizationDialog = _resolveComponent("ChangeOrganizationDialog")!
  const _component_TimedLogoutComponent = _resolveComponent("TimedLogoutComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.allLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.allLoaded)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass('main-layout ' + ((_ctx.orgName)? _ctx.orgName : ''))
        }, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onWrapperClick && _ctx.onWrapperClick(...args)))
          }, [
            _createVNode(_component_TopBar, {
              isUser: _ctx.isUser,
              onMenuToggle: _ctx.onMenuToggle,
              onSignOut: _ctx.signOut
            }, null, 8, ["isUser", "onMenuToggle", "onSignOut"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_view)
              ])
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.commitId), 1),
            _createVNode(_component_ProfileDialog),
            _createVNode(_component_ChangePassword),
            _createVNode(_component_ConfirmDialog),
            _createVNode(_component_ChangeOrganizationDialog),
            _createVNode(_component_TimedLogoutComponent)
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}