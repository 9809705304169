
import { defineComponent } from 'vue';
import {
  MenuItem, PipelineTemplate, Visualizations, FinalPipelineStatuses,
} from '@/models/customModels';
import {
// eslint-disable-next-line no-unused-vars
  listItems, statusClass, formatDateToHumanReadableFull, sendEBEvent, formatISODate, getStudyIdFromStudyPhase, remapPipelines, getCurrentUsersEmail,
} from '@/utils';
import * as customQueries from '@/graphql/customQueries';
import * as customSubscriptions from '@/graphql/customSubscriptions';
// eslint-disable-next-line no-unused-vars
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { API, graphqlOperation } from 'aws-amplify';
import { PipelineStatus, AnalysisType } from '@/models';
import * as mutations from '@/graphql/mutations';
import LaunchPipelineDialog from '@/components/Pipeline/LaunchPipelineDialog.vue';
import PipelineParamsDialog from '@/components/Pipeline/PipelineParamsDialog.vue';

// eslint-disable-next-line no-unused-vars
const CryptoJS = require('crypto-js');

export default defineComponent({
  components: {
    LaunchPipelineDialog,
    PipelineParamsDialog,
  },
  data() {
    return {
      loading: false,
      pipelines: [] as PipelineTemplate[],
      backupPipelines: [] as PipelineTemplate[],
      id: null as unknown as string | null,
      studyId: null as unknown as string,
      studyPhaseId: null as unknown as string,
      pipelineStatusUpdateSubscription: null as any,
      pipelineParamsForView: {},
      onlyShowPipelinesFromStudy: false,
      visualizations: [] as Visualizations[],
      filters: {
        name: { value: '', matchMode: FilterMatchMode.CONTAINS },
        analysisType: { value: '', matchMode: FilterMatchMode.EQUALS },
        status: { value: '', matchMode: FilterMatchMode.EQUALS },
        userEmail: { value: '', matchMode: FilterMatchMode.CONTAINS },
        studyPhaseName: { value: '', matchMode: FilterMatchMode.CONTAINS },
        pipelineStarted: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        pipelineCompleted: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      },
    };
  },
  async mounted() {
    this.parseRouteParams(this.$route.params.entityAndId as string[]);
    this.visualizations = await this.loadVisualizations();
  },
  methods: {
    async loadVisualizations(): Promise<Visualizations[]> {
      return listItems(customQueries.listVisualizations) as Promise<Visualizations[]>;
    },
    async parseRouteParams(routeParams: string[]): Promise<void> {
      this.loading = true;
      try {
        if (!routeParams) throw new Error('Bad params from route');
        const [entityName, id] = routeParams;
        switch (entityName) {
          case 'study':
            console.log('handle study');
            this.pipelines = this.remapPipelines(await this.loadPipelinesForStudy(id));
            if (this.pipelines.length > 0 && this.pipelines[0].study) this.$emit('setBreadcrumbItems', (this.pipelines[0]!.study as any)!.studyName);
            this.studyId = this.$route.params.entityAndId[1] as string;
            break;
          case 'studyPhase':
            this.pipelines = this.remapPipelines(await this.loadPipelinesForStudyPhase(id));
            if (this.pipelines.length > 0 && this.pipelines[0].study) this.$emit('setBreadcrumbItems', this.pipelines[0]!.studyPhase!.studyPhaseName, (this.pipelines[0]!.study as any)!.studyName);
            this.studyPhaseId = id;
            this.studyId = await getStudyIdFromStudyPhase(id);
            break;
          default:
            break;
        }
        this.id = id;
        this.pipelineStatusUpdateSubscription = this.getPipelineStatusUpdateSubscription();
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async loadPipelinesForStudy(studyId: string): Promise<PipelineTemplate[]> {
      let pipelines: PipelineTemplate[] = [];
      try {
        pipelines = await listItems(customQueries.pipelinesByStudyForPipelinesTable, { studyId, sortDirection: 'DESC' });
      } catch (error) {
        console.error(error);
      }
      console.log('pipelines :>> ', pipelines);
      return pipelines;
    },
    async loadPipelinesForStudyPhase(studyPhaseId: string): Promise<PipelineTemplate[]> {
      console.log('Loading pipelines for study phase');
      let pipelines: PipelineTemplate[] = [];
      try {
        pipelines = await listItems(customQueries.pipelinesByStudyPhaseForPipelinesTable, { studyPhaseId, sortDirection: 'DESC' });
      } catch (error) {
        console.error(error);
      }
      console.log('pipelines :>> ', pipelines);
      return pipelines;
    },
    remapPipelines(pipelines: PipelineTemplate[]): PipelineTemplate[] {
      return remapPipelines(pipelines);
    },
    getPipelineStatusUpdateSubscription() {
      return (API.graphql(
        graphqlOperation(customSubscriptions.onUpdatePipelineForStatus),
      ) as { subscribe: any }).subscribe({
        next: ({ value }: { value: any }) => {
          if (value?.data?.onUpdatePipeline) this.updatePipelineStatusInTable(value.data.onUpdatePipeline.id, value.data.onUpdatePipeline.status);
        },
        error: (error: any) => console.error(error),
      });
    },
    updatePipelineStatusInTable(id: string, pipelineStatus: PipelineStatus): void {
      try {
        const pipelineFromTableIndex: number = this.pipelines.findIndex((pipeline) => pipeline.id === id);
        if (pipelineFromTableIndex === -1) return;
        (this.pipelines[pipelineFromTableIndex].status as PipelineStatus) = pipelineStatus;
        this.pipelines[pipelineFromTableIndex].id = id;
      } catch (error) {
        console.error(error);
      }
    },
    capitalizeFirstLetterRestSmall(str: string): string {
      try {
        return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    pipelineActionClick(pipeline: PipelineTemplate, event: MouseEvent): void {
      (this.$refs[`pipeline-menu-${pipeline.id}`] as any).toggle(event);
    },
    pipelineActionItems(pipeline: PipelineTemplate): MenuItem[] {
      const openBatch = {
        label: 'Open Batch',
        command: () => {
          this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/batchSamplesBrowse/${pipeline.batchId}` });
        },
      };
      const showParams = {
        label: 'Show launch parameters',
        command: () => {
          this.openPipelineParamsDialog(pipeline?.parameters as string | undefined);
        },
      };
      const stopPipeline = {
        label: 'Stop Pipeline',
        command: () => {
          this.sendPipelineStopEvent(pipeline.id as string, pipeline.userEmail as string, pipeline.study?.organization.id as string, pipeline.study?.id as string, pipeline.studyPhase?.id);
        },
      };
      const relaunchPipeline = {
        label: 'Relaunch Pipeline',
        command: () => {
          this.sendRelaunchPipelineEvent(pipeline.id as string, pipeline.s3Event as string, pipeline.study?.organization.id as string, pipeline.study?.id as string, pipeline.studyPhase?.id);
        },
      };
      const options = [openBatch, showParams, relaunchPipeline];
      if (pipeline.status === PipelineStatus.RUNNING) options.push(stopPipeline);
      return options;
    },
    openPipelineParamsDialog(parameters: string | undefined) {
      if (parameters) {
        this.pipelineParamsForView = JSON.parse(parameters);
        this.$store.dispatch('setShowingPipelineParamsDialog', true);
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Missing pipeline parameters!',
          life: 5000,
        });
      }
    },
    updatePipelineInTable(id: string, pipelineStarted: string, currentUserEmail: string): void {
      try {
        const pipelineFromTableIndex: number = this.pipelines.findIndex((pipeline) => pipeline.id === id);
        if (pipelineFromTableIndex === -1) return;
        this.pipelines[pipelineFromTableIndex].id = id;
        this.pipelines[pipelineFromTableIndex].pipelineStarted = new Date(pipelineStarted);
        try {
          this.pipelines[pipelineFromTableIndex].userEmail = currentUserEmail;
        } catch (error) {
          console.error(error);
        }
        console.log(formatISODate(pipelineStarted));
        this.pipelines[pipelineFromTableIndex].pipelineCompleted = '';
      } catch (error) {
        console.error(error);
      }
    },
    async updatePipelineEntry(id: string, status: PipelineStatus, pipelineStarted: string, currentUserEmail: string) {
      this.updatePipelineInTable(id, pipelineStarted, currentUserEmail);
      this.updatePipelineStatusInTable(id, status);
      const pipelineCompleted = null as any;
      return API.graphql(graphqlOperation(mutations.updatePipeline, {
        input: {
          id, status, pipelineStarted, pipelineCompleted, userEmail: currentUserEmail,
        },
      }));
    },
    async sendPipelineStopEvent(id: string, userEmail: string, organizationId: string, studyId: string, studyPhaseId?: string | null | undefined): Promise<void> {
      const detail = {
        pipeline_id: id,
        action: 'bioinf_nf_pipeline',
        step: 'stop_pipeline_run',
        user_email: userEmail,
      };
      console.log('detail :>> ', detail);
      sendEBEvent(detail, organizationId, studyId, studyPhaseId);
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Stopping pipeline!',
        life: 3000,
      });
    },
    // eslint-disable-next-line no-unused-vars
    async sendRelaunchPipelineEvent(id: string, event: string, organizationId: string, studyId: string, studyPhaseId?: string | null | undefined): Promise<void> {
      const currentDateTime = new Date().toUTCString();
      const ukDateTimeISO = new Date(currentDateTime).toISOString();
      console.log(new Date(ukDateTimeISO));
      console.log(ukDateTimeISO);
      console.log('event :>> ', event);
      const { changedEvent, currentUserEmail } = await this.changeEventUserEmailIfNecessary(event);
      console.log('changedEvent :>> ', changedEvent);
      console.log('currentUserEmail :>> ', currentUserEmail);
      this.updatePipelineEntry(id, PipelineStatus.INITIATED, ukDateTimeISO, currentUserEmail);
      sendEBEvent(JSON.parse(event), organizationId, studyId, studyPhaseId);
      this.$toast.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Relaunching pipeline!',
        life: 3000,
      });
    },
    async changeEventUserEmailIfNecessary(event: string): Promise<{changedEvent: string, currentUserEmail: string}> {
      const jsonEvent: any = JSON.parse(event);
      const currentUserEmail: string = await getCurrentUsersEmail();
      jsonEvent.user_email = currentUserEmail;
      return { changedEvent: JSON.stringify(jsonEvent), currentUserEmail };
    },
    getStudyPhaseName(pipeline: PipelineTemplate): string {
      if (pipeline.studyPhase) return pipeline.studyPhase.studyPhaseName;
      return '';
    },
    statusClass(status: string): string {
      return statusClass(status);
    },
    formatDateToHumanReadableFull(date: Date): string {
      return formatDateToHumanReadableFull(date);
    },
    // formatISODate(str: string): string {
    //   return formatISODate(str);
    // },
    calculateDuration(startDate: Date, endDate: Date): string {
      try {
        if (!startDate) return '';
        if (!endDate) return '';
        if (startDate.getTime() > endDate.getTime()) return '';

        return this.msToHMS(endDate.getTime() - startDate.getTime());
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    msToHMS(ms: number): string {
    // console.log(ms);
    // const msi = 300006000;
      let seconds: number = Math.floor(ms / 1000);
      const days: number = Math.floor(seconds / 86400);
      seconds -= days * 86400;
      const hours: number = Math.floor(seconds / 3600);
      seconds -= hours * 3600;
      const minutes: number = Math.floor(seconds / 60);
      seconds -= minutes * 60;

      // console.log(days);
      if (days > 0) {
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
      }
      return `${hours}h ${minutes}m ${seconds}s`;
    },
    getAnalysisTypeDDOptions() {
      return Object.values(AnalysisType).map((analysisType) => this.capitalizeFirstLetterRestSmall(analysisType));
    },
    getStatusDDOption() {
      return Object.values(FinalPipelineStatuses);
    },
    openLaunchPipelineDialog() {
      this.$store.dispatch('setShowLaunchPipeline', true);
    },
    // eslint-disable-next-line no-unused-vars
    pushNewPipelineToTopOfTable(pipeline: any): void {
      try {
        // if (pipeline) this.pipelines.unshift(pipeline);
        this.parseRouteParams(this.$route.params.entityAndId as string[]);
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    showFilterPipelinesSwitch(): boolean {
      return this.$route.params.entityAndId[0] === 'study';
    },
  },
  watch: {
    onlyShowPipelinesFromStudy() {
      if (!this.onlyShowPipelinesFromStudy) {
        this.pipelines = this.remapPipelines(JSON.parse(JSON.stringify(this.backupPipelines)));
      } else {
        this.backupPipelines = JSON.parse(JSON.stringify(this.pipelines));
        this.pipelines = this.remapPipelines(this.pipelines.filter((batch: any) => !batch.studyPhase));
      }
    },
  },
});
