import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "app"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_CustomOrgDialog = _resolveComponent("CustomOrgDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_component_Toast),
    _createVNode(_component_CustomOrgDialog, {
      onDoneSettingCustomOrg: _cache[0] || (_cache[0] = ($event: any) => {_ctx.$router.push('/');})
    })
  ]))
}