
import { defineComponent } from 'vue';
import CustomOrgDialog from '@/components/Organization/CustomOrgDialog.vue';

export default defineComponent({
  name: 'Home',
  components: {
    CustomOrgDialog,
  },
});
