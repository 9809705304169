
import { Auth } from 'aws-amplify';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Login',
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    signOut() {
      document.body.classList.value = '';
      Auth.signOut().then(() => {
        this.$router.push('/auth/login');
      }).catch((e: any) => {
        console.log('error signing out: ', e);
      });
    },
  },
});
