
import { StudyPhase } from '@/models';
import {
  listItems, formatISOStringCustom, formatListForView, parseBatches, getStudyIdFromStudyPhase,
} from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
import { FilterMatchMode } from 'primevue/api';
import BatchDialog from '@/components/Batch/BatchDialog.vue';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { BatchTemplate } from '@/models/customModels';
import VisualizationLaunchDialog from '@/components/Visualization/VisualizationLaunchDialog.vue';
import LaunchPipelineDialog from '../Pipeline/LaunchPipelineDialog.vue';

export default defineComponent({
  name: 'Batch Table',
  emits: ['newBatchAdded', 'setBreadcrumbItems'],
  components: {
    LaunchPipelineDialog,
    BatchDialog,
    VisualizationLaunchDialog,
  },
  data() {
    return {
      studyPhases: [] as StudyPhase[],
      studyPhaseForUpdate: null as unknown as StudyPhase,
      batches: [] as any,
      backupBatches: [] as any[],
      selectedBatches: [] as BatchTemplate[],
      batchesToVisualize: [] as BatchTemplate[],
      batchData: {} as any,
      loading: false,
      onlyShowBatchesFromStudy: false,
      filters: {
        batchName: { value: '', matchMode: 'contains' },
        studyPhaseName: { value: '', matchMode: 'contains' },
        phase: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      studyId: '' as string,
      studyPhaseId: null as unknown as string,
      id: '' as string,
      filterColumns: ['id', 'created', 'domainSampleId', 'groups', 'metadataImmunopeptidomicsList', 'MetadataNanoStringList', 'MetadataRnaSeqList', 'sampleName', 'barcode', 'barcodes', 'annotations'] as any,
      stepStart: 2,
    };
  },
  mounted() {
    this.parseRouteParams(this.$route.params.entityAndId as string[]);
  },
  methods: {
    async parseRouteParams(routeParams: string[]): Promise<void> {
      try {
        if (!routeParams || routeParams.length < 2) throw new Error('Bad params from route');
        const [entityName, id] = routeParams;
        switch (entityName) {
          case 'study':
            await this.loadBatchesForStudy(id);
            if (this.batches.length > 0) this.$emit('setBreadcrumbItems', this.batches[0].study.studyName);
            this.studyId = this.$route.params.entityAndId[1] as string;
            break;
          case 'studyPhase':
            await this.loadBatchesForStudyPhase(id);
            if (this.batches.length > 0) this.$emit('setBreadcrumbItems', this.batches[0].studyPhaseName, this.batches[0].study.studyName);
            this.studyPhaseId = id;
            this.studyId = await getStudyIdFromStudyPhase(id);
            break;
          default:
            break;
        }
        this.id = id;
      } catch (error) {
        console.error(error);
      }
    },

    async runPipeline(data: any) {
      this.batchData = data;
      this.$store.dispatch('setShowLaunchPipeline', true);
    },
    async loadBatchesForStudy(studyId: string): Promise<void> {
      this.loading = true;
      try {
        let batches;
        let studyPhases: StudyPhase[];
        if (studyId) {
          [studyPhases, batches] = await Promise.all([
            listItems(customQueries.studyPhasesByStudyForStudyPhaseBrowse, { studyId, sortDirection: 'DESC' }) as unknown as StudyPhase[],
            listItems(customQueries.batchByStudyForBatchTable, { studyId, sortDirection: 'DESC' }),
          ]);
          batches = parseBatches(batches, this.filterColumns);
          batches = this.extractNestedValuesForFilter(batches);
        } else {
          throw new Error('No study id found');
        }
        console.log('batches :>> ', batches);
        this.batches = batches;
        this.studyPhases = studyPhases;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async loadBatchesForStudyPhase(studyPhaseId: string): Promise<void> {
      this.loading = true;
      try {
        let batches = [];
        if (studyPhaseId) {
          batches = await listItems(customQueries.batchesByStudyPhaseForBatchTable, { studyPhaseId, sortDirection: 'DESC' }) as unknown as StudyPhase[];
          batches = parseBatches(batches, this.filterColumns);
          batches = this.extractNestedValuesForFilter(batches);
        } else {
          throw new Error('No study phase id found');
        }
        this.batches = batches;
        console.log('this.batches :>> ', this.batches);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    extractNestedValuesForFilter(batches: any[]) {
      return batches.map((batch) => {
        const b = batch;
        if (batch.studyPhase && batch.studyPhase.studyPhaseName) b.studyPhaseName = batch.studyPhase.studyPhaseName;
        return b;
      });
    },
    pushNewBatchToTopOfTable(batch: any): void {
      try {
        if (batch) this.batches.unshift(batch);
        this.parseRouteParams(this.$route.params.entityAndId as string[]);
      } catch (error) {
        console.error(error);
      }
    },
    updateStudyPhaseInTable(studyPhase: StudyPhase): void {
      try {
        if (studyPhase) {
          const studyPhaseToUpdateIndex: number | null | undefined = this.studyPhases.findIndex((sp) => sp.id === studyPhase.id);
          if (studyPhaseToUpdateIndex !== -1) {
            let newStudyPhaseObject = this.studyPhases[studyPhaseToUpdateIndex];
            newStudyPhaseObject = studyPhase;
            this.studyPhases[studyPhaseToUpdateIndex] = newStudyPhaseObject;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteBatch(batches: any): Promise<void> {
      try {
        this.batches = this.batches.filter((sp: { id: any; }) => sp.id !== batches.id);
        API.graphql(graphqlOperation(mutations.deleteBatch, { input: { id: batches.id } }));
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Batch deleted successfully!', life: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to delete Batch!', life: 5000,
        });
      }
    },
    batchActionClick(studyPhase: StudyPhase, event: MouseEvent) {
      (this.$refs[`study-menu-${studyPhase.id}`] as any).toggle(event);
    },
    batchActionItems(batch: BatchTemplate) {
      const launch = {
        label: 'Launch Pipeline',
        command: () => {
          // this.projectForPipeline = project;
          this.batchData = batch;

          this.$store.dispatch('setShowLaunchPipeline', true);
        },
      };
      const showSamples = {
        label: 'Samples',
        command: () => {
          this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/samplesBrowse/batch/${batch.id}` });
        },
      };
      // const deleteBatch = {
      //   label: 'Delete',
      //   command: () => {
      //     this.$confirm.require({
      //       message: 'Are you sure you want to proceed?',
      //       header: 'Confirmation',
      //       icon: 'pi pi-exclamation-triangle',
      //       accept: async () => {
      //         this.deleteBatch(batch);
      //       },
      //       reject: () => {
      //       },
      //     });
      //   },
      // };
      const options = [showSamples];
      if (this.$store.state.precedenceLevel <= 5) options.push(launch);
      // if (this.$store.state.precedenceLevel <= 2) options.push(...[deleteBatch]);
      return options;
    },
    async exploreStudy(): Promise<void> {
      try {
        const orgId: string = (await this.$store.state.activeOrganization).id;
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/explore/${orgId}!${this.$route.params.studyId as string}` });
      } catch (error) {
        console.error(error);
      }
    },
    async exploreStudyPhase(id: string): Promise<void> {
      try {
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/explore/${id}` });
      } catch (error) {
        console.error(error);
      }
    },
    openStudyPhaseDialog() {
      this.$store.dispatch('setShowingStudyPhaseDialog', true);
    },
    openBatchDialog() {
      console.log('this.studyId :>> ', this.studyId);
      this.$store.dispatch('setShowingBatchDialog', true);
    },
    formatListForView(list: string[]) {
      return formatListForView(list);
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    onBatchSelect(event: any) {
      if (event.data) {
        this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/batchSamplesBrowse/${event.data.id}` });
        console.log('Batch view here');
      }
    },
    onStudySelect(event: any) {
      if (event.data) {
        this.$router.push({ path: `studyPhaseBrowse/${event.data.id}` });
      }
    },
    getStudyPhaseName(batch: any): string {
      try {
        if (batch && batch.studyPhaseName) return batch.studyPhaseName;
        return '';
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    openLaunchVisualizationDialog() {
      try {
        this.batchesToVisualize = this.selectedBatches;
        this.$store.dispatch('setShowingLaunchVisualizationDialog', true);
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    showFilterBatchesSwitch() {
      return this.$route.params.entityAndId[0] === 'study';
    },
  },
  watch: {
  // eslint-disable-next-line func-names
    '$store.state.activeOrganization': async function () {
      // this.loadStudiesForOrg(activeOrganization.id);
    },
    onlyShowBatchesFromStudy() {
      if (!this.onlyShowBatchesFromStudy) {
        this.batches = JSON.parse(JSON.stringify(this.backupBatches));
      } else {
        this.backupBatches = JSON.parse(JSON.stringify(this.batches));
        this.batches = this.batches.filter((batch: any) => !batch.studyPhase);
      }
    },
  },
});
