
// import { listItems } from '@/utils';
// import { StudyPhase } from '@/models';
import {
  listItems, formatISOStringCustom,
} from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import { VisualizationSession } from '@/models';
// import { FilterMatchMode } from 'primevue/api';
import * as mutations from '@/graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

export default defineComponent({
  name: 'LoadVisSession',
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      vizSessions: [] as VisualizationSession[],
      // filters: {
      //   email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      // },
    };
  },
  // async mounted() {
  //   await this.main();
  // },
  methods: {
    async main() {
      this.loading = true;
      console.log('Main in LoadVisSession dialog');
      try {
        this.vizSessions = await this.loadVisualizationSessions();
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    async loadVisualizationSessions(): Promise<VisualizationSession[]> {
      return listItems(customQueries.listVisualizationSessionsForLoad, { sortDirection: 'DESC' });
    },
    loadSession(event: any) {
      console.log('event.data :>> ', event.data);
      this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/visualize/${event.data.id}`);
      this.hideDialog();
      // http://localhost:8080/m2m/5f0756d0-aa8d-422f-a36c-58affb7c8a7a/visualize/U2FsdGVkX1!sD5RF7Kxog69sdd!cEiLbhZTfvdY04JvNci4DV4Au5Rx5t4SAlcFL+PDj!4ENmZzsH+abDZqN6AFgNC30HZ5fQvo3Sl4T71Z0ySiWzFPGQ18bEmgqQh!FcZPlvHhfffYnxh+MufJgSrqHiDgmp+AOysXspS0ctmiEhq!Yz1qHbSwqM!AClMcIdXnG+THKeD1foluOTGb35iMG160r!YCg7RLdynlhYWbah8U9FWxASlWlmCY5FLVHfkG7S0yjLWdBYhzIHbE!B9Q+Wo5zxhn6S7R24ud+gySx!BkIvaiyiUthyZ8gXtj91qJPxtOzIDZ0mtKI7TTOQHPDKwr8wTl5ENNM3AvY+aK7NeDBViqoBCOORy6cK3tMrF5osFC5Xkc5ucLUel5BugzMXlO6Cf0zfAYuDXlc60y87YxflgFOFaErHK0DXbrAjEjxKoQZge91UvxWa0!oLlkP2d2GB8nOwyPCcPCTL4CwemJcZRLEq9jb4a68YNZvke9stp!iWDrH8joO!UJJeIJueiocqOA7Tu3sXmMgiUm0GtbjfLv!d4gvZdXnUgNWclFzotbUtRt9wNvi9Rs9fS9nSNvIuBtEY5bE8E3MEGeroYAwy8VmcTpGpfOYbEiG
    },
    async deleteSession(session: VisualizationSession) {
      console.log('session :>> ', session);
      this.loading = true;
      try {
        await API.graphql(graphqlOperation(mutations.deleteVisualizationSession, { input: { id: session.id } }));
        this.vizSessions = this.vizSessions.filter((ses) => ses.id !== session.id);
        this.loading = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Session successfully deleted!',
          life: 6000,
        });
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to delete session!',
          life: 3000,
        });
      }
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    hideDialog() {
      this.$store.dispatch('setShowingLoadVisualizationSession', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingLoadVisualizationSession': async function () {
      this.showing = this.$store.state.showingLoadVisualizationSession;
      if (this.showing) this.main();
    },
  },
});
