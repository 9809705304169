
// import { listItems } from '@/utils';
// import { StudyPhase } from '@/models';
// import * as mutations from '@/graphql/mutations';
// import * as customModels from '@/models/customModels';

import {
  AWSConfigCredentialsObj, // EntityQAObject, ExperimentSpecificVariable, entryESV, // sampleESV, // MetadataGeneralTemplate, // AnalysisType,
} from '@/models/customModels';
import {
  formatDateToHumanReadableOnlyDate, assumeCredentials,
} from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import AWS from 'aws-sdk';
import axios from 'axios';
import { graphqlOperation, API } from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as showdown from 'showdown';
import { Batch, Pipeline } from '@/models';

// eslint-disable-next-line import/no-webpack-loader-syntax
const reportTemplate = (require('raw-loader!@/assets/report_template.html')).default;

export default defineComponent({
  name: 'Samples Report Dialog',
  emits: ['sampleMetadata'],

  props: {
    // pipelinesData: { type: Object, required: false },
    studyPhase: { type: Boolean, required: false },
  },
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      entryValudation: false as boolean,
      description: null as unknown as string | undefined,
      activeOrgName: '',
      activeTabIndex: 0,
      studyId: '',
      studyPhaseId: '',
      dropdownOptions: [] as any,
      dropdownSelected: [] as any,
      reportName: '' as string,
      reportDescription: '' as string,
      markdownText: '' as string,
      bucket: process.env.VUE_APP_DATA_BUCKET,
      credentials: null as unknown as AWSConfigCredentialsObj,
      inFiles: {} as any,
      inPaths: {} as any,
      outFiles: {} as any,
      outPaths: {} as any,
      pipelinesData: {} as any,
      s3Contents: {} as any,
      resultsPath: '' as any,
      batch: null as unknown as Batch | null,
      pipelines: [] as Pipeline[],
      studyName: null as unknown as string,
      studyBatchPipelines: {} as any,
    };
  },
  // async beforeMount() {
  //   await this.main();
  // },
  async mounted() {
    this.loading = true;
    if (this.$route.params.origin[0] === 'studyPhase') {
      this.studyPhaseId = this.$route.params.origin[1];
      this.credentials = await this.setCredentialForReportDialog(this.studyPhaseId, null) as AWSConfigCredentialsObj;
      await this.loadPipelinesFromStudyPhase(this.studyPhaseId);
      if (this.$store.state.precedenceLevel < 5) {
        await this.loadPipelinesFromStudy(this.studyId);
      }
      await this.getInOutFiles(this.pipelinesData);
    } else if (this.$route.params.origin[0] === 'batch') {
      const batch = (await API.graphql(graphqlOperation(customQueries.getBatchForReport, { id: this.$route.params.origin[1] })) as any).data.getBatch;
      this.credentials = await this.setCredentialForReportDialog(null, batch) as AWSConfigCredentialsObj;
      const pipelines = (batch as any).pipelines.items;
      this.batch = batch;
      this.pipelines = pipelines;
      this.pipelinesData = pipelines;

      console.log('batch for report :>> ', batch);
      console.log('pipelines :>> ', pipelines);
      await this.getInOutFiles(pipelines);
    }
    this.loading = false;
  },
  methods: {
    async main() {
      console.log('Main triggered in report comp');
      console.log('this.$route.params.origin[0] :>> ', this.$route.params.origin[0]);
      console.log('this.pipelines :>> ', this.pipelines);
      this.loading = true;
      if (this.$route.params.origin[0] === 'studyPhase') {
        console.log('this.pipelinesData :>> ', this.pipelinesData);
        // console.log(this.batch!.studyPhase!.studyPhaseName);
        this.completedPipelinesDropdown(this.pipelinesData);

        this.parseOutFiles(this.pipelinesData);
      } else if (this.$route.params.origin[0] === 'batch') {
        console.log('this.pipelines :>> ', this.pipelines);
        console.log('this.batch :>> ', this.batch);
        this.completedPipelinesDropdown(this.pipelines);
        if (this.batch?.studyPhaseId) {
          this.reportName = this.batch!.studyPhase!.studyPhaseName;
          this.reportDescription = this.batch!.studyPhase!.description;
        } else {
          this.reportName = this.batch!.study!.studyName;
          this.reportDescription = this.batch!.study!.description;
        }
        this.parseOutFiles(this.pipelines);
        // console.log('batch for report :>> ', batch);
        // console.log('pipelines :>> ', pipelines);
        // await this.getInOutFiles(pipelines);
      }
      // this.completedPipelinesDropdown();
      this.loading = false;

      return null;
    },
    async setCredentialForReportDialog(studyPhaseId: string | null, batch: Batch | null): Promise<AWSConfigCredentialsObj | null> {
      if (studyPhaseId) return this.setCredentialsForReportDialogIfOpenedFromStudyPhase(studyPhaseId);
      if (batch) return this.setCredentialsForReportDialogIfOpenedFromBatch(batch);
      throw new Error('Both studyPhaseId and batch are null in setCredentialForReportDialog');
    },
    async setCredentialsForReportDialogIfOpenedFromStudyPhase(studyPhaseId: string): Promise<AWSConfigCredentialsObj | null> {
      const studyPhaseForParentIds: { id: string; study: { id: string; organization: { id: string; }; }; } | null = await this.getStudyPhaseForParentIds(studyPhaseId);
      if (studyPhaseForParentIds) {
        return assumeCredentials(this.$store.state.precedenceLevel, studyPhaseForParentIds.study.organization.id, studyPhaseForParentIds.study.id, studyPhaseForParentIds.id);
      }
      return null;
    },
    async setCredentialsForReportDialogIfOpenedFromBatch(batch: Batch): Promise<AWSConfigCredentialsObj | null> {
      if (batch && batch.study && batch.study.organization) {
        return await assumeCredentials(this.$store.state.precedenceLevel, batch!.study!.organization.id, batch.study.id, (batch.studyPhase) ? batch?.studyPhase.id : null) as AWSConfigCredentialsObj;
      }
      return null;
    },
    async getStudyPhaseForParentIds(studyPhaseId: string): Promise<{ id: string; study: { id: string; organization: { id: string; }; }; } | null> {
      try {
        const res = await API.graphql(graphqlOperation(customQueries.studyPhaseForParentIds, { id: studyPhaseId }));
        return (res as any).data.getStudyPhase;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getInOutFiles(pipelines: Pipeline[]) {
      pipelines.forEach(async (pipeline: Pipeline) => {
        if (pipeline.status === 'COMPLETED' || pipeline.status === 'FINISHED') {
          console.log('pipeline :>> ', pipeline);
          const paramsJsonPath = pipeline.s3ParamsFile?.split(`s3://${this.bucket}/`)[1];
          const studyBatchPipeline = (paramsJsonPath?.split('/')[2] === 'batches');
          this.studyBatchPipelines[pipeline.id] = studyBatchPipeline;
          console.log('studyBatchPipeline :>> ', studyBatchPipeline);
          if (!(studyBatchPipeline && this.$store.state.precedenceLevel >= 5)) {
            try {
              // console.log('paramsJsonPath :>> ', paramsJsonPath);
              const paramsJsonData = await this.getPresignedLink(paramsJsonPath as string);
              // console.log('paramsJsonData :>> ', paramsJsonData);
              const paramsJson = JSON.parse(paramsJsonData);
              const files: any[] = [];
              const paths = new Set<string>();
              /*
              const inputsFile = paramsJson.input_csv_s3_path.split(`s3://${this.bucket}/`)[1];
              const fileData = await this.getPresignedLink(inputsFile);
              fileData.split('\n').forEach((line: any) => {
                line.split(',').forEach((row: any) => {
                  if (row.includes('s3://')) {
                    const fileName = row.split('/').slice(-1)[0];
                    const parts = row.split('/').slice(0, -1);
                    const path = parts.join('/');
                    paths.add(path);
                    files.push(fileName);
                  }
                });
              });
              */
              const metadataIds = Object.keys(paramsJson.metadata_uuids_to_s3_paths);
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < metadataIds!.length; i++) {
                console.log(metadataIds[i]);
                const pathList = paramsJson.metadata_uuids_to_s3_paths[metadataIds[i]];
                console.log(pathList);
                pathList!.forEach((path: any) => {
                  // eslint-disable-next-line no-param-reassign
                  console.log(path);
                  const fileName = path.split('/').slice(-1)[0];
                  const parts = path.split('/').slice(0, -1);
                  const pathOnly = parts.join('/');
                  paths.add(pathOnly);
                  files.push(fileName);
                });
              }
              const pathsList = Array.from(paths.values());
              this.inPaths[pipeline.id] = pathsList.join(', ');
              this.resultsPath = paramsJson.results_s3_path;
              this.outPaths[pipeline.id] = paramsJson.results_s3_path;
              this.inFiles[pipeline.id] = files;
            } catch (error) {
              console.error(error);
              const files = ['No Input csv file for the pipeline run.'];
              this.inFiles[pipeline.id] = files;
            }
            try {
              const resultsPath = this.resultsPath.split(`s3://${this.bucket}/`)[1];
              this.s3Contents[pipeline.id] = [];
              this.getFilePathsToSendToPlot(pipeline.id, resultsPath);
              await this.s3Contents;
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },
    parseOutFiles(pipelines: Pipeline[]) {
      pipelines.forEach(async (pipeline: Pipeline) => {
        if (pipeline.status === 'COMPLETED' || pipeline.status === 'FINISHED') {
          const studyBatchPipeline = this.studyBatchPipelines[pipeline.id];
          if (!(studyBatchPipeline && this.$store.state.precedenceLevel >= 5)) {
            const files: any[] = [];
            // eslint-disable-next-line no-unused-vars
            for (const [key, value] of Object.entries(this.s3Contents[pipeline.id])) {
              const fileInfo = value as any;
              const fileName = fileInfo.Key.split('/').slice(-1)[0];
              files.push(fileName);
            }
            this.outFiles[pipeline.id] = files;
          }
        }
      });
    },
    async getFilePathsToSendToPlot(id: string, key: string, token:any = null) {
      // eslint-disable-next-line no-unused-vars
      return new Promise<void>((resolve, reject) => {
        const s3 = new AWS.S3(({ region: 'eu-west-2', credentials: this.credentials }));
        let params = {} as any;
        if (token == null) {
          params = {
            Bucket: this.bucket,
            Prefix: key,
          };
        } else {
          params = {
            Bucket: this.bucket,
            Prefix: key,
            ContinuationToken: token,
          };
        }
        try {
          s3.listObjectsV2(params, (err, data: any) => {
            if (data) {
              this.s3Contents[id].push(...data.Contents);
              if (!data.IsTruncated) {
                resolve();
              } else {
                this.getFilePathsToSendToPlot(key, data.NextContinuationToken).then(resolve);
              }
            }
            if (err) {
            // this.loading = false;
              console.error(err);
            }
          });
        } catch (error) {
          console.error(error);
        }
      });
    },

    completedPipelinesDropdown(pipelines: Pipeline[]) {
      if (pipelines) {
        pipelines.forEach((pipeline: Pipeline) => {
          if (pipeline.status === 'COMPLETED' || pipeline.status === 'FINISHED') {
            this.dropdownOptions.push({ nameType: `${pipeline.name} - ${pipeline.analysisType.toLowerCase()}`, value: pipeline.id, name: pipeline.name });
            this.dropdownSelected.push({ nameType: `${pipeline.name} - ${pipeline.analysisType.toLowerCase()}`, value: pipeline.id, name: pipeline.name });
          }
        });
      }
    },
    async loadPipelinesFromStudyPhase(id: string) {
      try {
        let pipelinesData = null as unknown as any;
        pipelinesData = await API.graphql(graphqlOperation(customQueries.studyPhaseForSampleReportDialog, { id }));

        this.pipelinesData = pipelinesData.data.getStudyPhase.pipelines.items;
        this.reportName = pipelinesData.data.getStudyPhase.studyPhaseName;
        this.reportDescription = pipelinesData.data.getStudyPhase.description;
        this.studyName = pipelinesData.data.getStudyPhase.study.studyName;
        this.studyId = pipelinesData.data.getStudyPhase.study.id;
        console.log('this.pipelinesData:', this.pipelinesData);
      } catch (error) {
        console.error(error);
        this.pipelinesData = [];
      }
    },
    async loadPipelinesFromStudy(id: string) {
      try {
        let studyPipelinesData = null as unknown as any;
        studyPipelinesData = await API.graphql(graphqlOperation(customQueries.studyForSampleReportDialog, { id }));
        console.log('studyPipelinesData:', studyPipelinesData);
        this.pipelinesData = studyPipelinesData.data.getStudy.pipelines.items;
      } catch (error) {
        console.error(error);
        this.pipelinesData = [];
      }
    },

    addTextElementWithValue(elementText: string, elementValue: string, textTagType = 'h3', valueTagType = 'span'): HTMLElement {
      const elementDiv = document.createElement('div');
      elementDiv.style.display = 'flex';
      const elementTextHeading = document.createElement(textTagType);
      elementTextHeading.innerHTML = elementText;
      elementTextHeading.style.alignItems = 'center';
      const elementValueSpan = document.createElement(valueTagType);
      elementValueSpan.innerHTML = elementValue;
      elementValueSpan.style.display = 'flex';
      elementValueSpan.style.alignItems = 'center';
      elementValueSpan.style.paddingLeft = '10px';
      elementDiv.appendChild(elementTextHeading);
      elementDiv.appendChild(elementValueSpan);
      return elementDiv;
    },
    addAccordion(pipelineName: string, pipelineElement: Node) : Node {
      const accordionElement = document.createElement('div');
      const btn = document.createElement('button');
      btn.innerHTML = pipelineName;
      btn.classList.add('accordion');
      accordionElement.appendChild(btn);
      const panelDiv = document.createElement('div');
      panelDiv.classList.add('panel');
      // const data = document.createElement('p');
      // data.innerHTML = 'Some nice data';
      panelDiv.appendChild(pipelineElement);
      accordionElement.appendChild(panelDiv);
      return accordionElement;
    },
    createFileListHTML(fileNames: string[], label: string) {
      const containerDiv = document.createElement('div');
      const labelElement = document.createElement('p');
      labelElement.innerHTML = label;
      const ul = document.createElement('ul');

      for (const fileName of fileNames) {
        const li = document.createElement('li');
        li.textContent = fileName;
        ul.appendChild(li);
      }
      containerDiv.appendChild(labelElement);
      containerDiv.appendChild(ul);
      return containerDiv;
    },
    async generateDownloadReport() {
      const templateElement = document.createElement('div');
      templateElement.innerHTML = reportTemplate;

      let studyDiv = null;
      if (this.batch && this.batch?.study) {
        studyDiv = this.addTextElementWithValue('Study name: ', this.batch?.study?.studyName as string);
      } else {
        studyDiv = this.addTextElementWithValue('Study name: ', this.studyName as string);
      }
      templateElement.getElementsByClassName('studyName')[0].appendChild(studyDiv);

      if (this.batch?.studyPhase) {
        const studyPhaseDiv = this.addTextElementWithValue('Study phase name: ', this.batch?.studyPhase?.studyPhaseName as string);
        templateElement.getElementsByClassName('studyPhaseName')[0].appendChild(studyPhaseDiv);
      }

      const descriptionDiv = this.addTextElementWithValue('Description: ', this.reportDescription);
      templateElement.getElementsByClassName('studyPhaseName')[0].appendChild(descriptionDiv);

      const converter = new showdown.Converter();
      const markdownHtml = converter.makeHtml(this.markdownText);
      const mdElement = document.createElement('div');
      mdElement.innerHTML = markdownHtml;
      templateElement.getElementsByClassName('markdownTextDiv')[0].appendChild(mdElement);

      this.dropdownSelected.forEach(async (selectedPIpeline: any) => {
        if (this.pipelinesData) {
          for (let i = 0; i < this.pipelinesData.length; i += 1) {
            const element = this.pipelinesData[i];
            if (selectedPIpeline.name === element.name) {
              if (element.status === 'COMPLETED' || element.status === 'FINISHED') {
                const pipelineElement = document.createElement('div');
                let paramsString = '';
                const params = JSON.parse(element.parameters);
                if (params) {
                  for (const [key, value] of Object.entries(params)) {
                    const param = `--${key} ${value} `;
                    paramsString += param;
                  }
                }
                console.log('element :>> ', element);
                const Initiator = this.addTextElementWithValue('Initiator: ', element.userEmail, 'strong');
                pipelineElement.appendChild(Initiator);

                const addNFcommand = this.addTextElementWithValue('NextFlow run command: ', `nextflow main.nf ${paramsString}`, 'strong');
                pipelineElement.appendChild(addNFcommand);

                const addInputs = document.createElement('h4');
                addInputs.innerHTML = 'Pipeline \ninputs:';
                pipelineElement.appendChild(addInputs);

                pipelineElement.appendChild(this.createFileListHTML(this.inPaths[element.id].split(','), 'Paths'));
                pipelineElement.appendChild(this.createFileListHTML(this.inFiles[element.id].filter((file: string) => file !== ''), 'Files'));

                const addOutputs = document.createElement('h4');
                addOutputs.innerHTML = 'Pipeline outputs:';
                pipelineElement.appendChild(addOutputs);

                pipelineElement.appendChild(this.createFileListHTML(this.outPaths[element.id].split(','), 'Paths'));

                pipelineElement.appendChild(this.createFileListHTML(this.outFiles[element.id].filter((file: string) => file !== ''), 'Files'));

                if (i < this.pipelinesData.length - 1) {
                  const addBreak = document.createElement('hr');
                  pipelineElement.appendChild(addBreak);
                }
                const accordion: Node = this.addAccordion(selectedPIpeline.nameType, pipelineElement);
                templateElement.getElementsByClassName('pipelinesDiv')[0].appendChild(accordion);
              }
            }
          }
        }
      });

      const timestampElement = templateElement.getElementsByClassName('timestamp')[0];
      if (timestampElement) timestampElement.innerHTML = formatDateToHumanReadableOnlyDate(new Date());
      const templateElementString = templateElement.innerHTML;
      this.download(templateElementString, `report_${formatDateToHumanReadableOnlyDate(new Date()).replaceAll('/', '-')}.html`);
      this.hideDialog();
    },
    download(text: string, filename: string) {
      const blob = new Blob([text], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    },

    async getPresignedLink(key: String) {
      const s3 = new AWS.S3(({ region: 'eu-west-2', credentials: this.credentials }));
      const params = { Bucket: this.bucket, Key: key };
      const url = s3.getSignedUrl('getObject', params);
      const fileBlob = await axios({ url, method: 'GET', responseType: 'blob' });
      const data = fileBlob.data.text();
      return data;
    },
    hideDialog() {
      this.loading = false;
      this.activeTabIndex = 0;
      this.description = undefined;
      this.$store.dispatch('setShowingSamplesReportDialog', false);
      this.dropdownOptions = [];
      this.dropdownSelected = [];
      this.reportName = '';
      this.reportDescription = '';
      this.markdownText = '';
      this.s3Contents = {};
      this.resultsPath = '';
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingSamplesReportDialog': async function () {
      this.showing = this.$store.state.showingSamplesReportDialog;
      if (this.showing) this.main();
    },
  },
});
