/* eslint-disable no-unused-vars */
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import * as AWSEventBridge from '@aws-sdk/client-eventbridge';
import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { EntityType, Study, StudyPhase } from '@/models';
import {
  AWSConfigCredentialsObj, ActiveOrganizationObject, EntityQA, EntityQAObject, PipelineTemplate,
} from '@/models/customModels';
import store from '@/store';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api-graphql';
import axios from 'axios';

type passedParamsType = {
  [key: string]: any;
  limit?: number;
};

const ADMIN_GROUP = 'M2MAdmin';
const M2M_ORG_ID = process.env.VUE_APP_M2M_ORG_ID;
const ACCOUNT_NUMBER = process.env.VUE_APP_AWS_ACCOUNT_ID;

// Wait time in milliseconds
export function wait(time: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, time));
}

// Can be called for any list query on graphql
export async function listItems<T>(query: string, passedParams?: passedParamsType): Promise<T[]> {
  try {
    const allItems = [];
    let params: passedParamsType | undefined = passedParams;
    if (!params) {
      params = { limit: 1000 };
    } else if (!('limit' in params)) {
      params.limit = 1000;
    }
    const gqlResponse = await API.graphql(graphqlOperation(query, params));
    const data = ('data' in gqlResponse) ? gqlResponse.data : undefined;
    if (!data) throw new Error('Data is undeinfed from api call in listItems');

    const responseItems = data[Object.keys(data)[0]];
    allItems.push(...responseItems.items);
    let nextToken = responseItems.nextToken;
    while (nextToken !== null && nextToken !== undefined) {
      const nextParams = params;
      nextParams.nextToken = nextToken;
      const nextGqlResponse = await API.graphql(graphqlOperation(query, nextParams));
      const nextData = ('data' in nextGqlResponse) ? nextGqlResponse.data : undefined;
      if (!nextData) throw new Error('Data is undeinfed from api call in listItems');
      const nextResponseItems = nextData[Object.keys(nextData)[0]];
      allItems.push(...nextResponseItems.items);
      nextToken = nextResponseItems.nextToken;
    }
    return allItems;
  } catch (error) {
    console.error('Error in listing items');
    console.error(error);
    return [];
  }
}

// Accepts the ISO 8061 time format and return only date
export function formatISOStringCustom(string: string): string {
  try {
    // dd/mm/yyyy
    const formatedDate: string = string.split('T')[0].split('-').reverse().join('/');
    return formatedDate;
  } catch (error) {
    console.error(error);
    return '';
  }
}

// Accepts the ISO 8061 time format and return only date
export function formatDateToHumanReadableOnlyDate(date: Date): string {
  try {
    // dd/mm/yyyy
    const formatedDate: string = date.toISOString().split('T')[0].split('-').reverse().join('/');
    return formatedDate;
  } catch (error) {
    console.error(error);
    return '';
  }
}

// Accepts the ISO 8061 time format and return date and time
export function formatDateToHumanReadableFull(date: Date): string {
  try {
    if (!date) return '';
    const formatedDate: string = formatDateToHumanReadableOnlyDate(date);
    const formatedTime: string = date.toISOString().split('T')[1].split('.')[0];
    return `${formatedDate} ${formatedTime}`;
  } catch (error) {
    console.error(error);
    return '';
  }
}

// Accepts the ISO 8061 time format and return date and time
export function formatDateToHumanReadableDate(date: Date): string {
  try {
    if (!date) return '';
    const formatedDate: string = formatDateToHumanReadableOnlyDate(date);
    return `${formatedDate}`;
  } catch (error) {
    console.error(error);
    return '';
  }
}

export function formatISODate(date: string): string {
  try {
    if (!date) return '';
    const formatedDate = date.split('T')[0].split('-').reverse().join('/');
    const formatedTime: string = date.split('T')[1].split('.')[0];
    return `${formatedDate} ${formatedTime}`;
  } catch (error) {
    console.error(error);
    return '';
  }
}

export async function sendNotificationEmail(content: string, passedEmails: string[], sendToM2MAdmins?: boolean, organizationId?: string): Promise<void> {
  try {
    let uniqueEmails: string[] = Array.from(new Set(passedEmails));
    if (!uniqueEmails || uniqueEmails.length === 0) uniqueEmails = ['filip.gunic@m2m.bio'];
    const authCredentials = await Auth.currentCredentials();
    const credentials: AWS.Credentials = new AWS.Credentials({
      accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
    });
    const eventbridge = new AWSEventBridge.EventBridge({ region: 'eu-west-2', credentials });
    const detail = {
      action: 'send_email',
      user_email: uniqueEmails,
      content,
      send_to_m2m_admins: (sendToM2MAdmins) || false,
      organization_id: (organizationId) || null,
    };
    const params = {
      Entries: [ /* required */
        {
          Detail: JSON.stringify(detail),
          DetailType: 'PassEventToOrchestrator',
          EventBusName: `arn:aws:events:eu-west-2:${ACCOUNT_NUMBER}:event-bus/M2MplatformEventBus`,
          Resources: [
            `arn:aws:events:eu-west-2:${ACCOUNT_NUMBER}:event-bus/M2MplatformEventBus`,
          ],
          Source: 'front.end',
          Time: new Date(),
        },
      ],
    };
    console.log('params :>> ', params);
    eventbridge.putEvents(params, (err: any, data: any) => {
      if (err) console.log(err, err.stack); // an error occurred
      else console.log(data); // successful response
    });
  } catch (error) {
    console.error(error);
  }
}

export async function eventTodeleteS3Data(action: string, content: any, barcodeids: any, notificationinfo: any, mutationName: string): Promise<void> {
  try {
    const authCredentials = await Auth.currentCredentials();
    const credentials: AWS.Credentials = new AWS.Credentials({
      accessKeyId: authCredentials.accessKeyId, secretAccessKey: authCredentials.secretAccessKey, sessionToken: authCredentials.sessionToken,
    });
    const eventbridge = new AWSEventBridge.EventBridge({ region: 'eu-west-2', credentials });
    const detail = {
      action,
      content,
      barcodeids,
      notificationinfo,
      mutationName,
    };
    const params = {
      Entries: [ /* required */
        {
          Detail: JSON.stringify(detail),
          DetailType: 'PassEventToDeleteS3Data',
          EventBusName: `arn:aws:events:eu-west-2:${ACCOUNT_NUMBER}:event-bus/M2MplatformEventBus`,
          Resources: [
            `arn:aws:events:eu-west-2:${ACCOUNT_NUMBER}:event-bus/M2MplatformEventBus`,
          ],
          Source: 'front.end',
          Time: new Date(),
        },
      ],
    };
    console.log('params :>> ', params);
    eventbridge.putEvents(params, (err: any, data: any) => {
      if (err) console.log(err, err.stack); // an error occurred
      else console.log(data); // successful response
    });
  } catch (error) {
    console.error(error);
  }
}

export async function getCurrentUsersEmail(): Promise<string> {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.email;
  } catch (error) {
    console.error(error);
    return '';
  }
}

export async function fixGroups() {
  try {
    console.log('Fixing groups');
    const allStudies = await listItems(customQueries.listStudiesWithOrganization);
    console.log('allStudies :>> ', allStudies);
    const newReadGroups = [];
    const newWriteGroups = [];
    for (let i = 0; i < allStudies.length; i += 1) {
      const study: Study = allStudies[i] as Study;
      const updateStudyInput: any = {
        id: study.id,
        readGroups: [`S/${study.id}/User`],
        writeGroups: [`S/${study.id}/Admin`],
        adminGroups: [`S/${study.id}/Admin`],
      };
      console.log('study :>> ', study);
      if (study.organizationId === 'c64fb3cb-47e3-4af2-842b-32f84c76693c') {
        newReadGroups.push(`S/${study.id}/User`);
        newWriteGroups.push(`S/${study.id}/Admin`);
      }
      // await API.graphql(graphqlOperation(mutations.updateStudy, {
      //   input: updateStudyInput,
      // }));
    }
    const updateOrgInput: any = {
      id: 'c64fb3cb-47e3-4af2-842b-32f84c76693c',
      readGroups: ['ORG/c64fb3cb-47e3-4af2-842b-32f84c76693c/Admin', ...newReadGroups],
      writeGroups: ['ORG/c64fb3cb-47e3-4af2-842b-32f84c76693c/Admin', ...newWriteGroups],
    };
    await API.graphql(graphqlOperation(mutations.updateOrganization, {
      input: updateOrgInput,
    }));
    console.log('Done fixing groups');
  } catch (error) {
    console.error(error);
  }
}

export async function fixStudyPhaseGroups(): Promise<void> {
  try {
    console.log('Fixing study phase groups');
    const allStudyPhases: StudyPhase[] = await listItems(customQueries.listStudyPhasesForFixGroups);
    // All are in m2m org
    const m2mOrgReadGroups = allStudyPhases[0].study?.organization?.readGroups;
    const m2mOrgWriteGroups = allStudyPhases[0].study?.organization?.writeGroups;
    for (let i = 0; i < allStudyPhases.length; i += 1) {
      const studyPhase: StudyPhase = allStudyPhases[i];
      if (studyPhase && studyPhase.study && studyPhase.study.organization) {
        console.log('studyPhase.study.organization.organizationName :>> ', studyPhase?.study?.organization.organizationName);
        m2mOrgReadGroups?.push(`SP/${studyPhase.id}/User`);
        m2mOrgReadGroups?.push(`CRO/${studyPhase.id}`);
        m2mOrgWriteGroups?.push(`SP/${studyPhase.id}/Admin`);
      }
    }
    console.log('m2mOrgReadGroups :>> ', m2mOrgReadGroups);
    console.log('m2mOrgWriteGroups :>> ', m2mOrgWriteGroups);
  } catch (error) {
    console.error(error);
  }
}

export function parseBatches(batches: any, filterColumns: any) {
  batches.forEach((batch: any) => {
    const batchAnnotations = JSON.parse(batch.batchAnnotations);
    const annotationNames = [] as any;
    const groups = JSON.parse(batch.batchGroups);
    const groupNames = [];
    for (let i = 0; i < groups.length; i += 1) {
      groupNames.push(groups[i].name);
    }
    const annotationColumns = Object.keys(batchAnnotations[0]);

    try {
      // for (let i = 0; i < annotations.length; i += 1) {
      //   annotationNames.push(batchAnnotations.annotations[i].name);
      // }
      batchAnnotations[0].annotations.forEach((annotation: any) => {
        annotationNames.push(annotation.name);
      });
    } catch (error) {
      for (let i = 0; i < annotationColumns.length; i += 1) {
        if (filterColumns.indexOf(annotationColumns[i]) === -1) {
          annotationNames.push(annotationColumns[i]);
        }
      }
      console.error('old batch entries');
      console.error(error);
    }

    // eslint-disable-next-line no-param-reassign
    batch.annotationNames = annotationNames;
    // eslint-disable-next-line no-param-reassign
    batch.samplesCount = batchAnnotations.length;
    // eslint-disable-next-line no-param-reassign
    batch.groupNames = groupNames;
    // eslint-disable-next-line no-param-reassign
    batch.batchAnnotations = batchAnnotations;
    // eslint-disable-next-line no-param-reassign
    batch.batchGroups = groups;
    // batch.studyID =
  });
  return batches;
}

export async function setActiveOrganizationPromiseForM2MAdmin() {
  try {
    if (!M2M_ORG_ID) throw new Error('No m2m organization id in environment variable');
    const activeOrganization: ActiveOrganizationObject = {
      id: M2M_ORG_ID,
      organizationName: 'm2m',
      organizationDescription: 'M2M',
    };
    return activeOrganization;
  } catch (error) {
    console.error(error);
    return false;
  }
}

// ORG/org_uuid/Admin
export async function setActiveOrganizationPromiseByOrganizationAdminGroup(group: string): Promise<ActiveOrganizationObject | null> {
  try {
    const organizationObjectForActiveOrg = await API.graphql(graphqlOperation(customQueries.getOrganizationBasic, { id: group.split('/')[1] }));
    const data = ('data' in organizationObjectForActiveOrg) ? organizationObjectForActiveOrg.data : undefined;
    if (!data) throw new Error('Data is undeinfed from api call in setActiveOrganizationPromiseByOrganizationAdminGroup');

    const activeOrganization: ActiveOrganizationObject = {
      id: data.getOrganization.id,
      organizationName: data.getOrganization.organizationName,
      organizationDescription: data.getOrganization.organizationDescription,
    };
    return activeOrganization;
  } catch (error) {
    console.error(error);
    return null;
  }
}
// S/study_uuid/Admin or S/study_uuid/User
export async function setActiveOrganizationPromiseByStudyGroup(group: string): Promise<ActiveOrganizationObject | null> {
  try {
    const studyObejctForActiveOrg = await API.graphql(graphqlOperation(customQueries.getStudyBasic, { id: group.split('/')[1] }));

    const data = ('data' in studyObejctForActiveOrg) ? studyObejctForActiveOrg.data : undefined;
    if (!data) throw new Error('Data is undeinfed from api call in setActiveOrganizationPromiseByOrganizationAdminGroup');

    const activeOrganization: ActiveOrganizationObject = {
      id: data.getStudy.organization.id,
      organizationName: data.getStudy.organization.organizationName,
      organizationDescription: data.getStudy.organization.organizationDescription,
    };
    return activeOrganization;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// SP/study_uuid/Admin or SP/study_uuid/User
export async function setActiveOrganizationPromiseByStudyPhaseGroup(group: string): Promise<ActiveOrganizationObject | null> {
  try {
    console.log('group.split(/)[1] :>> ', group.split('/')[1]);
    const studyPhaseObejctForActiveOrg = await API.graphql(graphqlOperation(customQueries.getStudyPhaseBasic, { id: group.split('/')[1] }));

    const data = ('data' in studyPhaseObejctForActiveOrg) ? studyPhaseObejctForActiveOrg.data : undefined;
    if (!data) throw new Error('Data is undeinfed from api call in setActiveOrganizationPromiseByOrganizationAdminGroup');

    const activeOrganization: ActiveOrganizationObject = {
      id: data.getStudyPhase.study.organization.id,
      organizationName: data.getStudyPhase.study.organization.organizationName,
      organizationDescription: data.getStudyPhase.study.organization.organizationDescription,
    };
    return activeOrganization;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function isM2MGlobalAdmin(groups: string[]) {
  try {
    if (groups) {
      return groups.indexOf(ADMIN_GROUP) > -1;
    }
    return false;
  } catch (error) {
    console.error();
    return false;
  }
}

function isOrganizationAdmin(groups: string[]): false | string[] {
  try {
    if (groups) {
      const orgAdminGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        if (group.startsWith('ORG/')) {
          orgAdminGroups.push(group);
        }
      }
      if (orgAdminGroups.length <= 0) return false;
      return orgAdminGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isStudyAdmin(groups: string[]): false | string[] {
  try {
    if (groups) {
      const studyAdminGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        const split: string[] = group.split('/');
        if (split[0] === 'S' && split[2] === 'Admin') {
          studyAdminGroups.push(group);
        }
      }
      if (studyAdminGroups.length <= 0) return false;
      return studyAdminGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isStudyUser(groups: string[]): false | string[] {
  try {
    if (groups) {
      const studyUserGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        const split: string[] = group.split('/');
        if (split[0] === 'S' && split[2] === 'User') {
          studyUserGroups.push(group);
        }
      }
      if (studyUserGroups.length <= 0) return false;
      return studyUserGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isStudyPhaseAdmin(groups: string[]): false | string[] {
  try {
    if (groups) {
      const studyPhaseAdminGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        const split: string[] = group.split('/');
        if (split[0] === 'SP' && split[2] === 'Admin') {
          studyPhaseAdminGroups.push(group);
        }
      }
      if (studyPhaseAdminGroups.length <= 0) return false;
      return studyPhaseAdminGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isUser(groups: string[]): false | string[] {
  try {
    if (groups) {
      const userGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        const split: string[] = group.split('/');
        if (split[2] === 'User') {
          userGroups.push(group);
        }
      }
      if (userGroups.length <= 0) return false;
      return userGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isStudyPhaseUser(groups: string[]): false | string[] {
  try {
    if (groups) {
      const studyPhaseUserGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        const split: string[] = group.split('/');
        if (split[0] === 'SP' && split[2] === 'User') {
          studyPhaseUserGroups.push(group);
        }
      }
      if (studyPhaseUserGroups.length <= 0) return false;
      return studyPhaseUserGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function isCRO(groups: string[]): false | string[] {
  try {
    if (groups) {
      const CROGroups: string[] = [];
      for (let i = 0; i < groups.length; i += 1) {
        const group: string = groups[i];
        const split: string[] = group.split('/');
        if (split[0] === 'CRO') {
          CROGroups.push(group);
        }
      }
      if (CROGroups.length <= 0) return false;
      return CROGroups;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function setPrecedence(changeActiveOrg?: boolean): Promise<void> {
  try {
    console.log('Setting precedence');
    const user = await Auth.currentAuthenticatedUser();
    console.log('user :>> ', user);
    const groups: string[] = user.signInUserSession.accessToken.payload['cognito:groups'];
    if (isM2MGlobalAdmin(groups)) {
      console.log('User is M2M Admin');
      store.dispatch('setPrecedenceLevel', 1);
      if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseForM2MAdmin());
    } else {
      const orgAdminGroups: false | string[] = isOrganizationAdmin(groups);
      console.log('orgAdminGroups :>> ', orgAdminGroups);
      if (orgAdminGroups && orgAdminGroups.length > 0) {
        const firstOrgAdminGroup: string = orgAdminGroups[0];
        console.log(`User is organization administrator of org: ${firstOrgAdminGroup}`);
        store.dispatch('setPrecedenceLevel', 2);
        if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseByOrganizationAdminGroup(firstOrgAdminGroup));
        return;
      }
      const studyAdminGroups: false | string[] = isStudyAdmin(groups);
      if (studyAdminGroups && studyAdminGroups.length > 0) {
        const firstStudyAdminGroup: string = studyAdminGroups[0];
        console.log(`User is study administrator of study: ${firstStudyAdminGroup}`);
        store.dispatch('setPrecedenceLevel', 3);
        if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseByStudyGroup(firstStudyAdminGroup));
        return;
      }
      const studyUserGroups: false | string[] = isStudyUser(groups);
      if (studyUserGroups && studyUserGroups.length > 0) {
        const firstStudyUserGroup: string = studyUserGroups[0];
        console.log(`User is study user of study: ${firstStudyUserGroup}`);
        store.dispatch('setPrecedenceLevel', 4);
        if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseByStudyGroup(firstStudyUserGroup));
        return;
      }
      const studyPhaseAdminGroups: false | string[] = isStudyPhaseAdmin(groups);
      if (studyPhaseAdminGroups && studyPhaseAdminGroups.length > 0) {
        const firstStudyPhaseAdminGroup: string = studyPhaseAdminGroups[0];
        console.log(`User is study phase admin of study phase: ${firstStudyPhaseAdminGroup} (Bioinformatician)`);
        store.dispatch('setPrecedenceLevel', 5);
        if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseByStudyPhaseGroup(firstStudyPhaseAdminGroup));
        return;
      }
      const studyPhaseUserGroups: false | string[] = isStudyPhaseUser(groups);
      if (studyPhaseUserGroups && studyPhaseUserGroups.length > 0) {
        const firstStudyPhaseUserGroup: string = studyPhaseUserGroups[0];
        console.log(`User is study phase user of study phase: ${firstStudyPhaseUserGroup} (Explorer)`);
        store.dispatch('setPrecedenceLevel', 6);
        if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseByStudyPhaseGroup(firstStudyPhaseUserGroup));
        return;
      }
      const CROGroups: false | string[] = isCRO(groups);
      if (CROGroups && CROGroups.length > 0) {
        const firstCROGroupsGroup: string = CROGroups[0];
        console.log(`User is CRO user of study phase: ${firstCROGroupsGroup}`);
        store.dispatch('setPrecedenceLevel', 7);
        if (changeActiveOrg) store.dispatch('setActiveOrganization', setActiveOrganizationPromiseByStudyPhaseGroup(firstCROGroupsGroup));
        return;
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function validateInputName(name: string) {
  if (!(/^[a-zA-Z0-9_\-\\.]+( [a-zA-Z0-9_\-\\.]+)*$/.test(name)) && name !== '' && name !== null) {
    return false;
  }
  return true;
}

export function validateDigit(digit: string) {
  if (!(/^[0-9]+( [0-9]+)*$/.test(digit)) && digit !== '' && digit !== null) {
    return false;
  }
  return true;
}
export function validateURL(url: string) {
  if (!(/^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/.test(url)) && url !== '' && url !== null) {
    return false;
  }
  return true;
}
export function validateEmail(email: string) {
  if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{3,4})+$/.test(email)) && email !== '' && email !== null) {
    return false;
  }
  return true;
}
export function validateString(parsedVars: any, column: string) {
  const value = parsedVars[column].value;
  if (!isNaN(Number(value)) && value !== '' && value !== null) {
    return false;
  }
  return true;
}
export function validateFloat(parsedVars: any, column: string) {
  let value = parsedVars[column].value;

  if (value === '' || value === null) {
    return true;
  }
  value = `${parsedVars[column].value}`;
  if (isNaN(Number(value)) && value !== '' && value !== null) {
    return false;
  }
  if (!(value.indexOf('.') >= 0) && value !== '' && value !== null) {
    return false;
  }
  return true;
}
export function validateInteger(parsedVars: any, column: string) {
  let value = parsedVars[column].value;

  if (value === '' || value === null) {
    return true;
  }
  value = `${parsedVars[column].value}`;

  if (isNaN(Number(value)) && value !== '' && value !== null) {
    return false;
  }
  if ((value.indexOf('.') >= 0)) {
    return false;
  }
  return true;
}

export async function getOrgNameForClass(): Promise<string> {
  try {
    const activeOrgObj = await store.state.activeOrganization;
    if (activeOrgObj) return activeOrgObj.organizationName as string;
    return '';
  } catch (error) {
    console.error();
    return '';
  }
}

// eslint-disable-next-line no-unused-vars, consistent-return
export async function setCredentials(role: string): Promise<AWSConfigCredentialsObj | null> {
  try {
    console.log('Setting credentials for role :>> ', role);
    const promises = [];
    promises.push(Auth.currentUserPoolUser());
    promises.push(Auth.currentCredentials());
    const [user, authCredentials] = await Promise.all(promises);
    if (user === null || user === undefined || authCredentials === null || authCredentials === undefined) {
      console.error('Error getting user or credentials');
      return null;
    }
    // const accountNumber = await store.state.accountNumberPromise;
    if (ACCOUNT_NUMBER === null) {
      console.error('Error getting account number');
      return null;
    }
    if (role === 'M2MAdmin') {
      AWS.config.update({
        accessKeyId: authCredentials.accessKeyId,
        secretAccessKey: authCredentials.secretAccessKey,
        sessionToken: authCredentials.sessionToken,
        region: 'eu-west-2',
      });
      return {
        accessKeyId: authCredentials.accessKeyId,
        secretAccessKey: authCredentials.secretAccessKey,
        sessionToken: authCredentials.sessionToken,
        region: 'eu-west-2',
      };
    }
    const cognitoArn = `cognito-idp.eu-west-2.amazonaws.com/${user.pool.userPoolId}`;
    const roleArn = `arn:aws:iam::${ACCOUNT_NUMBER}:role/Role_${role.replaceAll('/', '_')}`;
    const identityId = authCredentials.identityId;
    const params = {
      CustomRoleArn: roleArn,
      IdentityId: identityId,
      Logins: {
        [cognitoArn]: user
          .getSignInUserSession()
          .getIdToken()
          .getJwtToken(),
      },
    };
    const cognitoidentity = new AWS.CognitoIdentity({ region: 'eu-west-2' });
    const credPromise = new Promise((resolve) => {
      // eslint-disable-next-line consistent-return
      cognitoidentity.getCredentialsForIdentity(params, (err, data) => {
        if (err) {
          console.log(err, err.stack);
          console.log('Null in getCredentialsForIdentity');
          return null;
        }
        try {
          AWS.config.update({
            accessKeyId: data.Credentials?.AccessKeyId,
            secretAccessKey: data.Credentials?.SecretKey,
            sessionToken: data.Credentials?.SessionToken,
            region: 'eu-west-2',
          });
          if (!data.Credentials?.AccessKeyId || !data.Credentials?.SecretKey || !data.Credentials?.SessionToken) return null;
          resolve({
            accessKeyId: data.Credentials?.AccessKeyId,
            secretAccessKey: data.Credentials?.SecretKey,
            sessionToken: data.Credentials?.SessionToken,
            region: 'eu-west-2',
          });
        } catch (error) {
          console.error('Error in AWS.config.update');
          console.error(error);
          return null;
        }
      });
    }) as Promise<AWSConfigCredentialsObj>;
    const res = await credPromise;
    if (res) return res;
    return res;
  } catch (error) {
    console.error('Error setting credentials');
    console.error(error);
    return null;
  }
}

export async function assumeCredentials(precedenceLevel: number, organizationId: string, studyId: string, studyPhaseId?: string | null | undefined): Promise<AWSConfigCredentialsObj | null> {
  console.log('precedenceLevel :>> ', precedenceLevel);
  switch (precedenceLevel) {
    case 1:
      console.log('Assuming Global admin credentials');
      return setCredentials('M2MAdmin');
    case 2:
      console.log(`Assuming organization admin credentials: ${organizationId}`);
      return setCredentials(`ORG/${organizationId}/Admin`);
    case 3:
      console.log(`Assuming study admin credentials: ${studyId}`);
      return setCredentials(`S/${studyId}/Admin`);
    case 5:
      console.log(`Assuming study phase admin / Bioinformatician credentials: ${studyId}`);
      return setCredentials(`SP/${studyPhaseId}/Admin`);
    case 6:
      console.log(`Assuming study phase user / Explorer credentials: ${studyId}`);
      return setCredentials(`SP/${studyPhaseId}/User`);
    case 7:
      console.log(`Assuming COR credentials: ${studyId}`);
      return setCredentials(`CRO/${studyPhaseId}`);
    default:
      console.error('Unsupported precedence level');
      return null;
  }
}

export async function assumeCredentialsFromPrecedenceLevel(precedenceLevel: number): Promise<AWSConfigCredentialsObj | null> {
  console.log('precedenceLevel :>> ', precedenceLevel);
  const user = await Auth.currentAuthenticatedUser();
  const groups: string[] = user.signInUserSession.accessToken.payload['cognito:groups'];
  let role;
  switch (precedenceLevel) {
    case 1:
      console.log('Assuming Global admin credentials');
      return setCredentials('M2MAdmin');
    case 2:
      role = groups.filter((grp) => grp.startsWith('ORG/'))[0];
      console.log(`Assuming organization admin credentials: ${role}`);
      return setCredentials(role);
    case 3:
      role = groups.filter((grp) => grp.match(/S\/.*\/Admin/g))[0];
      console.log(`Assuming study admin credentials: ${role}`);
      return setCredentials(role);
    case 5:
      role = groups.filter((grp) => grp.match(/SP\/.*\/Admin/g))[0];
      console.log(`Assuming study phase admin / Bioinformatician credentials: ${role}`);
      return setCredentials(role);
    case 6:
      role = groups.filter((grp) => grp.match(/SP\/.*\/User/g))[0];
      console.log(`Assuming study phase user / Explorer credentials: ${role}`);
      return setCredentials(role);
    case 7:
      role = groups.filter((grp) => grp.match(/CRO\/.*/g))[0];
      console.log(`Assuming CRO credentials: ${role}`);
      return setCredentials(role);
    default:
      console.error('Unsupported precedence level');
      return null;
  }
}

export async function sendEBEvent(detail: Object, organizationId: string, studyId: string, studyPhaseId?: string | null | undefined): Promise<void> {
  try {
    const credentials = await assumeCredentials(store.state.precedenceLevel, organizationId, studyId, studyPhaseId);
    if (!credentials) throw new Error('Bad credentials in sendEBEvent');
    const eventbridge = new AWSEventBridge.EventBridge({ region: 'eu-west-2', credentials });
    const params = {
      Entries: [ /* required */
        {
          Detail: JSON.stringify(detail),
          DetailType: 'PassEventToOrchestrator',
          EventBusName: `arn:aws:events:eu-west-2:${ACCOUNT_NUMBER}:event-bus/M2MplatformEventBus`,
          Resources: [
            `arn:aws:events:eu-west-2:${ACCOUNT_NUMBER}:event-bus/M2MplatformEventBus`,
          ],
          Source: 'front.end',
          Time: new Date(),
        },
      ],
    };
    console.log('params :>> ', params);
    eventbridge.putEvents(params, (err: any, data: any) => {
      if (err) console.log(err, err.stack); // an error occurred
      else console.log(data); // successful response
    });
  } catch (error) {
    console.error(error);
  }
}

export async function getQuestions(entityType: EntityType): Promise<EntityQAObject | null> {
  try {
    const resp: GraphQLResult<any> = await API.graphql(graphqlOperation(queries.questionsByEntityType, {
      entityType,
    }));
    if (resp && resp?.data?.questionsByEntityType?.items[0]?.questions) return (JSON.parse(resp.data.questionsByEntityType.items[0].questions));
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export function makeEntityQAObj(questionsObj: EntityQAObject, answers: { [key: string] : string}): EntityQAObject | null {
  try {
    const entityQArr: EntityQA[] = [];
    console.log('questionsObj :>> ', questionsObj);
    console.log('answers :>> ', answers);
    if (questionsObj.questions && questionsObj.questions.length > 0) {
      for (let i = 0; i < questionsObj.questions.length; i += 1) {
        const question = questionsObj.questions[i].question;
        const answer = answers[questionsObj.questions[i].question];
        console.log('answer :>> ', answer);
        if (answer) {
          const obj: EntityQA = {
            question,
            answer,
          };
          entityQArr.push(obj);
        }
      }
    }
    const questionGroupsArr = [];
    if (questionsObj.questionGroups && questionsObj.questionGroups.length > 0) {
      for (let i = 0; i < questionsObj.questionGroups.length; i += 1) {
        const questionGroup = questionsObj.questionGroups[i];
        const groupAnswers: EntityQA[] = [];
        for (let j = 0; j < questionGroup.questions.length; j += 1) {
          const question = questionGroup.questions[j];
          const answer = answers[question.question];
          if (answer && answer !== 'undefined' && answer !== 'NaN') {
            const o = {
              question: question.question,
              answer: `${answer}`,
              inputType: question.inputType,
            };
            groupAnswers.push(o);
          }
        }
        questionGroupsArr.push({
          groupLabel: questionGroup.groupLabel,
          questions: groupAnswers,
        });
      }
    }
    const entityQObj: any = {
      questions: entityQArr,
      questionGroups: questionGroupsArr,
    };
    console.log('entityQObj :>> ', entityQObj);
    return entityQObj;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function switchTheme(theme: string): void {
  const themeElement = document.getElementById('theme-link');
  if (themeElement) {
    themeElement.setAttribute('href', theme);
  }
}

export function switchToDarkTheme(): void {
  const newTheme = '/themes/vela-purple/theme.css';
  switchTheme(newTheme);
}

export function switchToLightTheme(): void {
  const newTheme = '/themes/saga-blue/theme.css';
  switchTheme(newTheme);
}

// Filter out duplicate objects from an array by a key
export function uniqueObjectsByKey<Type>(arr: Type[], key: string): Type[] {
  try {
    const unique = arr.filter(
      (obj: Type, index) => arr.findIndex((item: Type) => item[key as keyof Type] === obj[key as keyof Type]) === index,
    );
    const nonUnique = arr.filter(
      (obj: Type, index) => arr.findIndex((item: Type) => item[key as keyof Type] === obj[key as keyof Type]) !== index,
    );
    console.log('nonUnique :>> ', nonUnique);
    return unique;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export function formatListForView(list: string[]) {
  try {
    if (!list) return '';
    return list.join(', ');
  } catch (error) {
    console.error(error);
    return '';
  }
}

export function getOrgNameAndIdForRoute(route: any) {
  try {
    return `/${route.params.organizationName}/${route.params.organizationId}/`;
  } catch (error) {
    console.error(error);
    return '/';
  }
}

export function getHomeRouteForBreadcrumb(route: any) {
  try {
    return `${getOrgNameAndIdForRoute(route)}studyBrowse`;
  } catch (error) {
    console.error(error);
    return '/';
  }
}

export function statusClass(status: string): string {
  return status.toLowerCase().replace(' ', '-');
}
export async function getStudyIdFromStudyPhase(studyPaseId: string) {
  const resp: any = await API.graphql(graphqlOperation(customQueries.getStudyPhaseForStudyAndOrgId, { id: studyPaseId }));
  return resp?.data.getStudyPhase.study.id;
}

export function remapPipelines(pipelines: PipelineTemplate[]): PipelineTemplate[] {
  return pipelines.map((pipeline) => {
    const ppl = pipeline;
    if (ppl.pipelineStarted) ppl.pipelineStarted = new Date(ppl.pipelineStarted as string);
    if (ppl.pipelineCompleted) ppl.pipelineCompleted = new Date(ppl.pipelineCompleted as string);
    if (ppl.studyPhase && ppl.studyPhase.studyPhaseName) (ppl as any).studyPhaseName = ppl.studyPhase.studyPhaseName;
    return ppl;
  });
}

export async function getPresignedLink(key: String, credentials: AWSConfigCredentialsObj) {
  const s3 = new AWS.S3(({ region: 'eu-west-2', credentials }));
  const params = { Bucket: process.env.VUE_APP_MASTER_BUCKET, Key: key };
  const url = s3.getSignedUrl('getObject', params);
  const res = await axios({
    url, // File URL Goes Here
    method: 'GET',
    responseType: 'blob',
  });
  return res.config.url;
}

export function camelCaseToWords(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}
export async function updateDynamoDBDirectly(id: string, tableName: string, valuesToUpdate: {field: string, values: string[]}[]): Promise<void> {
  const credentials = await Auth.currentCredentials();
  const dynamodb = new AWS.DynamoDB({ region: 'eu-west-2', credentials });
  const key = {
    id: { S: id },
  };
  valuesToUpdate.map((value) => `${value.field} = list_append(${value.field}, :${value.field}`);
  const updateExpression = `SET ${valuesToUpdate.map((value) => `${value.field} = list_append(${value.field}, :${value.field})`).join(', ')}`;
  console.log('updateExpression :>> ', updateExpression);
  const expressionAttributeValues: any = {};
  valuesToUpdate.forEach((value) => { expressionAttributeValues[`:${value.field}`] = { L: value.values.map((s) => ({ S: s })) }; });
  const fullTableName = `${tableName}-${process.env.VUE_APP_API_ID}-${process.env.VUE_APP_DATA_BUCKET?.split('-')[3]}`;
  const updateParams = {
    TableName: fullTableName,
    Key: key,
    UpdateExpression: updateExpression,
    ExpressionAttributeValues: expressionAttributeValues,
    ReturnValues: 'UPDATED_NEW',
  };
  dynamodb.updateItem(updateParams, (err, data) => {
    console.log('data :>> ', data);
    if (err) {
      console.error('Error updating item:', err);
    } else if (data.Attributes) {
      console.log('Updated:', data.Attributes);
    } else {
      console.log('Item not updated.');
    }
  });
}

export function groupBy<Type>(array: Type[], key: string): Record<string, Type[]> {
  return array.reduce((rv: { [key: string]: Type[] }, x: any) => {
    const keyValue: string = x[key as string];
    // eslint-disable-next-line no-param-reassign
    (rv[keyValue] = rv[keyValue] || []).push(x);
    return rv;
  }, {});
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
