
import { defineComponent } from 'vue';
import StudyTable from '@/components/Study/StudyTable.vue';
import { getHomeRouteForBreadcrumb } from '@/utils';
import { MenuItem } from '@/models/customModels';

export default defineComponent({
  name: 'Studies',
  components: {
    StudyTable,
  },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [{ label: 'Studies', to: getHomeRouteForBreadcrumb(this.$route) }] as MenuItem[],
    };
  },
});
