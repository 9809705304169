
import { defineComponent } from 'vue';
import { Auth } from 'aws-amplify';
import { ActiveOrganizationObject, MenuItem } from '@/models/customModels';
// eslint-disable-next-line no-unused-vars
import AWS from 'aws-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  // eslint-disable-next-line no-unused-vars
  IAMClient, ListRolesCommand, DeleteRoleCommand, ListPoliciesCommand, DeletePolicyCommand, ListPolicyVersionsCommand, DeletePolicyVersionCommand, DetachRolePolicyCommand,
} from '@aws-sdk/client-iam';
import OpenVisualizationSessionDialog from '@/components/Visualization/OpenVisualizationSessionDialog.vue';

export default defineComponent({
  name: 'TopBar',
  components: {
    OpenVisualizationSessionDialog,
  },
  props: {
    isUser: { type: Boolean, required: true },
  },
  data() {
    return {
      itemOptions: [{
        label: 'Browse',
        icon: 'pi pi-list',
        command: () => {
          this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/studyBrowse`);
        },
      },
      {
        label: 'Search',
        icon: 'pi pi-search',
        command: () => {
          this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/search/`);
        },
      }] as unknown as MenuItem[],
      orgName: '' as String,
      profileMenuItems: [] as MenuItem[],
      logo: '',
    };
  },
  beforeMount() {
    // Force rebuild comment
    if (this.$store.state.precedenceLevel === 7) this.itemOptions = [];
    if (this.isUser) {
      this.itemOptions = [
        {
          label: 'Browse',
          icon: 'pi pi-list',
          command: () => {
            this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/studyBrowse`);
          },
        },
        {
          label: 'Search',
          icon: 'pi pi-search',
          command: () => {
            this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/search`);
          },
        },
      ] as unknown as MenuItem[];
    }
    if (this.$store.state.precedenceLevel <= 2) {
      this.itemOptions.push({
        label: 'Upload',
        icon: 'pi pi-upload',
        command: () => {
          this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/CRO`);
        },
      } as unknown as MenuItem);
      this.itemOptions.push({
        label: 'Admin Panel',
        command: () => {
          this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/admin/organizations` });
        },
      });
    }
    this.loadLogo();
    this.createProfileMenuItems();
  },
  methods: {
    async loadLogo(): Promise<void> {
      const activeOrganization = await this.$store.state.activeOrganization;
      this.orgName = activeOrganization.organizationName;
      if (!this.orgName) this.orgName = 'm2m';
      this.setLogo(this.orgName);
    },
    setLogo(orgName: String | null | undefined): void {
      if (!orgName) return;
      if (this.$store.state.precedenceLevel > 7) return;
      switch (orgName) {
        case 'm2m': {
          this.logo = new URL('@/assets/m2m-logo.png', import.meta.url).href;
          break;
        }
        case 'gwt': {
          this.logo = new URL('@/assets/gwt-logo.png', import.meta.url).href;
          break;
        }
        case 'tfb': {
          this.logo = new URL('@/assets/ThirtyFiveBio_logo_reg_png.png', import.meta.url).href;
          break;
        }
        case 'pt': {
          this.logo = new URL('@/assets/PathiosLogo.png', import.meta.url).href;
          break;
        }
        default: {
          this.logo = new URL('@/assets/m2m-logo.png', import.meta.url).href;
          break;
        }
      }
    },
    async createProfileMenuItems(): Promise<void> {
      try {
        const email = (await Auth.currentUserInfo()).attributes.email;
        const profileMenuItems: MenuItem[] = [
          {
            label: email,
          },
          {
            label: 'View Profile',
            command: () => {
              console.log('Show profile');
              this.$store.dispatch('showProfile');
            },
          },
          {
            label: 'Change Organization',
            command: () => {
              this.$store.dispatch('setShowingChangeOrganizationDialog', true);
            },
          },
          {
            label: 'Sign Out',
            command: () => {
              this.signOut();
            },
          },
        ];
        this.profileMenuItems = profileMenuItems;
      } catch (error) {
        console.error(error);
      }
    },
    getRoute() {
      try {
        if (this.$store.state.precedenceLevel === 7) return `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/CRO`;
        return `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/studyBrowse`;
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    signOut() {
      Auth.signOut().then(() => {
        this.$router.push('/auth/login');
      }).catch((e: any) => {
        console.log('error signing out: ', e);
      });
      document.body.classList.value = '';
    },
    menuToggle(event: any, refName: string): void {
      (this.$refs[refName] as any).toggle(event);
    },
    openHelpPage() {
      this.$router.push({ path: `/${this.$route.params.organizationName}/${this.$route.params.organizationId}/Help` });
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.activeOrganization': async function () {
      const activeOrganization: ActiveOrganizationObject = await this.$store.state.activeOrganization;
      if (activeOrganization) this.setLogo(activeOrganization.organizationName);
    },
  },
});
