export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      parameters
      studyId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;

export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      name
      status
      description
      s3Event
      s3ParamsFile
      s3Results
      visFileInfoS3path
      s3NfLog
      s3NfTasksLogs
      analysisType
      parameters
      studyId
      studyPhaseId
      scheduledPipeline
      scheduledDateTime
      launchablePipelineId
      batchId
      runId
      userEmail
      pipelineStarted
      pipelineCompleted
      readGroups
      writeGroups
      adminGroups
      created
      updated
      studyPipelinesId
      studyPhasePipelinesId
      batchPipelinesId
      launchablePipelinesPipelinesId
    }
  }
`;
