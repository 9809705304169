import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b4aa854"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "studies-table" }
const _hoisted_2 = { class: "flex justify-content-end flex-column sm:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_StudyDialog = _resolveComponent("StudyDialog")!
  const _component_LaunchPipelineDialog = _resolveComponent("LaunchPipelineDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        value: _ctx.studies,
        paginator: true,
        responsiveLayout: "scroll",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
        ref: "dt",
        filterDisplay: "row",
        globalFilterFields: ['name'],
        paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
        rows: 10,
        rowsPerPageOptions: [10,20,50],
        dataKey: "id",
        selectionMode: "single",
        loading: _ctx.loading,
        onRowSelect: _ctx.onStudySelect
      }, _createSlots({
        empty: _withCtx(() => [
          _createTextVNode(" No studies found. ")
        ]),
        loading: _withCtx(() => [
          _createTextVNode(" Loading studies... Please wait. ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: "Name",
            field: "studyName",
            sortable: true,
            filterMatchMode: "startsWith",
            ref: "studyName"
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                class: "p-column-filter",
                placeholder: "Search by study name"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_Column, {
            header: "Study Code",
            field: "studyCode",
            filterMatchMode: "startsWith",
            ref: "studyCode"
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                class: "p-column-filter",
                placeholder: "Search by study code"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_Column, {
            header: "Sample Type",
            field: "sampleType"
          }),
          _createVNode(_component_Column, {
            header: "Specimen",
            field: "specimen"
          }),
          _createVNode(_component_Column, {
            header: "Species",
            field: "disease"
          }),
          _createVNode(_component_Column, {
            header: "Assay Technology",
            field: "assayTechnology"
          }),
          _createVNode(_component_Column, {
            header: "Description",
            field: "description"
          }),
          _createVNode(_component_Column, {
            header: "Created",
            class: "created-column-date-only",
            field: "created",
            sortable: true
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatISOStringCustom(data.created)), 1)
            ]),
            _: 1
          }),
          (_ctx.$store.state.precedenceLevel < 5)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                header: ""
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Button, {
                      class: "rounded-btn larger-rounded-btn-mid",
                      label: "Study Batches",
                      disabled: data.batches.items.length === 0,
                      onClick: ($event: any) => {_ctx.$router.push(`/${_ctx.$route.params.organizationName}/${_ctx.$route.params.organizationId}/batchBrowse/study/${data.id}`);}
                    }, null, 8, ["disabled", "onClick"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.$store.state.precedenceLevel < 5)
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 1,
                header: ""
              }, {
                body: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_Button, {
                      class: "rounded-btn larger-rounded-btn-mid",
                      label: "Study Pipelines",
                      disabled: data.pipelines.items.length === 0,
                      onClick: ($event: any) => {_ctx.$router.push(`/${_ctx.$route.params.organizationName}/${_ctx.$route.params.organizationId}/pipelineBrowse/study/${data.id}`);}
                    }, null, 8, ["disabled", "onClick"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_Column, { class: "menu-column" }, {
            body: _withCtx(({data}) => [
              _createVNode(_component_Button, {
                icon: "pi pi-ellipsis-v",
                class: "text-btn",
                onClick: ($event: any) => (_ctx.studyActionClick(data, $event))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Menu, {
                ref: 'study-menu-' + data.id,
                model: _ctx.studyActionItems(data),
                popup: true
              }, null, 8, ["model"])
            ]),
            _: 1
          })
        ]),
        _: 2
      }, [
        (_ctx.$store.state.precedenceLevel <= 2)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Add Study",
                    icon: "pi pi-plus",
                    class: "p-button mb-2",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openStudyDialog()))
                  }),
                  _createVNode(_component_Button, {
                    type: "button",
                    label: "Explore",
                    icon: "pi pi-compass",
                    class: "p-button mb-2 ml-3",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.exploreOrg()))
                  })
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["value", "filters", "loading", "onRowSelect"])
    ]),
    _createVNode(_component_StudyDialog, {
      studyForUpdate: _ctx.studyForUpdate,
      onNewStudy: _ctx.addNewStudy,
      onUpdateStudy: _ctx.updateStudy
    }, null, 8, ["studyForUpdate", "onNewStudy", "onUpdateStudy"]),
    _createVNode(_component_LaunchPipelineDialog, {
      stepStart: _ctx.stepStart,
      studyId: _ctx.studyId
    }, null, 8, ["stepStart", "studyId"])
  ], 64))
}