import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f0122c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.experimentSpecificVariables, (variable, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            class: "add-btn mr-3",
            icon: "pi pi-plus",
            onClick: ($event: any) => (_ctx.addRow(index))
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            class: "remove-btn mr-3",
            icon: "pi pi-trash",
            onClick: ($event: any) => (_ctx.removeRow(index))
          }, null, 8, ["onClick"]),
          _createVNode(_component_InputText, {
            class: "name-field mr-2",
            modelValue: variable.name,
            "onUpdate:modelValue": ($event: any) => ((variable.name) = $event),
            modelModifiers: { trim: true },
            placeholder: "Name"
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_Dropdown, {
            class: "type-dd mr-2",
            modelValue: variable.type,
            "onUpdate:modelValue": ($event: any) => ((variable.type) = $event),
            options: _ctx.variableTypes,
            placeholder: "Type"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]),
          _createVNode(_component_InputText, {
            class: "description-field",
            modelValue: variable.description,
            "onUpdate:modelValue": ($event: any) => ((variable.description) = $event),
            modelModifiers: { trim: true },
            placeholder: "Description"
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ])
      ]))
    }), 128))
  ]))
}