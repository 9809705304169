
import { defineComponent } from 'vue';
import StudyPhaseTable from '@/components/StudyPhase/StudyPhaseTable.vue';
import { getHomeRouteForBreadcrumb } from '@/utils';
import { MenuItem } from '@/models/customModels';

export default defineComponent({
  name: 'Study Phases',
  components: {
    StudyPhaseTable,
  },
  data() {
    return {
      breadcrumbHome: { icon: 'pi pi-home', to: getHomeRouteForBreadcrumb(this.$route) },
      breadcrumbItems: [{ label: 'Studies', to: getHomeRouteForBreadcrumb(this.$route) }, { label: 'Study Phases', to: this.$route.fullPath }] as MenuItem[],
    };
  },
  methods: {
    updateBreadCrumbWithStudyName(studyName: string) {
      const bcItem: MenuItem = this.breadcrumbItems.at(-1) as MenuItem;
      bcItem.label = `Study: ${studyName} | Study Phases`;
    },
  },
});
