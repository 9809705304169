
import { defineComponent } from 'vue';
import PipelineParamsTable from '@/components/Pipeline/PipelineParamsTable.vue';

export default defineComponent({
  name: 'Pipeline Params Dialog',
  components: { PipelineParamsTable },
  props: ['params'],
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      activeOrgName: null as unknown as string | null,
      pipelineParams: {},
    };
  },
  methods: {
    async main() {
      this.loading = true;
      try {
        this.activeOrgName = this.$route.params.organizationName as string;
        this.pipelineParams = this.params;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    hideDialog() {
      this.loading = false;
      this.activeOrgName = null;
      this.pipelineParams = {};
      this.$store.dispatch('setShowingPipelineParamsDialog', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingPipelineParamsDialog': async function () {
      this.showing = this.$store.state.showingPipelineParamsDialog;
    },
    params() {
      if (this.params && Object.keys(this.params).length > 0) this.main();
    },
  },
});
