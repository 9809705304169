
import { defineComponent } from 'vue';
// eslint-disable-next-line no-unused-vars
import { API, graphqlOperation } from 'aws-amplify';
// eslint-disable-next-line no-unused-vars
import * as mutations from '@/graphql/mutations';
// eslint-disable-next-line no-unused-vars
import { sendNotificationEmail } from '@/utils';

export default defineComponent({
  data() {
    return {
      showing: false,
      organizationName: null as unknown as { key: string, name: string, id: string } | null,
      availableOrgs: [],
    };
  },
  methods: {
    parseAvailableOrgs(availableOrgs: string) {
      try {
        return JSON.parse(availableOrgs.replace(/\\/g, '')).values;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    hideDialog() {
      this.organizationName = null;
      this.$store.dispatch('setShowingAddCustomOrg', false);
      this.$emit('doneSettingCustomOrg');
      this.showing = false;
    },
    async save() {
      try {
        if (this.organizationName === null || this.organizationName === undefined) {
          this.$toast.add({
            severity: 'error',
            summary: 'Organization name error',
            detail: 'You select an organization.',
            life: 7000,
          });
          return;
        }
        const userVariables = {
          id: this.$store.state.newUserData.id,
          organization: this.organizationName.key,
          readGroups: [`ORG/${this.organizationName.id}/Admin`],
        };
        this.sendNewUserEmail(this.$store.state.newUserData, this.organizationName.id);
        API.graphql(graphqlOperation(mutations.updateUser, { input: userVariables }));
        this.hideDialog();
      } catch (error) {
        console.error('Error in adding new users organization name');
        console.error(error);
      }
    },
    async sendNewUserEmail(userData: { id: string, email: string }, organizationId: string) {
      try {
        sendNotificationEmail(`A new user has signed in. User's email: '${userData.email}'`, ['filip.gunic@m2m.bio'], true, organizationId);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.availableOrgs = this.parseAvailableOrgs(process.env.VUE_APP_AVAILABLE_CUSTOM_ORGS as string);
    if (this.$store.state.showingAddCustomOrg !== false) {
      this.showing = true;
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingAddCustomOrg': async function () {
      this.showing = this.$store.state.showingAddCustomOrg;
    },
  },
});
