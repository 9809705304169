<template>
  <div class="app-container">
    <div class="inner-wrapper">
      <div class="no-grp-view flex justify-content-center align-items-center">
        <div class="container no-grp-text">
          <h1>404!</h1>
          <h3>You are seeing this because you have entered an invalid route. This page does not exist!</h3>
        </div>
      </div>
    </div>
  </div>
  </template>

  <style lang="scss">
//   .welcome {
//     color: white;
//   }

//   .windows {
//     background-color: white;
//   }

  .app-container{
    // padding-top: 50%;
    background: url('~@/assets/footer_and_login.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    background-size: 100% 100%;
    height: 100vh;
  }

  .inner-wrapper {
    padding-top: 10%;
    padding-right: 10%;
    padding-left: 10%;
  }

  .no-grp-view{
    background-size: cover;
    position: relative;
    height: 50vh;
    background-color: rgb(29, 29, 29, 0.9);
    border-radius: 25px;
  }

  .no-grp-text {
    text-align: center;
  }
  </style>
