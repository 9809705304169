
import { EntityType, Study } from '@/models';
import { listItems, formatISOStringCustom } from '@/utils';
import { defineComponent } from 'vue';
import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
import type { PropType } from 'vue';
import StudyDialog from '@/components/Study/StudyDialog.vue';
import { API, graphqlOperation } from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLResult } from '@aws-amplify/api';
import { OrganizationTemplate } from '@/models/customModels';
import LaunchPipelineDialog from '../Pipeline/LaunchPipelineDialog.vue';

export default defineComponent({
  props: {
    parsedStudies: { type: Array as PropType<Study[]>, required: false },
    loadingStudies: { type: Boolean, required: false },
  },
  components: {
    StudyDialog,
    LaunchPipelineDialog,
  },
  data() {
    return {
      studies: [] as Study[],
      loading: false,
      studyForUpdate: null as unknown as Study,
      filters: {
        studyName: { value: '', matchMode: 'contains' },
        studyCode: { value: '', matchMode: 'contains' },
      },
      items: [
        {
          label: 'temp',
        },
      ],
      stepStart: 1,
      studyId: '' as string,
    };
  },
  mounted() {
    //
    // Git comment
    if (this.parsedStudies) {
      this.studies = this.parsedStudies;
      this.$watch(() => this.parsedStudies, (value: Study[] | undefined) => {
        console.log('Change');
        if (value) {
          this.studies = value;
        } else {
          this.studies = [];
        }
      });
    } else {
      this.loadStudiesForOrg();
    }
  },
  methods: {
    async loadStudiesForOrg(organizationId?: string): Promise<void> {
      this.loading = true;
      try {
        let studies: Study[];
        if (organizationId) {
          studies = await listItems(customQueries.studiesByOrganizationForStudyTable, { organizationId, sortDirection: 'DESC' });
        } else {
          const activeOrganization = await this.$store.state.activeOrganization;
          if (activeOrganization) {
            studies = await listItems(customQueries.studiesByOrganizationForStudyTable, { organizationId: activeOrganization.id, sortDirection: 'DESC' });
          } else throw new Error('No active organization in loadStudiesForOrg');
        }
        console.log('studies :>> ', studies);
        this.studies = studies;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    studyActionClick(study: Study, event: MouseEvent) {
      (this.$refs[`study-menu-${study.id}`] as any).toggle(event);
    },
    studyActionItems(study: Study) {
      const explore = {
        label: 'Explore',
        command: () => {
          this.exploreStudy(`${study.organizationId}!${study.id}`);
        },
      };
      const update = {
        label: 'Update',
        command: () => {
          this.studyForUpdate = study;
          this.$store.dispatch('setShowingStudyDialog', true);
        },
      };
      const details = {
        label: 'Details',
        command: () => {
          this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/entytyDetailTabs/${EntityType.STUDY}/${study.id}`);
        },
      };
      const options = [details];
      if (this.$store.state.precedenceLevel <= 3) options.push(explore);
      if (this.$store.state.precedenceLevel <= 2) options.push(update);
      return options;
    },
    // eslint-disable-next-line no-unused-vars
    addNewStudy(study: Study): void {
      try {
        // if (study) this.studies.unshift(study);
        this.loadStudiesForOrg(this.$route.params.organizationId as string);
      } catch (error) {
        console.error(error);
      }
    },
    updateStudy(study: Study): void {
      try {
        if (study) {
          const studyToUpdateIndex: number | null | undefined = this.studies.findIndex((s) => s.id === study.id);
          if (studyToUpdateIndex !== -1) {
            let newStudyObject = this.studies[studyToUpdateIndex];
            newStudyObject = study;
            this.studies[studyToUpdateIndex] = newStudyObject;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async deleteStudy(study: Study): Promise<void> {
      try {
        this.studies = this.studies.filter((s) => s.id !== study.id);
        console.log('this.studies :>> ', this.studies);
        API.graphql(graphqlOperation(mutations.deleteStudy, { input: { id: study.id } }));
        const organization = (await API.graphql(graphqlOperation(customQueries.getOrganizationGroups, { id: study.organizationId })) as GraphQLResult<any>).data.getOrganization;
        let orgReadGroups = (organization.readGroups) ? organization.readGroups : [];
        orgReadGroups = orgReadGroups.filter((grp: string) => grp !== `S/${study.id}/User`);
        let orgWriteGroups = (organization.writeGroups) ? organization.writeGroups : [];
        orgWriteGroups = orgReadGroups.filter((grp: string) => grp !== `S/${study.id}/Admin`);
        const organizationUpdateObj: OrganizationTemplate = {
          id: organization.id,
          readGroups: orgReadGroups,
          writeGroups: orgWriteGroups,
        };
        API.graphql(graphqlOperation(mutations.updateOrganization, { input: organizationUpdateObj }));
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Study deleted successfuly!', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to delete study!', life: 3000,
        });
      }
    },
    async exploreOrg(): Promise<void> {
      try {
        const orgId = (await this.$store.state.activeOrganization).id;
        this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/explore/${orgId}`);
      } catch (error) {
        console.error(error);
      }
    },
    async exploreStudy(id: string): Promise<void> {
      try {
        this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/explore/${id}`);
      } catch (error) {
        console.error(error);
      }
    },
    onStudySelect(event: any) {
      if (event.data) {
        this.$router.push(`/${this.$route.params.organizationName}/${this.$route.params.organizationId}/studyPhaseBrowse/${event.data.id}`);
      }
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
    openStudyDialog() {
      this.$store.dispatch('setShowingStudyDialog', true);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.activeOrganization': async function () {
      const activeOrganization = await this.$store.state.activeOrganization;
      if (activeOrganization) this.loadStudiesForOrg(activeOrganization.id);
    },
  },
});
