
// eslint-disable-next-line no-unused-vars
import { Auth } from 'aws-amplify';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Admin',
  data() {
    return {
      activeIndex: 0,
      tabMenuItems: [
        {
          label: 'Organizations',
          to: 'organizations',
        },
        // {
        //   label: 'Launchable Pipelines',
        //   to: 'launchablePipelineActions',
        // },
      ],
    };
  },
  async mounted() {
    if (this.$store.state.precedenceLevel <= 3) {
      this.tabMenuItems.push({
        label: 'Users',
        to: 'users',
      });
    }
    // Commented out becouse we will need to add a launchable pipeline creation dialog. Probably here
    // const groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
    // if (groups.some((grp) => grp === 'M2MAdmin')) {
    //   this.tabMenuItems.push({
    //     label: 'Admin Functions',
    //     to: 'adminFunctions',
    //   });
    // }
  },
  methods: {
  },
});
