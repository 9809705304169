import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f09c386"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "studies-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.studyPhases,
      paginator: true,
      responsiveLayout: "scroll",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
      ref: "dt",
      filterDisplay: "row",
      globalFilterFields: ['name'],
      paginatorTemplate: "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
      currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
      rows: 10,
      rowsPerPageOptions: [10,20,50],
      dataKey: "id",
      selectionMode: "single",
      loading: _ctx.loading,
      onRowSelect: _ctx.onStudyPhaseSelect
    }, {
      empty: _withCtx(() => [
        _createTextVNode(" No study phases found. ")
      ]),
      loading: _withCtx(() => [
        _createTextVNode(" Loading study phases... Please wait. ")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          header: "Name",
          field: "studyPhaseName",
          sortable: true,
          filterMatchMode: "startsWith",
          ref: "studyPhaseName",
          style: {"width":"12rem"}
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
              class: "p-column-filter",
              placeholder: "Search by study phase name",
              style: {"min-width":"12rem"}
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          _: 1
        }, 512),
        _createVNode(_component_Column, {
          field: "phase",
          header: "Phase",
          showFilterMenu: false,
          style: {"width":"11rem"}
        }, {
          filter: _withCtx(({ filterModel, filterCallback }) => [
            _createVNode(_component_Dropdown, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
              onChange: ($event: any) => (filterCallback()),
              options: _ctx.phaseDDOptions,
              placeholder: "Select a Phase",
              class: "p-column-filter",
              style: {"min-width":"11rem"},
              showClear: (filterModel.value) ? true : false
            }, {
              option: _withCtx((slotProps) => [
                _createTextVNode(_toDisplayString(_ctx.formatPhaseName(slotProps.option)), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "onChange", "options", "showClear"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, _toDisplayString(_ctx.formatPhaseName(data.phase)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Group Names",
          field: "groupNames"
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, _toDisplayString(_ctx.formatListForView(data.groupNames)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Description",
          field: "description"
        }),
        _createVNode(_component_Column, {
          header: "Created",
          class: "created-column-date-only",
          field: "created",
          sortable: true
        }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, _toDisplayString(_ctx.formatISOStringCustom(data.created)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "filters", "loading", "onRowSelect"])
  ]))
}