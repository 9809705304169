import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2689ea98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "vstack mb-3" }
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        class: "lbl-check",
        for: _ctx.index
      }, _toDisplayString(_ctx.name), 9, _hoisted_3),
      _withDirectives(_createVNode(_component_InputSwitch, {
        class: "check",
        id: _ctx.index,
        modelValue: _ctx.localValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', _ctx.localValue))),
        binary: ""
      }, null, 8, ["id", "modelValue"]), [
        [_directive_tooltip, _ctx.description]
      ])
    ])
  ]))
}