
import { Auth } from 'aws-amplify';
import { wait } from '@/utils';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TimedLogoutComponent',
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress'],
      warningTimer: null as any,
      logoutTimer: null as any,
    };
  },
  mounted() {
    console.log('TimedLogoutComponent mounted');
    this.events.forEach((event: string) => window.addEventListener(event, () => this.resetTimers()));
    this.setTimers();
  },
  unmounted() {
    this.events.forEach((event: string) => window.removeEventListener(event, () => this.resetTimers()));
    this.clearTimers();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(() => this.warnUser(), (1000 * 60 * 60 * 2) - 10000);
      this.logoutTimer = setTimeout(() => this.signOut(), 1000 * 60 * 60 * 2); // 2h
    },
    clearTimers() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.checkTimeSinceActive();
    },
    checkTimeSinceActive() {
      const timeSinceActive = parseInt(localStorage.getItem('timeSinceActive') as string, 10);
      const now = Date.now();
      if (now - timeSinceActive > 1000 * 60 * 60 * 2) {
        Auth.signOut().then(() => {
          this.$router.push('/auth/login');
        }).catch((e: any) => {
          console.log('error signing out: ', e);
        });
      } else {
        localStorage.setItem('timeSinceActive', Date.now().toString());
      }
    },
    resetTimers() {
      this.clearTimers();
      this.setTimers();
    },
    async warnUser() {
      this.$confirm.require({
        message: 'Session will expire soon if no action is performed!',
        header: 'Are you there?',
        icon: 'pi pi-info-circle',
        accept: async () => {
          this.resetTimers();
        },
      });
      await wait(100);
      const btn: Element = document.getElementsByClassName('p-confirm-dialog-reject')[0];
      if (btn) (btn as HTMLLIElement).style.display = 'none';
    },
    signOut() {
      Auth.signOut().then(() => {
        this.$router.push('/auth/login');
      }).catch((e: any) => {
        console.log('error signing out: ', e);
      });
      document.body.classList.value = '';
    },
  },
  watch: {
  },
});
