
import {
  CognitoGroupsObjct, User, DBUser, UserAttribute, StudyPhaseTemplate, StudyTemplate,
} from '@/models/customModels';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { defineComponent } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { formatISOStringCustom, listItems, formatDateToHumanReadableOnlyDate } from '@/utils';
import ChangeGroupDialog from '@/components/Users/ChangeGroupDialog.vue';
import * as customQueries from '@/graphql/customQueries';
import * as queries from '@/graphql/queries';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';

export default defineComponent({
  name: 'Users',
  components: {
    ChangeGroupDialog,
  },
  data() {
    return {
      loading: true,
      exportingUsersTable: false,
      users: [] as User[],
      usernameForChangeGroup: '' as string,
      emailForChangeGroup: '' as string,
      filters: {
        email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
    };
  },
  mounted() {
    switch (this.$store.state.precedenceLevel) {
      case 1:
        this.loadAllUsers();
        break;
      case 2:
        this.loadUsersForSpecificGroups('ORG');
        break;
      case 3:
        this.loadUsersForSpecificGroups('S');
        break;
      default:
        console.error('Wrong precedence level');
        break;
    }
  },
  methods: {
    // Expect that the current user is M2MAdmin
    async loadAllUsers() {
      this.loading = true;
      // const test = await this.listGroupsCall();
      // console.log('test :>> ', test);
      try {
        const usersCallRes: any = await this.loadUsersCall();
        let nextToken: string = usersCallRes?.nextTokenFromApi;
        const users: User[] = usersCallRes?.users;
        while (nextToken) {
          const usersCallResNext: any = await this.loadUsersCall(nextToken);
          nextToken = usersCallResNext?.nextTokenFromApi;
          users.push(...usersCallResNext.users);
        }
        this.users = this.formatUsersForTable(users);
        console.log('this.users :>> ', this.users);
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async loadUsersCall(nextToken?: string): Promise<object> {
      try {
        const apiName = 'AdminQueries';
        const path = '/listUsers';
        const myInit: any = {
          queryStringParameters: {
            limit: 60, // Max limit is 60
            ...nextToken ? { token: nextToken } : {}, // Conditional property setting
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        const res = await API.get(apiName, path, myInit);
        const nextTokenFromApi: string | null | undefined = res.NextToken;
        const users: User[] = res.Users;
        return { users, nextTokenFromApi };
      } catch (error) {
        console.error('LoadUsersCall failed');
        console.error(error);
        return [];
      }
    },
    formatUsersForTable(users: User[]): User[] {
      try {
        const formatedUsers = users.map((user) => {
          const sameUser: User = user;
          // eslint-disable-next-line no-return-assign
          sameUser.Attributes.map((attribute: UserAttribute) => sameUser[attribute.Name as keyof User] = attribute.Value);
          return sameUser;
        });
        return formatedUsers;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async loadUsersForSpecificGroups(type: string) {
      try {
        const groups: string[] = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.payload['cognito:groups'];
        const allOrgUsersFromDBPromise: Promise<DBUser[]> = this.getAllUsersByOrgFromDB(this.$route.params.organizationName as string);
        let users: User[] = [];
        if (type === 'ORG') {
          console.log('ORG');
          const organizationUserPromises: Promise<User[]>[] = [];
          const studyUserPromises: Promise<{ users: User[]; studies: StudyTemplate[]; }>[] = [];
          const studyPhaseUserPromises: Promise<User[]>[] = [];
          groups.forEach((grp: string) => {
            if (grp) {
              if (grp.startsWith('ORG/')) {
                organizationUserPromises.push(this.listUsersInGroup(grp));
                const orgId = grp.split('/').at(1);
                if (orgId) {
                  studyUserPromises.push(this.getUsersFromStudiesByOrganization(orgId));
                }
              }
            }
          });
          // const users: User[] = (await Promise.all(organizationUserPromises)).flat();
          const studyUserObjects: { users: User[]; studies: StudyTemplate[];}[] = (await Promise.all(studyUserPromises));
          const studyUsers: User[] = [];
          studyUserObjects.forEach((studyUserObject) => {
            if (studyUserObject.users) studyUsers.push(...studyUserObject.users);
            if (studyUserObject.studies) {
              studyUserObject.studies.forEach((study) => {
                if (study.id) studyPhaseUserPromises.push(this.getUsersFromStudyPhasesByStudy(study.id));
              });
            }
          });
          const studyPhaseUsers: User[] = (await Promise.all(studyPhaseUserPromises)).flat();
          users.push(...(await Promise.all(organizationUserPromises)).flat());
          users.push(...studyUsers);
          users.push(...studyPhaseUsers);
          users = await this.filterUsersFromDB(allOrgUsersFromDBPromise, users);
        } else if (type === 'S') {
          const studyUserPromises: Promise<User[]>[] = [];
          const studyPhaseUserPromises: Promise<User[]>[] = [];
          groups.forEach((grp: string) => {
            if (grp) {
              if (grp.startsWith('S/')) {
                studyUserPromises.push(this.listUsersInGroup(grp));
                const studyId = grp.split('/').at(1);
                if (studyId) studyPhaseUserPromises.push(this.getUsersFromStudyPhasesByStudy(studyId));
              }
            }
          });
          const studyUsers = (await Promise.all(studyUserPromises)).flat();
          const studyPhaseUsers = (await Promise.all(studyPhaseUserPromises)).flat();
          users.push(...studyUsers);
          users.push(...studyPhaseUsers);
        } else {
          console.error('Wrong type of loadUsersForSpecificGroups');
          return;
        }
        const uniqueUsers = users.filter(
          (obj, index) => users.findIndex((item) => item.Username === obj.Username) === index,
        );
        this.users = this.formatUsersForTable(uniqueUsers);
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.users = [];
        this.loading = false;
      }
    },
    async getAllUsersByOrgFromDB(orgName: string): Promise<DBUser[]> {
      const usersFromDB: DBUser[] = await listItems(queries.usersByOrganization, { organization: orgName });
      return usersFromDB;
    },
    // It is expected that the getAllUsersByOrgFromDB would be complete by the time filterUsersFromDB is called
    async filterUsersFromDB(allOrgUsersFromDBPromise: Promise<DBUser[]>, users: User[]): Promise<User[]> {
      const allOrgUsersFromDB = await allOrgUsersFromDBPromise;
      for (let i = 0; i < allOrgUsersFromDB.length; i += 1) {
        const userFromDB: DBUser = allOrgUsersFromDB[i];
        // eslint-disable-next-line no-continue
        if (users.some((usr) => usr.Username === userFromDB.id)) continue;
        users.push({
          Username: userFromDB.id,
          email: userFromDB.email,
          UserCreateDate: userFromDB.created,
          Enabled: true,
          Attributes: [
            {
              Name: 'email',
              Value: userFromDB.email,
            },
          ],
        });
      }
      return users;
    },
    async listUsersInGroup(groupName: string) {
      const firstRes: { users: User[]; NextToken: string | null; } = await this.listUsersInGroupCall(groupName);
      const users: User[] = firstRes.users;
      let nextToken = firstRes.NextToken;
      while (nextToken) {
        const paginatedRes: { users: User[]; NextToken: string | null; } = await this.listUsersInGroupCall(groupName, nextToken);
        users.push(...paginatedRes.users);
        nextToken = paginatedRes.NextToken;
      }
      return users;
    },
    async listUsersInGroupCall(groupName: string, nextToken?: string): Promise<{ users: User[]; NextToken: string | null; }> {
      const apiName = 'AdminQueries';
      const path = '/listUsersInGroup';
      const myInit = {
        queryStringParameters: {
          groupname: groupName,
          limit: 60,
          ...nextToken ? { token: nextToken } : {},
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      const { NextToken, ...rest } = await API.get(apiName, path, myInit);
      const users = rest;
      const res = {
        users: users.Users,
        NextToken,
      };
      return res;
    },
    async getUsersFromStudyPhasesByStudy(studyId: string): Promise<User[]> {
      try {
        const userPromises: Promise<User[]>[] = [];
        const studyPhases = await listItems(customQueries.studyPhasesByStudyForGroupSelection, { studyId });
        studyPhases.forEach((sp) => {
          userPromises.push(this.listUsersInGroup(`SP/${(sp as StudyPhaseTemplate).id}/Admin`));
          userPromises.push(this.listUsersInGroup(`SP/${(sp as StudyPhaseTemplate).id}/User`));
        });
        const users = (await Promise.all(userPromises)).flat();
        return users;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getUsersFromStudiesByOrganization(organizationId: string): Promise<{ users: User[]; studies: StudyTemplate[]; }> {
      try {
        const userPromises: Promise<User[]>[] = [];
        const studies: StudyTemplate[] = await listItems(customQueries.studiesByOrganizationForGroupSelection, { organizationId });
        studies.forEach((s) => {
          userPromises.push(this.listUsersInGroup(`S/${(s as StudyTemplate).id}/Admin`));
          userPromises.push(this.listUsersInGroup(`S/${(s as StudyTemplate).id}/User`));
        });
        const users = (await Promise.all(userPromises)).flat();
        return { users, studies };
      } catch (error) {
        console.error(error);
        return {
          users: [],
          studies: [],
        };
      }
    },
    async exportCSV() {
      this.exportingUsersTable = true;
      try {
        const listUserGroupsPromises = [];
        for (let i = 0; i < this.users.length; i += 1) {
          const user = this.users[i];
          listUserGroupsPromises.push(this.listGroupsForUser(user.Username));
        }
        const userGroups: object[] = await Promise.all(listUserGroupsPromises);
        const remappedUsersPromises = [];
        for (let i = 0; i < this.users.length; i += 1) {
          const user = this.users[i];
          const userGroupsObj = userGroups[i];
          remappedUsersPromises.push(this.remapAllGroups(user, userGroupsObj));
        }
        const remappedUsers = await Promise.all(remappedUsersPromises);
        this.users = remappedUsers;
        (this.$refs as any).dt.exportCSV();
        this.exportingUsersTable = false;
      } catch (error) {
        console.error(error);
        this.exportingUsersTable = false;
      }
    },
    async listGroupsForUser(userName: string): Promise<object> {
      try {
        const listGroupsForUserCallRes: any = await this.listGroupsForUserCall(userName);
        let nextToken: string = listGroupsForUserCallRes?.nextTokenFromApi;
        const groups: CognitoGroupsObjct[] = listGroupsForUserCallRes?.groups;
        while (nextToken) {
          const listGroupsForUserCallResNext: any = await this.listGroupsForUserCall(userName, nextToken);
          nextToken = listGroupsForUserCallResNext?.nextTokenFromApi;
          groups.push(...listGroupsForUserCallResNext.groups);
        }
        const groupStrings: string[] = groups.map((grp) => grp?.GroupName);
        return {
          userName,
          groupStrings,
        };
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async listGroupsForUserCall(userName: string, nextToken?: string): Promise<object> {
      try {
        const apiName = 'AdminQueries';
        const path = '/listGroupsForUser';
        const myInit: any = {
          queryStringParameters: {
            limit: 60, // Max limit is 60
            username: userName,
            ...nextToken ? { token: nextToken } : {}, // Conditional property setting
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
        };
        const res = await API.get(apiName, path, myInit);
        const nextTokenFromApi: string | null | undefined = res.NextToken;
        const groups: string[] = res.Groups;
        return { groups, nextTokenFromApi };
      } catch (error) {
        console.error('LoadUsersCall failed');
        console.error(error);
        return [];
      }
    },
    async remapAllGroups(user: User, userGroupsObj: object): Promise<User> {
      try {
        const passedUser = user;
        const userGroupsRemappedPromises = (userGroupsObj as any).groupStrings.map(async (groups: any) => this.remapGroupName(groups));
        const userGroupsRemapped = await Promise.all(userGroupsRemappedPromises);
        const userGroupsFilters = userGroupsRemapped.filter((grp) => grp !== '');
        if (user.Username === (userGroupsObj as any).userName) passedUser.groups = userGroupsFilters;
        return passedUser;
      } catch (error) {
        console.error(error);
        return user;
      }
    },
    async remapGroupName(groupName: string): Promise<string> {
      try {
        const entity = groupName.split('/')[0];
        switch (entity) {
          case 'ORG': {
            const orgEntity: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getOrganizationBasic, { id: groupName.split('/')[1] }));
            return `${orgEntity.data.getOrganization.organizationName} - Admin`;
          }
          case 'S': {
            const studyEntity: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getStudyBasic, { id: groupName.split('/')[1] }));
            return `${studyEntity.data.getStudy.studyName} - ${groupName.split('/')[2]}`;
          }
          case 'SP': {
            const studyPhaseEntity: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getStudyPhaseBasic, { id: groupName.split('/')[1] }));
            return `${studyPhaseEntity.data.getStudyPhase.studyPhaseName} - ${groupName.split('/')[2]}`;
          }
          case 'CRO': {
            const studyPhaseEntity: GraphQLResult<any> = await API.graphql(graphqlOperation(customQueries.getStudyPhaseBasic, { id: groupName.split('/')[1] }));
            return `${studyPhaseEntity.data.getStudyPhase.studyPhaseName} - CRO`;
          }
          case 'M2MAdmin': {
            return 'M2MAdmin';
          }
          default: {
            console.error(`Unhandled groupName: ${groupName}`);
            return groupName;
          }
        }
      } catch (error) {
        console.error(error);
        return '';
      }
    },
    getExportFilename() {
      return `exported_users_${formatDateToHumanReadableOnlyDate(new Date())}`;
    },
    // eslint-disable-next-line no-unused-vars
    userActionClick(user: User, event: any) {
      (this.$refs[`user-menu-${user.Username}`] as any).toggle(event);
    },
    userActionItems(user: User) {
      const changeGroup = {
        label: 'Change Group',
        command: () => {
          // this.showChangeGroupDialog = !this.showChangeGroupDialog;
          this.usernameForChangeGroup = user.Username;
          this.emailForChangeGroup = user.email as string;
          this.$store.dispatch('setShowingChangeGroup', true);
        },
      };
      const enable = {
        label: 'Enable',
        command: async () => {
          try {
            await this.enableUser(user);
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: `User ${user.email} Enabled!`,
              life: 3000,
            });
            this.updateUserInTable(user.Username, 'Enabled', true);
          } catch (error) {
            console.error(error);
          }
        },
      };
      const disable = {
        label: 'Disable',
        command: async () => {
          try {
            await this.disableUser(user);
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: `User ${user.email} Disabled!`,
              life: 3000,
            });
            this.updateUserInTable(user.Username, 'Enabled', false);
          } catch (error) {
            console.error(error);
          }
        },
      };
      if (user.Enabled) {
        return [changeGroup, disable];
      }
      return [changeGroup, enable];
    },
    async enableUser(user: User): Promise<void> {
      const apiName = 'AdminQueries';
      const path = '/enableUser';
      const myInit = {
        body: {
          username: `${user.Username}`,

        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      API.post(apiName, path, myInit);
    },
    async disableUser(user: User): Promise<void> {
      const apiName = 'AdminQueries';
      const path = '/disableUser';
      const myInit = {
        body: {
          username: `${user.Username}`,

        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
      };
      API.post(apiName, path, myInit);
    },
    updateUserInTable(username: String, attributeToUpdate: string, newValue: any): void {
      try {
        const userToUpdateIndex: number | null | undefined = this.users.findIndex((u) => u.Username === username);
        if (userToUpdateIndex) {
          const newUserObject = this.users[userToUpdateIndex];
          newUserObject[attributeToUpdate] = newValue;
          this.users[userToUpdateIndex] = newUserObject;
        }
      } catch (error) {
        console.error(error);
      }
    },
    formatISOStringCustom(date: string) {
      return formatISOStringCustom(date);
    },
  },
});
