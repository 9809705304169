
// import { listItems } from '@/utils';
// import { Study } from '@/models';
import {
  validateInputName, getOrgNameForClass, getQuestions, makeEntityQAObj, updateDynamoDBDirectly,
} from '@/utils';
import { API, graphqlOperation } from 'aws-amplify';
import { defineComponent } from 'vue';
// import * as customQueries from '@/graphql/customQueries';
import * as mutations from '@/graphql/mutations';
import { EntityQA, EntityQAObject, StudyTemplate } from '@/models/customModels';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { GraphQLResult } from '@aws-amplify/api';
import { EntityType, Study } from '@/models';
import DynamicQuestionsRenderer from '@/components/Utils/DynamicQuestions/DynamicQuestionsRenderer.vue';

export default defineComponent({
  name: 'Users',
  components: { DynamicQuestionsRenderer },
  emits: {
    newStudy: (study: Study) => {
      if (study) {
        return true;
      }
      console.error('Invalid newStudy event payload!');
      return false;
    },
    updateStudy: (study: Study) => {
      if (study) {
        return true;
      }
      console.error('Invalid updateStudy event payload!');
      return false;
    },
  },
  props: {
    studyForUpdate: { type: Study, required: false },
  },
  data() {
    return {
      loading: false as boolean,
      showing: false as boolean,
      studyName: null as unknown as string | null,
      studyCode: null as unknown as string | null,
      description: null as unknown as string | undefined,
      activeOrgName: '',
      questions: {} as EntityQAObject | null,
      questionData: {} as { [key: string] : string },
    };
  },
  async beforeMount() {
    await this.main();
  },
  methods: {
    async main() {
      this.loading = true;
      try {
        [this.activeOrgName, this.questions] = await Promise.all([getOrgNameForClass(), getQuestions(EntityType.STUDY)]);
        if (!this.studyForUpdate) {
          this.loading = false;
          return;
        }
        if (this.studyForUpdate.studyName) this.studyName = this.studyForUpdate.studyName as string;
        if (this.studyForUpdate.studyCode) this.studyCode = this.studyForUpdate.studyCode as string;
        if (this.studyForUpdate.description) this.description = this.studyForUpdate.description as string;
        if (this.studyForUpdate.studyAnswers) {
          JSON.parse(this.studyForUpdate.studyAnswers).questions.forEach((answerObj: EntityQA) => {
            if (answerObj.answer && answerObj.answer !== 'NaN' && answerObj.answer !== 'undefined') this.questionData[answerObj.question] = answerObj.answer as string;
          });
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    async createStudy() {
      this.loading = true;
      try {
        if (!this.studyName) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Study Name cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.studyCode) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Study Code cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.description) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Description cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.validateStudyName() || !this.validateStudyCode) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Validation error!', life: 3000,
          });
          this.loading = false;
          return;
        }
        const activeOrganization = await this.$store.state.activeOrganization;
        const newStudyTemplate: StudyTemplate = {
          studyName: this.studyName,
          organizationStudiesId: activeOrganization.id,
          organizationId: activeOrganization.id,
          studyCode: this.studyCode!,
          description: this.description,
          adminGroups: [`ORG/${activeOrganization!.id}/Admin`],
        };
        if (this.questions) {
          newStudyTemplate.studyAnswers = JSON.stringify(makeEntityQAObj(this.questions, this.questionData));
        }
        const newStudyObject: GraphQLResult<any> = await API.graphql(graphqlOperation(mutations.createStudy, { input: newStudyTemplate }));
        const newStudyId = newStudyObject.data.createStudy.id;
        const writeGroups: string[] = [`S/${newStudyId}/Admin`]; // Change if we want to allow study admins to delete studies in the future
        const readGroups: string[] = [];
        const croGroups: string[] = [];
        const updateStudyObj = {
          id: newStudyId,
          readGroups,
          writeGroups,
          croGroups,
        };
        API.graphql(graphqlOperation(mutations.updateStudy, { input: updateStudyObj }));
        this.updateOrganizationGroups(newStudyObject.data.createStudy.organizationId, newStudyId);
        this.$emit('newStudy', newStudyObject.data.createStudy as Study);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Study created successfuly!', life: 3000,
        });
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to create study!', life: 3000,
        });
      }
      this.hideDialog();
    },
    async updateOrganizationGroups(organizationId: string, studyId: string): Promise<void> {
      updateDynamoDBDirectly(organizationId, 'Organization', [{ field: 'readGroups', values: [`S/${studyId}/Admin`] }]);
    },
    async updateStudy() {
      console.log('Updateing org');
      this.loading = true;
      try {
        if (!this.studyName) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Study Name cannot be empty!', life: 3000,
          });
          this.loading = false;
          return;
        }
        if (!this.validateStudyName()) {
          this.$toast.add({
            severity: 'error', summary: 'Error', detail: 'Validation error!', life: 3000,
          });
          this.loading = false;
          return;
        }
        const updateStudytemplate: StudyTemplate = {};
        updateStudytemplate.id = this.studyForUpdate?.id;
        if (this.questions) {
          updateStudytemplate.studyAnswers = JSON.stringify(makeEntityQAObj(this.questions, this.questionData));
        }
        if (this.studyName && this.studyName !== this.studyForUpdate!.studyName) {
          updateStudytemplate.studyName = this.studyName;
        }
        if (this.studyCode && this.studyCode !== this.studyForUpdate!.studyCode) {
          updateStudytemplate.studyCode = this.studyCode;
        }
        if (this.description !== this.studyForUpdate!.description) {
          updateStudytemplate.description = this.description;
        }
        const updatedStudy: GraphQLResult<any> = await API.graphql(graphqlOperation(mutations.updateStudy, { input: updateStudytemplate }));
        this.$emit('updateStudy', updatedStudy.data.updateStudy);
        this.$toast.add({
          severity: 'success', summary: 'Success', detail: 'Study updated successfuly!', life: 3000,
        });
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$toast.add({
          severity: 'error', summary: 'Error', detail: 'Failed to update study!', life: 3000,
        });
      }
      this.hideDialog();
    },
    updateQuestionData({ id, value }: {id: string, value: any}) {
      this.questionData[id as keyof Object] = value;
    },
    validateStudyName() {
      return validateInputName(this.studyName! as string);
    },
    validateStudyCode() {
      return validateInputName(this.studyCode! as string);
    },
    async getOrgNameForClassLocal() {
      this.activeOrgName = await getOrgNameForClass();
    },
    hideDialog() {
      this.studyName = null;
      this.studyCode = null;
      this.description = undefined;
      this.loading = false;
      this.$store.dispatch('setShowingStudyDialog', false);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.showingStudyDialog': async function () {
      this.showing = this.$store.state.showingStudyDialog;
      if (this.showing) this.main();
    },
  },
});
