import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CreateOrganizationDialog = _resolveComponent("CreateOrganizationDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_DataTable, {
        value: _ctx.organizations,
        loading: _ctx.loading,
        paginator: true,
        rows: 10,
        dataKey: "id",
        rowHover: true,
        filters: _ctx.filters,
        "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
        ref: "dt",
        filterDisplay: "row",
        globalFilterFields: ['organizationName'],
        responsiveLayout: "scroll",
        paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
        rowsPerPageOptions: [10,20,50]
      }, {
        empty: _withCtx(() => [
          _createTextVNode(" No organizations found. ")
        ]),
        loading: _withCtx(() => [
          _createTextVNode(" Loading organizations... Please wait. ")
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "organizationName",
            header: "Name",
            filterMatchMode: "startsWith",
            ref: "organizationName",
            sortable: true,
            style: {"width":"16rem"}
          }, {
            filter: _withCtx(({filterModel,filterCallback}) => [
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: filterModel.value,
                "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                onKeydown: _withKeys(($event: any) => (filterCallback()), ["enter"]),
                class: "p-column-filter",
                placeholder: "Search by Organization name"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
            ]),
            _: 1
          }, 512),
          _createVNode(_component_Column, {
            header: "Notification Email",
            field: "notificationEmail",
            style: {"width":"12rem"}
          }),
          _createVNode(_component_Column, {
            header: "Description",
            field: "description",
            style: {"width":"30rem"}
          }),
          _createVNode(_component_Column, {
            header: "Website URL",
            field: "url",
            style: {"width":"14rem"}
          }),
          _createVNode(_component_Column, {
            header: "Status",
            style: {"width":"8rem"}
          }, {
            body: _withCtx(({data}) => [
              _createElementVNode("span", {
                class: _normalizeClass(`customer-badge status-${data.active ? 'active' : 'inactive'}`)
              }, _toDisplayString(data.active ? 'Active' : 'Inactive'), 3)
            ]),
            _: 1
          }),
          _createVNode(_component_Column, { class: "menu-column" }, {
            body: _withCtx(({data}) => [
              _createVNode(_component_Button, {
                icon: "pi pi-ellipsis-v",
                class: "text-btn",
                onClick: ($event: any) => (_ctx.organizationActionClick(data, $event))
              }, null, 8, ["onClick"]),
              _createVNode(_component_Menu, {
                ref: 'study-menu-' + data.id,
                model: _ctx.organizationActionItems(data),
                popup: true
              }, null, 8, ["model"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "loading", "filters"])
    ]),
    _createVNode(_component_CreateOrganizationDialog, {
      onNewOrganization: _ctx.addNewOrganization,
      onUpdateOrganization: _ctx.updateOrganization,
      organizationForUpdate: _ctx.organizationForUpdate
    }, null, 8, ["onNewOrganization", "onUpdateOrganization", "organizationForUpdate"])
  ], 64))
}