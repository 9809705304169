
import { Organization } from '@/models';
import { defineComponent } from 'vue';
import { listItems } from '@/utils';
import * as queries from '@/graphql/queries';
import CreateOrganizationDialog from '@/components/Organization/OrganizationDialog.vue';

export default defineComponent({
  name: 'Organizations',
  components: {
    CreateOrganizationDialog,
  },
  data() {
    return {
      organizations: [] as Organization[],
      loading: true,
      organizationForUpdate: null as unknown as Organization,
      filters: {
        organizationName: { value: '', matchMode: 'contains' },
      },
    };
  },
  mounted() {
    this.loadAllOrganizations();
  },
  methods: {
    async loadAllOrganizations(): Promise<void> {
      this.loading = true;
      try {
        const allOrganizations: Organization[] = await listItems(queries.listOrganizations);
        this.organizations = allOrganizations;
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    addNewOrganization(organization: Organization) {
      try {
        if (organization) this.organizations.unshift(organization);
      } catch (error) {
        console.error(error);
      }
    },
    updateOrganization(organization: Organization) {
      try {
        if (organization) {
          const organizationToUpdateIndex: number | null | undefined = this.organizations.findIndex((org) => org.id === organization.id);
          if (organizationToUpdateIndex !== -1) {
            let newOrganizationObject = this.organizations[organizationToUpdateIndex];
            newOrganizationObject = organization;
            this.organizations[organizationToUpdateIndex] = newOrganizationObject;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    organizationActionClick(organization: Organization, event: MouseEvent) {
      (this.$refs[`study-menu-${organization.id}`] as any).toggle(event);
    },
    organizationActionItems(organization: Organization) {
      const update = {
        label: 'Update',
        command: () => {
          console.log('organization for update :>> ', organization);
          this.organizationForUpdate = organization as Organization;
          this.showCreateOrganizationDialog();
        },
      };
      return [update];
    },
    showCreateOrganizationDialog() {
      this.$store.dispatch('setShowingCreateOrganizationDialog', true);
    },
  },
});
